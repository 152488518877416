import React, { Component } from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import LANGUAGE from '../../../service/Language-data';
import UploadWallNoCrop from '../../../service/components/UploadWallNoCrop';
import { IMAGE, VIDEO } from '../../../service/media-type';
//import EmbeddedEditor from '../EmbeddedEditor';
import { getAllVideoCategories, getVideoById, updateVideoById, getVideoCategoriesById } from '../../../service/axios-service';
import MultiSelectTree from '../../../service/components/MultiSelectTree_antd';

class UpdateVideo extends Component{
    state={
        categories:[],
        category_id: 1,
        title: '',
        desc:'',
        images: [],
        videos: [],
        draft_text:"",
        category: [],
    }

    static propTypes={
        langState: PropTypes.string.isRequired,
        videoId: PropTypes.number.isRequired,
        logInName: PropTypes.string.isRequired
    }

    handleChange=(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleURLImages=(images)=>{
        this.setState({ images });        
    }

    handleURLVideos=(videos)=>{
        this.setState({ videos });        
    }

    
    //草稿功能
save_draft=()=>{
    console.log("开始保存草稿");
    const { category_id, title, desc, images, videos,category} =this.state;
    const data={ category_id, title, desc, images, videos,category };

    //save to local storage
    localStorage.setItem("edit_video_draft",JSON.stringify(data));
}

setDataFromDraft=(e)=>{
    var draft=localStorage.getItem("edit_video_draft");
    var draft_data = JSON.parse(draft);
    console.log("load from draft,="+ draft_data.title);
    this.setState({
        title: draft_data.title,
        category_id:draft_data.category_id,
        images:draft_data.images,
        desc:draft_data.desc,
        videos:draft_data.videos,
        draft_text:"",
        category:draft_data.category
    });
   // e.preventDefault();
}
startTimer=()=>{
    //check draft existing
    var draft=localStorage.getItem("edit_video_draft");
    if(draft!=null){
        var draft_data = JSON.parse(draft);
        if(draft_data.title.length>0 || draft_data.desc.length>0){
            this.setState({
                draft_text:"你有没完成的草稿，继续编辑?"
                //continue edit, so need to set data
            });
        }
    }
    //启动定时器，1分钟保存草稿一次
    this.timer = setInterval(() => this.save_draft(), 30000)
    console.log("start")
  }
  stopTimer=()=>{
    clearInterval(this.timer);
    console.log("stop");
    
  }
  clearDraft=()=>{
    localStorage.removeItem("edit_video_draft");
  }
  resetTimer=()=>{
    //this.setState({time: 0})
    console.log("reset")
  }
  componentWillUnmount(){
    //stop timer
    this.stopTimer();
  }
  getCategoryValue=(value)=>{
    const category = value.filter(one=> +one>0)
    this.setState({ category });
}
    handleSubmit=(e)=>{
        const username = this.props.logInName;
        const { videoId } = this.props;
        const { category_id, title, desc, images, videos,category} =this.state;

        const data={ username, category_id, title, desc, images, videos,category };
        updateVideoById(videoId, data)
            .then(res => {
                this.stopTimer();
                this.clearDraft();
                alert("One video was updated!");
                this.setState({
                    category_id: 1,
                    title: '',
                    desc:'',
                    images: [],
                    videos: [],
                    category:[]
                }); 
                this.startTimer();
            })
            .catch(err=>{ 
                console.log("[Error] - At UpdateVideo/handleSubmit/updateVideoById!");
                console.log(err);
            });
        e.preventDefault();
    }

    componentDidMount(){
        const { videoId } = this.props;
        axios.all([
            getAllVideoCategories(), 
            getVideoById(videoId),
            getVideoCategoriesById(videoId)
        ]).then(axios.spread((r1, r2,r3)=>{
            let category = [];
            r3.data.forEach(one=>{
                category.push(one.categories_id);
            })     
            // console.log()         
            this.setState({ 
                categories:     r1.data,
                category_id:    r2.data[0].video_category_id,
                title:          r2.data[0].video_title,
                desc:           r2.data[0].video_description,
                images:         r2.data[0].cover_image_path !== '' ? [{ id: videoId, value: r2.data[0].cover_image_path}]:[],
                videos:         r2.data[0].video_path !== '' ? [{ id: videoId, value: r2.data[0].video_path}]:[],
                category 
            });
            //hide video upload tag
            var upload_video_tag = document.getElementsByClassName("ant-upload ant-upload-select ant-upload-select-text");
            if(upload_video_tag!=null && upload_video_tag!=undefined){
                //upload_video_tag.classList.remove("ant-upload-select-text");
                upload_video_tag[0].classList.add("ant-upload-select-text-hide");
                //console.log("change css,sees doesn't work?");
            }else{
                console.log("没找到?");
            }
            this.startTimer();

        })).catch((err) => { 
            console.log("[Error] - At UpdateVideo/componentDidMount!");
            console.log(err);         
        });
    }

    render(){
        let lang='';
        if(this.props.langState ==='cn') lang=LANGUAGE.UpdateVideo.cn;
        else lang=LANGUAGE.UpdateVideo.en;
        
        const { categories, category_id, title, desc, images, videos,draft_text,category} =this.state;

        return(
            <div className="container-fluid">
                <div className="card rounded">
                    <div className="card-header"> 
                        <div className="form-group col-md-8">
                            <h5>{lang[0]}</h5>
                            <br/>
                            <div className="small-note-text">(*)系统会每隔30秒自动保存草稿</div>                                 
                        </div>
                        <div className="text-right col-md-4">
                                <Link to="/videos" className="btn btn-primary btn-sm rounded" title={lang[1]}><i className="fas fa-arrow-alt-circle-right"></i></Link>
                        </div>
                    </div>
                    <div className="card-body">   
<div className="divDraft">
<a type="button" onClick={this.setDataFromDraft}  title={draft_text}>{draft_text}</a>  
</div>                                      
                        <form onSubmit={this.handleSubmit} encType="multipart/form-data">
                            <div className="form-row">                
                                <div className="form-group col-md-8">
                                    <label htmlFor="title" >{lang[1]}</label>
                                    <input name="title" type="text" className="form-control" id="title" value={title} onChange={this.handleChange} required/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="category">{lang[2]}</label>

                                    <MultiSelectTree
                                                    init={ category }
                                                    data={categories}
                                                    property1={'categories_id'}
                                                    property2={'parent_id'}
                                                    property3={'name'}
                                                    getValue={this.getCategoryValue}
                                                />                                           
                                    {/* <select name="category_id" className="custom-select mr-sm-2" id="category" value={category_id} onChange={this.handleChange}>
                                        {categories.map((one, index) => <option key={index} value={one.categories_id}>{one.name}</option> )}
                                    </select> */}
                                </div>  
                            </div>
                            <div className="form-row row-panel">
                                
                                <div className="form-group col-md-4">
                                    <label htmlFor="question_upload_image">{lang[6]}</label>
                                    <div className="form-group float-right">
                                    <UploadWallNoCrop
                                                    some={1}
                                                    listType="text"
                                                    load={ videos }
                                                    urls={this.handleURLVideos}
                                                    format={ VIDEO.toString() }
                                                    />
                                    </div>
                                    <div className="form-group small-note-text">* 请确认视频文件为mp4或者mov文件格式! <br/>文件不要超过100M!<br/>删除文件请把鼠标移动到文件名上面</div>
                                    {/* <input name="upload" type="button" className="form-control btn btn-outline-secondary btn-sm rounded" id="question_upload_image" value={lang[5]} data-toggle="modal" data-target="#images"/> */}
                                </div>
                                <div className="form-group col-md-1">
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="question_upload_image">{lang[5]}</label>
                                    <div className="form-group float-right">
                                         <UploadWallNoCrop
                                                    some={1}
                                                    listType="picture-card"
                                                    load={ images }
                                                    urls={this.handleURLImages}
                                                    format={ IMAGE.toString() }
                                                />
                                    </div>
                                    <div className="form-group small-note-text">* 封面图片如果不上传，系统会自动在视频中截取一幅图作为封面图片! <br/> 删除及预览图片，请移动鼠标到图片中，然后选择相应的功能!</div>
                                </div>



                      
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label htmlFor="desc">{lang[8]}</label>
                                    <textarea name="desc" className="form-control" id="desc" rows="15" value={desc} onChange={this.handleChange}/>
                                    {/*<EmbeddedEditor 
                                        content={this.state.desc}
                                        getContent={this.getEditorContent}/>*/}
                                </div>
                            </div>
                            <div className="btn-group">
                                <button type="submit" className="btn btn-success btn-sm mr-2 rounded" title={lang[9]}><i className="fas fa-save"></i></button>
                                <Link to="/videos" className="btn btn-primary btn-sm rounded" title={lang[10]}><i className="fas fa-arrow-alt-circle-right"></i></Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );        
    }
}

export default UpdateVideo;