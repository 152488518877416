import React, { Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Link} from 'react-router-dom';

import LANGUAGE from '../../../service/Language-data';
import EmbeddedEditor from '../../../service/components/EmbeddedEditor';
import UploadWallNoCrop from '../../../service/components/UploadWallNoCrop';
import { IMAGE } from '../../../service/media-type';
import { getQuestionById, getAllQuestionCategories, getAllCurrencies, getQuestionAllImagesById, updateQuestionById } from '../../../service/axios-service';

class UpdateQuestion extends Component{
    state={
        categories: [],
        currencies: [],
        title: '',
        category_id: 1,
        payable: 0,
        currency_id: 1,
        question: '',
        images: [],
        deleteImagesIds: []
    };
    
    static propTypes={
        langState: PropTypes.string.isRequired,
        logInName: PropTypes.string.isRequired,
        questionId: PropTypes.number.isRequired
    }

    handleChange=(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleURLImages=(images)=>{
        this.setState({ images });        
    }

    getEditorContent=(content)=>{
        this.setState({ question: content });
    }

    handleSubmit=(e)=>{
        const { title, category_id, payable, currency_id, question, images}=this.state;
        const { questionId } = this.props;
        const username = this.props.username;

        if(question === ''){
            alert(`What is the content of your question? It cannot be empty. Please try again!`);
        }
        else{
            const newImages = [];
            let { deleteImagesIds } = this.state;
            images.forEach(one=>{
                if(one.id === 0){
                    newImages.push(one);
                }
                else if(deleteImagesIds.includes(one.id)){
                        deleteImagesIds=deleteImagesIds.filter(id=>id!==one.id);
                }
            });
            
            let cover_image = '';
            const cover = images.find((one=> one.id !== 0));
            if(cover) cover_image = cover.value;

            const data={ username, title, cover_image, category_id, payable, currency_id, question, newImages, deleteImagesIds};
            updateQuestionById(questionId, data)
                .then(res => {
                    alert("One question was updated!");
                    this.setState({
                        title: '',
                        category_id: 1,
                        payable: 0,
                        currency_id: 1,
                        question: '',
                        images: [],
                        deleteImagesIds: []
                    });
                })
                .catch(err=>{ 
                    console.log("[Error] - At UpdateQuestion/handleSubmit/updateQuestionById!");
                    console.log(err);
                });
        }
        e.preventDefault();
    }

    componentDidMount(){
        const { questionId } = this.props;
        axios.all([
            getQuestionById(questionId), 
            getAllQuestionCategories(), 
            getAllCurrencies(),
            getQuestionAllImagesById(questionId)
        ]).then(axios.spread((r1, r2, r3, r4)=>{
            const deleteImagesIds = [];
            r4.data.forEach(one=>{
                deleteImagesIds.push(one.id);
            })
            this.setState({
                categories: r2.data,
                currencies: r3.data,
                title: r1.data[0].title,
                category_id: r1.data[0].categories_id,
                payable: r1.data[0].payable,
                currency_id: r1.data[0].currency_id,
                question: r1.data[0].questions,
                images: r4.data,
                deleteImagesIds
            });
        })).catch((err)=>{ 
            console.log("[Error] - At UpdatQuestion/componentDidMount!");
            console.log(err);
        });
    }

    render(){
        let lang='';
        if(this.props.langState ==='cn') lang=LANGUAGE.UpdateQuestion.cn;
        else lang=LANGUAGE.UpdateQuestion.en;
        
        const { categories, currencies,title, category_id, payable, currency_id, question, images}=this.state;

        return(
            <div className="container">
                <div className="card rounded">
                    <div className="card-header"> 
                        <h5>{lang[0]}</h5>
                    </div>
                    <div className="card-body">
                        <br/>
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-row">          
                                <div className="form-group col-md-8">
                                    <label htmlFor="title">{lang[1]}</label>
                                    <input name="title" type="text" className="form-control" id="title" value={title}  onChange={this.handleChange} required/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="categories">{lang[2]}</label>
                                    <select name="category_id" className="custom-select mr-sm-2" id="categories" value={category_id} onChange={this.handleChange}>
                                            {categories.map((one, index) => <option key={index} value={one.categories_id}>{one.name}</option> )}
                                    </select>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <label htmlFor="question_upload_image">{lang[3]}</label>
                                    <input name="upload" type="button" className="form-control btn btn-outline-secondary btn-sm rounded" id="question_upload_image" value={lang[4]} data-toggle="modal" data-target="#images"/>
                                </div>
                                    <div className="modal fade" id="images" tabIndex="-1" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="images">{lang[5]}</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <UploadWallNoCrop
                                                    some={8}
                                                    listType="picture-card"
                                                    load={ images }
                                                    urls={this.handleURLImages}
                                                    format={ IMAGE.toString() }
                                                />
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-primary rounded" data-dismiss="modal">{lang[6]}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="reward">{lang[7]}</label>
                                    <input name="payable" type="text" className="form-control" id="reward" value={payable}  onChange={this.handleChange}/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="currency">{lang[8]}</label>
                                    <select name="currency_id" className="custom-select mr-sm-2" id="currency" value={currency_id} onChange={this.handleChange}>
                                            {currencies.map((one, index) => <option key={index} value={one.currencies_id}>{one.name}</option> )}
                                    </select>
                                </div>
                            </div>
                            
                            <div className="form-group">
                                <label>{lang[9]}</label>
                                <EmbeddedEditor 
                                    content={question}
                                    getContent={this.getEditorContent}/>
                            </div>
                            <div className="btn-group">
                                <button type="submit" className="btn btn-success btn-sm mr-2 rounded" title={lang[10]}><i className="fas fa-save"></i></button>
                                <Link to="/questions" className="btn btn-primary btn-sm rounded" title={lang[11]}><i className="fas fa-arrow-alt-circle-right"></i></Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );        
    }
}


export default UpdateQuestion;