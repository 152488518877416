// Positive Decimals or Positive integers or Zero(0)
export const isP0_number = RegExp(/^(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)|[0]{1,1}$)$/);
//const isP0_number = RegExp(/^((([^0][0-9]+|0)\.([0-9]{1,5}))$)|^(([1-9]+)\.([0-9]{1,5})$)/);
//const isP0_number = RegExp(/^(([1-9]+)|([0-9]+\.[0-9]{1,2}))$/);
// Positive Decimals or Positive integers
export const isP_number = RegExp(/^(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*))$/);

//Positive Integer or Zero(0)
export const isP0_integer = RegExp(/^([1-9]\d*|[0]{1,1})$/);

// Positive Integer
export const isP_integer = RegExp(/^[0-9]*[1-9][0-9]*$/);

// Email
export const isEmail = (val)=>{
    var pattern = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
    var domains= [
        "qq.com","163.com","vip.163.com","263.net","yeah.net","sohu.com","sina.cn","sina.com","eyou.com","gmail.com",
        "hotmail.com", "42du.cn", "yahoo.com", "msn.com", "aol.com", "ask.com", "live.com", "0355.net", "163.net", 
        "3721.net", "googlemail.com", "mail.com", "aim.com", "walla.com", "inbox.com","126.com", "21cn.com", "tom.com",
        "etang.com", "56.com", "x.cn", "chinaren.com", "sogou.com", "citiz.com", "hongkong.com", "ctimail.com", 
        "hknet.com", "netvigator.com", "mail.hk.com", "swe.com.hk", "ITCCOLP.COM.HK", "BIZNETVIGATOR.COM", "SEED.NET.TW", 
        "TOPMARKEPLG.COM.TW", "PCHOME.COM.TW"
    ];
    if(pattern.test(val)) {
        var domain = val.substring(val.indexOf("@")+1);
        for(var i = 0; i< domains.length; i++) {
            if(domain === domains[i]) {
                return true;
            }
        }
    }
    return false
}

