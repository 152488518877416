import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import LANGUAGE from '../../../service/Language-data';
import UploadWallNoCrop from '../../../service/components/UploadWallNoCrop';
import { IMAGE } from '../../../service/media-type';
import { getTopicById, insertThread } from '../../../service/axios-service';

class AddThread extends Component{
    state={
        topic: '',
        contents: '',
        images: []
    }

    static propTypes={
        langState: PropTypes.string.isRequired,
        logInName: PropTypes.string.isRequired,
        topicId: PropTypes.number.isRequired
    };

    handleChange=(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleURLImages=(images)=>{
        this.setState({ images });        
    }

    handleClickSave=(e)=>{
        const { contents, images } = this.state;
        const username = this.props.logInName;
        const { topicId } = this.props;
        if(contents === ''){
            alert(`What is the content that you want to post? It cannot be empty. Please try again!`);
        }
        else{
            const data={ topicId, username, contents, images };
            insertThread(data)
                .then(res=>{
                    alert(`A post was created!`);
                    this.setState({
                        topic: '',
                        contents: '',
                        images: []
                    })
                })
                .catch((err) => { 
                    console.log("[Error] - At AddThread/handleClickSave/insertTopic!");
                    console.log(err);            
            });
            e.preventDefault();
        }
    }

    componentDidMount(){
        const { topicId } = this.props;
        getTopicById(topicId)
            .then((res) => {
                this.setState({ topic: res.data[0].contents });
            })
            .catch((err) => { 
                console.log("[Error] - At AddThread/componentDidMount!");
                console.log(err);           
            });
    }

    render(){
        let lang='';
        if(this.props.langState ==='cn') lang=LANGUAGE.AddThread.cn;
        else lang=LANGUAGE.AddThread.en;

        const { topic, contents, images } = this.state;
        return(
            <div className="container-fluid">
                <div className="card rounded">
                    <div className="card-header">
                        <span style={{fontSize: "1.4em"}}><i className="fas fa-pencil-alt"></i>&nbsp;{lang[0]}</span>
                        <div className="float-right">
                            <div className=" btn-toolbar " >
                                <div className="btn-group-sm mr-2" role="group" aria-label="First group">
                                    <button type="button" className="btn btn-success" onClick={this.handleClickSave} title={lang[1]}><i className="fas fa-save"></i></button>
                                </div>
                                <Link to='/topics/threads'>
                                    <div className="btn-group-sm mr-2" role="group" aria-label="Second group">
                                        <button type="button" className="btn btn-primary" title={lang[2]}><i className="fas fa-arrow-alt-circle-right"></i></button>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-body" style={{fontWeight: 'bold'}}>
                        <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="topic" className="col-form-label">{lang[3]}</label>
                            </div>
                            <div className="col col-sm-10" id="tags">
                                <textarea name="topic" className="form-control" id="topic" rows={10} value={topic} disabled/>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="content" className="col-form-label">{lang[4]}</label>
                            </div>
                            <div className="col col-sm-10">
                                <textarea name="contents" className="form-control" id="content" rows={10} value={contents} onChange={this.handleChange}/>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="images" className="col-form-label">{lang[5]}</label>
                            </div>
                            <div className="col col-sm-10 input-group-sm" id="images">
                                <UploadWallNoCrop
                                    some={8}
                                    listType="picture-card"
                                    load={ images }
                                    urls={this.handleURLImages}
                                    format={ IMAGE.toString() }
                                />                                
                            </div>
                        </div>
                    </div>
                </div>            
            </div>
        );
    }
}

export default AddThread;