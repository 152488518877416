import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Link} from 'react-router-dom';
import LANGUAGE from '../../../service/Language-data';
import apiRoot from '../../../config.api';

import OrdersTable from './OrdersTable';
import SearchBar from '../../../service/components/SearchBar';
import PagingBar from '../../../service/components/PagingBar';

import { addShippingTracking,getOrderBySearch } from '../../../service/axios-service';
import { toCurrency,formatOrderId,formatDateTime } from '../../../service/func-service';

var url  = require('url');

class OrdersManagement extends Component{
    state={
            subjectList:[],
            shipping_providers:[],
            searchContent:'',     // Accept the value of "change"; Used for search after the button is clicked.
            searchChange:'',      // Accept the value changed in "input".
            currentPage: 1,
            currentId: '',
            currentTitle: '',
            currentTotal_answers: '',
            shipping_providers_id:1,
            tracking_id:'',
            shipped_date:'',
            search_by_key:'',
            search_by_value:''
        };
    
    static propTypes = {
        langState: PropTypes.string.isRequired
    };
    

    handleProviderChange=(e)=>{
       // const { shipping_providers_id} = this.state;  
        this.setState({
            shipping_providers_id : e.target.value,
            [e.target.name]: e.target.value
            
        });
    }
    handleTrackingIdChange=(e)=>{
        //const { tracking_id} = this.state;  
        this.setState({
            tracking_id : e.target.value,
            [e.target.name]: e.target.value
            
        });
    }
    handleShippedDateChange=(e)=>{
        //const { shipped_date} = this.state;  
        this.setState({
            shipped_date : e.target.value,
            [e.target.name]: e.target.value
            
        });
    }
    handleSearchContent=(e)=>{
        
        this.setState({
            searchChange: e.target.value
        });
    };
    
    handleSearchSubmit=(e)=>{
        this.setState({
            searchContent: this.state.searchChange,
            currentPage: 1,        
        });
        e.preventDefault();
    }

    handlePageChange=(e)=>{
        this.setState({
            currentPage: +e.target.value
        });
    }

    handleClickDelete=(id, name)=>{
        if(window.confirm(`Do you really want to delete ${name}?`)){
            axios.put(apiRoot + "products/delete", {id:id})
                .then(res => {
                    alert(`You have deleted ${name}.`);
                    axios.get(apiRoot + "products")
                    .then((res) => {                   
                        this.setState({ subjectList: res.data });  
                    })
                    .catch((err) => {
                        console.log("[Error] - GET /products - at OrdersManagement component!");
                        console.log(err);           
                    });
                })
                .catch(err=>{
                    console.log("[Error] - PUT /products/delete - at OrdersManagement component!");
                    console.log(err);
            });
        }
    }

    handleClickOrderDetails=(id)=>{
        this.props.clickDetail(id);     
        
        console.log("444444");
    }
    displayCurrency=(id)=>{
        if(parseInt(id)===1) return "￥";
        if(parseInt(id)===2) return "C$";
        if(parseInt(id)===3) return "$";
    }
    displayPaymentMethod=(id)=>{
       // console.log('displayPaymentMethod==,id:'+id);
        if(parseInt(id) === 0) return "信用卡";
        if(parseInt(id) ===1) return "我的钱包";
        if(parseInt(id) ===2) return "支付宝";
        if(parseInt(id) ===3) return "微信支付";
    }
    displaySource=(id)=>{
        if(id ===1) return "App";
        if(id ===2) return "网站";
    }    
    handleClickSaveTracking=(id)=>{
        
        //const { shipping_providers_id, tracking_id} = this.state;  

        //

        const { shipping_providers_id, tracking_id, shipped_date } = this.state;
        console.log("shipping_tracking id="+id +" 2="+ shipping_providers_id+" 3="+tracking_id +" 4="+shipped_date);     
        var orders_id = id;
        var user_id = localStorage.getItem("logInId");
        const data={ orders_id, shipping_providers_id, tracking_id, shipped_date,user_id };
        addShippingTracking(data)
            .then(res => {
                alert("Order shipping tracking was added!");
                // this.setState({
                //     title: '',
                //     category_id: 1,
                //     images:[],
                //     content: '',
                //     feature_img_tag:''

                // });
            })
            .catch(err=>{ 
                console.log("[Error] - At AddDoc/handleSubmit/insertDoc!");
                console.log(err);
                alert("Sorry！ The system is very busy now. The data cannot be saved. Please try it later.");
            });
       // e.preventDefault();
    }    


    

    componentDidMount(){
        axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");

        axios.get(apiRoot + "shipping_providers")
            .then((res) => { 
                this.setState({ shipping_providers: res.data });  
            })
            .catch((err) => {
                console.log("[Error] - GET /orders - at OrdersManagement component!");
                console.log(err);         
        });

        var urlobj = window.location.toString();
       
        var q = url.parse(urlobj,true).query;
        console.log(" ### =" + urlobj + JSON.stringify(q));
        var key=  q.key;
        var value = q.value;
        var date_range = q.date_range;

        console.log("111="+ key +" 222=" +  value);
        if(key !== undefined && value !=undefined ){
            this.setState({
                search_by_key:key,
                search_by_value:value
            });

            var data ={
                key,
                value,
                date_range
            };
            getOrderBySearch(data)
            .then((res) => { 
                this.setState({ subjectList: res.data });  
            })
            .catch((err) => {
                console.log("[Error] - GET /orders - at OrdersManagement component!");
                console.log(err);         
            });       
        }else{
            axios.get(apiRoot + "orders")
            .then((res) => { 
                this.setState({ subjectList: res.data });  
            })
            .catch((err) => {
                console.log("[Error] - GET /orders - at OrdersManagement component!");
                console.log(err);         
        });            
        }
                
    }

    render(){
        let lang='';
        if(this.props.langState ==='cn') lang=LANGUAGE.OrdersManagement.cn;
        else lang=LANGUAGE.OrdersManagement.en;

        const {subjectList, searchContent,shipping_providers, currentPage,shipping_providers_id,tracking_id,shipped_date} = this.state;
        const { permissions } = this.props;

        const row=[];
        subjectList.forEach((one, index)=>{            
            if( (one.orders_uuid!=null && one.orders_uuid.indexOf(searchContent)===-1)){
                return;
            }
            //console.log(JSON.stringify(one));
            var modalKeyTarget = "#Orders" + one.orders_id;
            var modalKey =  "Orders" + one.orders_id;
            var dTracking ="shipping_providers_"+ one.orders_id;
            var valTrackingId="tracking_id"+one.orders_id;
            var valShippedDate = "shipped_date"+one.orders_id;
            row.push(
                <tr key={index} >
                    {/* <td style={{verticalAlign: 'middle'}} className='text-center'>
                        {(()=>{
                                switch(one.products_image.slice(0,4)){
                                    case '':        return (<span>{'No Image'}</span>);
                                    case 'http':    return (<img src={one.products_image} style={{height: '35px'}} alt={index}/>);
                                    default:        return (<a href={apiRoot + 'display/image/file?file=' + one.products_image}>
                                                                <img src={apiRoot + 'display/image/file?file=' + one.products_image} style={{height: '50px'}} alt={index}/>
                                                            </a>)
                                }
                            })()}
                    </td> */}
                    <td style={{verticalAlign: 'middle'}} className='text-center'>{ formatOrderId(one.orders_id) }</td>
                    <td style={{verticalAlign: 'middle'}} className='text-center'>{ formatDateTime(one.date_purchased)}</td>
                    <td style={{verticalAlign: 'middle'}} className='text-center'>{one.orders_status_name}</td>
                    <td style={{verticalAlign: 'middle'}} className='text-center'>{one.delivery_name}</td>
                    <td style={{verticalAlign: 'middle'}} className='text-center'>{one.delivery_street_address},{one.delivery_city},{one.delivery_state},{one.delivery_postcode}</td>
                    <td style={{verticalAlign: 'middle'}} className='text-center'>{ this.displayPaymentMethod(one.payment_method)}</td>
                    <td style={{verticalAlign: 'middle'}} className='text-center'>{ toCurrency(one.total_amount,one.currency)}</td>
                    <td style={{verticalAlign: 'middle'}} className='text-center'>{ this.displaySource(one.order_from)}</td>
                    <td style={{verticalAlign: 'middle'}} className='text-center'>{ one.username }</td>
                    
                    <td style={{verticalAlign: 'middle'}}>
                            <div className="btn-group">
                            {
                                one.orders_status === 2? 
                                <Link to="/products/stockOut"  className="btn btn-success btn-sm mr-2 rounded"
                                 onClick={(e)=>{this.handleClickOrderDetails(one.orders_id);}} 
                                 >{lang[14]}</Link>:null
                            }
                            {
                                permissions[8][4].value?
                                <Link to="/products/order_details"  className="btn btn-success btn-sm rounded"
                                 onClick={(e)=>{this.handleClickOrderDetails(one.orders_id);}} 
                                 title={lang[13]}><i className="fas fa-info-circle"></i></Link>:null
                            }   
                            </div>
                        
                            <div className="modal fade" id={modalKey} tabIndex="-1" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="images">{lang[7]}</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">


                                     <div className="form-group col-md-12">
                                    <label htmlFor="currency">{lang[10]}</label>
                                    <select name={dTracking} className="custom-select mr-sm-2" id={dTracking} value={shipping_providers_id} onChange={this.handleProviderChange}>
                                            {shipping_providers.map((one, index) => <option key={index} value={one.id}>{one.provider_name}</option> )}
                                    </select>
                                </div>
                                <div className="form-group col-md-12">
                                    <label htmlFor="tracking_id">{lang[11]}</label>
                                    <input name={valTrackingId} type="text" className="form-control" id={valTrackingId} value={tracking_id}  
                                    onChange={this.handleTrackingIdChange}/>
                                </div>
                                <div className="form-group col-md-12">
                                    <label htmlFor="shipped_date">{lang[10]} 格式:YYYY-MM-DD</label>
                                    <input name={valShippedDate} type="date" className="form-control" id={valShippedDate} 
                                    value={shipped_date}  onChange={this.handleShippedDateChange} title="Format:2018-08-18"/>
                                </div>                                
                                {/* <div className="btn-group">
                                <button type="submit" className="btn btn-success btn-sm mr-2 rounded" title={lang[9]}><i className="fas fa-save"></i></button>
                                <Link to="/questions" className="btn btn-primary btn-sm rounded" title={lang[12]}><i className="fas fa-arrow-alt-circle-right"></i></Link>
                            </div>                                 */}
                                            </div>
                                            <div className="modal-footer">

                                             
                                <button type="submit" className="btn btn-success btn-primary rounded" data-dismiss="modal"
                                onClick={()=>this.handleClickSaveTracking(one.orders_id)}
                                title={lang[9]}><i className="fas fa-save"></i>&nbsp;&nbsp;{lang[9]} </button>
                            
                                <button type="button" className="btn btn-primary rounded" data-dismiss="modal">{lang[12]}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>  

                    </td>   
                    {/* <td style={{verticalAlign: 'middle'}} className='text-center'>{one.products_quantity}</td>
                    {one.products_status === 1? 
                        <td style={{verticalAlign: 'middle'}} className='text-center' >Enabled</td>:
                        <td style={{verticalAlign: 'middle', color:'red'}} className='text-center'>Disabled</td>
                    }
                    {
                        permissions[8][3].value || permissions[8][4].value?
                        <td style={{verticalAlign: 'middle'}}                                                                >
                            <div className="btn-group">
                            {
                                permissions[8][3].value?
                                <Link to="/products/update" className="btn btn-success btn-sm mr-2 rounded" onClick={()=>this.handleClickUpdate(one.products_id)} title={lang[2]}><i className="fas fa-pencil-alt"></i></Link>:null
                            }
                            {
                                permissions[8][4].value?
                                <button type="button" className="btn btn-danger btn-sm rounded" onClick={(e)=>{this.handleClickDelete(one.products_id, one.products_name); e.preventDefault();}} title={lang[3]}><i className="fas fa-trash-alt"></i></button>:null
                            }   
                            </div>
                        </td>:null
                    } */}
                </tr>
            );
        });

        const totalPages=Math.ceil(row.length/10);
        const onePage=row.reverse().slice((currentPage*10-10), (currentPage*10));
        return(
            <div className="container-fluid">
                <div className="card rounded">
                    <div className="card-header">
                        <h5>{lang[0]}</h5>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <div className="float-left">
                                    <SearchBar 
                                        langState={this.props.langState}
                                        searchContent={this.handleSearchContent}
                                        searchSubmit={this.handleSearchSubmit} />
                                </div>
                            </div>
                            {/* {
                                permissions[8][2].value?
                                <div className="col">
                                    <div className="float-right">
                                        <Link to='/products/add' className="btn btn-primary btn-sm float-right rounded" title={lang[1]}><i className="fas fa-plus-square"></i></Link> 
                                    </div>
                                </div>:null
                            } */}
                        </div>           
                        <OrdersTable
                            langState={this.props.langState}
                            onePage={onePage}
                            permissions={this.props.permissions}/>
                        <PagingBar 
                            totalPages={totalPages}                     
                            currentPage={this.state.currentPage}
                            pageChange={this.handlePageChange}/>

                          
                    </div>
                </div>                
            </div>
        );
    }
}

export default OrdersManagement;