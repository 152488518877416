import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Link} from 'react-router-dom';
import apiRoot from '../../../config.api';


import LANGUAGE from '../../../service/Language-data';
import EmbeddedEditor from '../../../service/components/EmbeddedEditor';
import { isEmail } from '../../../service/validation';
import UploadWallNoCrop from '../../../service/components/UploadWallNoCrop';
import { IMAGE } from '../../../service/media-type';
import { 
    getUsersMarriageStatus, 
    getUsersRoles, 
    getAllDictionaryItems,
    getAllUsers,
    insertUser } from '../../../service/axios-service';

class AddUser extends Component{
    state={
        constellation_array: [],
        marStatus: [],     // Store attribute groups
        roles: [],
        username: '',
        email: '',
        mobile: '',
        birthday: '',
        constellation: '',
        marital_id: 3,
        role_id: 2,
        desc: '',
        password: '',
        confirm: '',
        images: [],
        allFirstLevel: [],
        allSecondLevel: [],
        checkedArr1: [],
        checkedArr2: [], 
        groupPermissions: [],
        permissions:[],
        groupPermissionRows:[]          
    }

    static propTypes={
        langState: PropTypes.string.isRequired
    };

    handleChange=(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleRoleChange=(e)=>{
        const { groupPermissions,checkedArr1, checkedArr2,permissions,groupPermissionRows   } = this.state;

        console.log(" dddddd =" + e.target.value);
        const groupPermissions2 = groupPermissionRows.filter(one=> parseInt( one.user_group_id) ===  parseInt( e.target.value));
        const groupPermissionsIds = [];
        groupPermissions2.forEach(one=>{
            groupPermissionsIds.push(one.permissions_id);
        });
        permissions.forEach(one=>{
            groupPermissionsIds.includes(one.id)? one.value=true : one.value=false
        })
        const checkedArr22 = permissions.filter(one=>+one.pid !== 0);
        const checkedArr12 = permissions.filter(one=>+one.pid === 0);


        this.setState({
            [e.target.name]: e.target.value,
            checkedArr1:checkedArr12,
            checkedArr2:checkedArr22,
            groupPermissions:groupPermissions2
        });
    }

    handleCheckedSecondLevel=(e,id)=>{
        let { checkedArr1, checkedArr2, checkedAll } = this.state;
        let currentOne = checkedArr2.find(one=>one.id === id);
        currentOne.value = e.target.checked;

        let forCheckedAllSecond = checkedArr2.filter(one=>one.pid === currentOne.pid).filter(one=> one.value);
        if(forCheckedAllSecond.length === 0) checkedArr1.find(one=> one.id === currentOne.pid).value = false;
        else checkedArr1.find(one=> one.id === currentOne.pid).value = true;
        
        let forCheckedAllFirst = checkedArr1.find(one=>!one.value);
        if(forCheckedAllFirst) checkedAll=false;

        this.setState({ checkedArr1, checkedArr2, checkedAll }); 
    }

    handleCheckedFirstLevel=(e,id)=>{
        let { checkedArr1, checkedArr2, checkedAll } = this.state;        
        checkedArr2.filter(one=>one.pid === id).forEach( one=>{one.value = e.target.checked });
        checkedArr1.find(one=>one.id === id).value = e.target.checked;

        let forCheckedAllFirst = checkedArr1.find(one=>!one.value);
        if(forCheckedAllFirst) checkedAll=false;

        this.setState({ checkedArr1, checkedArr2, checkedAll }); 
    }

    handleCheckedAll=(e)=>{
        const { checkedArr1, checkedArr2 } = this.state;
        checkedArr1.forEach( one=>{one.value = e.target.checked });
        checkedArr2.forEach( one=>{one.value = e.target.checked });

        this.setState({ checkedArr1, checkedArr2, checkedAll: e.target.checked });
    }


    handleURLImages=(images)=>{
        this.setState({ images });        
    }

    getEditorContent=(content)=>{
        this.setState({ desc: content });
    }

    handleClickSave=(e)=>{
        const { username, email, mobile, birthday, constellation, marital_id, role_id, desc, password, confirm, images, checkedArr1, checkedArr2 } = this.state;
        
        //let { name, subjectList } = this.state;
        const checkedArr = checkedArr1.concat(checkedArr2);
        //console.log("sssss=" + JSON.stringify( checkedArr));


        getAllUsers()
            .then((res) => {
                const haveOneUser = res.data.find(one=> one.username === username);
                const haveOneEmail = res.data.find(one=> one.email === email);
                const haveOneMobile = res.data.find(one=> one.mobile === mobile);
                if(password === ''){
                    alert(`You must enter a password. Try again, please.`);
                }
                else if(password !== confirm){
                    alert(`The password didn't match. Try again, please.`);
                }
                else if(username === ''){
                    alert(`You must enter a username. Try again, please.`);
                }
                else if(email === ''){
                    alert(`You must enter an email address. Try again, please.`);
                }
                else if(mobile === ''){
                    alert(`You must enter an mobile number. Try again, please.`);
                }
                else if(haveOneUser){
                    alert(`The username has been used. Try again, please.`);
                }
                else if(haveOneEmail){
                    alert(`The email address has been used. Try again, please.`);
                }
                else if(!isEmail(email)){
                    alert(`The email you input is incorrect. Try again, please.`);
                }
                else if(haveOneMobile){
                    alert(`The mobile number has been used. Try again, please.`);
                }
                else{
                    const data={username, email, mobile, birthday, constellation, marital_id, role_id, desc, password, images,checkedArr};                    
                    insertUser(data)
                        .then(res => {
                            alert("A user was added!");
                            this.setState({
                                username: '',
                                email: '',
                                mobile: '',
                                birthday: '',
                                constellation: '',
                                marital_id: 3,
                                role_id: 2,
                                desc: '',
                                password: '',
                                confirm: '',
                                images: []   
                            });
                        })
                        .catch(err=>{ 
                            console.log("[Error] - At AddUser/handleClickSave/insertUser!");
                            console.log(err);
                        });
                }
        }).catch((err) => {  
            console.log("[Error] - At AddUser/handleClickSave/getAllUsers!");
            console.log(err);        
        });
        e.preventDefault();
    }

    componentDidMount(){
        axios.all([
            getUsersMarriageStatus(), 
            getUsersRoles(), 
            getAllDictionaryItems()
        ]).then(axios.spread((r1,r2,r3)=>{
            const constellation_array = r3.data.filter(one=>one.dict_key === '星座')
            this.setState({ 
                marStatus: r1.data,
                roles: r2.data,
                constellation_array,
                constellation: constellation_array[0].id
            });
        })).catch((err) => { 
            console.log("[Error] - At AddUser/componentDidMount!");
            console.log(err);            
        });


        const {  role_id,permissions,groupPermissionRows } = this.state;
        axios.get(apiRoot + "users/permissions")
            .then((res) => {
                const allFirstLevel = res.data.filter(one=>+one.parent_id === 0);
                const allSecondLevel = res.data.filter(one=>+one.parent_id !== 0);

                let checkedArr=[];
                res.data.forEach(one=>{
                    checkedArr.push( {id: one.id, pid: one.parent_id, value: false} );
                    permissions.push( {id: one.id, pid: one.parent_id, value: false} );
                });

                axios.get(apiRoot + "users/groups_permissions")
                    .then((res) => {
                        console.log(" role id =" + role_id);
                        // groupPermissionRows = res.data;
                        res.data.forEach(one=>{
                            groupPermissionRows.push(one);
                        });

                      

                        const groupPermissions = res.data.filter(one=> one.user_group_id === role_id);
                        const groupPermissionsIds = [];
                        groupPermissions.forEach(one=>{
                            groupPermissionsIds.push(one.permissions_id);
                            
                        });
                        checkedArr.forEach(one=>{
                            groupPermissionsIds.includes(one.id)? one.value=true : one.value=false
                        })
                        const checkedArr2 = checkedArr.filter(one=>+one.pid !== 0);
                        const checkedArr1 = checkedArr.filter(one=>+one.pid === 0);
                        this.setState({ 
                            checkedArr2, 
                            allSecondLevel,
                            checkedArr1,
                            allFirstLevel,
                            groupPermissions,
                            permissions,
                            groupPermissionRows
                        });
                    })
                    .catch((err) => {
                        console.log("Getting groups permissions error in UpdateRole component!");
                });
            })
            .catch((err) => {            
                console.log("Getting permissions error in UpdateRole component!");   
            });
    }

    render(){
        let lang='';
        if(this.props.langState ==='cn') lang=LANGUAGE.AddUser.cn;
        else lang=LANGUAGE.AddUser.en;

        const { constellation_array, marStatus, roles, username, email, mobile, birthday, constellation, password, confirm, marital_id, role_id, images,
            allFirstLevel, allSecondLevel, checkedArr1, checkedArr2,
        } = this.state;

        const row=[];
        let count = 0;
        allFirstLevel.forEach((one1, index1)=>{
            row.push( 
                    <tr key={count++} className="table-active">
                        <th className="text-center">{one1.id}</th>
                        <th>{one1.permission_name}</th>
                        <td className="text-center"><input type="checkbox" name="checkbox" checked={checkedArr1.find(one=> one.id === one1.id).value} onChange={(e)=>this.handleCheckedFirstLevel(e, one1.id)}/></td>        
                    </tr>
            );
            allSecondLevel.filter((one2, index2)=> one2.parent_id === one1.id ).forEach((one3, index3)=>{
                // if( (one3.permission_name.toUpperCase().indexOf(searchContent.toUpperCase())===-1)){
                //     return;
                // }
                row.push(
                    <tr key={count++} >
                        <th className="text-center">{one3.id}</th>
                        <td>{one3.permission_name}</td>
                        <td className="text-center"><input type="checkbox" name="checkbox" checked={checkedArr2.find(one=> one.id === one3.id).value} onChange={(e)=>this.handleCheckedSecondLevel(e, one3.id)}/></td>        
                    </tr>
                );
            })
        });

        return(
            <div className="container-fluid">
                <div className="card rounded">
                    <div className="card-header">
                        <span style={{fontSize: "1.4em"}}><i className="fas fa-pencil-alt"></i>&nbsp;{lang[0]}</span>
                        <div className="float-right">
                            <div className=" btn-toolbar " >
                                <div className="btn-group-sm mr-2" role="group" aria-label="First group">
                                    <button type="button" className="btn btn-success" onClick={this.handleClickSave} title={lang[1]}><i className="fas fa-save"></i></button>
                                </div>
                                <Link to='/users'>
                                    <div className="btn-group-sm mr-2" role="group" aria-label="Second group">
                                        <button type="button" className="btn btn-primary" title={lang[2]}><i className="fas fa-arrow-alt-circle-right"></i></button>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-body" style={{fontWeight: 'bold'}}>


                    <ul className="nav nav-tabs" id="myTab" role="tablist">
  <li className="nav-item">
    <a className="nav-link active" id="user-info-tab" data-toggle="tab" href="#user-info" role="tab" aria-controls="home"
      aria-selected="true">{lang[18]}</a>
  </li>
  <li className="nav-item">
    <a className="nav-link" id="permission-tab" data-toggle="tab" href="#permissions" role="tab" aria-controls="images"
      aria-selected="false">{lang[17]}</a>
  </li>
</ul>

<div className="tab-content" id="myTabContent">
<div className="tab-pane fade show active" id="user-info" role="tabpanel" aria-labelledby="user-info-tab">
                        <div className="form-row">
                                {/*Basic Info **********************************************************************/}
                                <div className="form-group col-md-6 mt-2">
                                    <div className="card bg-light rounded">
                                        <div className="card-body">
                                            <div className="mb-2">
                                                <h6 className="card-title mb-2">{lang[3]}</h6>
                                                <hr/>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col col-sm-2 text-right input-group-sm">
                                                    <label htmlFor="username" className="col-form-label">{lang[4]}</label>
                                                </div>
                                                <div className="col col-sm-10 input-group-sm">
                                                    <input name="username" type="text" className="form-control" id="username" value={username} onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col col-sm-2 text-right input-group-sm">
                                                    <label htmlFor="email" className="col-form-label">{lang[5]}</label>
                                                </div>
                                                <div className="col col-sm-10 input-group-sm">
                                                    <input name="email" type="email" className="form-control" id="email"  value={email} onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col col-sm-2 text-right input-group-sm">
                                                    <label htmlFor="mobile" className="col-form-label">{lang[6]}</label>
                                                </div>
                                                <div className="col col-sm-10 input-group-sm">
                                                    <input name="mobile" type="text" className="form-control" id="mobile"  value={mobile} onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col col-sm-2 text-right input-group-sm">
                                                    <label htmlFor="birthday" className="col-form-label">{lang[7]}</label>
                                                </div>
                                                <div className="col col-sm-10 input-group-sm">
                                                    <input name="birthday" type="date" className="form-control" id="birthday"  value={birthday} onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col col-sm-2 text-right input-group-sm">
                                                    <label htmlFor="constellation" className="col-form-label">{lang[8]}</label>
                                                </div>
                                                <div className="col col-sm-10 input-group-sm">
                                                    <select name="constellation" className="form-control custom-select" id="constellation" value={constellation} onChange={this.handleChange}>
                                                        {constellation_array.map((one, index) => <option key={index} value={one.id}>{one.dict_value}</option> )}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col col-sm-2 text-right input-group-sm">
                                                    <label htmlFor="marital_id" className="col-form-label">{lang[9]}</label>
                                                </div>
                                                <div className="col col-sm-10 input-group-sm">
                                                    <select name="marital_id" className="form-control custom-select" id="marital_id" value={marital_id} onChange={this.handleChange}>
                                                        {marStatus.map((one, index) => <option key={index} value={one.id}>{one.status_text}</option> )}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col col-sm-2 text-right input-group-sm">
                                                    <label htmlFor="role_id" className="col-form-label">{lang[10]}</label>
                                                </div>
                                                <div className="col col-sm-10 input-group-sm">
                                                    <select name="role_id" className="form-control custom-select" id="role_id" value={role_id} 
                                                    onChange={this.handleRoleChange}>
                                                        {roles.map((one, index) => <option key={index} value={one.id}>{one.group_name}</option> )}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col col-sm-2 text-right input-group-sm">
                                                    <label className="col-form-label">{lang[11]}</label>
                                                </div>
                                                <div className="col col-sm-10 input-group-sm">
                                                    <EmbeddedEditor 
                                                        content={this.state.desc}
                                                        getContent={this.getEditorContent}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="form-group col-md-6 mt-2">
                                    {/*Password **********************************************************************/}
                                    <div className="card bg-light rounded mb-3">
                                        <div className="card-body">
                                            <div className="mb-2">
                                                <h6 className="card-title mb-2">{lang[12]}</h6>
                                                <hr/>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col col-sm-2 text-right input-group-sm">
                                                    <label htmlFor="password" className="col-form-label">{lang[13]}</label>
                                                </div>
                                                <div className="col col-sm-10 input-group-sm">
                                                    <input name="password" type="password" className="form-control" id="password"  value={password} onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col col-sm-2 text-right input-group-sm">
                                                    <label htmlFor="confirm" className="col-form-label">{lang[14]}</label>
                                                </div>
                                                <div className="col col-sm-10 input-group-sm">
                                                <input name="confirm" type="password" className="form-control" id="confirm"  value={confirm} onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*Profile Image **********************************************************************/}
                                    <div className="card bg-light rounded">
                                        <div className="card-body">
                                            <div className="mb-2">
                                                <h6 className="card-title mb-2">{lang[15]}</h6>
                                                <hr/>
                                            </div>
                                            <div className="row">
                                                <div className="col col-sm-2 text-right input-group-sm">
                                                    <label htmlFor="image" className="col-form-label">{lang[16]}</label>
                                                </div>
                                                <div className="col col-sm-10 input-group-sm">
                                                    <UploadWallNoCrop
                                                        some={1}
                                                        listType="picture-card"
                                                        load={ images }
                                                        urls={this.handleURLImages}
                                                        format={ IMAGE.toString() }
                                                    />    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                        </div>  
</div>

<div className="tab-pane fade" id="permissions" role="tabpanel" aria-labelledby="permissions-tab">

<div className="table-responsive">
                                        <table className="table table-hover table-bordered rounded">
                                            <thead>
                                                <tr className="table-primary">
                                                    <th scope="col" style={{width: "5%"}} className="text-center">#</th>
                                                    <th scope="col" style={{width: "45%"}}>Name</th>
                                                    <th scope="col" style={{width: "5%"}} className="text-center"><input checked={this.state.checkedAll} type="checkbox" onChange={this.handleCheckedAll}/></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {row}
                                            </tbody>
                                        </table>
                                    </div>
</div>    

</div>

                     

                    </div> 
                </div>               
            </div>
        );
    }
}

export default AddUser;