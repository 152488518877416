import React, { Component } from 'react';
import LANGUAGE from '../../../service/Language-data';

import axios from 'axios';
import apiRoot from '../../../config.api';
import {getPermissions} from '../../../service/permission';
class LogIn extends Component{
    state={
        rememberMe: true,
    }   
    handleChange=(e)=>{
        this.props.logInChange(e);    
        localStorage.setItem("current_login_username",e.target.value);    
    }
    handleRememberMeChange=(e)=>{
        //this.props.logInChange(e);    
        //localStorage.setItem("current_login_username",e.target.value);  
        const {rememberMe} = this.state;  
        this.setState({
            rememberMe: !rememberMe
        });
    }
    handleSubmit=(e)=>{        
        const {rememberMe} = this.state;
        console.log(" rememberMe=" + rememberMe);
        if(rememberMe === true){
            const password = document.getElementById("inputPassword").value
            localStorage.setItem("current_login_pwd",password);
            localStorage.setItem("auto_login",1);
            this.props.logInSubmit(e)
        }else{
            localStorage.setItem("current_login_pwd","");
            localStorage.setItem("auto_login",0);        
        }
        //this.props.logInSubmit(e)
    }  
    componentDidMount(){
        const isAutoLogin= localStorage.getItem("auto_login");
        if(isAutoLogin !== null && isAutoLogin !== undefined && isAutoLogin === "1"){
            const logInName = localStorage.getItem("current_login_username");
            const password = localStorage.getItem("current_login_pwd");
            axios.post(apiRoot, {
                username: logInName,
                password
              })
                .then((res) => {
                  if(res.data.result!=="Success"){
                    alert("You input a wrong password. Please try again.")
                  }
                  else{
                    sessionStorage.setItem("authorization", true); 
                    sessionStorage.setItem("logInName", logInName);
                    sessionStorage.setItem("logInId", res.data.id);
                    localStorage.setItem("logInId",res.data.id);
                    localStorage.setItem("happiify_token",res.data.token);//
                    localStorage.setItem("happiify_portrait",res.data.portrait);
                    
                    const roleId = res.data.usergroups_id;// userList.find(one=> one.username === logInName).usergroups_id;
                    axios.defaults.headers.common['x-access-token'] = res.data.token;
                    const data={user_group_id:roleId,
                      user_id:res.data.id};
                    axios.post(apiRoot + "users/admin_permissions",data)
                      .then((res) => {
                        const permissions = res.data;
                        console.log("permissions =" + JSON.stringify(permissions));
                        let permissionsIds = [];
                        permissions.forEach(one=>{
                            permissionsIds.push(one.permissions_id);
                        });
                        sessionStorage.setItem("permissionsIds", permissionsIds);      
                        this.setState({
                          authorization: sessionStorage.getItem("authorization"),     // Note: Here, get the value whose type is string. That is to say that the value is "true".
                          permissionsIds: sessionStorage.getItem("permissionsIds").split(',')
                        }); 
                        //做相应的跳转
                        window.location.reload(true);
                        // const perms =  getPermissions(permissionsIds);

                        // if(perms[0][0].value) this.props.history.replace('/dashboard');
                        // else if(perms[1][0].value) this.props.history.replace('/documents');
                        // else if(perms[2][0].value) this.props.history.replace('/videos');
                        // else if(perms[3][0].value) this.props.history.replace('/events');
                        // else if(perms[4][0].value) this.props.history.replace('/lessons');
                        // else if(perms[5][0].value) this.props.history.replace('/questions');                                
                        // else if(perms[6][0].value) this.props.history.replace('/health/doctors');
                        // else if(perms[7][0].value) this.props.history.replace('/users');
                        // else if(perms[8][0].value) this.props.history.replace('/products');
                        // else if(perms[9][0].value) this.props.history.replace('/location/countries');                        
                      })
                      .catch((err) => {
                        console.log("[Error] - GET /users/groups_permissions - at App component!");
                        console.log(err); 
                    });
                  }
                })
                .catch((err) => {
                  console.log("[Error] - GET / - at App component!");
                  console.log(err);            
              });            
        }
    }
    render(){
        let lang='';
        if(this.props.langState ==='cn') lang=LANGUAGE.LogIn.cn;
        else lang=LANGUAGE.LogIn.en;
        const {rememberMe} = this.state;
 
        return(
            <div className="card rounded">
                <div className="card-header">
                    <h3>{lang[0]}</h3>
                </div>
                <div className="card-body">
                    <div className=" col-md-6 offset-md-3">
                        <div className="card rounded">
                            <div className="card-header">
                                <h5>{lang[1]}</h5>
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-group row">
                                        <label htmlFor="inputUsername" className="col-md-3 col-form-label">{lang[2]}</label>
                                        <div className="col-md-9" >
                                            <input type="text" className="form-control" name="logInName" id="inputUsername" onChange={this.handleChange} required/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="inputPassword" className="col-md-3 col-form-label">{lang[3]}</label>
                                        <div className="col-md-9">
                                            <input type="password" className="form-control" name="password" id="inputPassword" placeholder="Password" 
                                            ref={input=>this.password = input} required/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        
                                        <div className="col-md-12"  >
                                            <input type="checkbox"  name="rememberMe" id="rememberMe" defaultChecked={rememberMe}
                                             onChange={this.handleRememberMeChange} /> {lang[7]}
                                        </div>
                                        {/* <div className="col-md-11">     
                                             <label htmlFor="rememberMe" className="col-form-label"></label>
                                        </div> */}
                                    </div>                                    
                                    <div className="form-group row">
                                        <div className="col-md-12">
                                            <button type="submit" className="btn btn-primary col-md-12 rounded">{lang[4]}</button>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-12">
                                            <a href="/reset_password">{lang[5]}</a>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        );
    }
}

export default LogIn;