import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Link} from 'react-router-dom';
import LANGUAGE from '../../../service/Language-data';
import apiRoot from '../../../config.api';
import dateFormat from 'dateformat';
import { toCurrency,formatOrderId } from '../../../service/func-service';


class OrderDetails extends Component{
    state={
            orders_info:[],
            orders_products:[],
            searchContent:'',     // Accept the value of "change"; Used for search after the button is clicked.
            searchChange:'',      // Accept the value changed in "input".
            currentPage: 1,
            currentId: '',
            currentTitle: '',
            currentTotal_answers: '',
            currencies_id:1
        };
    
    static propTypes = {
        langState: PropTypes.string.isRequired
    };
    

    
    handleSearchSubmit=(e)=>{
        this.setState({
            searchContent: this.state.searchChange,
            currentPage: 1,        
        });
        e.preventDefault();
    }

    handlePageChange=(e)=>{
        this.setState({
            currentPage: +e.target.value
        });
    }

   
    

    componentDidMount(){
        const { orders_id } = this.props;
        console.log("orders id ="+orders_id);
        axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
        axios.get(apiRoot + "orders/"+orders_id)
            .then((res) => { 
                this.setState({ orders_info: res.data[0],
                currencies_id:res.data[0].currency
                });  
            })
            .catch((err) => {
                console.log("[Error] - GET /orders - at OrdersManagement component!");
                console.log(err);         
        });
        axios.get(apiRoot + "orders/details/"+orders_id)
            .then((res) => { 
                this.setState({ orders_products: res.data });  
            })
            .catch((err) => {
                console.log("[Error] - GET /orders - at OrdersManagement component!");
                console.log(err);         
        });

                
    }

    render(){
        let lang='';
        if(this.props.langState ==='cn') lang=LANGUAGE.OrdersDetails.cn;
        else lang=LANGUAGE.OrdersDetails.en;

        const {orders_info, searchContent,orders_products, currentPage,currencies_id} = this.state;
        const { permissions } = this.props;

        const row=[];
        console.log("orders info");
        //console.log(JSON.stringify(orders_info));
        var sub_total=0;
        orders_products.forEach((one, index)=>{            
            sub_total = sub_total +one.products_quantity*one.products_price * one.exchange_rate;

            row.push(
                <tr key={index} >
                    <td style={{verticalAlign: 'middle'}} className='text-center'>
                        {(()=>{
                                switch(one.products_image.slice(0,4)){
                                    case '':        return (<span>{'No Image'}</span>);
                                    case 'http':    return (<img src={one.products_image} style={{height: '35px'}} alt={index}/>);
                                    default:        return (<a href={apiRoot + 'display/image/file?file=' + one.products_image}>
                                                                <img src={apiRoot + 'display/image/file?file=' + one.products_image} style={{height: '50px'}} alt={index}/>
                                                            </a>)
                                }
                            })()}
                    </td>
                    <td style={{verticalAlign: 'middle'}} className='text-center'>{one.products_name} <br/><br/>
                    { one.products_options !==null ? one.products_options + ":" +one.products_options_values + "("+one.price_prefix + one.options_values_price +")" :""}                   
                    </td>
                    <td style={{verticalAlign: 'middle'}} className='text-center'>{one.products_quantity}</td>
                    <td style={{verticalAlign: 'middle'}} className='text-center'>{ toCurrency(one.products_price*one.exchange_rate ,currencies_id)}</td>

                    
                    {/* <td style={{verticalAlign: 'middle'}} className='text-center'>{one.products_quantity}</td>
                    {one.products_status === 1? 
                        <td style={{verticalAlign: 'middle'}} className='text-center' >Enabled</td>:
                        <td style={{verticalAlign: 'middle', color:'red'}} className='text-center'>Disabled</td>
                    }
                    {
                        permissions[8][3].value || permissions[8][4].value?
                        <td style={{verticalAlign: 'middle'}}                                                                >
                            <div className="btn-group">
                            {
                                permissions[8][3].value?
                                <Link to="/products/update" className="btn btn-success btn-sm mr-2 rounded" onClick={()=>this.handleClickUpdate(one.products_id)} title={lang[2]}><i className="fas fa-pencil-alt"></i></Link>:null
                            }
                            {
                                permissions[8][4].value?
                                <button type="button" className="btn btn-danger btn-sm rounded" onClick={(e)=>{this.handleClickDelete(one.products_id, one.products_name); e.preventDefault();}} title={lang[3]}><i className="fas fa-trash-alt"></i></button>:null
                            }   
                            </div>
                        </td>:null
                    } */}
                </tr>
            );
        });

        const totalPages=Math.ceil(row.length/10);
        const onePage=row.reverse().slice((currentPage*10-10), (currentPage*10));
        return(
            <div className="container-fluid">
                <div className="card rounded">
                    <div className="card-header">
                        <h5>{lang[0]}</h5>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            {/* <div className="col">
                                <div className="float-left">
                                    <SearchBar 
                                        langState={this.props.langState}
                                        searchContent={this.handleSearchContent}
                                        searchSubmit={this.handleSearchSubmit} />
                                </div>
                            </div> */}
                            { 
                                <div className="col">
                                    <div className="float-right">
                                        <Link to='/products/invoice' className="btn btn-primary btn-sm float-right rounded" 
                                        title={lang[17]}>{lang[17]} </Link> 
                                    </div>
                                </div>
                            } 
                        </div>    

                        <div className="row">
                            <div className="col-md-6">
                            <p>{lang[4]}</p>
                            <p>
                                {orders_info.delivery_name} <br/>
                                {orders_info.customers_telephone} <br/>
                            {orders_info.delivery_street_address},{orders_info.delivery_city},{orders_info.delivery_state},{orders_info.delivery_postcode}
                            </p>
                            </div>

                            <div className="col-md-6">
                                <p>{lang[5]}</p>
                                <p>
                                {orders_info.billing_name} <br/>
                                {orders_info.customers_telephone} <br/>
                            {orders_info.billing_street_address},{orders_info.billing_city},{orders_info.billing_state},{orders_info.billing_postcode}
                            </p>
                            </div>
                        </div>       
                        <div className="table-responsive rounded">
                <table className="table table-hover table-bordered text-center">
                    <thead>
                        <tr className="table-primary">
                            <th scope="col" style={{width:"20%"}}></th>
                            <th scope="col" style={{width:"20%"}}>{lang[1]}</th>
                            <th scope="col" style={{width:"10%"}}>{lang[2]}</th>
                            <th scope="col" style={{width:"15%"}}>{lang[3]}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {onePage}
                    </tbody>
                </table>                
            </div>

<div className="row">
<div className="col-md-10">
<p> {lang[12]}: { formatOrderId(orders_info.orders_id)}</p>
<p> {lang[6]}: { orders_info.orders_status_name }</p>
<p> {lang[7]}: { dateFormat(orders_info.date_purchased, "yyyy-mm-dd h:MM:ss")}</p>
</div>
<div className="col-md-2">

<p> {lang[8]}: { toCurrency(sub_total,currencies_id) }</p>
<p> {lang[9]}: { toCurrency(orders_info.total_tax,currencies_id) }</p>
<p> {lang[10]}: { toCurrency(orders_info.total_shipping_fee,currencies_id) }</p>
<p> {lang[11]}: { toCurrency(orders_info.total_amount,currencies_id) }</p>

</div>

</div>


                        {/* <PagingBar 
                            totalPages={totalPages}                     
                            currentPage={this.state.currentPage}
                            pageChange={this.handlePageChange}/> */}

                          
                    </div>
                </div>                
            </div>
        );
    }
}

export default OrderDetails;