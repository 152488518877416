import React, { Component } from 'react';
import axios from 'axios';
import LANGUAGE from '../../../service/Language-data';
import { 
    resetPassword, 
} from '../../../service/axios-service';

class ResetPassword extends Component{
    state={
        user_name: '',
    }    

    componentDidMount(){
        var current_user = localStorage.getItem("current_login_username");
        if(current_user!=null){
            this.setState({
                user_name : current_user
            });
        }
   } 


    handleChange=(e)=>{
        //this.props.logInChange(e);        
        this.setState({
            [e.target.name]: e.target.value,
            user_name : e.target.value
        });
    }

    handleSubmit=(e)=>{        
        //this.props.resetPasswordSubmit(e)

        const {  user_name, 
             } = this.state;
        if(user_name==null || user_name==undefined) user_name=localStorage.getItem("current_login_username");
        console.log(" username is ="+ user_name);
        var username = user_name;
        const data={ username}

        resetPassword(data)
                .then(res => {
          console.log(res);   
          if(res.data=="Success!"){
            alert("新密码已发送，请查收！");
            //jump to login
            window.location.href ="/login";
          } else{
            alert("发送失败，请检查用户名是否正确！");
          }           
            // if(one1.length==0){
            //     alert("没有该用户的信息，请检查用户名是否正确！");
            // }else{
            //     var email= one1[0].email;
            //     if(email.indexOf("@")>0){
            //         //This is e-mail, reset via e-mail
            //     }else{
            //         //reset via phone
            //     }
            // }
        }).catch(err=>{ 
            console.log("[Error] - At ResetPassword!");
            console.log(err);
        });
        e.preventDefault();            
    }  
    
    render(){
        let lang='';
        const { user_name
             } = this.state;        

        if(this.props.langState ==='cn') lang=LANGUAGE.LogIn.cn;
        else lang=LANGUAGE.LogIn.en;

        return(
            <div className="card rounded">
                {/* <div className="card-header">
                    <h3>{lang[0]}</h3>
                </div> */}
                <div className="card-body">
                    <div className=" col-md-6 offset-md-3">
                        <div className="card rounded">
                            <div className="card-header">
                                <h5>{lang[6]}</h5>
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-group row">
                                        <label htmlFor="inputUsername" className="col-md-3 col-form-label">{lang[2]}</label>
                                        <div className="col-md-9" >
                                            <input type="text" value={user_name} className="form-control" name="user_name" id="user_name" onChange={this.handleChange} required/>
                                        </div>
                                    </div>
                                    {/* <div className="form-group row">
                                        <label htmlFor="inputPassword" className="col-md-3 col-form-label">{lang[3]}</label>
                                        <div className="col-md-9">
                                            <input type="password" className="form-control" name="password" id="inputPassword" placeholder="Password" ref={input=>this.password = input} required/>
                                        </div>
                                    </div> */}
                                    <div className="form-group row">
                                        <div className="col-md-12">
                                            <button type="submit" className="btn btn-primary col-md-12 rounded">{lang[6]}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        );
    }
}

export default ResetPassword;