import React, {Component} from 'react';
//import PropTypes from 'prop-types';
//import axios from 'axios';
import {Link} from 'react-router-dom';
import LANGUAGE from '../service/Language-data';
import apiRoot from '../config.api';

class Header extends Component{
    state={
            flag: "../../images/flags/4x3/ca.svg",
            user_portrait: ''
        }

    handleLangCN=()=>{
        this.setState(
            {flag: "../../images/flags/4x3/cn.svg"}
        );
        this.props.langCN();
        
    }

    handleLangEN=()=>{
        this.setState(
            {flag: "../../images/flags/4x3/ca.svg"}
        );
        this.props.langEN();        
    }
    handleAvatarClick=(e)=>{

        // e.preventDefault();
        //get avatarDropDown
        var avatarDropDown=document.getElementById("avatarDropDown");
        if(avatarDropDown !==null){
            var dropdownClass = avatarDropDown.className;
            if(dropdownClass.indexOf('show') >0) {
                avatarDropDown.className="dropdown-menu dropdown-menu-right";
            }else{
                avatarDropDown.className="dropdown-menu dropdown-menu-right show";
            }
        }

    }
    handleLogOutClick=()=>{
        this.props.logOutClick();
    }
    
    componentDidUpdate(prevProps){
        const { authorization } = this.props;
        if(authorization !== prevProps.authorization){
            if(authorization==='true'){
                //happiify_portrait
                let user_portrait = localStorage.getItem("happiify_portrait");
                if(user_portrait===null) user_portrait='';
                this.setState({ user_portrait });
/*
不应该这样做
                axios.get(apiRoot + "users")
                .then((res) => {
                    const currentOne = res.data.find(one=> one.username === logInName);                     
                    let user_portrait = '';
                    currentOne? user_portrait = currentOne.portrait : user_portrait = '';
                    this.setState({ user_portrait });
                })
                .catch((err) => {            
                    console.log("Getting users error in Header component!");            
                });
*/
            }
        }
    }

    render(){
        let lang='';
        if(this.props.langState ==='cn') lang=LANGUAGE.Header.cn;
        else lang=LANGUAGE.Header.en;

        const { authorization } = this.props;
        const { user_portrait } = this.state;
        return(          
            <header id="header" className="header">
                <div className="header-menu">
                    <div className="col-sm-1">
                        <a href="0" onClick={(e)=>e.preventDefault()} id="menuToggle" className="menutoggle pull-left"><i className="fas fa-tasks"></i></a>
                    </div>
                    {/* 
                       
                    <div className="col align-self-center"> */}

                        <div className="col-sm-1">
                            <div className="btn-group float-left ml-2">
                                <p>Version: 0.4.12</p>
                            </div>
                        </div>
                        <div className="col-sm-10">
                            <div className="btn-group float-right">
                                <div className="dropdown mr-2">
                                    <a href="0" onClick={(e)=>e.preventDefault()} id="dLabel" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-offset="0,10">
                                        <img className='flag-icon' src={(this.props.langState !== 'cn')? '../../images/flags/4x3/ca.svg':'../../images/flags/4x3/cn.svg'} alt="flag"/>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dLabel" style={{minWidth: "75px"}}>
                                        <button className="dropdown-item" type="button" onClick={this.handleLangCN}>
                                            <img className='flag-icon' src='../../images/flags/4x3/cn.svg' alt="flag"/>
                                        </button>
                                        <div className="dropdown-divider"></div>
                                        <button className="dropdown-item" type="button" onClick={this.handleLangEN}>
                                            <img className='flag-icon' src='../../images/flags/4x3/ca.svg' alt="flag"/>
                                        </button>
                                    </div>
                                </div>
                                <div className="dropdown" >
                                    {/* <a href="0"  id="dLabel" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-offset="0,10"> */}
                                        {authorization === "true" && 
                                        (()=>{
                                            switch(user_portrait.slice(0,4)){
                                                case '':        return (null);
                                                case 'http':    return (<img className="user-avatar rounded-circle" onClick={(e)=>this.handleAvatarClick(e)}  src={user_portrait} alt="User Avatar" width="25" height="25"/>);
                                                default:        return (<img className="user-avatar rounded-circle" onClick={(e)=>this.handleAvatarClick(e)}  src={apiRoot + 'display/image/file?file=' + user_portrait} alt="User Avatar" width="25" height="25"/>)
                                            }                                            
                                        })()}
                                    {/* </a> */}
                                    <div id="avatarDropDown" className="dropdown-menu dropdown-menu-right" aria-labelledby="dLabel" style={{fontSize: '0.8em'}}>
                                        {/* <button className="dropdown-item" type="button">{lang[0]}</button>
                                        <button className="dropdown-item" type="button">{lang[1]}</button>
                                        <button className="dropdown-item" type="button">{lang[2]}</button> */}
                                        {/* <div className="dropdown-divider"></div> */}
                                        <Link to="/login" className="dropdown-item" onClick={this.handleLogOutClick}><i className="fa fa-power-off mr-1"></i>{lang[3]}</Link>
                                    </div>
                                </div>
                            </div>







                          
                        </div>
                    </div>
              
            </header>
        );
    }

}

export default Header;