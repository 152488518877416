import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import LANGUAGE from '../../../service/Language-data';
import UploadWallNoCrop from '../../../service/components/UploadWallNoCrop';
import { IMAGE } from '../../../service/media-type';
import { getAllLanguages, getAllTopicTags, insertTag } from '../../../service/axios-service';

class AddTag extends Component{
    state={
        languages: [],
        tag_name: '',        
        language_id: 1,        
        description: '',
        images: []
    }

    static propTypes={
        langState: PropTypes.string.isRequired
    };

    handleChange=(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleURLImages=(images)=>{
        this.setState({ images });        
    }

    handleClickSave=(e)=>{
        const { tag_name, language_id, description, images } = this.state;
        getAllTopicTags()
            .then(res=>{
                const sameName = res.data.find(one=>one.tags_name === tag_name);
                if(tag_name === ''){
                    alert(`What is the tag name? It cannot be empty. Please try again!`);
                }        
                else if( sameName ){
                    alert(`The name you typed already exists! Please try again!`);
                } 
                else{
                    const data={ tag_name, language_id, description, images };
                    insertTag(data)
                        .then(res=>{
                            alert(`A tag was created!`);
                            this.setState({
                                tag_name: '',        
                                language_id: 1,        
                                description: '',
                                images: []
                            })
                        })
                        .catch((err) => { 
                            console.log("[Error] - At AddTag/handleClickSave/insertTag!");
                            console.log(err);            
                    });
                }
            })
        e.preventDefault();
    }

    componentDidMount(){
        getAllLanguages()
            .then((res) => {    
                this.setState({ languages: res.data });
            })
            .catch((err) => { 
                console.log("[Error] - At AddTag/componentDidMount!");
                console.log(err);           
            });
    }

    render(){
        let lang='';
        if(this.props.langState ==='cn') lang=LANGUAGE.AddTag.cn;
        else lang=LANGUAGE.AddTag.en;

        const { languages, tag_name, language_id, description, images } = this.state;
        return(
            <div className="container-fluid">
                <div className="card rounded">
                    <div className="card-header">
                        <span style={{fontSize: "1.4em"}}><i className="fas fa-pencil-alt"></i>&nbsp;{lang[0]}</span>
                        <div className="float-right">
                            <div className=" btn-toolbar " >
                                <div className="btn-group-sm mr-2" role="group" aria-label="First group">
                                    <button type="button" className="btn btn-success" onClick={this.handleClickSave} title={lang[1]}><i className="fas fa-save"></i></button>
                                </div>
                                <Link to='/topics/tags'>
                                    <div className="btn-group-sm mr-2" role="group" aria-label="Second group">
                                        <button type="button" className="btn btn-primary" title={lang[2]}><i className="fas fa-arrow-alt-circle-right"></i></button>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-body" style={{fontWeight: 'bold'}}>
                        <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="tag_name" className="col-form-label">{lang[3]}</label>
                            </div>
                            <div className="col col-sm-10 input-group-sm">
                                <input name="tag_name" type="text" className="form-control" id="tag_name" value={tag_name} onChange={this.handleChange}/>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="lang" className="col-form-label">{lang[4]}</label>
                            </div>
                            <div className="col col-sm-10 input-group-sm">
                                <select name="language_id" className="custom-select mr-sm-2" id="lang" value={language_id} onChange={this.handleChange}>
                                    {languages.map((one, index) => <option key={index} value={one.id}>{one.name}</option> )}
                                </select>
                            </div>
                        </div>    
                        <hr/>
                        <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="content" className="col-form-label">{lang[5]}</label>
                            </div>
                            <div className="col col-sm-10">
                                <textarea name="description" className="form-control" id="content" rows={10} value={description} onChange={this.handleChange}/>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="images" className="col-form-label">{lang[6]}</label>
                            </div>
                            <div className="col col-sm-10 input-group-sm" id="images">
                                <UploadWallNoCrop
                                    some={1}
                                    listType="picture-card"
                                    load={ images }
                                    urls={this.handleURLImages}
                                    format={ IMAGE.toString() }
                                />                                
                            </div>
                        </div>
                    </div>
                </div>            
            </div>
        );
    }
}

export default AddTag;