import React, { Component} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import LANGUAGE from '../../../service/Language-data';
import UploadWall from '../../../service/components/UploadWall';
import { IMAGE } from '../../../service/media-type';
import { getDocById, getAllDocCategories, updateDocById, getDocCategoriesById, getDocKeywordsById } from '../../../service/axios-service';
import EmbeddedEditor from '../../../service/components/EmbeddedEditor';
import MultiSelectTree from '../../../service/components/MultiSelectTree_antd';

class UpdateDoc extends Component{
    state={
        categories:[],
        title: '',
        category_id: 1,
        images:[],
        content: '',
        draft_text:"",
        category:[],
        keyWords:"",
        }

    static propTypes={
        langState: PropTypes.string.isRequired,
        docId: PropTypes.number.isRequired,
        logInName: PropTypes.string.isRequired
    }

    handleChange=(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleURLImages=(images)=>{
        this.setState({ images });        
    }
    
    getEditorContent=(content)=>{
        this.setState({ content });
    }

    handleSubmit=(e)=>{        
        const { title, category_id, images, content,category,keyWords } = this.state;
        const username = this.props.logInName;
        const { docId } = this.props;

        if(content === ''){
            alert(`What is the content of your article? It cannot be empty. Please try again!`);
        }else if (category.length==0){
            alert("请至少选择一个文章目录")
        }else{
            var parent_cate =[10,15,16,23,26];

            for(var i=0;i<category.length;i++){
                if (parent_cate.includes( category[i] ) === true){
                    alert("请不要选择一级目录");
                    e.preventDefault();
                    return;
                }
            }
            const data = { username, title, category_id, images, content,category,keyWords }
            updateDocById(docId, data)
                .then(res => {
                    this.stopTimer();
                    this.clearDraft();
                    alert("A document was updated!");
                    this.setState({
                        title: '',
                        category_id: 1,
                        images:[],
                        content: '',
                        keyWords:''
                    });
                    this.startTimer();
                })
                .catch(err=>{ 
                    console.log("[Error] - At UpdateDoc/handleSubmit/updateDocById!");
                    console.log(err);
                    alert("Sorry！ The system is very busy now. The data cannot be saved. Please try it later.");
                });
        }
        e.preventDefault();
    }

    componentDidMount(){
        const { docId } = this.props;
        axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
        axios.all([
            getAllDocCategories(),
            getDocById(docId),
            getDocCategoriesById(docId),
            getDocKeywordsById(docId)
        ]).then(axios.spread((r1, r2, r3,r4)=>{
            let category = [];
            r3.data.forEach(one=>{
                category.push(one.categories_id);
            })     
            let keyWords='';
            r4.data.forEach( (one)=>{
                keyWords = keyWords + one.keywords_text +" ";
            })       
            this.setState({
                categories:     r1.data,
              //  category_id:    r2.data[0].categories_id,
                images:         r2.data[0].feature_image_url !== '' ? [{ id: docId, value: r2.data[0].feature_image_url}]:[],
                title:          r2.data[0].post_title,
                content:        r2.data[0].post_content,
                category,
                keyWords
            });
            this.startTimer();
        })).catch((err) => { 
            console.log("[Error] - At UpdateDoc/componentDidMount!");
            console.log(err);        
        });
        
    }

//草稿功能
save_draft=()=>{
    
    const { title, category_id, images, content,keyWords } = this.state;
    const data={  title, category_id, images, content,keyWords};
    console.log("开始保存草稿,title=" + title);
    //save to local storage
    localStorage.setItem("edit_article_draft",JSON.stringify(data));
}

setDataFromDraft=(e)=>{
    var draft=localStorage.getItem("edit_article_draft");
    var draft_data = JSON.parse(draft);
    console.log("load from draft,="+ draft_data.title);
    this.setState({
        title: draft_data.title,
        category_id:draft_data.category_id,
        images:draft_data.images,
        content:draft_data.content,
        keyWords:draft_data.keyWords,
        draft_text:""
    });
    e.preventDefault();
}

getCategoryValue=(value)=>{
    const category = value.filter(one=> +one>0)
    this.setState({ category });
}
startTimer=()=>{
    //check draft existing
    var draft=localStorage.getItem("edit_article_draft");
    if(draft!=null){
        this.setState({
            draft_text:"你有没完成的草稿，继续编辑?"
            //continue edit, so need to set data
        });
    }
    //启动定时器，1分钟保存草稿一次
    this.timer = setInterval(() => this.save_draft(), 30000)
    console.log("start")
  }
  stopTimer=()=>{
    clearInterval(this.timer);
    console.log("stop");
    
  }
  clearDraft=()=>{
    localStorage.removeItem("edit_article_draft");
  }
  resetTimer=()=>{
    //this.setState({time: 0})
    console.log("reset")
  }
  componentWillUnmount(){
    //stop timer
    this.stopTimer();
  }    
    render(){
        let lang='';
        if(this.props.langState ==='cn') lang=LANGUAGE.UpdateDoc.cn;
        else lang=LANGUAGE.UpdateDoc.en;
        
        const {categories, title, category_id, images, content,draft_text,category,keyWords } = this.state;
        return(
            <div className="container-fluid">
                <div className="card rounded">
                    <div className="card-header"> 
                  
                        <div className="form-group col-md-8">
                            <h5>{lang[0]}</h5>
                            <br/>
                            <div className="small-note-text">(*)系统会每隔30秒自动保存草稿</div> 
                        </div>
                        <div className="text-right col-md-4">
                            <Link to="/documents" className="btn btn-primary btn-sm rounded" title={lang[1]}><i className="fas fa-arrow-alt-circle-right"></i></Link>
                        </div> 

                                                 
                    </div>
                    <div className="card-body">   
<div className="divDraft">
<a type="button" onClick={this.setDataFromDraft}  title={draft_text}>{draft_text}</a>  
</div>

                        <form onSubmit={this.handleSubmit}>
                            <div className="form-row">
                                <div className="form-group col-md-8">
                                    <label htmlFor="title">{lang[1]}</label>
                                    <input name="title" type="text" className="form-control" id="title" value={title} onChange={this.handleChange} required/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="category_id">{lang[2]}</label>

                                    <MultiSelectTree 
                                                    init={ category }
                                                    data={categories}
                                                    property1={'categories_id'}
                                                    property2={'parent_id'}
                                                    property3={'name'}
                                                    getValue={this.getCategoryValue}
                                                />                                    
                                    {/* <select name="category_id" className="custom-select mr-sm-2" id="category_id" value={category_id} onChange={this.handleChange}>
                                             {categories.map((one, index) => <option key={index} value={one.categories_id}>{one.name}</option> )}
                                    </select> */}
                                </div>
                            </div>



                            <div className="form-row">

                            
                            <div className="form-group col-md-8">          

                                <div className="form-group  row-panel" style={{height:"130px"}}>
                                    <label htmlFor="question_upload_image">{lang[3]}</label>
                                    <div className="form-group float-right">
                                    <UploadWall
                                                    some={1}
                                                    listType="picture-card"
                                                    load={ images }
                                                    urls={this.handleURLImages}
                                                    format={ IMAGE.toString() }
                                                />
                                         </div>
                                        <div className="form-group small-note-text">* 删除及预览图片，请移动鼠标到图片中，然后选择相应的功能!</div>
                                    {/* <input name="upload" type="button" className="form-control btn btn-outline-secondary btn-sm rounded" id="question_upload_image" value={lang[4]} data-toggle="modal" data-target="#images"/> */}
                                </div>
                                
                                    <div className="modal fade" id="images" tabIndex="-1" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="images">{lang[5]}</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <UploadWall
                                                    some={1}
                                                    listType="picture-card"
                                                    load={ images }
                                                    urls={this.handleURLImages}
                                                    format={ IMAGE.toString() }
                                                />
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-primary rounded" data-dismiss="modal">{lang[6]}</button>
                                            </div>
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>
<div className="form-group col-md-4">
        <label htmlFor="title">{lang[10]}</label>
        <input name="keyWords" type="text" className="form-control" id="keyWords" value={keyWords} 
        onChange={this.handleChange} required/>
    </div>

</div>



                            <div className="form-group">
                                <label>{lang[7]}</label>
                                <EmbeddedEditor 
                                    content={content}
                                    getContent={this.getEditorContent}/>
                            </div>
                            <div className="btn-group">
                                <button type="submit" className="btn btn-success btn-sm mr-2 rounded" title={lang[8]}><i className="fas fa-save"></i></button>
                                <Link to="/documents" className="btn btn-primary btn-sm rounded" title={lang[9]}><i className="fas fa-arrow-alt-circle-right"></i></Link>
                            </div>            
                        </form>
                    </div>
                </div>
            </div>
        );        
    }
}

export default UpdateDoc;