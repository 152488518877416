import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Link} from 'react-router-dom';
import LANGUAGE from '../../../service/Language-data';
import apiRoot from '../../../config.api';
import { getAllLanguages, getWarehouseById } from '../../../service/axios-service';

class UpdateWarehouse extends Component{
    state={
        langList: [],
        name: '',
        street: '',
        city: '',
        state: '',
        country: '',
        postcode: '',
        contact_phone: '',
        notes: '',
        language: '' 
    }

    static propTypes={
        langState: PropTypes.string.isRequired,
        warehouseId: PropTypes.number.isRequired
    };

    handleChange=(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    handleURLImages=(images)=>{
        this.setState({ images });        
    }

    handleClick=(e)=>{
        const id = this.props.warehouseId;
        const { langList, name, street,city,state,country,postcode,contact_phone,notes,language } = this.state;

                    axios.post(apiRoot + "products/warehouse/update", {
                            id, name,street,city,state,country,postcode,contact_phone,notes, language
                        })
                        .then(res => {
                            alert("An warehouse was updated!");
                            this.setState({
                                name: '',
                                street: '',
                                city: '',
                                state: '',
                                country: '',
                                postcode: '',
                                contact_phone: '',
                                notes: '',
                                language: langList[0].name
                            });                
                        })
                        .catch(err=>{
                            console.log("[Error] - PUT /products/attribute_groups/update - at UpdateAttributeGroup component!");
                            console.log(err);
                    });
                    e.preventDefault();
                
    }

    componentDidMount(){
        const { warehouseId } = this.props;
        axios.all([
            getAllLanguages(), 
            getWarehouseById(warehouseId)
        ]).then(axios.spread((r1,r2)=>{
            this.setState({ 
                languages:      r1.data,
                name:       r2.data[0].warehouse_name,
                street: r2.data[0].street,
                city: r2.data[0].city,
                state: r2.data[0].state,
                country: r2.data[0].country,
                postcode: r2.data[0].postcode,
                contact_phone: r2.data[0].contact_phone,
                notes: r2.data[0].notes
            });
        })).catch((err) => { 
                console.log("[Error] - At UpdateTag/componentDidMount!");
                console.log(err);           
        });        
    }

    render(){
        let lang='';
        if(this.props.langState ==='cn') lang=LANGUAGE.Warehouse.cn;
        else lang=LANGUAGE.Warehouse.en;

        const { langList, name, language } = this.state;
        return(
            <div className="container-fluid">
                <div className="card rounded">
                    <div className="card-header">
                        <span style={{fontSize: "1.4em"}}><i className="fas fa-pencil-alt"></i>&nbsp;{lang[10]}</span>
                        <div className="float-right">
                            <div className=" btn-toolbar " >
                                <div className="btn-group-sm mr-2" role="group" aria-label="First group">
                                    <button type="button" className="btn btn-success" onClick={this.handleClick} title={lang[1]}><i className="fas fa-save"></i></button>
                                </div>
                                <Link to='/warehouse'>
                                    <div className="btn-group-sm mr-2" role="group" aria-label="Second group">
                                        <button type="button" className="btn btn-primary" title={lang[4]}><i className="fas fa-arrow-alt-circle-right"></i></button>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-body" style={{fontWeight: 'bold'}}>
                        <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="name" className="col-form-label">{lang[5]}</label>
                            </div>
                            <div className="col col-sm-10 input-group-sm">
                                <input name="name" type="text" className="form-control" id="name" value={name} onChange={this.handleChange} required/>
                            </div>
                        </div>
                        <hr/>

                        <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="contact_phone" className="col-form-label">{lang[11]}</label>
                            </div>
                            <div className="col col-sm-10 input-group-sm">
                                <input name="contact_phone" type="text" className="form-control" id="contact_phone" value={this.state.contact_phone} onChange={this.handleChange} required/>
                            </div>
                        </div>
                        <hr/>

                        <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="street" className="col-form-label">{lang[12]}</label>
                            </div>
                            <div className="col col-sm-10 input-group-sm">
                                <input name="street" type="text" className="form-control" id="street" value={this.state.street} onChange={this.handleChange} required/>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="city" className="col-form-label">{lang[13]}</label>
                            </div>
                            <div className="col col-sm-10 input-group-sm">
                                <input name="city" type="text" className="form-control" id="city" value={this.state.city} onChange={this.handleChange} required/>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="state" className="col-form-label">{lang[15]}</label>
                            </div>
                            <div className="col col-sm-10 input-group-sm">
                                <input name="state" type="text" className="form-control" id="state" value={this.state.state} onChange={this.handleChange} required/>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="country" className="col-form-label">{lang[16]}</label>
                            </div>
                            <div className="col col-sm-10 input-group-sm">
                                <input name="country" type="text" className="form-control" id="country" value={this.state.country} onChange={this.handleChange} required/>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="postcode" className="col-form-label">{lang[17]}</label>
                            </div>
                            <div className="col col-sm-10 input-group-sm">
                                <input name="postcode" type="text" className="form-control" id="postcode" value={this.state.postcode} onChange={this.handleChange} required/>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="notes" className="col-form-label">{lang[14]}</label>
                            </div>
                            <div className="col col-sm-10 input-group-sm">
                                <input name="notes" type="text" className="form-control" id="notes" value={this.state.notes} onChange={this.handleChange} required/>
                            </div>
                        </div>
                        <hr/>                                                                                                                                                


                    </div>
                </div>            
            </div>
        );
    }
}

export default UpdateWarehouse;