import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Link} from 'react-router-dom';

import LANGUAGE from '../../../service/Language-data';
import SingleMultiSelect from '../../../service/components/Select_antd';
import UploadWallNoCrop from '../../../service/components/UploadWallNoCrop';
import { IMAGE } from '../../../service/media-type';
import { getAllTopicTags, getTopicById, getTopicAllTagsById, getTopicAllImagesById, updateTopicById } from '../../../service/axios-service';

class UpdateTopic extends Component{
    state={
        options: [],
        tags: [],
        contents: '',
        images: [],
        views: 0,
        answers: 0
    }

    static propTypes={
        langState: PropTypes.string.isRequired,
        logInName: PropTypes.string.isRequired,
        topicId: PropTypes.number.isRequired
    };

    handleSelects=(value)=>{
        this.setState({ tags: value });
    }

    handleChange=(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleURLImages=(images)=>{
        this.setState({ images });        
    }

    handleClickSave=(e)=>{
        const { tags, contents, images } = this.state;
        const { topicId } = this.props;
        if(contents === ''){
            alert(`What is the content that you want to post? It cannot be empty. Please try again!`);
        }
        else{
            const data={ tags, contents, images };
            updateTopicById(topicId, data)
                .then(res=>{
                    alert(`A topic was updated!`);
                    this.setState({
                        tags: [],
                        contents: '',
                        images: [],
                        views: 0,
                        answers: 0
                    })
                })
                .catch((err) => { 
                    console.log("[Error] - At UpdateTopic/handleClickSave/updateTopicById!");
                    console.log(err);            
            });
            e.preventDefault();
        }
    }

    componentDidMount(){
        const { topicId } = this.props;
        axios.all([
            getTopicById(topicId), 
            getTopicAllTagsById(topicId), 
            getTopicAllImagesById(topicId),
            getAllTopicTags()
        ]).then(axios.spread((r1,r2,r3,r4)=>{
            const tags=[];
            r2.data.forEach(one=>{
                tags.push(one.tags_id);
            });
            const images=[];
            r3.data.forEach(one=>{
                images.push({id: one.id, value: one.media_url});
            });
            this.setState({ 
                contents: r1.data[0].contents,
                views: r1.data[0].total_views,
                answers: r1.data[0].total_answers,
                tags, 
                images,
                options: r4.data
            });
        })).catch((err) => { 
                console.log("[Error] - At UpdateTopic/componentDidMount!");
                console.log(err);           
        });
    }

    render(){
        let lang='';
        if(this.props.langState ==='cn') lang=LANGUAGE.UpdateTopic.cn;
        else lang=LANGUAGE.UpdateTopic.en;

        const { options, tags, contents, images, views, answers } = this.state;
        return(
            <div className="container-fluid">
                <div className="card rounded">
                    <div className="card-header">
                        <span style={{fontSize: "1.4em"}}><i className="fas fa-pencil-alt"></i>&nbsp;{lang[0]}</span>
                        <div className="float-right">
                            <div className=" btn-toolbar " >
                                <div className="btn-group-sm mr-2" role="group" aria-label="First group">
                                    <button type="button" className="btn btn-success" onClick={this.handleClickSave} title={lang[1]}><i className="fas fa-save"></i></button>
                                </div>
                                <Link to='/topics'>
                                    <div className="btn-group-sm mr-2" role="group" aria-label="Second group">
                                        <button type="button" className="btn btn-primary" title={lang[2]}><i className="fas fa-arrow-alt-circle-right"></i></button>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-body" style={{fontWeight: 'bold'}}>
                        <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="tags" className="col-form-label">{lang[3]}</label>
                            </div>
                            <div className="col col-sm-10 input-group-sm" id="tags">
                                <SingleMultiSelect
                                    mode='multiple'
                                    load={tags}
                                    options={options}
                                    selects={this.handleSelects}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="content" className="col-form-label">{lang[4]}</label>
                            </div>
                            <div className="col col-sm-10">
                                <textarea name="contents" className="form-control" id="content" rows={15} value={contents} onChange={this.handleChange}/>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="answers" className="col-form-label">{lang[5]}</label>
                            </div>
                            <div className="col col-sm-10 input-group-sm" id="answers">
                                <input name="answers" type="text" className="form-control" value={answers} disabled/>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="views" className="col-form-label">{lang[6]}</label>
                            </div>
                            <div className="col col-sm-10 input-group-sm" id="views">
                                <input name="views" type="text" className="form-control" value={views} disabled/>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="images" className="col-form-label">{lang[7]}</label>
                            </div>
                            <div className="col col-sm-10 input-group-sm" id="images">
                                <UploadWallNoCrop
                                    some={8}
                                    listType="picture-card"
                                    load={ images }
                                    urls={this.handleURLImages}
                                    format={ IMAGE.toString() }
                                />                                
                            </div>
                        </div>
                    </div>
                </div>            
            </div>
        );
    }
}

export default UpdateTopic;