/*
*  The function name in this file use the same name in server.js.
**/

import axios from "axios";
import apiRoot from '../config.api';

// Part 1: The functions below work for export functions. ****************************************************************
const createFormData=(obj)=>{
    const formData = new FormData();
    for(var item in obj){
        formData.append(item, obj[item]);
    }
    return formData;
}

// Part 2: General functions like currency, language, dictionary and so on. **************************************************
export const getAllCurrencies=()=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "currencies");
}

export const getAllLanguages=()=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "languages");
}

export const getAllDictionaryItems=()=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "dictionary");
}

// Part 3: Special functions for different management content. ***********************************************************
// LESSON MANAGEMENT ------------------------------------------------------------------ 
export const getAllLessons=()=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "lessons")
}

export const deleteLesson=(id)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.put(apiRoot + "lessons/delete", {id})
}

export const getLessonById=(lessonId)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "lesson/" + lessonId)
}

export const getLessonListById=(lessonId)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "lesson/list/" + lessonId);
}

export const getLessonAttachById=(lessonId)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "lesson/attach/" + lessonId);
}

export const insertLesson=(obj)=>{
    const formData = createFormData(obj);
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.post(apiRoot + "lessons/add", formData, {
                headers:{'Content-Type':'multipart/form-data'}
            });
}

export const updateLessonById=(lessonId, obj)=>{
    const formData = createFormData(obj);
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.put(apiRoot + "lesson/" + lessonId, formData, {
                headers:{'Content-Type':'multipart/form-data'}
            });
}

export const getAllLessonCategories=()=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "lessons/categories");
}

// QUESTION MANAGEMENT ----------------------------------------------------------------
export const getQuestionById=(questionId)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "question/" + questionId);
}

export const insertQuestion=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
    return  axios.post(apiRoot + "questions/add", obj);
}

export const updateQuestionById=(questionId, obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); 
    return axios.put(apiRoot + "question/" + questionId, obj);
}

export const getQuestionAllImagesById=(questionId)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); 
    return axios.get(apiRoot + "question/images/" + questionId);
}

export const getAllQuestionCategories=()=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "questions/categories");
}

export const getQuestionAllAnswersById=(questionId)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "question/answers/" + questionId);
}

export const getAllQuestionsAnswersImages=()=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "questions/answers/images");
}

export const getQuestionAnswerById=(answerId)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "question/answer/" + answerId);
}

export const updateQuestionAnswerById=(answerId, obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.put(apiRoot + "question/answer/" + answerId, obj);
}

export const deleteQuestionAnswer=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.put(apiRoot + "questions/answers/delete", obj);
}

export const insertQuestionAnswer=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.post(apiRoot + "questions/answers/add", obj);
}

export const getAnswerAllImagesById=(answerId)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "question/answer/images/" + answerId);
}

// VIDEO MANAGEMENT ----------------------------------------------------------------
export const getAllVideoCategories=()=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "videos/categories");
}

export const insertVideo=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
    return  axios.post(apiRoot + "videos/add", obj);
}

export const getVideoById=(videoId)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "video/" + videoId);
}
export const getVideoCategoriesById=(videoId)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "video_cate/" + videoId);
}

export const updateVideoById=(videoId, obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.put(apiRoot + "video/" + videoId, obj);
}

// DOCUMENT MANAGEMENT ----------------------------------------------------------------
export const getDocById=(docId)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "document/" + docId);
}
export const getDocCategoriesById=(docId)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "document_cate/" + docId);
}
export const getDocKeywordsById=(docId)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "document_keywords/" + docId);
}


export const insertDoc=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
    return  axios.post(apiRoot + "documents/add", obj);
}
export const addShippingTracking=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
    return  axios.post(apiRoot + "shipping/add_tracking", obj);
}
export const updateDocById=(docId, obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); 
    return axios.put(apiRoot + "document/" + docId, obj);
}

export const getAllDocCategories=()=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "documents/categories");
}

// PRODUCT MANAGEMENT ----------------------------------------------------------------
export const getProductById=(productId)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "product/" + productId);
}

export const insertProduct=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
    return  axios.post(apiRoot + "products/add", obj);
}
export const insertProductInPreviewMode=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
    return  axios.post(apiRoot + "products/previewAdd", obj);
}


export const updateProductById=(productId, obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); 
    return axios.put(apiRoot + "product/" + productId, obj);
}

export const getAllProductCategories=()=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); 
    return axios.get(apiRoot + "products/categories");
}

export const getProductCategoriesById=(productId)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "product/categories/" + productId);
}

export const getAllProductAttributes=()=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "products/attributes");
}

export const getProductAttributesById=(productId)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "product/attributes/" + productId);
}

export const getProductsTaxeClasses=()=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "products/tax_class");
}

export const getAllManufacturers=()=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "products/manufacturers");
}

export const getAllBrands=()=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "products/brands");
}

export const getProductAllImages=(productId)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "product/images/search?productId=" + productId);
}

// HEALTH MANAGEMENT ----------------------------------------------------------------
export const getAllDoctors=()=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "health/doctors");
}

export const getDoctorByUsername=(username)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "health/doctors/search?username=" + username);
}

export const getDoctorById=(doctorId)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "health/doctor/" + doctorId);
}

export const insertDoctor=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
    return  axios.post(apiRoot + "health/doctors/add", obj);
}

export const updateDoctorById=(doctorId, obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.put(apiRoot + "health/doctor/" + doctorId, obj);
}

export const getAllDoctorCategories=()=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "health/doctors/categories");
}

// USER MANAGEMENT ----------------------------------------------------------------
export const getAllUsers=()=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "users");
}

export const getUserById=(userId)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "user/" + userId);
}
export const getUserPermissionById=(userId)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "user/permission/" + userId);
}

export const getUserByName=(username)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "users/search?username=" + username);
}

export const resetPassword=(obj)=>{
    return  axios.post(apiRoot + "user/reset_pwd", obj);
}


export const insertUser=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
    return  axios.post(apiRoot + "users/add", obj);
}

export const updateUserById=(userId, obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.put(apiRoot + "user/" + userId, obj);
}

export const getUsersMarriageStatus=()=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "users/marriage");
}

export const getUsersRoles=()=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "users/roles");
}

// TOPIC MANAGEMENT ----------------------------------------------------------------
export const getAllTopics=()=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "topics");
}

export const getTopicById=(topicId)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "topic/" + topicId);
}

export const deleteTopics=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.put(apiRoot + "topics/delete", obj);
}

export const insertTopic=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
    return  axios.post(apiRoot + "topics/add", obj);
}

export const updateTopicById=(topicId, obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.put(apiRoot + "topic/" + topicId, obj);
}

export const getAllTopicTagMapping=()=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "topics/topic_tag");
}

export const getAllTopicTags=()=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "topics/tags");
}

export const getTopicAllTagsById=(topicId)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "topic/tags/" + topicId);
}

export const getTopicTagById=(tagId)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "topic/tag/" + tagId);
}

export const deleteTopicTags=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.put(apiRoot + "topics/tags/delete", obj);
}

export const insertTag=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
    return  axios.post(apiRoot + "topics/tags/add", obj);
}

export const updateTagById=(tagId, obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.put(apiRoot + "topic/tag/" + tagId, obj);
}

export const getTopicAllImagesById=(topicId)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "topic/images/" + topicId);
}

export const getAllTopicThreads=()=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "topics/threads");
}

export const getTopicThreadById=(threadId)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "topic/thread/" + threadId);
}

export const getTopicsThreadsCount=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.put(apiRoot + "topics/threads/count", obj);
}

export const getAllTopicThreadImages=()=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "topics/threads/images");
}

export const getTopicThreadAllImagesById=(threadId)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "topic/thread/images/" + threadId);
}

export const deleteTopicsThreads=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.put(apiRoot + "topics/threads/delete", obj);
}

export const insertThread=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
    return  axios.post(apiRoot + "topics/threads/add", obj);
}

export const updateThreadById=(threadId, obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.put(apiRoot + "topic/thread/" + threadId, obj);
}

//////新的API统一使用Java API v2
export const getAllAvailableCountries=()=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.get(apiRoot + "location/countries/enable");
}

export const addShippingMethod=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
    return  axios.post(apiRoot + "shop/shipping/add_method", obj);
}
export const getShippingMethod=(obj)=>{
    return  axios.post(apiRoot + "shop/shipping/get_method", obj);
}
export const deleteShippingMethod=(id)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); return axios.put(apiRoot + "shop/shipping/delete_method", {id})
}
//
export const updateShippingMethod=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
    return  axios.post(apiRoot + "shop/shipping/update_method", obj);
}
export const getShippingMethodByProductId=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
    return  axios.post(apiRoot + "shop/shipping/get_product_shipping_method", obj);
}
export const getManufacturerById=(manufacturersId)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); 
    return axios.get(apiRoot + "products/manufacturer/" + manufacturersId);
}

export const getBrandById=(brandId)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); 
    return axios.get(apiRoot + "products/brand/" + brandId);
}

export const getWarehouseById=(warehouseId)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); 
    return axios.get(apiRoot + "products/warehouse/" + warehouseId);
}
export const getSupplierById=(supplierId)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); 
    return axios.get(apiRoot + "products/supplier/" + supplierId);
}


export const loadWarehouse=()=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); 
    return axios.get(apiRoot + "products/loadwarehouse");
}

export const getWarehouseStockByProductId=(productId)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token"); 
    return axios.get(apiRoot + "products/getWarehouseStock/" + productId);
}

export const productStockIn=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
    return  axios.post(apiRoot + "products/stockIn", obj);
}

export const productStockOut=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
    return  axios.post(apiRoot + "products/stockOut", obj);
}

export const loadStockIn=()=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
    return  axios.get(apiRoot + "products/loadStockIn");
} 

export const loadStockOut=()=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
    return  axios.get(apiRoot + "products/loadStockOut");
} 

export const loadSupplier=()=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
    return  axios.get(apiRoot + "products/loadsupplier");
} 

export const orderReporting=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
    return  axios.post(apiRoot + "report/order_reporting", obj);
}
export const userReportingByDateType=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
    return  axios.post(apiRoot + "report/user_reporting_by_date_type", obj);
}
export const orderReportingByDateType=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
    return  axios.post(apiRoot + "report/order_reporting_by_date_type", obj);
}
export const articleReportingByDateType=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
    return  axios.post(apiRoot + "report/article_reporting_by_date_type", obj);
}
export const bestProductReporting=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
    return  axios.post(apiRoot + "report/best_product_reporting", obj);
}
export const lowStockProductReporting=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
    return  axios.post(apiRoot + "report/low_stock_product_reporting", obj);
}
export const neverPurchaseProductReporting=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
    return  axios.post(apiRoot + "report/never_purchase_product_reporting", obj);
}
export const getOrderReportingByDateRange=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
    return  axios.post(apiRoot + "report/getOrderReportingByDateRange", obj);
}
export const getProductReportingByDateRange=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
    return  axios.post(apiRoot + "report/getProductReportingByDateRange", obj);
}
export const getCustomerReportingByDateRange=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
    return  axios.post(apiRoot + "report/getCustomerReportingByDateRange", obj);
}
export const getDistributionReportingByDateRange=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
    return  axios.post(apiRoot + "report/getDistributionReportingByDateRange", obj);
}



export const getOrderBySearch=(obj)=>{
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
    return  axios.post(apiRoot + "report/getOrderBySearch", obj);
}

//report/best_product_reporting