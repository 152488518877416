import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

class SingleMultiSelect extends Component{
    state = {
        value: []
    }

    static ptopTypes = {
        mode: PropTypes.string.isRequired,
        load: PropTypes.array.isRequired,   
        options: PropTypes.array.isRequired
    }
    handleChange=(value)=>{
        this.setState({ value });
        this.props.selects(value);
    }

    componentDidMount(){
        this.setState({
          vlaue: this.props.load
        })
    }

    componentDidUpdate(prevProps){
        const { load } = this.props;
        if(load.length !== prevProps.load.length){
            if(load.length === 0){
                this.setState({ value: [] });
            }
            else if(prevProps.load.length === 0){
                this.setState({ value: load });
            }
        }
    }
      
    render() {
        const { options, mode } = this.props;
        const { value } = this.state;
        const children = [];
        const Option = Select.Option;
        options.forEach((one, index)=>{
            children.push(<Option key={index} value={one.id}>{one.tags_name}</Option>);
        });

        return(
            <Select
                mode={ mode }
                value = { value }
                style={{ width: '100%' }}
                placeholder="Please select"
                onChange={this.handleChange}
            >
                {children}
            </Select>
        );
    }
}

export default SingleMultiSelect;