import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Link} from 'react-router-dom';
import LANGUAGE from '../../../service/Language-data';

import EmbeddedEditor from '../../../service/components/EmbeddedEditor';
import UploadWallNoCrop from '../../../service/components/UploadWallNoCrop';
import { IMAGE } from '../../../service/media-type';
import { getQuestionAnswerById, getAnswerAllImagesById, updateQuestionAnswerById } from '../../../service/axios-service';

class UpdateAnswer extends Component{
    state={
        answer:'',
        images: [],
        deleteImagesIds: [],
        isChanged: false //For answer change, if there is not changes, it's false
    }

    static propTypes={
        langState: PropTypes.string.isRequired,
        questionId: PropTypes.number.isRequired,
        answerId: PropTypes.number.isRequired
    }
    
    getEditorContent=(content)=>{
        this.setState({ 
            answer: content,
            isChanged: true
         });
    }
    
    handleURLImages=(images)=>{
        this.setState({ images });        
    }
    
    handleClickSave=(e)=>{
        const { answer, images, isChanged } = this.state;
        const { answerId } = this.props;

        const newImages = [];
        let { deleteImagesIds } = this.state;
        images.forEach(one=>{
            if(one.id === 0){
                newImages.push(one);
            }
            else if(deleteImagesIds.includes(one.id)){
                    deleteImagesIds=deleteImagesIds.filter(id=>id!==one.id);
            }
        });

        if((!isChanged) && deleteImagesIds.length === 0 && newImages.length === 0){
            alert(`You did Not do anything. Please try again, or return to answers list. `);
        }
        else{
            const data ={ 
                answer:             answer, 
                images:             newImages,
                deleteImagesIds:    deleteImagesIds
            }
            updateQuestionAnswerById(answerId, data)        
                .then(res => {
                    alert("An answer was updated!");
                    this.setState({
                        answer:'',
                        images: [],
                        deleteImagesIds: [],
                        isChanged: false
                    });
                })
                .catch(err=>{
                    console.log("[Error] - At UpdateAnswer/handleClickSave/updateQuestionAnswerById!");
                    console.log(err);
                });
        }
        e.preventDefault();
    }

    componentDidMount(){
        const { answerId } = this.props;
        axios.all([
            getQuestionAnswerById(answerId),
            getAnswerAllImagesById(answerId)
        ]).then(axios.spread((r1, r2) => {
            const images = [];
            const deleteImagesIds = [];
            r2.data.forEach(one=>{
                images.push({id: one.id, value: one.media_url});
                deleteImagesIds.push(one.id);
            })
            this.setState({ 
                answer: r1.data[0].answers,
                images,
                deleteImagesIds 
            });
        }))
        .catch((err) => { 
            console.log("[Error] - GET /question/answer/answerId - at UpdateAnswer component!");
            console.log(err);      
        });
    }
    
    render(){
        let lang='';
        if(this.props.langState ==='cn') lang=LANGUAGE.UpdateAnswer.cn;
        else lang=LANGUAGE.UpdateAnswer.en;

        const { answer, images } = this.state
        
        return(
            <div className="container">
                <div className="card rounded">
                    <div className="card-header"> 
                        <h5>{lang[0]}</h5>
                    </div>
                    <div className="card-body">
                        <div className="mb-5">
                            <h6>{lang[3]}</h6>
                            <EmbeddedEditor 
                                content={ answer }
                                getContent={this.getEditorContent}/>
                        </div>
                        <h6>{lang[2]}</h6>
                        <hr/>
                        <UploadWallNoCrop
                            some={8}
                            listType="picture-card"
                            load={ images }
                            urls={this.handleURLImages}
                            format={ IMAGE.toString() }
                        />
                        <hr/>                            
                        <div className="btn-group mt-2">
                            <button type="submit" className="btn btn-success btn-sm mr-2 rounded" title={lang[4]} onClick={this.handleClickSave}><i className="fas fa-save"></i></button>
                            <Link to="/questions/answers" className="btn btn-primary btn-sm rounded" title={lang[1]}><i className="fas fa-arrow-alt-circle-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        );        
    }
}

export default UpdateAnswer;