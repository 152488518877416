import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Link} from 'react-router-dom';
import LANGUAGE from '../../service/Language-data';
import SearchBar from '../../service/components/SearchBar';
import PagingBar from '../../service/components/PagingBar';
import {GoogleCharts} from 'google-charts';
import { userReportingByDateType,orderReportingByDateType,articleReportingByDateType,bestProductReporting,lowStockProductReporting,neverPurchaseProductReporting } from '../../service/axios-service';
import { toCurrency } from '../../service/func-service';


class Dashboard extends Component{
    state={
            subjectList:[],
            orders_status:[],
            orders_total:[],
            searchContent:'',     // Accept the value of "change"; Used for search after the button is clicked.
            searchChange:'',      // Accept the value changed in "input".
            currentPage: 1,
            isSearchByProduct:false,
            start: new Date().toISOString().slice(0, 10),
            end: new Date().toISOString().slice(0, 10),
            orders_data:[],
            users_data:[],
            article_data:[],
            best_ordered_product:[],
            best_viewed_product:[],
            never_purchased_product:[],
            low_stock_product:[]
        };
    
    static propTypes = {
        langState: PropTypes.string.isRequired
    };
    
    drawOrderChart=() =>{
        const { orders_data } = this.state;
        console.log("drawOrderChart ****** ");
        var dataRMB = new GoogleCharts.api.visualization.DataTable();
        dataRMB.addColumn('date', 'Date');
        dataRMB.addColumn('number', 'RMB');

        let dataArrayRMB=[];
        let dataArrayCAD=[];

        for(var i=0; i< orders_data.length;i++){
            if(parseInt(orders_data[i].currency) === 1){
                dataArrayRMB.push([
                    new Date(orders_data[i].report_date),
                    orders_data[i].total_amount
                ]);
            }
            if(parseInt(orders_data[i].currency) === 2){
                dataArrayCAD.push([
                   new Date( orders_data[i].report_date),
                    orders_data[i].total_amount
                ]);
            }            
        }
        dataRMB.addRows(dataArrayRMB);        

        var dataCAD = new GoogleCharts.api.visualization.DataTable();
        dataCAD.addColumn('date', 'Date');
        dataCAD.addColumn('number', 'CAD');
        dataCAD.addRows(dataArrayCAD);        

        var barsRMB = new GoogleCharts.api.visualization.ColumnChart(document.getElementById('chartOrdersRMB'));
        barsRMB.draw(dataRMB, null);
    
        var barsCAD = new GoogleCharts.api.visualization.ColumnChart(document.getElementById('chartOrdersCAD'));
        barsCAD.draw(dataCAD, null);        
        // Add our over/out handlers.
        // GoogleCharts.api.visualization.events.addListener(this.barsVisualization, 'onmouseover', this.barMouseOver,{passive: true});
        // GoogleCharts.api.visualization.events.addListener(this.barsVisualization, 'onmouseout', this.barMouseOut,{passive: true});        
        // const bar_chart = new GoogleCharts.api.visualization.BarChart(document.getElementById('chartOrders'));
        // bar_chart.draw(data);
    }
    drawUserChart=() =>{
        var i=0;
        console.log(' drawUserChart ......');
        var data = new GoogleCharts.api.visualization.DataTable();
        data.addColumn('date', 'Date');
        data.addColumn('number', 'Count');
        const { users_data } = this.state;
        let dataArray=[];
        for(var i=0; i< users_data.length;i++){
            dataArray.push([
                new Date(users_data[i].report_date),
                users_data[i].iCount
            ])
        }
        data.addRows(dataArray);        
        var barsUser = new GoogleCharts.api.visualization.ColumnChart(document.getElementById('chartUsers'));
        barsUser.draw(data, null);
    
        // Add our over/out handlers.
        // GoogleCharts.api.visualization.events.addListener(this.barsUser, 'onmouseover', this.barsUserMouseOver,{passive: false});
        // GoogleCharts.api.visualization.events.addListener(this.barsUser, 'onmouseout', this.barsUserMouseOut,{passive: false});        
        // const bar_chart = new GoogleCharts.api.visualization.BarChart(document.getElementById('chartOrders'));
        // bar_chart.draw(data);
    }      
    drawArticleChart=() =>{
        console.log(' drawArticleChart ......');
        var data = new GoogleCharts.api.visualization.DataTable();
        data.addColumn('date', 'Date');
        data.addColumn('number', 'Count');
        const { article_data } = this.state;
        let dataArray=[];
        for(var i=0; i< article_data.length;i++){
            // if(i%2==0){
                dataArray.push([
                   new Date( article_data[i].report_date),
                    article_data[i].iCount
                ])
    
            // }else{
            //     dataArray.push([
            //         '',
            //         article_data[i].iCount
            //     ])
                    
            // }
        }
        data.addRows(dataArray);        
        var bars = new GoogleCharts.api.visualization.ColumnChart(document.getElementById('chartArticle'));
        bars.draw(data, null);
    
        // Add our over/out handlers.
        // GoogleCharts.api.visualization.events.addListener(this.barsUser, 'onmouseover', this.barsUserMouseOver,{passive: false});
        // GoogleCharts.api.visualization.events.addListener(this.barsUser, 'onmouseout', this.barsUserMouseOut,{passive: false});        
        // const bar_chart = new GoogleCharts.api.visualization.BarChart(document.getElementById('chartOrders'));
        // bar_chart.draw(data);
    }     
    // drawBestOrderedTable=() =>{

    // }
    // drawBestViewedTable=() =>{

    // }
    

    handleProviderChange=(e)=>{
       // const { shipping_providers_id} = this.state;  
        this.setState({
            shipping_providers_id : e.target.value,
            [e.target.name]: e.target.value
            
        });
    }
    handleTrackingIdChange=(e)=>{
        //const { tracking_id} = this.state;  
        this.setState({
            tracking_id : e.target.value,
            [e.target.name]: e.target.value
            
        });
    }
    handleSearchContent=(e)=>{
        
        this.setState({
            searchChange: e.target.value
        });
    };
    
    handleSearchSubmit=(e)=>{
        this.setState({
            searchContent: this.state.searchChange,
            currentPage: 1,        
        });
        //e.preventDefault();

        //do search by products keyword
        const {start,end,searchChange} = this.state;

        var keyword =searchChange;
        const data={ start, end,keyword
       };
        // orderReporting(data)
        //     .then((res) => { 
        //         let dataArray = res.data;
        //         this.setState({ 
        //             isSearchByProduct:true,
        //             orders_status: dataArray[0],
        //             orders_total:dataArray[1],
        //             subjectList: dataArray[2],
        //          });  
        //     })
        //     .catch((err) => {
        //         console.log("[Error] - GET /orders - at Dashboard component!");
        //         console.log(err);         
        // });        
    }

    handlePageChange=(e)=>{
        this.setState({
            currentPage: +e.target.value
        });
    }
    displayCurrency=(id)=>{
        if(parseInt(id)===1) return "￥";
        if(parseInt(id)===2) return "C$";
        if(parseInt(id)===3) return "$";
    }
    handleClickOrderDetails=(id)=>{
        // this.props.clickDetail(id);     
        sessionStorage.setItem('view_orders_id', id);
        console.log("444444");
    }
    handleClickUserReport=(typeId)=>{
        const data={ report_by:typeId };
         userReportingByDateType(data)
         .then(r1=>{
            this.setState({ 
                users_data: r1.data,
            });
            GoogleCharts.load(this.drawUserChart);
        }).catch((err) => { 
            console.log("[Error] - At AddProduct/componentDidMount!");
            console.log(err);         
        });        
    }
    handleClickOrderReport=(typeId)=>{
        const data={ report_by:typeId };
        orderReportingByDateType(data)
         .then(r1=>{
            this.setState({ 
                orders_data: r1.data,
            });
            GoogleCharts.load(this.drawOrderChart);
        }).catch((err) => { 
            console.log("[Error] - At AddProduct/componentDidMount!");
            console.log(err);         
        });        
    }
    handleClickArticleReport=(typeId)=>{
        const data={ report_by:typeId };
        articleReportingByDateType(data)
         .then(r1=>{
            this.setState({ 
                article_data: r1.data,
            });
            GoogleCharts.load(this.drawArticleChart);
        }).catch((err) => { 
            console.log("[Error] - At AddProduct/componentDidMount!");
            console.log(err);         
        });        
    }

    handleChange=(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    handleClickSearchByDate=(e)=>{
        //console.log('ddddddddddddd');
        const {start,end} = this.state;

        if(start === '' || start === null || start === undefined) {
            alert("Please choose start date");
            return;
        }
        if(end === '' || end === null || end === undefined) {
            alert("Please choose start date");
            return;
        }    
        var keyword ='';
        const data={ start, end,keyword
       };
        // orderReporting(data)
        //     .then((res) => { 
        //         let dataArray = res.data;
        //         this.setState({ 
        //             isSearchByProduct:false,
        //             orders_status: dataArray[0],
        //             orders_total:dataArray[1],
        //             subjectList: dataArray[2],
        //          });  
        //     })
        //     .catch((err) => {
        //         console.log("[Error] - GET /orders - at Dashboard component!");
        //         console.log(err);         
        // });


    }

    browseProduct=(id)=>{
        window.open("https://shop.happiify.me/#/goodsDetails?productId="+id,"_blank");
    }
  

    componentDidMount(){
        const data={ report_by:2 };
        const best_view_data ={report_by:2};
        const best_ordered_data = {report_by:1};
       axios.all([
        userReportingByDateType(data),
        orderReportingByDateType(data),
        articleReportingByDateType(data),
        bestProductReporting(best_view_data),
        bestProductReporting(best_ordered_data)
        // lowStockProductReporting(),
        // neverPurchaseProductReporting()
    ]).then(axios.spread((r1,r2,r3,r4,r5)=>{
        this.setState({ 
            users_data: r1.data,
            orders_data: r2.data,
            article_data:r3.data,
            best_viewed_product:r4.data,
            best_ordered_product:r5.data
            // low_stock_product:r6.data,
            // never_purchased_product:r7.data
        });
        GoogleCharts.load(this.drawOrderChart);
        GoogleCharts.load(this.drawUserChart);
       // GoogleCharts.load(this.drawArticleChart);
        // GoogleCharts.load(this.drawBestOrderedTable);
        // GoogleCharts.load(this.drawBestViewedTable);
    })).catch((err) => { 
        console.log("[Error] - At AddProduct/componentDidMount!");
        console.log(err);         
    });


        
                
    }

    render(){
        let lang='';
        if(this.props.langState ==='cn') lang=LANGUAGE.Dashboard.cn;
        else lang=LANGUAGE.Dashboard.en;

        const {subjectList, searchContent,currentPage,orders_status,start, end,never_purchased_product,low_stock_product,best_viewed_product,best_ordered_product} = this.state;
        const { permissions } = this.props;


        const low_stock_product_row=[];
        low_stock_product.forEach((one, index)=>{            
            low_stock_product_row.push(
                <tr key={index} >
                     <td style={{verticalAlign: 'middle'}} className='text-left'>
                     <a href="#" onClick={(e)=>{this.browseProduct(one.products_id);}}> {one.products_name}</a>
                         </td>
                    <td style={{verticalAlign: 'middle'}} className='text-center'>{one.warehouse_name}</td>
                    <td style={{verticalAlign: 'middle'}} className='text-center'>{one.quantity}</td>

                </tr>
            );
        });
        const never_purchased_row=[];
        never_purchased_product.forEach((one, index)=>{            
            never_purchased_row.push(
                <tr key={index} >
                     <td style={{verticalAlign: 'middle'}} className='text-left'>
                     <a href="#" onClick={(e)=>{this.browseProduct(one.products_id);}}> {one.products_name}</a>
                         </td>

                </tr>
            );
        });        





        const best_viewed_row=[];
        best_viewed_product.forEach((one, index)=>{            
            best_viewed_row.push(
                <tr key={index} >
                     <td style={{verticalAlign: 'middle'}} className='text-left'>
                     <a href="#" onClick={(e)=>{this.browseProduct(one.products_id);}}> {one.products_name}</a>
                         </td>
                    <td style={{verticalAlign: 'middle'}} className='text-center'>{one.viewed}</td>

                </tr>
            );
        });

        const best_ordered_row=[];
        best_ordered_product.forEach((one, index)=>{            
            //console.log(JSON.stringify(one));
            best_ordered_row.push(
                <tr key={index} >
                    
                    <td style={{verticalAlign: 'middle'}} className='text-left'>
                       <a href="#" onClick={(e)=>{this.browseProduct(one.products_id);}}> {one.products_name}</a>
                        </td>
                    <td style={{verticalAlign: 'middle'}} className='text-center'>{ toCurrency(one.total_amount,one.currencies_id)}</td>
                    
                </tr>
            );
        });        
        return(
            <div className="container-fluid">
                <div className="card rounded">
                    <div className="card-header">
                        

                        <span style={{fontSize: "1.4em"}}><i className="fas fa-pencil-alt"></i>&nbsp;{lang[2]}</span>
                        <div className="float-right">
                            <div className=" btn-toolbar " >
                                {/* <div className="btn-group-sm mr-2" role="group" aria-label="First group">
                                    <button type="button" className="btn btn-success" onClick={this.handleClickSave}><i className="fas fa-save" title={lang[1]}></i></button>
                                </div>
                                <div className="btn-group-sm mr-2" role="group" aria-label="First group">
                                    <button type="button" className="btn btn-success" onClick={this.handleClickPreview}>{lang[45]}</button>
                                </div> */}

                                {/* <Link to='/products/orders_mgr'>
                                    <div className="btn-group-sm mr-2" role="group" aria-label="Second group">
                                        <button type="button" className="btn btn-primary">{lang[12]}&nbsp;
                                            <i className="fas fa-arrow-alt-circle-right" title={lang[12]}></i></button>
                                    </div>
                                </Link> */}
                            </div>
                        </div>                        
                    </div>
                    <div className="card-body">
                        
<div className="row">
{/* 订单 */}
<div className="col-md-12">
<div className="box box-info " id="order-statistics-box">
<div className="box-header with-border">
<h3 className="box-title">
<i className="fa fa-shopping-cart"></i> &nbsp;&nbsp;
<Link to='/products/order_dashboard'> {lang[14]} </Link>
</h3>
<div className="box-tools pull-right">
<button className="btn-s btn-xs btn-info btn-flat margin-r-5 bg-light-blue" onClick={(e)=>{this.handleClickOrderReport(3);}}
data-chart-role="toggle-chart" data-chart-period="year">{lang[16]}</button>
<button className="btn-s btn-xs btn-info btn-flat margin-r-5" data-chart-role="toggle-chart" onClick={(e)=>{this.handleClickOrderReport(2);}}
 data-chart-period="month">{lang[17]}</button>
<button className="btn-s btn-xs btn-info btn-flat" 
onClick={(e)=>{this.handleClickOrderReport(1);}}
data-chart-role="toggle-chart" data-chart-period="week">{lang[18]}</button>


</div>
</div>
<div className="box-body">
<div className="chart">
    <div className="chartjs-size-monitor col-md-6"  >
        <div  id="chartOrdersRMB">
<img src="/images/loading.gif" />
            </div>
            </div>


    <div className="chartjs-size-monitor col-md-6"  >
        <div  id="chartOrdersCAD">
        <img src="/images/loading.gif" />
            </div>
            </div>            
</div>


</div>
</div>
</div>


</div>




<div className="row">

{/* 用户 */}

<div className="col-md-12">
<div className="box box-info " id="order-statistics-box">
<div className="box-header with-border">
<h3 className="box-title">
<i className="fa fa-user"></i>&nbsp;&nbsp;
<Link to='/users'> {lang[15]}</Link>
</h3>
<div className="box-tools pull-right">
<button className="btn-s btn-xs btn-info btn-flat margin-r-5 bg-light-blue" onClick={(e)=>{this.handleClickUserReport(3);}}  data-chart-role="toggle-chart" data-chart-period="year">{lang[16]}</button>
<button className="btn-s btn-xs btn-info btn-flat margin-r-5" onClick={(e)=>{this.handleClickUserReport(2);}}  data-chart-role="toggle-chart" data-chart-period="month">{lang[17]}</button>
<button className="btn-s btn-xs btn-info btn-flat" onClick={(e)=>{this.handleClickUserReport(1);}}  data-chart-role="toggle-chart" data-chart-period="week">{lang[18]}</button>


</div>
</div>
<div className="box-body">
<div className="chart">
    <div className="chartjs-size-monitor" >
        <div id="chartUsers" >
        <img src="/images/loading.gif" />            
            </div>
            </div>
            </div>

{/* chart here */}

</div>
</div>
</div>







</div>






<div className="row">

{/* 最佳产品 */}

<div className="col-md-6">
<div className="box-no-shadow box-info " id="order-statistics-box">
<div className="box-header with-border">
<h3 className="box-title">
<i className="fa fa-cart-arrow-down"></i>&nbsp;&nbsp;
{lang[20]}
</h3>

</div>
<div className="box-body">
<div className="chart">
    <div className="chartjs-size-monitor" >
        <div id="bestOrderedProduct" >


        <div className="table-responsive rounded">
                <table className="table table-hover table-bordered text-center">
                    <thead>
                        <tr className="table-primary">
                            <th scope="col" style={{width:"20%"}}>{lang[11]}</th>
                            <th scope="col" style={{width:"15%"}}>{lang[6]}</th>                            
                        </tr>
                    </thead>
                    <tbody>
                        {best_ordered_row}
                    </tbody>
                </table>                
            </div>

            </div>
            </div>
            </div>



</div>
</div>
</div>


<div className="col-md-6">
<div className="box-no-shadow box-info " id="order-statistics-box">
<div className="box-header with-border">
<h3 className="box-title">
<i className="fa fa-chrome"></i>&nbsp;&nbsp;
{lang[21]}
</h3>
</div>
<div className="box-body">
<div className="chart">
    <div className="chartjs-size-monitor" >
        <div id="bestViewedProduct" >
  
        <div className="table-responsive rounded">
              
              <table className="table table-hover table-bordered text-center">
                  <thead>
                      <tr className="table-primary">
                      <th scope="col" style={{width:"20%"}}>{lang[11]}</th>
                          <th scope="col" style={{width:"20%"}}>{lang[4]}</th>                          
                      </tr>
                  </thead>
                  <tbody>
                      {best_viewed_row}
                  </tbody>
              </table>                
          </div>

            </div>
            </div>
            </div>



</div>
</div>
</div>




</div>


{/* 低库存产品及从没销售的产品 */}


<div className="row">

{/* 低库存产品 */}

<div className="col-md-6">
<div className="box-no-shadow box-info " id="order-statistics-box">
<div className="box-header with-border">
<h3 className="box-title">
<i className="fa fa-cart-arrow-down"></i>&nbsp;&nbsp;
{lang[22]}
</h3>

</div>
<div className="box-body">
<div className="chart">
    <div className="chartjs-size-monitor" >
        <div id="bestOrderedProduct" >


        <div className="table-responsive rounded">
                <table className="table table-hover table-bordered text-center">
                    <thead>
                        <tr className="table-primary">
                            <th scope="col" style={{width:"20%"}}>{lang[11]}</th>
                            <th scope="col" style={{width:"15%"}}>{lang[25]}</th>    
                            <th scope="col" style={{width:"15%"}}>{lang[24]}</th>                            
                        </tr>
                    </thead>
                    <tbody>
                        {low_stock_product_row}
                    </tbody>
                </table>                
            </div>

            </div>
            </div>
            </div>



</div>
</div>
</div>

{/* 从没销售的产品 */}
<div className="col-md-6">
<div className="box-no-shadow box-info " id="order-statistics-box">
<div className="box-header with-border">
<h3 className="box-title">
<i className="fa fa-chrome"></i>&nbsp;&nbsp;
{lang[23]}
</h3>
</div>
<div className="box-body">
<div className="chart">
    <div className="chartjs-size-monitor" >
        <div id="bestViewedProduct" >
  
        <div className="table-responsive rounded">
              
              <table className="table table-hover table-bordered text-center">
                  <thead>
                      <tr className="table-primary">
                      <th scope="col" style={{width:"20%"}}>{lang[11]}</th>
                          {/* <th scope="col" style={{width:"20%"}}>{lang[4]}</th>                           */}
                      </tr>
                  </thead>
                  <tbody>
                      {never_purchased_row}
                  </tbody>
              </table>                
          </div>

            </div>
            </div>
            </div>



</div>
</div>
</div>




</div>


                    </div>
                </div>                
            </div>
        );
    }
}

export default Dashboard;