import React, {Component} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
//import PropTypes from 'prop-types';
import LANGUAGE from '../../../service/Language-data';
import apiRoot from '../../../config.api';

import DocTable from './DocTable';
import SearchBar from '../../../service/components/SearchBar';
import PagingBar from '../../../service/components/PagingBar';
import { formatDateTime } from '../../../service/func-service';
// import CategoriesTree from '../../../service/components/CategoriesTree';
import Tree from 'rc-tree';

class DocManagement extends Component{
    state={
            docList:[],
            searchContent:'',     // Accept the value of "change"; Used for search after the button is clicked.
            searchChange:'',      // Accept the value changed in "input".
            currentPage: 1,
            allCategories: [],
            categories_id:1,
            categories_name:'id:1 - 推荐',
            defaultExpandedKeys:[15,16,26]
        };
    
    /*static propTypes={
        langState: PropTypes.string.isRequired,
        logInName: PropTypes.string.isRequired,
        clickUpdate: PropTypes.func.isRequired
    }*/
    
    handleSearchContent=(e)=>{
        this.setState({
            searchChange: e.target.value
        });
    };
    
    handleSearchSubmit=(e)=>{
        this.setState({
            searchContent: this.state.searchChange,
            currentPage: 1,        
        });
        e.preventDefault();
    }

    handlePageChange=(e)=>{
        this.setState({
            currentPage: +e.target.value
        });
    }

    handleClickDelete=(e, id,status)=>{
        var text = `Do you really want to disable No. ${id} document?`;
        if(status === 1){
            text = `Do you really want to enable No. ${id} document?`;
        }
        const {categories_id} = this.state;
        if(window.confirm(text)){
            var user_id = localStorage.getItem("logInId");
            axios.put(apiRoot + "documents/delete", {
                id,
                user_id,
                status
              })
                .then(res => {
                    alert("操作成功!");
                    this.getPostsByCategoriesId(categories_id);
                    // alert("You have deleted a document.");
                    // axios.get(apiRoot + "documents")
                    // .then((res) => {     
                    //     this.setState({ docList: res.data });
                    // })
                    // .catch((err) => {            
                    //     console.log("Getting documents error in DocManagement component!");            
                    // });
                })
                .catch(err=>{
                    console.log("Deleting documents error in DocManagement component!");
            });
            e.preventDefault();
        }
    }

    handleClickUpdate=(id)=>{
        this.props.clickUpdate(id);
    }
    handleClickShowAtHome=(id,isShow)=>{

        var popText ="";
        if( parseInt( isShow)==0){
            popText ="请确认是否推荐这篇文章!" ;
        }else{
            popText ="请确认是否取消推荐!" ;
        }

        const {categories_id} = this.state;
        if(window.confirm(popText)){
            axios.put(apiRoot + "posts/recommend", {id:id,show_at_home:isShow})
                .then(res => {
                    alert("操作成功!");
                    this.getPostsByCategoriesId(categories_id);
                    // axios.get(apiRoot + "documents")
                    // .then((res) => {                   
                    //     this.setState({ docList: res.data });  
                    // })
                    // .catch((err) => {
                    //     console.log("[Error] - GET /products - at ProductManagement component!");
                    //     console.log(err);           
                    // });
                })
                .catch(err=>{
                    console.log("[Error] - PUT /products/delete - at ProductManagement component!");
                    console.log(err);
            });
        }        
    }
    getPostsByCategoriesId=(categories_id)=>{
        axios.post(apiRoot + "documentsByCategoriesId",{categories_id})
            .then((res) => {            
                this.setState({ docList: res.data });  
            })
            .catch((err) => {            
                console.log("Getting documents error!");            
            });
    }
    componentDidMount(){
        axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
        axios.get(apiRoot + "documents/categories")
            .then((res) => {            
                this.setState({ allCategories: res.data });
            })
            .catch((err) => {            
                console.log("Getting categories error in DocCategories component!");            
            });        
        axios.post(apiRoot + "documentsByCategoriesId",{categories_id:1})
            .then((res) => {            
                this.setState({ docList: res.data });  
            })
            .catch((err) => {            
                console.log("Getting documents error!");            
            });
    }
    handleTreeSelect =(selectedKeys, info) => {
        // console.log('onSelect', selectedKeys, info);
        var selKey = info.node.props.eventKey;
        var selTitle = info.node.props.title;
        //alert("selected" + selKey +" title="+selTitle);
        this.setState({
            categories_name:selTitle,
            categories_id:selKey
        });
        this.getPostsByCategoriesId(selKey);
    }
    buildTree=(data, id , pid)=> {
        var treeList = [];
        var DBdata =[];
        // The "for" loop implements a deep copy for input "data" to prevent the original "data" from being changed.
        for(let i=0; i<data.length ; i++){
            var b= JSON.parse(JSON.stringify(data[i]));
            b.name = "id: " + b.categories_id + " - " + b.name;
            treeList.push(b);
            DBdata.push(b);
        }
        var afun = function(ys, json) {  
                var len = json.length;  
                while(len > 0) {  
                    len--;  
                    var oo = json[len];  
                    if(ys[id] === oo[pid]) {  
                        ys.children = ys.children || [];  
                        ys.children.push(oo)  
                        json.splice(len, 1);  
                    }  
                }  
            }
        DBdata.forEach(function(value) {  
            afun(value, treeList);  
        });
        return treeList;  
    }     
    render(){
        let lang='';
        if(this.props.langState ==='cn') lang=LANGUAGE.DocManagement.cn;
        else lang=LANGUAGE.DocManagement.en;

        const {docList, searchContent,allCategories,categories_name}=this.state;
        const { permissions } = this.props;


        var treeList = this.buildTree(allCategories, 'categories_id', 'parent_id');
        // The two statements below work for changing properties' name. So, it can satisfy the requirements of rc-tree.
        var treeListJSON=JSON.stringify(treeList).replace(/categories_id/g, "key").replace(/name/g, "title");
        var treeListStr=JSON.parse(treeListJSON);

        const row=[];
        docList.forEach((doc, index)=>{
            if(
                (doc.username.toUpperCase().indexOf(searchContent.toUpperCase())===-1)&&
                (doc.post_title.toUpperCase().indexOf(searchContent.toUpperCase())===-1)){
                return;
            }
            row.push(
                <tr key={index}>
                    <th style={{verticalAlign: 'middle'}} scope="row">{doc.id}</th>
                    <td style={{verticalAlign: 'middle'}} >{doc.username}</td>
                    <td style={{verticalAlign: 'middle'}} >{doc.post_title}</td>
                    <td style={{verticalAlign: 'middle'}} >
                        {(()=>{
                            switch(doc.feature_image_url.slice(0,4)){
                                case '':        return (<span>{'No Image'}</span>);
                                case 'http':    return (<img src={doc.feature_image_url} style={{height: '50px'}} alt={index}/>);
                                default:        return (<a href={apiRoot + 'display/image/file?file=' + doc.feature_image_url}>
                                                            <img src={apiRoot + 'display/image/file?file=' + doc.feature_image_url} style={{height: '50px'}} alt={index}/>
                                                        </a>)
                            }
                        })()}
                    </td>
                    <td style={{verticalAlign: 'middle'}} >{formatDateTime(doc.create_time)}</td>
                    <td style={{verticalAlign: 'middle'}} >{formatDateTime(doc.modify_time)} <br/>
                    {doc.update_by_user !== null ? "By "+doc.update_by_user:""}
                    </td>
                    <td style={{verticalAlign: 'middle'}} >{doc.total_viewed}</td>
                    {doc.recommend === 1? 
                        <td style={{verticalAlign: 'middle', color:'green'}} className='text-center' >Yes</td>:
                        <td style={{verticalAlign: 'middle', color:'red'}} className='text-center'>No</td>
                    } 
                                        {doc.active === 1? 
                        <td style={{verticalAlign: 'middle'}} className='text-center' >
                            <a href="#" onClick={(e)=>this.handleClickDelete(e,doc.id,0)} >
                            Enabled </a> </td>:
                        <td style={{verticalAlign: 'middle', color:'red'}} className='text-center'>
                            <a href="#" onClick={(e)=>this.handleClickDelete(e,doc.id,1)} >
                            Disabled </a> </td>
                    }
                    {
                        permissions[1][3].value || permissions[1][4].value?
                        <td style={{verticalAlign: 'middle'}} >
                            <div className="btn-group">
                                {
                                    permissions[1][3].value?
                                    <button className="btn btn-success btn-sm mr-2 rounded" 
                                    onClick={()=>this.handleClickShowAtHome(doc.id,doc.recommend)} title={lang[2]}><i className="fas fa-home"></i></button>:null
                                }                                     
                                { 
                                    permissions[1][3].value? 
                                    <Link to="/documents/update" className="btn btn-success mr-2 btn-sm rounded" onClick={()=>this.handleClickUpdate(doc.id)}  title={lang[2]}><i className="fas fa-pencil-alt"></i></Link>
                                    : null
                                }
                                {/* {
                                    permissions[1][4].value?
                                    <button type="button" className="btn btn-danger btn-sm rounded" onClick={(e)=>this.handleClickDelete(e,doc.id)}  title={lang[3]}><i className="fas fa-trash-alt" ></i></button>
                                    : null
                                } */}
                            </div>
                        </td> : null
                    }
                </tr>
            );
        });

        const totalPages=Math.ceil(row.length/10);
        const onePage=row.reverse().slice((this.state.currentPage*10-10), (this.state.currentPage*10));

        return(  
            <div className="container-fluid">
                <div className="card rounded">
                    <div className="card-header">
                        <h5>{lang[0]}</h5>
                    </div>
                    <div className="card-body">

<div className="row">
<div className="col-md-2">
    当前目录:{categories_name}
{/* <CategoriesTree 
onSelect={this.handleTreeSelect}
data={this.state.allCategories}/> */}


<div style={{fontSize:"1.2em"}}>
                <Tree 
                    treeData={treeListStr}
                    defaultExpandAll
                    onSelect={this.handleTreeSelect}
                    // defaultExpandedKeys={this.state.defaultExpandedKeys}
                    //selectedKeys={this.state.selectedKeys}
                    // multiple
                    //defaultExpandParent
                    showLine
                    //showIcon={false}
                ></Tree>
    </div>

    </div>    
    <div className="col-md-10">    
                <div className="row">
                            <div className="col">
                                <div className="float-left">
                                    <SearchBar 
                                        langState={this.props.langState}
                                        searchContent={this.handleSearchContent}
                                        searchSubmit={this.handleSearchSubmit} />
                                </div>
                            </div>
                            {
                                permissions[1][2].value?
                                <div className="col">
                                    <div className="float-right">
                                        <Link to='/documents/add' className="btn btn-primary btn-sm rounded" title={lang[1]} ><i className="fas fa-plus-square"></i></Link> 
                                    </div>
                                </div>: null
                            }
                        </div>    
                        <DocTable
                            langState={this.props.langState}
                            onePage={onePage}
                            permissions={this.props.permissions}/>
                        <PagingBar 
                            totalPages={totalPages}                     
                            currentPage={this.state.currentPage}
                            pageChange={this.handlePageChange}/>
    </div>                            
</div>                            
                    </div>
                </div>      
            </div>
        );
    }
}

export default DocManagement;