import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Link} from 'react-router-dom';
import LANGUAGE from '../../../service/Language-data';
import apiRoot from '../../../config.api';

import { 
    loadWarehouse,
    getWarehouseStockByProductId,
    productStockIn    
} from '../../../service/axios-service';

//产品入库管理
class StockIn extends Component{
    state={
        warehouseList: [],
        stockInfoList:[],
        warehoue_id: 1,
        quantity: 0,
        language: '' 
    }

    static propTypes={
        langState: PropTypes.string.isRequired,
        productId: PropTypes.number.isRequired,
    };

    handleChange=(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleClick=(e)=>{
       // const { langState } = this.props;
        const { productId } = this.props;
        const { warehoue_id, quantity,stockInfoList } = this.state;
        const userId = sessionStorage.getItem('logInId');

        var isInsert =1;
        stockInfoList.forEach((one, index)=>{   
            if(one.id === warehoue_id) isInsert=0;
        });
        const data={ productId,warehoue_id, quantity, userId,isInsert  };
        productStockIn(data)
            .then((res) => {
                alert("A product was Stock In!");
            })
            .catch((err) => { 
                console.log("[Error] - GET /products/attribute_groups - at StockIn component!");
                console.log(err);            
        });
    }

    componentDidMount(){
        const { langState } = this.props;
        const language = langState==='cn'? '简体中文':'English';
        const { productId } = this.props;
        console.log("componentDidMount ");
        axios.all([
            loadWarehouse(),
            getWarehouseStockByProductId(productId)   
        ]).then(axios.spread((r1,r2)=>{

            console.log("get data from database");
            this.setState({ 
                warehouseList:      r1.data,
                stockInfoList:       r2.data
            });
        })).catch((err) => { 
             console.log(err);           
        });    
    }

    render(){
       console.log(" render start");
        let lang='';
        const { langState } = this.props;
        if(langState ==='cn') lang=LANGUAGE.StockIn.cn;
        else lang=LANGUAGE.StockIn.en;

        let stockInProductName = sessionStorage.getItem('stockInProductName');

        
        const {langList,warehouseList, stockInfoList,quantity} = this.state;
        const row=[];
        if(stockInfoList !== undefined){
        stockInfoList.forEach((one, index)=>{            
            // if( (one.warehouse_name.toUpperCase().indexOf(searchContent.toUpperCase())===-1)){
            //     return;
            // }
            row.push(
                <tr key={index} >
                   <td style={{verticalAlign: 'middle'}} className="text-center">{one.id}</td>
                    <td style={{verticalAlign: 'middle'}} >{one.warehouse_name}</td>
                    <td style={{verticalAlign: 'middle'}} >{one.quantity}</td>
                </tr>
            );
        });
        }
        // const totalPages=Math.ceil(row.length/10);
        const onePage=row;


        
        return(
            <div className="container-fluid">
                <div className="card rounded">
                    <div className="card-header">
                        <span style={{fontSize: "1.4em"}}><i className="fas fa-pencil-alt"></i>&nbsp;{lang[0]}</span>
                        <div className="float-right">
                            <div className=" btn-toolbar " >
                                <div className="btn-group-sm mr-2" role="group" aria-label="First group">
                                    <button type="button" className="btn btn-success" onClick={this.handleClick} title={lang[1]}><i className="fas fa-save"></i></button>
                                </div>
                                <Link to='/products'>
                                    <div className="btn-group-sm mr-2" role="group" aria-label="Second group">
                                        <button type="button" className="btn btn-primary" title={lang[2]}><i className="fas fa-arrow-alt-circle-right"></i></button>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-body" style={{fontWeight: 'bold'}}>
                        <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="name" className="col-form-label">{lang[3]}</label>
                            </div>
                            <div className="col col-sm-10 input-group-sm">
                              <label htmlFor="name" className="col-form-label">{stockInProductName}</label>
                                {/* <input name="name" type="text" className="form-control" id="name" value={this.state.name} onChange={this.handleChange} required/> */}
                            </div>
                        </div>
                        <hr/>
                        <div className="row text_center">
                           <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="name" className="col-form-label">{lang[4]}</label>
                            </div>
                        
                        

                        <div className=" col col-sm-10 table-responsive rounded">
                            <table className="table table-hover table-bordered">
                                <thead>
                                    <tr className="table-primary">
                                        
                                        <th scope="col" style={{width: "5%"}} className="text-center">#</th>
                                        <th scope="col" style={{width: "40%"}}>{lang[5]}</th>
                                        <th scope="col" style={{width: "40%"}}>{lang[6]}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {onePage}
                                </tbody>
                            </table>
                        </div>
                        </div>
                        <hr/>
                        <div className="row text_center">
                           <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="name" className="col-form-label">{lang[7]}</label>
                            </div>
                        
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="lang" className="col-form-label">{lang[8]}</label>
                            </div>
                            <div className="col col-sm-3 input-group-sm">
                                <select name="warehoue_id" className="custom-select mr-sm-2" id="warehoue_id" value={this.state.warehoue_id} onChange={this.handleChange}>
                                    {warehouseList.map((one, index) => <option key={index} value={one.id}>{one.warehouse_name}</option> )}
                                </select>
                            </div>
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="quantity" className="col-form-label">{lang[9]}</label>
                            </div>
                            <div className="col col-sm-3 input-group-sm">
                                <input name="quantity" type="text" className="form-control" id="quantity" value={quantity} onChange={this.handleChange} required/>
                            </div>

                        </div>

  
                        
                    </div>
                </div>            
            </div>
        );
    }
}

export default StockIn;