import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import LANGUAGE from '../../../service/Language-data';
import SearchBar from '../../../service/components/SearchBar';
import PagingBar from '../../../service/components/PagingBar';
import { toCurrency,isValidCustom } from '../../../service/func-service';
import { getOrderReportingByDateRange } from '../../../service/axios-service';
import {GoogleCharts} from 'google-charts';



import axios from 'axios';

var moment = require('moment');
class RevenueReporting extends Component{
    state={
            subjectList:[],
            orders_status:[],
            orders_total:[],
            searchContent:'',     // Accept the value of "change"; Used for search after the button is clicked.
            searchChange:'',      // Accept the value changed in "input".
            currentPage: 1,
            isSearchByProduct:false,
            start: new Date().toISOString().slice(0, 10),
            end: new Date().toISOString().slice(0, 10),
            preset_value: 5, // 从 1开始
            compareToValue:1,
            dateText:'',
            vsDateText:'',
            orderRecords:[],
            previousOrderRecords:[],
            order_total:[],
            gross_sale_total:[],
            return_total:[],
            discount_total:[],
            net_sale_total:[],
            tax_total:[],
            shipping_total:[],
            total_sales:[],
            tableRowData:[],
            dataArrayRMB:[],
            dataArrayCAD:[]
        };
    
    static propTypes = {
        langState: PropTypes.string.isRequired
    };
    
    getCompareDate=(presetValue)=>{
        var retString ="";
        var tmp1 = new Date().toISOString();
        var thisYear= tmp1.slice(0, 4);
        var thisMonth= tmp1.slice(5, 7);
        var toDay = tmp1.slice(8, 10);
        const {compareToValue} = this.state;
        switch(parseInt( presetValue)){
            case 1: //Today
                if(compareToValue === 1){
                    //yesterday
                    var momentToday = moment(tmp1);
                    var yesterday = momentToday.subtract(1,"day");
                    retString= thisYear+"-"+thisMonth+"-"+toDay+","+thisYear+"-"+thisMonth+"-"+toDay+"|"+yesterday.format("YYYY-MM-DD")+","+yesterday.format("YYYY-MM-DD");            
                }else{
                    //last year
                    var momentToday = moment(tmp1);
                    var yesterday = momentToday.subtract(1,"year");
                    retString= thisYear+"-"+thisMonth+"-"+toDay+","+thisYear+"-"+thisMonth+"-"+toDay+"|"+yesterday.format("YYYY-MM-DD")+","+yesterday.format("YYYY-MM-DD");            

                }
                break;
            case 2: //Yesterday
                if(compareToValue === 1){
                    //yesterday
                    var momentToday = moment(tmp1);
                    var yesterday = momentToday.subtract(1,"day");
                    var str1 = yesterday.format("YYYY-MM-DD")+","+yesterday.format("YYYY-MM-DD");
                    var theDayBeforeYesterday = momentToday.subtract(2,"day");
                    retString= str1 + "|"+ theDayBeforeYesterday.format("YYYY-MM-DD")+","+theDayBeforeYesterday.format("YYYY-MM-DD");            
                }else{
                    //last year
                    var momentToday = moment(tmp1);
                    var yesterday = moment(tmp1).subtract(1,"day");
                    //yesterday = yesterday.subtract(1,"year");

                    var str1 = yesterday.format("YYYY-MM-DD")+","+yesterday.format("YYYY-MM-DD");
                    var theDayBeforeYesterday = momentToday.subtract(1,"day").subtract(1,"year");
                    //theDayBeforeYesterday = theDayBeforeYesterday.subtract(1,"year");
                    retString= str1 + "|"+ theDayBeforeYesterday.format("YYYY-MM-DD")+","+theDayBeforeYesterday.format("YYYY-MM-DD");            

                }

                break;
            case 3: // week to Today
                if(compareToValue === 1){
                    //yesterday
                    var momentToday = moment(tmp1);
                    var str2 = momentToday.format("YYYY-MM-DD");
                    
                    var weekDay = momentToday.weekday() - 1;
                    var yesterday = momentToday.subtract(weekDay,"day");
                    var str1 = yesterday.format("YYYY-MM-DD")+","+str2;

                    var previousDate2 = moment(tmp1).subtract(7,"day");
                    var previousDate1 = yesterday.subtract(7,"day");
                    retString= str1 + "|"+ previousDate1.format("YYYY-MM-DD")+","+previousDate2.format("YYYY-MM-DD");            
                }else{
                    //last year
                    var momentToday = moment(tmp1);
                    var str2 = momentToday.format("YYYY-MM-DD");
                    
                    var weekDay = momentToday.weekday() - 1;
                    var yesterday = momentToday.subtract(weekDay,"day");
                    var str1 = yesterday.format("YYYY-MM-DD")+","+str2;

                    var previousDate2 = moment(tmp1).subtract(1,"year");
                    var previousDate1 = yesterday.subtract(1,"year");
                    retString= str1 + "|"+ previousDate1.format("YYYY-MM-DD")+","+previousDate2.format("YYYY-MM-DD");                         
                }
                break;
            case 4: // last week
                if( compareToValue === 1){
                    
                    var weekDay1 = moment(tmp1).subtract(1, 'isoWeek').startOf('week');
                    var weekDay2 = moment(tmp1).subtract(1, 'isoWeek').endOf('week');

                    var previousWeekDay1= moment(tmp1).subtract(2, 'isoWeek').startOf('week');
                    var previousWeekDay2 = moment(tmp1).subtract(2, 'isoWeek').endOf('week');
                    retString= weekDay1.format("YYYY-MM-DD")+","+ weekDay2.format("YYYY-MM-DD") + "|"+ previousWeekDay1.format("YYYY-MM-DD")+","+previousWeekDay2.format("YYYY-MM-DD"); 
                }else{
                    var weekDay1 = moment(tmp1).subtract(1, 'isoWeek').startOf('week');
                    var weekDay2 = moment(tmp1).subtract(1, 'isoWeek').endOf('week');

                    var str1 = weekDay1.format("YYYY-MM-DD")+","+ weekDay2.format("YYYY-MM-DD");

                    var previousWeekDay1= weekDay1.subtract(1, 'year');
                    var previousWeekDay2 = weekDay2.subtract(1, 'year');
                    retString= str1 + "|"+ previousWeekDay1.format("YYYY-MM-DD")+","+previousWeekDay2.format("YYYY-MM-DD"); 
                }
                break;
            case 5: // month to  today
                if( compareToValue === 1){
                    var lastYear = thisYear;
                    var lastMonth = thisMonth -1;
                    if (thisMonth===1){
                        lastMonth=12;
                        lastYear = thisYear -1;
                    } 
                    retString= thisYear+"-"+thisMonth+"-01,"+thisYear+"-"+thisMonth+"-"+toDay+"|"+lastYear+"-"+lastMonth+"-01,"+lastYear+"-"+lastMonth+"-"+toDay;            
                           
                }else{
                    var day1 = moment(tmp1).subtract(1, 'year').startOf('month');
                    var day2 = moment(tmp1).subtract(1, 'year');
                    retString= thisYear+"-"+thisMonth+"-01,"+thisYear+"-"+thisMonth+"-"+toDay+"|"+day1.format("YYYY-MM-DD")+","+day2.format("YYYY-MM-DD");            
                }
                break;
            case 6: // last month
                if( compareToValue === 1){
                    var day1 = moment(tmp1).subtract(1, 'month').startOf('month');
                    var day2 = moment(tmp1).subtract(1, 'month').endOf('month');

                    var previousDay1= moment(tmp1).subtract(2, 'month').startOf('month');
                    var previousDay2 = moment(tmp1).subtract(2, 'month').endOf('month');
                    retString= day1.format("YYYY-MM-DD")+","+ day2.format("YYYY-MM-DD") + "|"+ previousDay1.format("YYYY-MM-DD")+","+previousDay2.format("YYYY-MM-DD"); 
                        
                }else{
                    var day1 = moment(tmp1).subtract(1, 'month').startOf('month');
                    var day2 = moment(tmp1).subtract(1, 'month').endOf('month');

                    var previousDay1= moment(tmp1).subtract(1, 'month').subtract(1, 'year').startOf('month');
                    var previousDay2 = moment(tmp1).subtract(1, 'month').subtract(1, 'year').endOf('month');
                    retString= day1.format("YYYY-MM-DD")+","+ day2.format("YYYY-MM-DD") + "|"+ previousDay1.format("YYYY-MM-DD")+","+previousDay2.format("YYYY-MM-DD"); 
                }
                break;
            case 7: // Quarter to  today
                if( compareToValue === 1){
                    //moment().quarter(moment().quarter()).startOf('quarter')
                    var day1 = moment().quarter(moment(tmp1).quarter()).startOf('quarter');
                    console.log(" ++++ " + day1.format("YYYY-MM-DD"));
                    var day2 = moment(tmp1);

                    var n = day2.diff(day1,"day");

                    var objQuarter = this.getQuarter();

                    var previousDay1 = objQuarter.quarter3.start;
                    var str1 = previousDay1.format("YYYY-MM-DD");
                    var previousDay2 = previousDay1.add(n,"day");
                

                    // var previousDay1= moment().quarter(moment(tmp1).quarter() - 1 ).startOf('quarter');
                    // console.log(" ++++ " + previousDate1.format("YYYY-MM-DD"));
                    // var previousDay2 = previousDate1.add(n,"day");                        

                    retString= day1.format("YYYY-MM-DD")+","+ day2.format("YYYY-MM-DD") + "|"+ str1+","+previousDay2.format("YYYY-MM-DD"); 
                }else{
                    var day1 = moment().quarter(moment(tmp1).quarter()).startOf('quarter');
                    console.log(" ++++ " + day1.format("YYYY-MM-DD"));
                    var day2 = moment(tmp1);

                    var n = day2.diff(day1,"day");

                    var str = day1.format("YYYY-MM-DD")+","+ day2.format("YYYY-MM-DD");

                    var previousDay1 = day1.subtract(1,"year");
                    var str1 = previousDay1.format("YYYY-MM-DD");
                    var previousDay2 = day2.subtract(1,"year");
                            

                    retString= str + "|"+ str1+","+previousDay2.format("YYYY-MM-DD"); 

                }

                break;
            case 8: // last Quarter
                if( compareToValue === 1){
                    var objQuarter = this.getQuarter();
                    var day1 = objQuarter.quarter3.start;
                    var day2 = objQuarter.quarter3.end;
                    var previousDay1 = objQuarter.quarter2.start;
                    var previousDay2 = objQuarter.quarter2.end;
                    retString= day1.format("YYYY-MM-DD")+","+ day2.format("YYYY-MM-DD") + "|"+ previousDay1.format("YYYY-MM-DD")+","+previousDay2.format("YYYY-MM-DD");                                             
                }else{
                    var objQuarter = this.getQuarter();
                    var day1 = objQuarter.quarter3.start;
                    var day2 = objQuarter.quarter3.end;
                    var str = day1.format("YYYY-MM-DD")+","+ day2.format("YYYY-MM-DD");
                    var previousDay1 = objQuarter.quarter3.start.subtract(1,"year");
                    var previousDay2 = objQuarter.quarter3.end.subtract(1,"year");
                    retString= str + "|"+ previousDay1.format("YYYY-MM-DD")+","+previousDay2.format("YYYY-MM-DD");                                             

                }

                break;
            case 9: // year to  today
                var day1 = moment(tmp1).startOf('year');
                var day2 = moment(tmp1);

                var n = day2.diff(day1,"day");
                var str1 = day1.format("YYYY-MM-DD");
                var str2 = day2.format("YYYY-MM-DD");
                var previousDay1 = day1.subtract(1,"year");
                var previousDay2 = day2.subtract(1,"year");   
                retString= str1+","+ str2 + "|"+ previousDay1.format("YYYY-MM-DD")+","+previousDay2.format("YYYY-MM-DD");                                                                    
                break;     
            case 10: // last year
                var day1 = moment(tmp1).subtract(1,"year").startOf('year');
                var day2 = moment(tmp1).subtract(1,"year").endOf('year');

                var n = day2.diff(day1,"day");
                var str1 = day1.format("YYYY-MM-DD");
                var str2 = day2.format("YYYY-MM-DD");
                var previousDay1 = day1.subtract(1,"year");
                var previousDay2 = day2.subtract(1,"year");   
                retString= str1+","+ str2 + "|"+ previousDay1.format("YYYY-MM-DD")+","+previousDay2.format("YYYY-MM-DD");                           
                break;                           
            case 20: // Custom
                if( compareToValue === 1){
                    const {start,end} = this.state;
                    var day1 = moment(start);
                    var day2 = moment(end);

                    var n = day2.diff(day1,"day");
                    var str1 = day1.format("YYYY-MM-DD");
                    var str2 = day2.format("YYYY-MM-DD");
                    var previousDay1 = day1.subtract(n,"day");
                    var previousDay2 = moment(start).subtract(1,"day");  
                    
                    retString= str1+","+ str2 + "|"+ previousDay1.format("YYYY-MM-DD")+","+previousDay2.format("YYYY-MM-DD");                      
                }else{
                    const {start,end} = this.state;
                    var day1 = moment(start);
                    var day2 = moment(end);

                    var n = day2.diff(day1,"day");
                    var str1 = day1.format("YYYY-MM-DD");
                    var str2 = day2.format("YYYY-MM-DD");
                    var previousDay1 = day1.subtract(1,"year");
                    var previousDay2 = day2.subtract(1,"year");  
                    
                    retString= str1+","+ str2 + "|"+ previousDay1.format("YYYY-MM-DD")+","+previousDay2.format("YYYY-MM-DD");                      

                }
                break;                  
        }
        console.log("presetValue=" + presetValue+ " compareToValue=" + compareToValue + " return =" + retString);
        return retString;
    }

    getQuarter=()=>{
        const obj = {};

        /* 0-Jan, 1-Feb, 2-Mar */
        if (moment().month() <= 2) { 
            obj.quarter1 = { start: moment().month(3).startOf('month').add('years', -1), end: moment().month(5).endOf('month').add('years', -1) };
            obj.quarter2 = { start: moment().month(6).startOf('month').add('years', -1), end: moment().month(8).endOf('month').add('years', -1) };
            obj.quarter3 = { start: moment().month(9).startOf('month').add('years', -1), end: moment().month(11).endOf('month').add('years', -1) };
            obj.quarter4 = { start: moment().month(0).startOf('month'), end: moment().month(2).endOf('month') };    
        } else {
            obj.quarter1 = { start: moment().month(3).startOf('month'), end: moment().month(5).endOf('month') };
            obj.quarter2 = { start: moment().month(6).startOf('month'), end: moment().month(8).endOf('month') };
            obj.quarter3 = { start: moment().month(9).startOf('month'), end: moment().month(11).endOf('month') };
            obj.quarter4 = { start: moment().month(0).startOf('month').add('years', 1), end: moment().month(2).endOf('month').add('years', 1) };    
        }
        console.log(obj);   
        return obj;        
    }
    getMonthToDate=()=>{
        var tmp1 = new Date().toISOString();
        var day1 = moment(tmp1);
        var day2 = moment(tmp1).startOf("month");
        // console.log("222="+tmp1);
        // var thisYear= tmp1.slice(0, 4);
        // var thisMonth= tmp1.slice(5, 7);
        // var toDay = tmp1.slice(8, 10);
        return day2.format("MMM DD,YYYY")+" - " + day1.format("MMM DD,YYYY");
    }
    getPreviousMonthToDate=()=>{
        // var thisYear= parseInt(new Date().toISOString().slice(0, 4));
        // var thisMonth=parseInt(new Date().toISOString().slice(5, 7));
        // var toDay = new Date().toISOString().slice(8, 10);  

        var tmp1 = new Date().toISOString();
        var day1 = moment(tmp1).subtract(1,"month").startOf("month");
        var day2 = moment(tmp1).subtract(1,"month");
        
        return day1.format("MMM DD,YYYY")+" - " + day2.format("MMM DD,YYYY");
    }

    setPresetText=(presetValue)=>{
       // const {langState} = this.state;
        let lang='';
        if(this.props.langState ==='cn') lang=LANGUAGE.Reporting.cn;
        else lang=LANGUAGE.Reporting.en;  
        const {compareToValue} = this.state;  
        var tmp1 = new Date().toISOString();    
        switch(parseInt( presetValue)){
            case 1: //Today
                if(compareToValue === 1){
                    //yesterday
                    var momentToday = moment(tmp1);
                    
                    var text1= lang[6] + momentToday.format("MMM DD,YYYY");
                    var yesterday = momentToday.subtract(1,"day");
                    var text2 = "vs. " + lang[18] + yesterday.format("MMM DD,YYYY");
                    this.setState({
                        dateText: text1,
                        vsDateText: text2
                    });
                }else{
                    //last year
                    var momentToday = moment(tmp1);
                    
                    var text1= lang[6] + momentToday.format("MMM DD,YYYY");
                    var yesterday = momentToday.subtract(1,"year");
                    var text2 = "vs. " + lang[19] + yesterday.format("MMM DD,YYYY");
                    this.setState({
                        dateText: text1,
                        vsDateText: text2
                    });                    
                }
                break;
            case 2: //Yesterday
                if(compareToValue === 1){
                    //yesterday
                    var momentToday = moment(tmp1);
                    var yesterday = momentToday.subtract(1,"day");
                    var text1= lang[7] + yesterday.format("MMM DD,YYYY");
                    var theDayBeforeYesterday = momentToday.subtract(2,"day");
                    var text2 = "vs. " + lang[18] + theDayBeforeYesterday.format("MMM DD,YYYY");
                    this.setState({
                        dateText: text1,
                        vsDateText: text2
                    });
                }else{
                    //last year
                    var momentToday = moment(tmp1);
                    var yesterday = moment(tmp1).subtract(1,"day");
                   // yesterday = yesterday.subtract(1,"year");

                    var str1 = yesterday.format("YYYY-MM-DD")+","+yesterday.format("YYYY-MM-DD");
                    var theDayBeforeYesterday = momentToday.subtract(1,"day").subtract(1,"year");
                    // theDayBeforeYesterday = theDayBeforeYesterday.subtract(1,"year");

                    var text1= lang[7] + yesterday.format("MMM DD,YYYY");
                    var text2 = "vs. " + lang[19] + theDayBeforeYesterday.format("MMM DD,YYYY");
                    this.setState({
                        dateText: text1,
                        vsDateText: text2
                    });
                }

                break;
            case 3: // week to Today
                if( compareToValue === 1){
                    var momentToday = moment(tmp1);
                    var str2 = momentToday.format("MMM DD,YYYY");
                    
                    var weekDay = momentToday.weekday() - 1;
                    var yesterday = momentToday.subtract(weekDay,"day");
                    var str1 = yesterday.format("MMM DD,YYYY")+" - "+str2;

                    var previousDate2 = moment(tmp1).subtract(7,"day");
                    var previousDate1 = yesterday.subtract(7,"day");

                    var text1= lang[8] + str1;
                    var theDayBeforeYesterday = momentToday.subtract(2,"day");
                    var text2 = "vs. " + lang[18] + previousDate1.format("MMM DD,YYYY") +" - " + previousDate2.format("MMM DD,YYYY");
                    this.setState({
                        dateText: text1,
                        vsDateText: text2
                    });
                }else{
                    var momentToday = moment(tmp1);
                    var str2 = momentToday.format("MMM DD,YYYY");
                    
                    var weekDay = momentToday.weekday() - 1;
                    var yesterday = momentToday.subtract(weekDay,"day");
                    var str1 = yesterday.format("MMM DD,YYYY")+" - "+str2;

                    var previousDate2 = moment(tmp1).subtract(1,"year");
                    var previousDate1 = yesterday.subtract(1,"year");

                    var text1= lang[8] + str1;
                    var theDayBeforeYesterday = momentToday.subtract(2,"day");
                    var text2 = "vs. " + lang[19] + previousDate1.format("MMM DD,YYYY") +" - " + previousDate2.format("MMM DD,YYYY");
                    this.setState({
                        dateText: text1,
                        vsDateText: text2
                    });
                }
                break;
            case 4: // last week
                if( compareToValue === 1){
                    var weekDay1 = moment(tmp1).subtract(1, 'isoWeek').startOf('week');
                    var weekDay2 = moment(tmp1).subtract(1, 'isoWeek').endOf('week');

                    var previousDate1= moment(tmp1).subtract(2, 'isoWeek').startOf('week');
                    var previousDate2 = moment(tmp1).subtract(2, 'isoWeek').endOf('week');
                    var text1= lang[9] + weekDay1.format("MMM DD,YYYY") +" - " + weekDay2.format("MMM DD,YYYY");
                    
                    var text2 = "vs. " + lang[18] + previousDate1.format("MMM DD,YYYY") +" - " + previousDate2.format("MMM DD,YYYY");
                    this.setState({
                        dateText: text1,
                        vsDateText: text2
                    });                    
                }else{
                    var weekDay1 = moment(tmp1).subtract(1, 'isoWeek').startOf('week');
                    var weekDay2 = moment(tmp1).subtract(1, 'isoWeek').endOf('week');

                    var text1= lang[9] + weekDay1.format("MMM DD,YYYY") +" - " + weekDay2.format("MMM DD,YYYY");

                    var previousDate1= weekDay1.subtract(1, 'year');
                    var previousDate2 = weekDay2.subtract(1, 'year');
                    
                    
                    var text2 = "vs. " + lang[19] + previousDate1.format("MMM DD,YYYY") +" - " + previousDate2.format("MMM DD,YYYY");
                    this.setState({
                        dateText: text1,
                        vsDateText: text2
                    });   
                }
                break;
            case 5: // month to  today
                if( compareToValue === 1){
                    var text1= lang[10] + this.getMonthToDate();
                    var text2 = "vs. " + lang[18] + this.getPreviousMonthToDate();
                    this.setState({
                        dateText: text1,
                        vsDateText: text2
                    });
                }else{
                    var text1= lang[10] + this.getMonthToDate();

                    var previousDate1 = moment(tmp1).subtract(1, 'year').startOf('month');
                    var previousDate2 = moment(tmp1).subtract(1, 'year');

                    var text2 = "vs. " + lang[19] + previousDate1.format("MMM DD,YYYY") +" - " + previousDate2.format("MMM DD,YYYY");
                    this.setState({
                        dateText: text1,
                        vsDateText: text2
                    });                    
                }
                break;
            case 6: // last month
                if( compareToValue === 1){
                    var day1 = moment(tmp1).subtract(1, 'month').startOf('month');
                    var day2 = moment(tmp1).subtract(1, 'month').endOf('month');

                    var previousDay1= moment(tmp1).subtract(2, 'month').startOf('month');
                    var previousDay2 = moment(tmp1).subtract(2, 'month').endOf('month');     
                    var text1= lang[11] + day1.format("MMM DD,YYYY") + " - " + day2.format("MMM DD,YYYY");
                    var text2 = "vs. " + lang[18] + previousDay1.format("MMM DD,YYYY") + " - " + previousDay2.format("MMM DD,YYYY");
                    this.setState({
                        dateText: text1,
                        vsDateText: text2
                    });                                       
                }else{
                    var day1 = moment(tmp1).subtract(1, 'month').startOf('month');
                    var day2 = moment(tmp1).subtract(1, 'month').endOf('month');

                    var previousDay1= moment(tmp1).subtract(1, 'month').subtract(1, 'year').startOf('month');
                    var previousDay2 = moment(tmp1).subtract(1, 'month').subtract(1, 'year').endOf('month');     
                    var text1= lang[11] + day1.format("MMM DD,YYYY") + " - " + day2.format("MMM DD,YYYY");
                    var text2 = "vs. " + lang[19] + previousDay1.format("MMM DD,YYYY") + " - " + previousDay2.format("MMM DD,YYYY");
                    this.setState({
                        dateText: text1,
                        vsDateText: text2
                    });    
                }
                break;
            case 7: // Quarter to  today
                if( compareToValue === 1){
                    var day1 = moment().quarter(moment(tmp1).quarter()).startOf('quarter');
                    console.log(" ++++ " + day1.format("YYYY-MM-DD"));
                    var day2 = moment(tmp1);

                    var n = day2.diff(day1,"day");

                    var objQuarter = this.getQuarter();

                    var previousDay1 = objQuarter.quarter3.start;
                    var str1 = previousDay1.format("MMM DD,YYYY");
                    var previousDay2 = previousDay1.add(n,"day");  
                    
                    var text1= lang[12] + day1.format("MMM DD,YYYY") + " - " + day2.format("MMM DD,YYYY");
                    var text2 = "vs. " + lang[18] + str1 + " - " + previousDay2.format("MMM DD,YYYY");
                    this.setState({
                        dateText: text1,
                        vsDateText: text2
                    });                       
                }else{
                    var day1 = moment().quarter(moment(tmp1).quarter()).startOf('quarter');
                    console.log(" ++++ " + day1.format("YYYY-MM-DD"));
                    var day2 = moment(tmp1);

                    var n = day2.diff(day1,"day");

                    // var objQuarter = this.getQuarter();
                    var str = day1.format("MMM DD,YYYY") + " - " + day2.format("MMM DD,YYYY");

                    var previousDay1 = day1.subtract(1,"year");
                    var str1 = previousDay1.format("MMM DD,YYYY");
                    var previousDay2 = day2.subtract(1,"year");  
                    
                    var text1= lang[12] + str;
                    var text2 = "vs. " + lang[19] + str1 + " - " + previousDay2.format("MMM DD,YYYY");
                    this.setState({
                        dateText: text1,
                        vsDateText: text2
                    }); 
                }
                break;
            case 8: // last Quarter
                if( compareToValue === 1){
                    var objQuarter = this.getQuarter();
                    var day1 = objQuarter.quarter3.start;
                    var day2 = objQuarter.quarter3.end;
                    var previousDay1 = objQuarter.quarter2.start;
                    var previousDay2 = objQuarter.quarter2.end;
                    var text1= lang[13] + day1.format("MMM DD,YYYY") + " - " + day2.format("MMM DD,YYYY");
                    var text2 = "vs. " + lang[18] + previousDay1.format("MMM DD,YYYY") + " - " + previousDay2.format("MMM DD,YYYY");
                    this.setState({
                        dateText: text1,
                        vsDateText: text2
                    });                       

                }else{
                    var objQuarter = this.getQuarter();
                    var day1 = objQuarter.quarter3.start;
                    var day2 = objQuarter.quarter3.end;
                    var text1= lang[13] + day1.format("MMM DD,YYYY") + " - " + day2.format("MMM DD,YYYY");

                    var previousDay1 = objQuarter.quarter3.start.subtract(1,"year");
                    var previousDay2 = objQuarter.quarter3.end.subtract(1,"year");
                    
                    var text2 = "vs. " + lang[19] + previousDay1.format("MMM DD,YYYY") + " - " + previousDay2.format("MMM DD,YYYY");
                    this.setState({
                        dateText: text1,
                        vsDateText: text2
                    });   
                }
                break;
            case 9: // year to  today
                
                var day1 = moment(tmp1).startOf('year');
                var day2 = moment(tmp1);

                var n = day2.diff(day1,"day");
                var str1 = day1.format("MMM DD,YYYY");
                var str2 = day2.format("MMM DD,YYYY");
                var previousDay1 = day1.subtract(1,"year");
                var previousDay2 = day2.subtract(1,"year");       
                var text1= lang[14] + str1 + " - " + str2;
                var text2 = "vs. " + compareToValue === 1?lang[18]:lang[19] + previousDay1.format("MMM DD,YYYY") + " - " + previousDay2.format("MMM DD,YYYY");
                this.setState({
                    dateText: text1,
                    vsDateText: text2
                });                                       
                break;     
            case 10: // last year
                
                var day1 = moment(tmp1).subtract(1,"year").startOf('year');
                var day2 = moment(tmp1).subtract(1,"year").endOf('year');

                var n = day2.diff(day1,"day");
                var str1 = day1.format("MMM DD,YYYY");
                var str2 = day2.format("MMM DD,YYYY");
                var previousDay1 = day1.subtract(1,"year");
                var previousDay2 = day2.subtract(1,"year");  
                
                var text1= lang[15] + str1 + " - " + str2;
                var text2 = "vs. " + compareToValue === 1?lang[18]:lang[19] + previousDay1.format("MMM DD,YYYY") + " - " + previousDay2.format("MMM DD,YYYY");
                this.setState({
                    dateText: text1,
                    vsDateText: text2
                });                     
                break; 
            case 20: // Custom
                if( compareToValue === 1){
                    const {start,end} = this.state;
                    var day1 = moment(start);
                    var day2 = moment(end);

                    var n = day2.diff(day1,"day");
                    var str1 = day1.format("MMM DD,YYYY");
                    var str2 = day2.format("MMM DD,YYYY");
                    var previousDay1 = day1.subtract(n,"day");
                    var previousDay2 = moment(start).subtract(1,"day");  
                    
                    var text1= lang[4] + str1 + " - " + str2;
                    var text2 = "vs. " + lang[18] + previousDay1.format("MMM DD,YYYY") + " - " + previousDay2.format("MMM DD,YYYY");
                    this.setState({
                        dateText: text1,
                        vsDateText: text2
                    });                     
                }else{
                    const {start,end} = this.state;
                    var day1 = moment(start);
                    var day2 = moment(end);

                    var n = day2.diff(day1,"day");
                    var str1 = day1.format("MMM DD,YYYY");
                    var str2 = day2.format("MMM DD,YYYY");
                    var previousDay1 = day1.subtract(1,"year");
                    var previousDay2 = day2.subtract(1,"year");  
                    
                    var text1= lang[4] + str1 + " - " + str2;
                    var text2 = "vs. " + lang[19] + previousDay1.format("MMM DD,YYYY") + " - " + previousDay2.format("MMM DD,YYYY");
                    this.setState({
                        dateText: text1,
                        vsDateText: text2
                    }); 
                }
                break;                                             
        }        
    }
    
    handleProviderChange=(e)=>{
       // const { shipping_providers_id} = this.state;  
        this.setState({
            shipping_providers_id : e.target.value,
            [e.target.name]: e.target.value
            
        });
    }
    handleTrackingIdChange=(e)=>{
        //const { tracking_id} = this.state;  
        this.setState({
            tracking_id : e.target.value,
            [e.target.name]: e.target.value
            
        });
    }
    handleSearchContent=(e)=>{
        
        this.setState({
            searchChange: e.target.value
        });
    };
    


    handlePageChange=(e)=>{
        this.setState({
            currentPage: +e.target.value
        });
    }
    displayCurrency=(id)=>{
        if(parseInt(id)===1) return "￥";
        if(parseInt(id)===2) return "C$";
        if(parseInt(id)===3) return "$";
    }
    handleClickOrderDetails=(id)=>{
        // this.props.clickDetail(id);     
        sessionStorage.setItem('view_orders_id', id);
        console.log("444444");
    }

    handleChange=(e)=>{
        console.log(" 11=" + e.target.name +" 222=" + e.target.value);
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleClickPresets=(e)=>{
        var tabPreset = document.getElementById("2-period");
        var tabCustom = document.getElementById("2-custom");


        tabPreset.setAttribute("class","components-button woocommerce-filters-date__tab is-active");
        tabCustom.setAttribute("class","components-button woocommerce-filters-date__tab");

        var tabPresetView = document.getElementById("2-period-view");
        var tabCustomView = document.getElementById("2-custom-view");
        tabPresetView.hidden = false;
        tabCustomView.hidden = true;

        tabPresetView.setAttribute("class","components-tab-panel__tab-content dataRangShow");
        tabCustomView.setAttribute("class","components-tab-panel__tab-content dataRangeHide");
    }
    handleClickCustom=(e)=>{
        var tabPreset = document.getElementById("2-period");
        var tabCustom = document.getElementById("2-custom");
        tabPreset.setAttribute("class","components-button woocommerce-filters-date__tab");
        tabCustom.setAttribute("class","components-button woocommerce-filters-date__tab is-active");        

        var tabPresetView = document.getElementById("2-period-view");
        var tabCustomView = document.getElementById("2-custom-view");
        tabPresetView.hidden = true;
        tabCustomView.hidden = false;
        tabPresetView.setAttribute("class","components-tab-panel__tab-content dataRangHide");
        tabCustomView.setAttribute("class","components-tab-panel__tab-content dataRangeShow");

    }

    handleClickDataRange=(e)=>{
        var btn = document.getElementById("btnDataRange");
        var spanItem = document.getElementById("spanDataRange");

        // get class name
        var btnClassName = btn.getAttribute("class");
        // console.log(" class name is " + btnClassName +" ddd=" + btnClassName.indexOf("is-open"));
        if(btnClassName.indexOf("is-open")>0){
             // 在打开状态,应该收起来
            // console.log("在打开状态,应该收起来");
             btn.setAttribute("class","components-button woocommerce-dropdown-button is-multi-line");
             spanItem.setAttribute("class","dataRangHide");
             spanItem.hidden=true;
         }else{
             btn.setAttribute("class","components-button woocommerce-dropdown-button is-open is-multi-line");
             spanItem.hidden=false;
             spanItem.setAttribute("class","dataRangShow");
             // console.log("应该打开");
        }
    }

    getPreviousPeriod=(start_date,end_date)=>{
        var momentStart = moment(start_date);
        var momentEnd = moment(end_date);        
        const {compareToValue} = this.state;
        if( compareToValue===1){
            // 上一个时间段
            // step 1, 计算有多少天
            var totalDays = moment.duration(momentStart.diff(momentEnd)).asDays();

            var previousStart = momentStart.subtract(totalDays,"day");
            var previousEnd  = momentStart.subtract(1,"day");

            var ret = previousStart.format("YYYY-MM-DD") +"," + previousEnd.format("YYYY-MM-DD");
            console.log("return ="+ ret);
            return ret;
        }else{
            // 去年
            var totalDays = moment.duration(momentStart.diff(momentEnd)).asDays();

            var previousStart = momentStart.subtract(1,"year");
            var previousEnd  = momentEnd.subtract(1,"year");

            var ret = previousStart.format("YYYY-MM-DD") +"," + previousEnd.format("YYYY-MM-DD");
            console.log("return ="+ ret);
            return ret;
        }
    }
    handleCustomSearch=(e)=>{
        //console.log('ddddddddddddd');
        const {start,end} = this.state;

        if(start === '' || start === null || start === undefined) {
            alert("Please choose start date");
            return;
        }
        if(end === '' || end === null || end === undefined) {
            alert("Please choose start date");
            return;
        }    
        if(isValidCustom(start,end) === false){
            alert("请选择2年内的日期范围");
            return;
        }

       // preset_value=20;//custom
       var data={ 
           start_date :start, 
           end_date:end
        };
       var datePreviousData = this.getPreviousPeriod(start,end);
       var datePrevious = datePreviousData.split(",");
       var previous_start_date=datePrevious[0];
       var previous_end_date = datePrevious[1];
       var previous_data={ 
           start_date: previous_start_date, 
           end_date:previous_end_date 
       };
       this.handleClickDataRange(e);

       axios.all([
        getOrderReportingByDateRange(data),
        getOrderReportingByDateRange(previous_data),

       ]).then(axios.spread((r1,r2)=>{
        this.setState({ 
            orderRecords: r1.data,
            previousOrderRecords: r2.data,
            dataArrayRMB:[],
            dataArrayCAD:[],
            order_total:[],
            gross_sale_total:[],
            return_total:[],
            discount_total:[],
            net_sale_total:[],
            tax_total:[],
            shipping_total:[],
            total_sales:[],
            tableRowData:[],            
         });  
         this.buildChartAndTable();
       }))
        .catch((err) => {
                 console.log("[Error] - GET /orders - at RevenueReporting component!");
                 console.log(err);         
         }
      );
      this.setState({
          preset_value:20
      });
       this.setPresetText(20);       


    }
  
    buildChartAndTableAtThisDay=(thisDay)=>{
        const { tableRowData} = this.state;

        var rowData = {
            'day':  thisDay,
            'rmb' :{
                order_total: 0,
                gross_sale_total:0.0,
                return_total:0.0,
                discount_total:0.0,
                net_sale_total:0.0,
                tax_total:0.0,
                shipping_total:0.0,
                total_sales:0.0
            },
            'cad':{
                order_total: 0,
                gross_sale_total:0.0,
                return_total:0.0,
                discount_total:0.0,
                net_sale_total:0.0,
                tax_total:0.0,
                shipping_total:0.0,
                total_sales:0.0
            }

        };
        
        tableRowData.push(rowData);
        this.setState({
            tableRowData
        });

        
    }
    setTableRows=() =>{
        const {tableRowData,orderRecords,previousOrderRecords,gross_sale_total,
            return_total,
            discount_total,
            net_sale_total,
            tax_total,
            shipping_total,
            total_sales,
            dataArrayRMB,
            dataArrayCAD
        } = this.state;
        //计算总计
        var order_total_rmb =0;
        var gross_sale_total_rmb=0;
        var return_total_rmb =0;
        var discount_total_rmb=0;
        var net_sale_total_rmb=0;
        var tax_total_rmb =0;
        var shipping_total_rmb=0;
        var total_sales_rmb=0;

        var order_total_cad =0;
        var gross_sale_total_cad=0;
        var return_total_cad =0;
        var discount_total_cad=0;
        var net_sale_total_cad=0;
        var tax_total_cad =0;
        var shipping_total_cad=0;
        var total_sales_cad=0;        



        

        
        
        for(var k=0;k<tableRowData.length;k++){
            //每天遍历
            for(var i=0;i< orderRecords.length;i++){
                if(orderRecords[i].report_date===tableRowData[k].day){
                    //found the day
                    if( parseInt( orderRecords[i].currency)===1){
                        if(orderRecords[i].orders_status>=2 && orderRecords[i].orders_status<=4){
                            tableRowData[k].rmb.order_total = tableRowData[k].rmb.order_total +1;
                            tableRowData[k].rmb.tax_total = tableRowData[k].rmb.tax_total + orderRecords[i].total_tax;
                            tableRowData[k].rmb.discount_total = tableRowData[k].rmb.discount_total + orderRecords[i].total_discount;
                            tableRowData[k].rmb.shipping_total = tableRowData[k].rmb.shipping_total +orderRecords[i].total_shipping_fee;
                            tableRowData[k].rmb.total_sales = tableRowData[k].rmb.total_sales +orderRecords[i].total_amount;
                            //net sale = total sale - shipping -tax
                            var net_sales = orderRecords[i].total_amount - orderRecords[i].total_tax - orderRecords[i].total_shipping_fee;
                            tableRowData[k].rmb.net_sale_total = tableRowData[k].rmb.net_sale_total + net_sales;
                        }else{
                            //退货，退款
                            tableRowData[k].rmb.order_total = tableRowData[k].rmb.order_total +1;
                            tableRowData[k].rmb.return_total = tableRowData[k].rmb.return_total + orderRecords[i].total_amount;
                        }

                    }else if( parseInt( orderRecords[i].currency)===2){
                        if(orderRecords[i].orders_status>=2 && orderRecords[i].orders_status<=4){
                            tableRowData[k].cad.order_total = tableRowData[k].cad.order_total +1;
                            tableRowData[k].cad.tax_total = tableRowData[k].cad.tax_total + orderRecords[i].total_tax;
                            tableRowData[k].cad.discount_total = tableRowData[k].cad.discount_total + orderRecords[i].total_discount;
                            tableRowData[k].cad.shipping_total = tableRowData[k].cad.shipping_total +orderRecords[i].total_shipping_fee;
                            tableRowData[k].cad.total_sales = tableRowData[k].cad.total_sales +orderRecords[i].total_amount;
                            //net sale = total sale - shipping -tax
                            var net_sales = orderRecords[i].total_amount - orderRecords[i].total_tax - orderRecords[i].total_shipping_fee;
                            tableRowData[k].cad.net_sale_total = tableRowData[k].cad.net_sale_total + net_sales;
                        }else{
                            //退货，退款
                            tableRowData[k].cad.order_total = tableRowData[k].cad.order_total +1;
                            tableRowData[k].cad.return_total = tableRowData[k].cad.return_total + orderRecords[i].total_amount;
                        }

                    }
                }
            }
            //遍历完一天，计算Gross Sale = net sale + discount
            tableRowData[k].rmb.gross_sale_total = tableRowData[k].rmb.net_sale_total + tableRowData[k].rmb.discount_total;
            tableRowData[k].cad.gross_sale_total = tableRowData[k].cad.net_sale_total + tableRowData[k].cad.discount_total;


            order_total_rmb = order_total_rmb + tableRowData[k].rmb.order_total ;
            gross_sale_total_rmb = gross_sale_total_rmb + tableRowData[k].rmb.gross_sale_total ;
            return_total_rmb = return_total_rmb + tableRowData[k].rmb.return_total ;
            discount_total_rmb = discount_total_rmb + tableRowData[k].rmb.discount_total ;

            net_sale_total_rmb = net_sale_total_rmb + tableRowData[k].rmb.net_sale_total ;
            tax_total_rmb = tax_total_rmb + tableRowData[k].rmb.tax_total ;
            shipping_total_rmb = shipping_total_rmb + tableRowData[k].rmb.shipping_total ;
            total_sales_rmb = total_sales_rmb + tableRowData[k].rmb.total_sales ;


    
            order_total_cad = order_total_cad + tableRowData[k].cad.order_total ;
            gross_sale_total_cad = gross_sale_total_cad + tableRowData[k].cad.gross_sale_total ;
            return_total_cad = return_total_cad + tableRowData[k].cad.return_total ;
            discount_total_cad = discount_total_cad + tableRowData[k].cad.discount_total ;

            net_sale_total_cad = net_sale_total_cad + tableRowData[k].cad.net_sale_total ;
            tax_total_cad = tax_total_cad + tableRowData[k].cad.tax_total ;
            shipping_total_cad = shipping_total_cad + tableRowData[k].cad.shipping_total ;
            total_sales_cad = total_sales_cad + tableRowData[k].cad.total_sales ;  
            
            dataArrayRMB.push([
                new Date(tableRowData[k].day),
                tableRowData[k].rmb.gross_sale_total
            ]);
            dataArrayCAD.push([
                new Date(tableRowData[k].day),
                tableRowData[k].cad.gross_sale_total
            ]);            
        }

        //计算上一个比较日期范围

        var previous_order_total_rmb =0;
        var previous_gross_sale_total_rmb=0;
        var previous_return_total_rmb =0;
        var previous_discount_total_rmb=0;
        var previous_net_sale_total_rmb=0;
        var previous_tax_total_rmb =0;
        var previous_shipping_total_rmb=0;
        var previous_total_sales_rmb=0;

        var previous_order_total_cad =0;
        var previous_gross_sale_total_cad=0;
        var previous_return_total_cad =0;
        var previous_discount_total_cad=0;
        var previous_net_sale_total_cad=0;
        var previous_tax_total_cad =0;
        var previous_shipping_total_cad=0;
        var previous_total_sales_cad=0;   
        
        for(var index=0;index < previousOrderRecords.length;index++ ){
            if( parseInt( previousOrderRecords[index].currency)===1){
                previous_order_total_rmb++;
                if(previousOrderRecords[index].orders_status>=2 && previousOrderRecords[index].orders_status<=4){

                    previous_discount_total_rmb= previous_discount_total_rmb + previousOrderRecords[index].total_discount;
                    previous_tax_total_rmb = previous_tax_total_rmb + previousOrderRecords[index].total_tax;
                    previous_shipping_total_rmb= previous_shipping_total_rmb + previousOrderRecords[index].total_shipping_fee;
                    previous_total_sales_rmb= previous_total_sales_rmb + previousOrderRecords[index].total_amount;                    
                }else{
                    previous_return_total_rmb = previous_return_total_rmb + previousOrderRecords[index].total_amount;

                }
            }else if( parseInt( previousOrderRecords[index].currency)===2){
                previous_order_total_cad++;
                if(previousOrderRecords[index].orders_status>=2 && previousOrderRecords[index].orders_status<=4){
                    previous_discount_total_cad= previous_discount_total_cad + previousOrderRecords[index].total_discount;
                    previous_tax_total_cad = previous_tax_total_cad + previousOrderRecords[index].total_tax;
                    previous_shipping_total_cad= previous_shipping_total_cad + previousOrderRecords[index].total_shipping_fee;
                    previous_total_sales_cad= previous_total_sales_cad + previousOrderRecords[index].total_amount;                    
                }else{
                    previous_return_total_cad = previous_return_total_cad + previousOrderRecords[index].total_amount;

                }
            }
        }
        //net sale = total sale - shipping -tax
        previous_net_sale_total_rmb = previous_total_sales_rmb - previous_shipping_total_rmb - previous_tax_total_rmb;
        previous_gross_sale_total_rmb = previous_net_sale_total_rmb + previous_discount_total_rmb;
        //Gross Sale = net sale + discount
        previous_net_sale_total_cad = previous_total_sales_cad - previous_shipping_total_cad - previous_tax_total_cad;
        previous_gross_sale_total_cad = previous_net_sale_total_cad + previous_discount_total_cad;

        //计算增长率

        //grosss sale
        var gross_sale_rate_rmb =previous_gross_sale_total_rmb === 0?  (gross_sale_total_rmb - previous_gross_sale_total_rmb) *100:  (gross_sale_total_rmb - previous_gross_sale_total_rmb) *100 / previous_gross_sale_total_rmb;
        gross_sale_rate_rmb = parseFloat(gross_sale_rate_rmb).toFixed(0);
        var gross_sale_rate_cad =previous_gross_sale_total_cad === 0? (gross_sale_total_cad - previous_gross_sale_total_cad) *100 : (gross_sale_total_cad - previous_gross_sale_total_cad) *100 / previous_gross_sale_total_cad;
        gross_sale_rate_cad = parseFloat(gross_sale_rate_cad).toFixed(0);
        gross_sale_total.push({
            current_rmb : gross_sale_total_rmb,
            previous_rmb: previous_gross_sale_total_rmb,
            rate_rmb: gross_sale_rate_rmb,
            current_cad: gross_sale_total_cad,
            previous_cad: previous_gross_sale_total_cad,
            rate_cad: gross_sale_rate_cad
        });

        //return 
        var return_rate_rmb =previous_return_total_rmb === 0?  (return_total_rmb - previous_return_total_rmb) *100:  (return_total_rmb - previous_return_total_rmb) *100 / previous_return_total_rmb;
        return_rate_rmb = parseFloat(return_rate_rmb).toFixed(0);
        var return_rate_cad =previous_return_total_cad === 0? (return_total_cad - previous_return_total_cad) *100 : (return_total_cad - previous_return_total_cad) *100 / previous_return_total_cad;
        return_rate_cad = parseFloat(return_rate_cad).toFixed(0);
        return_total.push({
            current_rmb : return_total_rmb,
            previous_rmb: previous_return_total_rmb,
            rate_rmb: return_rate_rmb,
            current_cad: return_total_cad,
            previous_cad: previous_return_total_cad,
            rate_cad: return_rate_cad
        });

        //coupon
        var discount_rate_rmb =previous_discount_total_rmb === 0?  (discount_total_rmb - previous_discount_total_rmb) *100:  (discount_total_rmb - previous_discount_total_rmb) *100 / previous_discount_total_rmb;
        discount_rate_rmb = parseFloat(discount_rate_rmb).toFixed(0);
        var discount_rate_cad =previous_discount_total_cad === 0? (discount_total_cad - previous_discount_total_cad) *100 : (discount_total_cad - previous_discount_total_cad) *100 / previous_discount_total_cad;
        discount_rate_cad = parseFloat(discount_rate_cad).toFixed(0);
        discount_total.push({
            current_rmb : discount_total_rmb,
            previous_rmb: previous_discount_total_rmb,
            rate_rmb: discount_rate_rmb,
            current_cad: discount_total_cad,
            previous_cad: previous_discount_total_cad,
            rate_cad: discount_rate_cad
        });      
        
        //net sale
        var net_sale_rate_rmb =previous_net_sale_total_rmb === 0?  (net_sale_total_rmb - previous_net_sale_total_rmb) *100:  (net_sale_total_rmb - previous_net_sale_total_rmb) *100 / previous_net_sale_total_rmb;
        net_sale_rate_rmb = parseFloat(net_sale_rate_rmb).toFixed(0);
        var net_sale_rate_cad =previous_net_sale_total_cad === 0? (net_sale_total_cad - previous_net_sale_total_cad) *100 : (net_sale_total_cad - previous_net_sale_total_cad) *100 / previous_net_sale_total_cad;
        net_sale_rate_cad = parseFloat(net_sale_rate_cad).toFixed(0);
        net_sale_total.push({
            current_rmb : net_sale_total_rmb,
            previous_rmb: previous_net_sale_total_rmb,
            rate_rmb: net_sale_rate_rmb,
            current_cad: net_sale_total_cad,
            previous_cad: previous_net_sale_total_cad,
            rate_cad: net_sale_rate_cad
        });     
        
        
        // tax

        var tax_rate_rmb =previous_tax_total_rmb === 0?  (tax_total_rmb - previous_tax_total_rmb) *100:  (tax_total_rmb - previous_tax_total_rmb) *100 / previous_tax_total_rmb;
        tax_rate_rmb = parseFloat(tax_rate_rmb).toFixed(0);
        var tax_rate_cad =previous_tax_total_cad === 0? (tax_total_cad - previous_tax_total_cad) *100 : (tax_total_cad - previous_tax_total_cad) *100 / previous_tax_total_cad;
        tax_rate_cad = parseFloat(tax_rate_cad).toFixed(0);
        tax_total.push({
            current_rmb : tax_total_rmb,
            previous_rmb: previous_tax_total_rmb,
            rate_rmb: tax_rate_rmb,
            current_cad: tax_total_cad,
            previous_cad: previous_tax_total_cad,
            rate_cad: tax_rate_cad
        });   
        
        //shipping

        var shipping_rate_rmb =previous_shipping_total_rmb === 0?  (shipping_total_rmb - previous_shipping_total_rmb) *100:  (shipping_total_rmb - previous_shipping_total_rmb) *100 / previous_shipping_total_rmb;
        shipping_rate_rmb = parseFloat(shipping_rate_rmb).toFixed(0);
        var shipping_rate_cad =previous_shipping_total_cad === 0? (shipping_total_cad - previous_shipping_total_cad) *100 : (shipping_total_cad - previous_shipping_total_cad) *100 / previous_shipping_total_cad;
        shipping_rate_cad = parseFloat(shipping_rate_cad).toFixed(0);
        shipping_total.push({
            current_rmb : shipping_total_rmb,
            previous_rmb: previous_shipping_total_rmb,
            rate_rmb: shipping_rate_rmb,
            current_cad: shipping_total_cad,
            previous_cad: previous_shipping_total_cad,
            rate_cad: shipping_rate_cad
        });  

        //total sale
        var total_sales_rate_rmb =previous_total_sales_rmb === 0?  (total_sales_rmb - previous_total_sales_rmb) *100:  (total_sales_rmb - previous_total_sales_rmb) *100 / previous_total_sales_rmb;
        total_sales_rate_rmb = parseFloat(total_sales_rate_rmb).toFixed(0);
        var total_sales_rate_cad =previous_total_sales_cad === 0? (total_sales_cad - previous_total_sales_cad) *100 : (total_sales_cad - previous_total_sales_cad) *100 / previous_total_sales_cad;
        total_sales_rate_cad = parseFloat(total_sales_rate_cad).toFixed(0);
        total_sales.push({
            current_rmb : total_sales_rmb,
            previous_rmb: previous_total_sales_rmb,
            rate_rmb: total_sales_rate_rmb,
            current_cad: total_sales_cad,
            previous_cad: previous_total_sales_cad,
            rate_cad: total_sales_rate_cad
        });          
       // console.log(JSON.stringify(gross_sale_total));
        this.setState({
            tableRowData,
            gross_sale_total,
            return_total,
            discount_total,
            net_sale_total,
            tax_total,
            shipping_total,
            total_sales,
            dataArrayRMB,
            dataArrayCAD            
        });

        GoogleCharts.load(this.drawBarChart);
 
    }
    handleDrawLineChart=(e)=>{
        GoogleCharts.load(this.drawLineChart);
    }
    handleDrawBarChart=(e)=>{
        GoogleCharts.load(this.drawBarChart);
    }

    drawBarChart=()=>{
        const {dataArrayRMB,dataArrayCAD} = this.state;
        var dataRMB = new GoogleCharts.api.visualization.DataTable();
        var dataCAD = new GoogleCharts.api.visualization.DataTable();

        dataRMB.addColumn('date', 'Date');
        dataRMB.addColumn('number', 'RMB');
        dataRMB.addRows(dataArrayRMB); 


        
        dataCAD.addColumn('date', 'Date');
        dataCAD.addColumn('number', 'CAD');
        dataCAD.addRows(dataArrayCAD);   


        var barsRMB = new GoogleCharts.api.visualization.ColumnChart(document.getElementById('chartOrdersRMB'));
        barsRMB.draw(dataRMB, null);
        //console.log(JSON.stringify(dataRMB));
    
        var barsCAD = new GoogleCharts.api.visualization.ColumnChart(document.getElementById('chartOrdersCAD'));
        barsCAD.draw(dataCAD, null);  

    }
    
    drawLineChart=()=>{
        const {dataArrayRMB,dataArrayCAD} = this.state;
        var dataRMB = new GoogleCharts.api.visualization.DataTable();
        var dataCAD = new GoogleCharts.api.visualization.DataTable();

        dataRMB.addColumn('date', 'Date');
        dataRMB.addColumn('number', 'RMB');
        dataRMB.addRows(dataArrayRMB); 


        
        dataCAD.addColumn('date', 'Date');
        dataCAD.addColumn('number', 'CAD');
        dataCAD.addRows(dataArrayCAD);   


        var barsRMB = new GoogleCharts.api.visualization.LineChart(document.getElementById('chartOrdersRMB'));
        barsRMB.draw(dataRMB, null);
        //console.log(JSON.stringify(dataRMB));
    
        var barsCAD = new GoogleCharts.api.visualization.LineChart(document.getElementById('chartOrdersCAD'));
        barsCAD.draw(dataCAD, null);  

    }    
    buildChartAndTable=()=>{
        const {preset_value} = this.state;
        var dateInCompare= this.getCompareDate(preset_value);
        var dateArray = dateInCompare.split("|");
        var dateNow = dateArray[0].split(",");
        var start_date=dateNow[0];
        var end_date = dateNow[1];

        // var momentEnd = moment(end_date);
        var momentStart = moment(start_date);
        while(momentStart.format("YYYY-MM-DD")!==end_date)
        {
           // console.log("date =" + momentStart.format("YYYY-MM-DD"));
            this.buildChartAndTableAtThisDay(momentStart.format("YYYY-MM-DD"));
            momentStart = momentStart.add(1,"day");
        }
        this.buildChartAndTableAtThisDay(end_date);

        //do records loop
        this.setTableRows();


    }
    handlePresetSearch=(e)=>{
        // Preset 查询
        const {preset_value} = this.state;
        this.doSearchByPreset(preset_value);
        this.handleClickDataRange(e);

    }
    handlePeriodChange=(e)=>{
        const {preset_value} = this.state;
        this.setState({
            preset_value: e.target.value
        })
    }
    handleCompareChange=(e)=>{
        const { compareToValue } = this.state;
        this.setState({
            compareToValue: e.target.value
        })
    }

    doSearchByPreset=(presetValue)=>{
        const {start,end,preset_value} = this.state;
        var keyword ='';

       
       var dateInCompare= this.getCompareDate(preset_value);

       console.log(" preset value =" + preset_value + " dateInCompare= " + dateInCompare);
       var dateArray = dateInCompare.split("|");
       var dateNow = dateArray[0].split(",");
       var start_date=dateNow[0];
       var end_date = dateNow[1];
       var data={ start_date, end_date };

       var datePrevious = dateArray[1].split(",");
       var previous_start_date=datePrevious[0];
       var previous_end_date = datePrevious[1];
       var previous_data={ 
           start_date: previous_start_date, 
           end_date:previous_end_date 
       };

       //hide
       axios.all([
        getOrderReportingByDateRange(data),
        getOrderReportingByDateRange(previous_data),

       ]).then(axios.spread((r1,r2)=>{
        this.setState({ 
            orderRecords: r1.data,
            previousOrderRecords: r2.data,
            dataArrayRMB:[],
            dataArrayCAD:[],
            order_total:[],
            gross_sale_total:[],
            return_total:[],
            discount_total:[],
            net_sale_total:[],
            tax_total:[],
            shipping_total:[],
            total_sales:[],
            tableRowData:[],               
         });  
         this.buildChartAndTable();
       }))
        .catch((err) => {
                 console.log("[Error] - GET /orders - at RevenueReporting component!");
                 console.log(err);         
         }
        );
         this.setPresetText(preset_value);
    }
    componentDidMount(){
        const {start,end,preset_value} = this.state;
        this.doSearchByPreset(preset_value);
               
    }

    render(){
        let lang='';
        if(this.props.langState ==='cn') lang=LANGUAGE.Reporting.cn;
        else lang=LANGUAGE.Reporting.en;

        const {subjectList, searchContent,currentPage,orders_status,start, end,orders_total,isSearchByProduct,
            dateText,vsDateText,tableRowData,
            gross_sale_total,
            return_total,
            discount_total,
            net_sale_total,
            tax_total,
            shipping_total,
            total_sales
        } = this.state;
        const { permissions } = this.props;



        
        const orders_status_row=[];
        tableRowData.forEach((one, index)=>{        
            var row_index_1 = index *2;
            var row_index_2 = index * 2 +1;    
            var linkTo = "/products/orders_mgr?key=search_by_date&value=" + one.day;

            orders_status_row.push(
                <tr id={row_index_2} key={row_index_2} >
               <td style={{verticalAlign: 'middle'}} className='woocommerce-table__item'>CAD</td>
               <td style={{verticalAlign: 'middle'}} className='woocommerce-table__item is-numeric'>
                  <Link to={linkTo}> {one.cad.order_total}</Link>
                   </td>
                    <td style={{verticalAlign: 'middle'}} className='woocommerce-table__item is-numeric'>{toCurrency(one.cad.gross_sale_total,2)}</td>
                    <td style={{verticalAlign: 'middle'}} className='woocommerce-table__item is-numeric'>{toCurrency(one.cad.return_total,2)}</td>
                    <td style={{verticalAlign: 'middle'}} className='woocommerce-table__item is-numeric'>{toCurrency(one.cad.discount_total,2)}</td>
                    <td style={{verticalAlign: 'middle'}} className='woocommerce-table__item is-numeric'>{toCurrency(one.cad.net_sale_total,2)}</td>
                    <td style={{verticalAlign: 'middle'}} className='woocommerce-table__item is-numeric'>{toCurrency(one.cad.tax_total,2)}</td>
                    <td style={{verticalAlign: 'middle'}} className='woocommerce-table__item is-numeric'>{toCurrency(one.cad.shipping_total,2)}</td>
                    <td style={{verticalAlign: 'middle'}} className='woocommerce-table__item is-numeric'>{toCurrency(one.cad.total_sales,2)}</td>                              
           </tr>

            );
            orders_status_row.push(
                <tr id={row_index_1} key={row_index_1} >
                     <th style={{verticalAlign: 'middle'}} className='woocommerce-table__item is-left-aligned is-sorted' rowSpan="2">{one.day}</th>
                    <td style={{verticalAlign: 'middle'}} className='woocommerce-table__item'>RMB</td>
                    <td style={{verticalAlign: 'middle'}} className='woocommerce-table__item is-numeric'>
                    <Link to={linkTo} > {one.rmb.order_total}</Link>
                    </td>
                    <td style={{verticalAlign: 'middle'}} className='woocommerce-table__item is-numeric'>{toCurrency(one.rmb.gross_sale_total,1)}</td>
                    <td style={{verticalAlign: 'middle'}} className='woocommerce-table__item is-numeric'>{toCurrency(one.rmb.return_total,1)}</td>
                    <td style={{verticalAlign: 'middle'}} className='woocommerce-table__item is-numeric'>{toCurrency(one.rmb.discount_total,1)}</td>
                    <td style={{verticalAlign: 'middle'}} className='woocommerce-table__item is-numeric'>{toCurrency(one.rmb.net_sale_total,1)}</td>
                    <td style={{verticalAlign: 'middle'}} className='woocommerce-table__item is-numeric'>{toCurrency(one.rmb.tax_total,1)}</td>
                    <td style={{verticalAlign: 'middle'}} className='woocommerce-table__item is-numeric'>{toCurrency(one.rmb.shipping_total,1)}</td>
                    <td style={{verticalAlign: 'middle'}} className='woocommerce-table__item is-numeric'>{toCurrency(one.rmb.total_sales,1)}</td>
                </tr>
            );            
        });

      

        const totalPages=Math.ceil(orders_status_row.length/10);
        const onePage=orders_status_row.reverse().slice((currentPage*10-10), (currentPage*10));
        return(
            <div className="container-fluid">
                <div className="card rounded">
                    <div className="card-header">
                        

                        <span style={{fontSize: "1.4em"}}><i className="fas fa-pencil-alt"></i>&nbsp;{lang[0]}</span>
                        <div className="float-right">
                            <div className=" btn-toolbar " >
                                {/* <div className="btn-group-sm mr-2" role="group" aria-label="First group">
                                    <button type="button" className="btn btn-success" onClick={this.handleClickSave}><i className="fas fa-save" title={lang[1]}></i></button>
                                </div>
                                <div className="btn-group-sm mr-2" role="group" aria-label="First group">
                                    <button type="button" className="btn btn-success" onClick={this.handleClickPreview}>{lang[45]}</button>
                                </div> */}

                                {/* <Link to='/products/orders_mgr'>
                                    <div className="btn-group-sm mr-2" role="group" aria-label="Second group">
                                        <button type="button" className="btn btn-primary">{lang[12]}&nbsp;
                                            <i className="fas fa-arrow-alt-circle-right" title={lang[12]}></i></button>
                                    </div>
                                </Link> */}
                            </div>
                        </div>                        
                    </div>
                    <div className="card-body woocommerce-page">
                        

<div className="woocommerce-filters">
    <div className="woocommerce-filters__basic-filters">
        <div className="woocommerce-filters-filter">
            <span className="woocommerce-filters-label">{lang[1]}:</span>
            <div>
            
                <button type="button" id="btnDataRange" onClick={this.handleClickDataRange}  aria-expanded="true" className="components-button woocommerce-dropdown-button is-multi-line">
                    <div className="woocommerce-dropdown-button__labels"><span>{dateText}</span>
                    <span>{vsDateText}</span>
                    </div>
                </button>
            <span id="spanDataRange" className="dataRangHide" hidden={true}>
                <div tabIndex="-1">
                    <div>
                        <div>
                            <div className="components-popover woocommerce-filters-date__content is-bottom is-center components-animate__appear is-from-top" >
                                <div className="components-popover__content" tabIndex="-1" >
                             
                                    <h4 className="woocommerce-filters-date__text">{lang[2]}</h4>
                                <div className="woocommerce-filters-date__tabs">
                                    <div role="tablist" aria-orientation="horizontal" className="components-tab-panel__tabs">
                                        <button type="button" role="tab" onClick={this.handleClickPresets} aria-selected="true" id="2-period" aria-controls="2-period-view" className="components-button woocommerce-filters-date__tab is-active">{lang[3]}</button>
                                        <button type="button" role="tab" onClick={this.handleClickCustom} aria-selected="false" id="2-custom" aria-controls="2-custom-view" className="components-button woocommerce-filters-date__tab" tabIndex="-1">{lang[4]}</button>
                                    </div>
                                    <div aria-labelledby="2-period" role="tabpanel" id="2-period-view" className="components-tab-panel__tab-content" tabIndex="0">
                                        <fieldset className="woocommerce-segmented-selection">
                            <legend className="screen-reader-text">{lang[5]}</legend>
                                        <div className="woocommerce-segmented-selection__container">
                                            <div className="woocommerce-segmented-selection__item">
                                                <input className="woocommerce-segmented-selection__input" onChange={this.handlePeriodChange} value="1" type="radio" name="period" id="today_227" defaultChecked="" />
                                                    <label htmlFor="today_227">
                                                        <span className="woocommerce-segmented-selection__label">{lang[6]}</span></label>
                                            </div>
                                            <div className="woocommerce-segmented-selection__item">
                                                <input className="woocommerce-segmented-selection__input" type="radio" onChange={this.handlePeriodChange} value="2" name="period" id="yesterday_228" />
                                                <label htmlFor="yesterday_228"><span className="woocommerce-segmented-selection__label">{lang[7]}</span></label>
                                            </div>
                                            <div className="woocommerce-segmented-selection__item">
                                                <input className="woocommerce-segmented-selection__input" type="radio" onChange={this.handlePeriodChange} value="3" name="period" id="week_229" />
                            <label htmlFor="week_229"><span className="woocommerce-segmented-selection__label">{lang[8]}</span></label>
                                            </div>
                                            <div className="woocommerce-segmented-selection__item">
                                                <input className="woocommerce-segmented-selection__input" type="radio" onChange={this.handlePeriodChange} value="4" name="period" id="last_week_230" />
                                                    <label htmlFor="last_week_230"><span className="woocommerce-segmented-selection__label">{lang[9]}</span></label>
                                            </div>
                                            <div className="woocommerce-segmented-selection__item">
                                                <input className="woocommerce-segmented-selection__input" type="radio" onChange={this.handlePeriodChange} value="5" name="period" id="month_231" />
                                                <label htmlFor="month_231"><span className="woocommerce-segmented-selection__label">{lang[10]}</span></label>
                                            </div>
                                            <div className="woocommerce-segmented-selection__item">
                                                <input className="woocommerce-segmented-selection__input" type="radio" onChange={this.handlePeriodChange} value="6" name="period" id="last_month_232" />
                                                <label htmlFor="last_month_232"><span className="woocommerce-segmented-selection__label">{lang[11]}</span></label>
                                            </div>
                                            <div className="woocommerce-segmented-selection__item">
                                                <input className="woocommerce-segmented-selection__input" type="radio" onChange={this.handlePeriodChange} value="7" name="period" id="quarter_233" />
                            <label htmlFor="quarter_233"><span className="woocommerce-segmented-selection__label">{lang[12]}</span></label>
                                            </div>
                                            <div className="woocommerce-segmented-selection__item">
                                                <input className="woocommerce-segmented-selection__input" type="radio" onChange={this.handlePeriodChange} value="8" name="period" id="last_quarter_234" />
                            <label htmlFor="last_quarter_234"><span className="woocommerce-segmented-selection__label">{lang[13]}</span></label>
                                            </div>
                                            <div className="woocommerce-segmented-selection__item">
                                                <input className="woocommerce-segmented-selection__input" type="radio" onChange={this.handlePeriodChange} value="9" name="period" id="year_235" />
                            <label htmlFor="year_235"><span className="woocommerce-segmented-selection__label">{lang[14]}</span></label>
                                            </div>
                                            <div className="woocommerce-segmented-selection__item">
                                                <input className="woocommerce-segmented-selection__input" type="radio" onChange={this.handlePeriodChange} value="10" name="period" id="last_year_236" />
                            <label htmlFor="last_year_236"><span className="woocommerce-segmented-selection__label">{lang[15]}</span></label>
                                            </div>
                                        </div>
                                        </fieldset>
                                        <div className="woocommerce-filters-date__content-controls">
                                            <h4 className="woocommerce-filters-date__text">{lang[17]}</h4>
                                            <fieldset className="woocommerce-segmented-selection">
                                                {/* <legend className="screen-reader-text">compare to</legend> */}
                                                <div className="woocommerce-segmented-selection__container">
                                                    <div className="woocommerce-segmented-selection__item">
                                                        <input className="woocommerce-segmented-selection__input" onChange={this.handleCompareChange} value="1" type="radio" name="compare" id="previous_period_237" defaultChecked="checked" />
                            <label htmlFor="previous_period_237"><span className="woocommerce-segmented-selection__label">{lang[18]}</span></label>
                                                    </div>
                                                    <div className="woocommerce-segmented-selection__item">
                                                        <input className="woocommerce-segmented-selection__input" onChange={this.handleCompareChange} value="2" type="radio" name="compare" id="previous_year_238" />
                            <label htmlFor="previous_year_238"><span className="woocommerce-segmented-selection__label">{lang[19]}</span></label>
                                                    </div>
                                                </div>
                                            </fieldset>
                                            <div className="woocommerce-filters-date__button-group">
                            <button type="button" onClick={this.handlePresetSearch} className="components-button woocommerce-filters-date__button is-button is-primary">{lang[21]}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div aria-labelledby="2-custom" hidden={true} role="tabpanel" id="2-custom-view" className="components-tab-panel__tab-content" tabIndex="1">
                                        <div className="woocommerce-calendar__inputs">
                                            <div className="woocommerce-calendar__input">
                                                <input type="date" value={start} name="start"  onChange={this.handleChange} className="woocommerce-calendar__input-text" aria-label="Start Date" id="_woo-dates-input351" aria-describedby="_woo-dates-input351-message" placeholder="mm/dd/yyyy" />
                                                <svg aria-hidden="true" role="img" focusable="false" className="dashicon dashicons-calendar" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path d="M15 4h3v14H2V4h3V3c0-.83.67-1.5 1.5-1.5S8 2.17 8 3v1h4V3c0-.83.67-1.5 1.5-1.5S15 2.17 15 3v1zM6 3v2.5c0 .28.22.5.5.5s.5-.22.5-.5V3c0-.28-.22-.5-.5-.5S6 2.72 6 3zm7 0v2.5c0 .28.22.5.5.5s.5-.22.5-.5V3c0-.28-.22-.5-.5-.5s-.5.22-.5.5zm4 14V8H3v9h14zM7 16V9H5v7h2zm4 0V9H9v7h2zm4 0V9h-2v7h2z"></path></svg>
                                                {/* <p className="screen-reader-text" id="_woo-dates-input351-message">Date input describing a selected date range's start date in format MM/DD/YYYY</p> */}
                                            </div>
                            <div className="woocommerce-calendar__inputs-to">{lang[16]}</div>
                                            <div className="woocommerce-calendar__input">
                                                <input type="date" value={end} name="end" onChange={this.handleChange} className="woocommerce-calendar__input-text" aria-label="End Date" id="_woo-dates-input352" aria-describedby="_woo-dates-input352-message" placeholder="mm/dd/yyyy"  />
                                                <svg aria-hidden="true" role="img" focusable="false" className="dashicon dashicons-calendar" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path d="M15 4h3v14H2V4h3V3c0-.83.67-1.5 1.5-1.5S8 2.17 8 3v1h4V3c0-.83.67-1.5 1.5-1.5S15 2.17 15 3v1zM6 3v2.5c0 .28.22.5.5.5s.5-.22.5-.5V3c0-.28-.22-.5-.5-.5S6 2.72 6 3zm7 0v2.5c0 .28.22.5.5.5s.5-.22.5-.5V3c0-.28-.22-.5-.5-.5s-.5.22-.5.5zm4 14V8H3v9h14zM7 16V9H5v7h2zm4 0V9H9v7h2zm4 0V9h-2v7h2z"></path></svg>
                                                {/* <p className="screen-reader-text" id="_woo-dates-input352-message">Date input describing a selected date range's end date in format MM/DD/YYYY</p> */}
                                            </div>
                                        </div>

                                        <div className="woocommerce-filters-date__content-controls is-custom">
                            <h4 className="woocommerce-filters-date__text">{lang[17]}</h4>
                                            <fieldset className="woocommerce-segmented-selection">
                                                {/* <legend className="screen-reader-text">compare to</legend> */}
                                                <div className="woocommerce-segmented-selection__container">
                                                    <div className="woocommerce-segmented-selection__item">
                                                        <input className="woocommerce-segmented-selection__input" onChange={this.handleCompareChange} value="1" type="radio" name="compare" id="previous_period_366" defaultChecked="" />
                                                        <label htmlFor="previous_period_366">
                            <span className="woocommerce-segmented-selection__label">{lang[18]}</span></label>
                                                    </div>
                                                    <div className="woocommerce-segmented-selection__item">
                                                        <input className="woocommerce-segmented-selection__input" onChange={this.handleCompareChange} value="2" type="radio" name="compare" id="previous_year_367" />
                            <label htmlFor="previous_year_367"><span className="woocommerce-segmented-selection__label">{lang[19]}</span></label>
                                                    </div>
                                                </div>
                                            </fieldset>
                                            <div className="woocommerce-filters-date__button-group">
                            <button type="button" className="components-button woocommerce-filters-date__button is-button is-default">{lang[20]}</button>
                            <button type="button" onClick={this.handleCustomSearch} 
                            className="components-button woocommerce-filters-date__button is-button is-primary">{lang[21]}</button>
                                            </div>
                                        </div>
                                        
                                    </div>



                                </div>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>

        </span></div></div></div></div>





<div role="menu" aria-orientation="horizontal" aria-label="Performance Indicators" aria-describedby="woocommerce-summary-helptext-248">
    {/* <p id="woocommerce-summary-helptext-248" className="screen-reader-text">List of data points available for filtering. Use arrow keys to cycle through the list. Click a data point for a detailed report.</p> */}
    <ul className="woocommerce-summary has-7-items">

        <li className="woocommerce-summary__item-container">
            <a href="#" 
            className="woocommerce-summary__item is-selected " aria-current="page" role="menuitem" data-link-type="wc-admin">
        <span className="woocommerce-summary__item-label">{lang[25]}</span>
        <span className="woocommerce-summary__item-data "> RMB: &nbsp;
            <span className="woocommerce-summary__item-value">{ gross_sale_total.length>0 ? toCurrency( gross_sale_total[0].current_rmb,1):0}</span>
            
            {
              gross_sale_total.length>0 ?
                gross_sale_total[0].rate_rmb > 0 ?
                <div className="woocommerce-summary__item-delta is-good-trend" role="presentation" aria-label="Up 4% from Previous Period:">
                  <svg className="gridicon gridicons-arrow-up woocommerce-summary__item-delta-icon needs-offset-x" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M13 20V7.83l5.59 5.59L20 12l-8-8-8 8 1.41 1.41L11 7.83V20h2z"></path></g></svg>
                  <span className="woocommerce-summary__item-delta-value">{gross_sale_total.length>0 ? gross_sale_total[0].rate_rmb:0}%</span>
            </div>
:gross_sale_total[0].rate_rmb < 0 ? 
<div className="woocommerce-summary__item-delta is-bad-trend" role="presentation" aria-label="Up 4% from Previous Period:">
<svg className="gridicon gridicons-arrow-down woocommerce-summary__item-delta-icon needs-offset-x" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M11 4v12.17l-5.59-5.59L4 12l8 8 8-8-1.41-1.41L13 16.17V4h-2z"></path></g></svg>
<span className="woocommerce-summary__item-delta-value">{gross_sale_total.length>0 ? gross_sale_total[0].rate_rmb:0}%</span>
            </div>
:<span>&nbsp;</span> 
:<span>&nbsp;</span> 
            }  
                
        </span>
        <span className="woocommerce-summary__item-data"> CAD: &nbsp;
            <span className="woocommerce-summary__item-value">{ gross_sale_total.length>0 ? toCurrency( gross_sale_total[0].current_cad,2):0}</span>
            {
              gross_sale_total.length>0 ?
                gross_sale_total[0].rate_cad > 0 ?
                <div className="woocommerce-summary__item-delta is-good-trend" role="presentation" aria-label="Up 4% from Previous Period:">
                  <svg className="gridicon gridicons-arrow-up woocommerce-summary__item-delta-icon needs-offset-x" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M13 20V7.83l5.59 5.59L20 12l-8-8-8 8 1.41 1.41L11 7.83V20h2z"></path></g></svg>
                  <span className="woocommerce-summary__item-delta-value">{gross_sale_total.length>0 ? gross_sale_total[0].rate_cad:0}%</span>
            </div>
:gross_sale_total[0].rate_cad < 0 ? 
<div className="woocommerce-summary__item-delta is-bad-trend" role="presentation" aria-label="Up 4% from Previous Period:">
<svg className="gridicon gridicons-arrow-down woocommerce-summary__item-delta-icon needs-offset-x" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M11 4v12.17l-5.59-5.59L4 12l8 8 8-8-1.41-1.41L13 16.17V4h-2z"></path></g></svg>
<span className="woocommerce-summary__item-delta-value">{gross_sale_total.length>0 ? gross_sale_total[0].rate_cad:0}%</span>
            </div>
:<span>&nbsp;</span> 
:<span>&nbsp;</span> 
            }  

            
        </span>
        <span className="woocommerce-summary__item-prev-label">{lang[18]}:</span>
         <span className="woocommerce-summary__item-prev-value">RMB: &nbsp; {gross_sale_total.length>0 ?  toCurrency(gross_sale_total[0].previous_rmb,1):0}</span>
         <span className="woocommerce-summary__item-prev-value">CAD: &nbsp; {gross_sale_total.length>0 ?  toCurrency(gross_sale_total[0].previous_cad,2):0}</span>
        </a>
        </li>

        <li className="woocommerce-summary__item-container">
            <a href="#" 
            className="woocommerce-summary__item is-selected " aria-current="page" role="menuitem" data-link-type="wc-admin">
        <span className="woocommerce-summary__item-label">{lang[26]}</span>
        <span className="woocommerce-summary__item-data"> RMB: &nbsp;
            <span className="woocommerce-summary__item-value">{ return_total.length>0 ? toCurrency( return_total[0].current_rmb,1):0}</span>
            
            {
              return_total.length>0 ?
                return_total[0].rate_rmb > 0 ?
                <div className="woocommerce-summary__item-delta is-good-trend" role="presentation" aria-label="Up 4% from Previous Period:">
                  <svg className="gridicon gridicons-arrow-up woocommerce-summary__item-delta-icon needs-offset-x" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M13 20V7.83l5.59 5.59L20 12l-8-8-8 8 1.41 1.41L11 7.83V20h2z"></path></g></svg>
                  <span className="woocommerce-summary__item-delta-value">{return_total.length>0 ? return_total[0].rate_rmb:0}%</span>
            </div>
:return_total[0].rate_rmb < 0 ? 
<div className="woocommerce-summary__item-delta is-bad-trend" role="presentation" aria-label="Up 4% from Previous Period:">
<svg className="gridicon gridicons-arrow-down woocommerce-summary__item-delta-icon needs-offset-x" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M11 4v12.17l-5.59-5.59L4 12l8 8 8-8-1.41-1.41L13 16.17V4h-2z"></path></g></svg>
<span className="woocommerce-summary__item-delta-value">{return_total.length>0 ? return_total[0].rate_rmb:0}%</span>
            </div>
:<span>&nbsp;</span> 
:<span>&nbsp;</span> 
            }  
                
        </span>
        <span className="woocommerce-summary__item-data"> CAD: &nbsp;
            <span className="woocommerce-summary__item-value">{ return_total.length>0 ? toCurrency( return_total[0].current_cad,2):0}</span>
            {
              return_total.length>0 ?
                return_total[0].rate_cad > 0 ?
                <div className="woocommerce-summary__item-delta is-good-trend" role="presentation" aria-label="Up 4% from Previous Period:">
                  <svg className="gridicon gridicons-arrow-up woocommerce-summary__item-delta-icon needs-offset-x" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M13 20V7.83l5.59 5.59L20 12l-8-8-8 8 1.41 1.41L11 7.83V20h2z"></path></g></svg>
                  <span className="woocommerce-summary__item-delta-value">{return_total.length>0 ? return_total[0].rate_cad:0}%</span>
            </div>
:return_total[0].rate_cad < 0 ? 
<div className="woocommerce-summary__item-delta is-bad-trend" role="presentation" aria-label="Up 4% from Previous Period:">
<svg className="gridicon gridicons-arrow-down woocommerce-summary__item-delta-icon needs-offset-x" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M11 4v12.17l-5.59-5.59L4 12l8 8 8-8-1.41-1.41L13 16.17V4h-2z"></path></g></svg>
<span className="woocommerce-summary__item-delta-value">{return_total.length>0 ? return_total[0].rate_cad:0}%</span>
            </div>
:<span>&nbsp;</span> 
:<span>&nbsp;</span> 
            }  

            
        </span>
        <span className="woocommerce-summary__item-prev-label">{lang[18]}:</span>
         <span className="woocommerce-summary__item-prev-value">RMB: &nbsp; {return_total.length>0 ?  toCurrency(return_total[0].previous_rmb,1):0}</span>
         <span className="woocommerce-summary__item-prev-value">CAD: &nbsp; {return_total.length>0 ?  toCurrency(return_total[0].previous_cad,2):0}</span>
        </a>
        </li>


{/* 折扣报表 */}
        <li className="woocommerce-summary__item-container">
            <a href="#" 
            className="woocommerce-summary__item is-selected " aria-current="page" role="menuitem" data-link-type="wc-admin">
        <span className="woocommerce-summary__item-label">{lang[27]}</span>
        <span className="woocommerce-summary__item-data "> RMB: &nbsp;
            <span className="woocommerce-summary__item-value">{ discount_total.length>0 ? toCurrency( discount_total[0].current_rmb,1):0}</span>
            
            {
              discount_total.length>0 ?
                discount_total[0].rate_rmb > 0 ?
                <div className="woocommerce-summary__item-delta is-good-trend" role="presentation" aria-label="Up 4% from Previous Period:">
                  <svg className="gridicon gridicons-arrow-up woocommerce-summary__item-delta-icon needs-offset-x" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M13 20V7.83l5.59 5.59L20 12l-8-8-8 8 1.41 1.41L11 7.83V20h2z"></path></g></svg>
                  <span className="woocommerce-summary__item-delta-value">{discount_total.length>0 ? discount_total[0].rate_rmb:0}%</span>
            </div>
:discount_total[0].rate_rmb < 0 ? 
<div className="woocommerce-summary__item-delta is-bad-trend" role="presentation" aria-label="Up 4% from Previous Period:">
<svg className="gridicon gridicons-arrow-down woocommerce-summary__item-delta-icon needs-offset-x" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M11 4v12.17l-5.59-5.59L4 12l8 8 8-8-1.41-1.41L13 16.17V4h-2z"></path></g></svg>
<span className="woocommerce-summary__item-delta-value">{discount_total.length>0 ? discount_total[0].rate_rmb:0}%</span>
            </div>
:<span>&nbsp;</span> 
:<span>&nbsp;</span> 
            }  
                
        </span>
        <span className="woocommerce-summary__item-data"> CAD: &nbsp;
            <span className="woocommerce-summary__item-value">{ discount_total.length>0 ? toCurrency( discount_total[0].current_cad,2):0}</span>
            {
              discount_total.length>0 ?
                discount_total[0].rate_cad > 0 ?
                <div className="woocommerce-summary__item-delta is-good-trend" role="presentation" aria-label="Up 4% from Previous Period:">
                  <svg className="gridicon gridicons-arrow-up woocommerce-summary__item-delta-icon needs-offset-x" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M13 20V7.83l5.59 5.59L20 12l-8-8-8 8 1.41 1.41L11 7.83V20h2z"></path></g></svg>
                  <span className="woocommerce-summary__item-delta-value">{discount_total.length>0 ? discount_total[0].rate_cad:0}%</span>
            </div>
:discount_total[0].rate_cad < 0 ? 
<div className="woocommerce-summary__item-delta is-bad-trend" role="presentation" aria-label="Up 4% from Previous Period:">
<svg className="gridicon gridicons-arrow-down woocommerce-summary__item-delta-icon needs-offset-x" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M11 4v12.17l-5.59-5.59L4 12l8 8 8-8-1.41-1.41L13 16.17V4h-2z"></path></g></svg>
<span className="woocommerce-summary__item-delta-value">{discount_total.length>0 ? discount_total[0].rate_cad:0}%</span>
            </div>
:<span>&nbsp;</span> 
:<span>&nbsp;</span> 
            }  

            
        </span>
        <span className="woocommerce-summary__item-prev-label">{lang[18]}:</span>
         <span className="woocommerce-summary__item-prev-value">RMB: &nbsp; {discount_total.length>0 ?  toCurrency(discount_total[0].previous_rmb,1):0}</span>
         <span className="woocommerce-summary__item-prev-value">CAD: &nbsp; {discount_total.length>0 ?  toCurrency(discount_total[0].previous_cad,2):0}</span>
        </a>
        </li>

             
{/* net sale report              */}
             
<li className="woocommerce-summary__item-container">
            <a href="#" 
            className="woocommerce-summary__item is-selected " aria-current="page" role="menuitem" data-link-type="wc-admin">
        <span className="woocommerce-summary__item-label">{lang[28]}</span>
        <span className="woocommerce-summary__item-data "> RMB: &nbsp;
            <span className="woocommerce-summary__item-value">{ net_sale_total.length>0 ? toCurrency( net_sale_total[0].current_rmb,1):0}</span>
            
            {
              net_sale_total.length>0 ?
                net_sale_total[0].rate_rmb > 0 ?
                <div className="woocommerce-summary__item-delta is-good-trend" role="presentation" aria-label="Up 4% from Previous Period:">
                  <svg className="gridicon gridicons-arrow-up woocommerce-summary__item-delta-icon needs-offset-x" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M13 20V7.83l5.59 5.59L20 12l-8-8-8 8 1.41 1.41L11 7.83V20h2z"></path></g></svg>
                  <span className="woocommerce-summary__item-delta-value">{net_sale_total.length>0 ? net_sale_total[0].rate_rmb:0}%</span>
            </div>
:net_sale_total[0].rate_rmb < 0 ? 
<div className="woocommerce-summary__item-delta is-bad-trend" role="presentation" aria-label="Up 4% from Previous Period:">
<svg className="gridicon gridicons-arrow-down woocommerce-summary__item-delta-icon needs-offset-x" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M11 4v12.17l-5.59-5.59L4 12l8 8 8-8-1.41-1.41L13 16.17V4h-2z"></path></g></svg>
<span className="woocommerce-summary__item-delta-value">{net_sale_total.length>0 ? net_sale_total[0].rate_rmb:0}%</span>
            </div>
:<span>&nbsp;</span> 
:<span>&nbsp;</span> 
            }  
                
        </span>
        <span className="woocommerce-summary__item-data"> CAD: &nbsp;
            <span className="woocommerce-summary__item-value">{ net_sale_total.length>0 ? toCurrency( net_sale_total[0].current_cad,2):0}</span>
            {
              net_sale_total.length>0 ?
                net_sale_total[0].rate_cad > 0 ?
                <div className="woocommerce-summary__item-delta is-good-trend" role="presentation" aria-label="Up 4% from Previous Period:">
                  <svg className="gridicon gridicons-arrow-up woocommerce-summary__item-delta-icon needs-offset-x" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M13 20V7.83l5.59 5.59L20 12l-8-8-8 8 1.41 1.41L11 7.83V20h2z"></path></g></svg>
                  <span className="woocommerce-summary__item-delta-value">{net_sale_total.length>0 ? net_sale_total[0].rate_cad:0}%</span>
            </div>
:net_sale_total[0].rate_cad < 0 ? 
<div className="woocommerce-summary__item-delta is-bad-trend" role="presentation" aria-label="Up 4% from Previous Period:">
<svg className="gridicon gridicons-arrow-down woocommerce-summary__item-delta-icon needs-offset-x" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M11 4v12.17l-5.59-5.59L4 12l8 8 8-8-1.41-1.41L13 16.17V4h-2z"></path></g></svg>
<span className="woocommerce-summary__item-delta-value">{net_sale_total.length>0 ? net_sale_total[0].rate_cad:0}%</span>
            </div>
:<span>&nbsp;</span> 
:<span>&nbsp;</span> 
            }  

            
        </span>
        <span className="woocommerce-summary__item-prev-label">{lang[18]}:</span>
         <span className="woocommerce-summary__item-prev-value">RMB: &nbsp; {net_sale_total.length>0 ?  toCurrency(net_sale_total[0].previous_rmb,1):0}</span>
         <span className="woocommerce-summary__item-prev-value">CAD: &nbsp; {net_sale_total.length>0 ?  toCurrency(net_sale_total[0].previous_cad,2):0}</span>
        </a>
        </li>

             
{/* tax report              */}
             
<li className="woocommerce-summary__item-container">
            <a href="#" 
            className="woocommerce-summary__item is-selected " aria-current="page" role="menuitem" data-link-type="wc-admin">
        <span className="woocommerce-summary__item-label">{lang[29]}</span>
        <span className="woocommerce-summary__item-data "> RMB: &nbsp;
            <span className="woocommerce-summary__item-value">{ tax_total.length>0 ? toCurrency( tax_total[0].current_rmb,1):0}</span>
            
            {
              tax_total.length>0 ?
                tax_total[0].rate_rmb > 0 ?
                <div className="woocommerce-summary__item-delta is-good-trend" role="presentation" aria-label="Up 4% from Previous Period:">
                  <svg className="gridicon gridicons-arrow-up woocommerce-summary__item-delta-icon needs-offset-x" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M13 20V7.83l5.59 5.59L20 12l-8-8-8 8 1.41 1.41L11 7.83V20h2z"></path></g></svg>
                  <span className="woocommerce-summary__item-delta-value">{tax_total.length>0 ? tax_total[0].rate_rmb:0}%</span>
            </div>
:tax_total[0].rate_rmb < 0 ? 
<div className="woocommerce-summary__item-delta is-bad-trend" role="presentation" aria-label="Up 4% from Previous Period:">
<svg className="gridicon gridicons-arrow-down woocommerce-summary__item-delta-icon needs-offset-x" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M11 4v12.17l-5.59-5.59L4 12l8 8 8-8-1.41-1.41L13 16.17V4h-2z"></path></g></svg>
<span className="woocommerce-summary__item-delta-value">{tax_total.length>0 ? tax_total[0].rate_rmb:0}%</span>
            </div>
:<span>&nbsp;</span> 
:<span>&nbsp;</span> 
            }  
                
        </span>
        <span className="woocommerce-summary__item-data"> CAD: &nbsp;
            <span className="woocommerce-summary__item-value">{ tax_total.length>0 ? toCurrency( tax_total[0].current_cad,2):0}</span>
            {
              tax_total.length>0 ?
                tax_total[0].rate_cad > 0 ?
                <div className="woocommerce-summary__item-delta is-good-trend" role="presentation" aria-label="Up 4% from Previous Period:">
                  <svg className="gridicon gridicons-arrow-up woocommerce-summary__item-delta-icon needs-offset-x" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M13 20V7.83l5.59 5.59L20 12l-8-8-8 8 1.41 1.41L11 7.83V20h2z"></path></g></svg>
                  <span className="woocommerce-summary__item-delta-value">{tax_total.length>0 ? tax_total[0].rate_cad:0}%</span>
            </div>
:tax_total[0].rate_cad < 0 ? 
<div className="woocommerce-summary__item-delta is-bad-trend" role="presentation" aria-label="Up 4% from Previous Period:">
<svg className="gridicon gridicons-arrow-down woocommerce-summary__item-delta-icon needs-offset-x" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M11 4v12.17l-5.59-5.59L4 12l8 8 8-8-1.41-1.41L13 16.17V4h-2z"></path></g></svg>
<span className="woocommerce-summary__item-delta-value">{tax_total.length>0 ? tax_total[0].rate_cad:0}%</span>
            </div>
:<span>&nbsp;</span> 
:<span>&nbsp;</span> 
            }  

            
        </span>
        <span className="woocommerce-summary__item-prev-label">{lang[18]}:</span>
         <span className="woocommerce-summary__item-prev-value">RMB: &nbsp; {tax_total.length>0 ?  toCurrency(tax_total[0].previous_rmb,1):0}</span>
         <span className="woocommerce-summary__item-prev-value">CAD: &nbsp; {tax_total.length>0 ?  toCurrency(tax_total[0].previous_cad,2):0}</span>
        </a>
        </li>

{/* shipping report              */}
             
<li className="woocommerce-summary__item-container">
            <a href="#" 
            className="woocommerce-summary__item is-selected " aria-current="page" role="menuitem" data-link-type="wc-admin">
        <span className="woocommerce-summary__item-label">{lang[30]}</span>
        <span className="woocommerce-summary__item-data "> RMB: &nbsp;
            <span className="woocommerce-summary__item-value">{ shipping_total.length>0 ? toCurrency( shipping_total[0].current_rmb,1):0}</span>
            
            {
              shipping_total.length>0 ?
                shipping_total[0].rate_rmb > 0 ?
                <div className="woocommerce-summary__item-delta is-good-trend" role="presentation" aria-label="Up 4% from Previous Period:">
                  <svg className="gridicon gridicons-arrow-up woocommerce-summary__item-delta-icon needs-offset-x" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M13 20V7.83l5.59 5.59L20 12l-8-8-8 8 1.41 1.41L11 7.83V20h2z"></path></g></svg>
                  <span className="woocommerce-summary__item-delta-value">{shipping_total.length>0 ? shipping_total[0].rate_rmb:0}%</span>
            </div>
:shipping_total[0].rate_rmb < 0 ? 
<div className="woocommerce-summary__item-delta is-bad-trend" role="presentation" aria-label="Up 4% from Previous Period:">
<svg className="gridicon gridicons-arrow-down woocommerce-summary__item-delta-icon needs-offset-x" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M11 4v12.17l-5.59-5.59L4 12l8 8 8-8-1.41-1.41L13 16.17V4h-2z"></path></g></svg>
<span className="woocommerce-summary__item-delta-value">{shipping_total.length>0 ? shipping_total[0].rate_rmb:0}%</span>
            </div>
:<span>&nbsp;</span> 
:<span>&nbsp;</span> 
            }  
                
        </span>
        <span className="woocommerce-summary__item-data"> CAD: &nbsp;
            <span className="woocommerce-summary__item-value">{ shipping_total.length>0 ? toCurrency( shipping_total[0].current_cad,2):0}</span>
            {
              shipping_total.length>0 ?
                shipping_total[0].rate_cad > 0 ?
                <div className="woocommerce-summary__item-delta is-good-trend" role="presentation" aria-label="Up 4% from Previous Period:">
                  <svg className="gridicon gridicons-arrow-up woocommerce-summary__item-delta-icon needs-offset-x" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M13 20V7.83l5.59 5.59L20 12l-8-8-8 8 1.41 1.41L11 7.83V20h2z"></path></g></svg>
                  <span className="woocommerce-summary__item-delta-value">{shipping_total.length>0 ? shipping_total[0].rate_cad:0}%</span>
            </div>
:shipping_total[0].rate_cad < 0 ? 
<div className="woocommerce-summary__item-delta is-bad-trend" role="presentation" aria-label="Up 4% from Previous Period:">
<svg className="gridicon gridicons-arrow-down woocommerce-summary__item-delta-icon needs-offset-x" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M11 4v12.17l-5.59-5.59L4 12l8 8 8-8-1.41-1.41L13 16.17V4h-2z"></path></g></svg>
<span className="woocommerce-summary__item-delta-value">{shipping_total.length>0 ? shipping_total[0].rate_cad:0}%</span>
            </div>
:<span>&nbsp;</span> 
:<span>&nbsp;</span> 
            }  

            
        </span>
        <span className="woocommerce-summary__item-prev-label">{lang[18]}:</span>
         <span className="woocommerce-summary__item-prev-value">RMB: &nbsp; {shipping_total.length>0 ?  toCurrency(shipping_total[0].previous_rmb,1):0}</span>
         <span className="woocommerce-summary__item-prev-value">CAD: &nbsp; {shipping_total.length>0 ?  toCurrency(shipping_total[0].previous_cad,2):0}</span>
        </a>
        </li>             
             
        <li className="woocommerce-summary__item-container">
            <a href="#" 
            className="woocommerce-summary__item is-selected " aria-current="page" role="menuitem" data-link-type="wc-admin">
        <span className="woocommerce-summary__item-label">{lang[31]}</span>
        <span className="woocommerce-summary__item-data "> RMB: &nbsp;
            <span className="woocommerce-summary__item-value">{ total_sales.length>0 ? toCurrency( total_sales[0].current_rmb,1):0}</span>
            
            {
              total_sales.length>0 ?
                total_sales[0].rate_rmb > 0 ?
                <div className="woocommerce-summary__item-delta is-good-trend" role="presentation" aria-label="Up 4% from Previous Period:">
                  <svg className="gridicon gridicons-arrow-up woocommerce-summary__item-delta-icon needs-offset-x" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M13 20V7.83l5.59 5.59L20 12l-8-8-8 8 1.41 1.41L11 7.83V20h2z"></path></g></svg>
                  <span className="woocommerce-summary__item-delta-value">{total_sales.length>0 ? total_sales[0].rate_rmb:0}%</span>
            </div>
:total_sales[0].rate_rmb < 0 ? 
<div className="woocommerce-summary__item-delta is-bad-trend" role="presentation" aria-label="Up 4% from Previous Period:">
<svg className="gridicon gridicons-arrow-down woocommerce-summary__item-delta-icon needs-offset-x" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M11 4v12.17l-5.59-5.59L4 12l8 8 8-8-1.41-1.41L13 16.17V4h-2z"></path></g></svg>
<span className="woocommerce-summary__item-delta-value">{total_sales.length>0 ? total_sales[0].rate_rmb:0}%</span>
            </div>
:<span>&nbsp;</span> 
:<span>&nbsp;</span> 
            }  
                
        </span>
        <span className="woocommerce-summary__item-data"> CAD: &nbsp;
            <span className="woocommerce-summary__item-value">{ total_sales.length>0 ? toCurrency( total_sales[0].current_cad,2):0}</span>
            {
              total_sales.length>0 ?
                total_sales[0].rate_cad > 0 ?
                <div className="woocommerce-summary__item-delta is-good-trend" role="presentation" aria-label="Up 4% from Previous Period:">
                  <svg className="gridicon gridicons-arrow-up woocommerce-summary__item-delta-icon needs-offset-x" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M13 20V7.83l5.59 5.59L20 12l-8-8-8 8 1.41 1.41L11 7.83V20h2z"></path></g></svg>
                  <span className="woocommerce-summary__item-delta-value">{total_sales.length>0 ? total_sales[0].rate_cad:0}%</span>
            </div>
:total_sales[0].rate_cad < 0 ? 
<div className="woocommerce-summary__item-delta is-bad-trend" role="presentation" aria-label="Up 4% from Previous Period:">
<svg className="gridicon gridicons-arrow-down woocommerce-summary__item-delta-icon needs-offset-x" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M11 4v12.17l-5.59-5.59L4 12l8 8 8-8-1.41-1.41L13 16.17V4h-2z"></path></g></svg>
<span className="woocommerce-summary__item-delta-value">{total_sales.length>0 ? total_sales[0].rate_cad:0}%</span>
            </div>
:<span>&nbsp;</span> 
:<span>&nbsp;</span> 
            }  

            
        </span>
        <span className="woocommerce-summary__item-prev-label">{lang[18]}:</span>
         <span className="woocommerce-summary__item-prev-value">RMB: &nbsp; {total_sales.length>0 ?  toCurrency(total_sales[0].previous_rmb,1):0}</span>
         <span className="woocommerce-summary__item-prev-value">CAD: &nbsp; {total_sales.length>0 ?  toCurrency(total_sales[0].previous_cad,2):0}</span>
        </a>
        </li>             
             
             </ul></div>



<div className="woocommerce-chart"><div className="woocommerce-chart__header">
    <h2 className="woocommerce-chart__title">Gross Sales</h2>
    <div className="woocommerce-legend woocommerce-legend__direction-row">
        <ul className="woocommerce-legend__list">
            <li className="woocommerce-legend__item woocommerce-legend__item-checked" id="woocommerce-legend-2__item__primary">
                <button id="woocommerce-legend-2__item-button__primary" title="">
                    <div className="woocommerce-legend__item-container"><span className="woocommerce-legend__item-checkmark woocommerce-legend__item-checkmark-checked" ></span>
<span className="woocommerce-legend__item-title">{dateText}</span>
<span className="woocommerce-legend__item-total">RMB:{ gross_sale_total.length>0 ? toCurrency( gross_sale_total[0].current_rmb,1):0}</span>
&nbsp;&nbsp;<span className="woocommerce-legend__item-total">CAD:{ gross_sale_total.length>0 ? toCurrency( gross_sale_total[0].current_cad,2):0}</span>
</div></button></li>

<li className="woocommerce-legend__item woocommerce-legend__item-checked" id="woocommerce-legend-2__item__secondary">
    <button id="woocommerce-legend-2__item-button__secondary" title="">
        <div className="woocommerce-legend__item-container">
            <span className="woocommerce-legend__item-checkmark woocommerce-legend__item-checkmark-checked" ></span>
            <span className="woocommerce-legend__item-title">{vsDateText}</span>
            <span className="woocommerce-legend__item-total">RMB:{ gross_sale_total.length>0 ? toCurrency( gross_sale_total[0].previous_gross_sale_total_rmb,1):0}</span>
&nbsp;&nbsp;<span className="woocommerce-legend__item-total">CAD:{ gross_sale_total.length>0 ? toCurrency( gross_sale_total[0].previous_gross_sale_total_cad,2):0}</span>            
            
        </div>
    </button></li></ul></div>

<div className="components-base-control woocommerce-chart__interval-select">
    <div className="components-base-control__field">
        {/* <select id="inspector-select-control-0" className="components-select-control__input">
            <option value="day">By day</option><option value="week">By week</option></select> */}
            </div>
    </div>
    <div role="menubar" aria-orientation="horizontal" className="woocommerce-chart__types">
        <button type="button" title="Line chart" onClick={this.handleDrawLineChart} aria-checked="true" role="menuitemradio" tabIndex="0" className="components-button components-icon-button woocommerce-chart__type-button woocommerce-chart__type-button-selected">
            <svg className="gridicon gridicons-line-graph" height="24" width="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M3 19h18v2H3zm3-3c1.1 0 2-.9 2-2 0-.5-.2-1-.5-1.3L8.8 10H9c.5 0 1-.2 1.3-.5l2.7 1.4v.1c0 1.1.9 2 2 2s2-.9 2-2c0-.5-.2-.9-.5-1.3L17.8 7h.2c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2c0 .5.2 1 .5 1.3L15.2 9H15c-.5 0-1 .2-1.3.5L11 8.2V8c0-1.1-.9-2-2-2s-2 .9-2 2c0 .5.2 1 .5 1.3L6.2 12H6c-1.1 0-2 .9-2 2s.9 2 2 2z"></path></g></svg>
         </button>
        <button type="button" title="Bar chart" onClick={this.handleDrawBarChart} aria-checked="false" role="menuitemradio" tabIndex="-1" className="components-button components-icon-button woocommerce-chart__type-button"><svg className="gridicon gridicons-stats-alt" height="24" width="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M21 21H3v-2h18v2zM8 10H4v7h4v-7zm6-7h-4v14h4V3zm6 3h-4v11h4V6z"></path></g></svg>
        </button>
    </div>
</div>
<div className="woocommerce-chart__body woocommerce-chart__body-column">
    <div className="d3-chart__container" >
        {/* <div className="d3-chart__tooltip" >
				<div>
					<h4>Gross Sales</h4>
					<ul>
					
					<li className="key-row">
						<div className="key-container">
							<span className="key-color" >
							</span>
							<span className="key-key">Week of December 3, 2019</span>
						</div>
						<span className="key-value">$4,084.26</span>
					</li>
				
					<li className="key-row">
						<div className="key-container">
							<span className="key-color" >
							</span>
							<span className="key-key">Week of November 26, 2019</span>
						</div>
						<span className="key-value">$838.49</span>
					</li>
				
					</ul>
				</div>
			</div> */}
        <div className="d3-base" id="chartOrdersRMB">
            
        </div>

        <div className="d3-base" id="chartOrdersCAD">
            
        </div>        
    </div>
   </div>
</div>
            



<div className="woocommerce-card woocommerce-table woocommerce-report-table woocommerce-analytics__card has-menu has-action">
    <div className="woocommerce-card__header"><div className="woocommerce-card__title-wrapper">
        <h2 className="woocommerce-card__title woocommerce-card__header-item">{lang[0]}</h2></div>
        <div className="woocommerce-card__action woocommerce-card__header-item">
            
</div>

<div className="woocommerce-card__menu woocommerce-card__header-item">
    <div className="woocommerce-ellipsis-menu">
        <div>
            {/* <button type="button" title="Choose which values to display" aria-expanded="false" className="components-button components-icon-button woocommerce-ellipsis-menu__toggle"><svg aria-hidden="true" role="img" focusable="false" className="dashicon dashicons-ellipsis" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path d="M5 10c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm12-2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-7 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path></svg></button> */}
</div></div></div></div><div className="woocommerce-card__body">
    <div className="woocommerce-table__table" aria-hidden="false" aria-labelledby="caption-7" role="group">
        <table>
            {/* <caption id="caption-7" className="woocommerce-table__caption screen-reader-text">{lang[0]}</caption> */}
            <tbody>
            <tr>
                <th role="columnheader" scope="col" className="woocommerce-table__header is-left-aligned is-sortable is-sorted" aria-sort="descending"><button type="button" aria-describedby="header-7-0" className="components-button components-icon-button has-text is-button is-default">
                <svg className="gridicon gridicons-chevron-down" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M20 9l-8 8-8-8 1.414-1.414L12 14.172l6.586-6.586"></path></g></svg>
                <span aria-hidden="false">{lang[23]}</span></button></th>
                <th role="columnheader" scope="col" className="woocommerce-table__header is-sortable is-numeric" aria-sort="none">
                <button type="button" aria-describedby="header-7-1" className="components-button components-icon-button has-text is-button is-default">
                        <svg className="gridicon gridicons-chevron-down" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M20 9l-8 8-8-8 1.414-1.414L12 14.172l6.586-6.586"></path></g></svg>
                        <span aria-hidden="false">{lang[22]}</span></button>
                </th>
                <th role="columnheader" scope="col" className="woocommerce-table__header is-sortable is-numeric" aria-sort="none">
                    <button type="button" aria-describedby="header-7-1" className="components-button components-icon-button has-text is-button is-default">
                        <svg className="gridicon gridicons-chevron-down" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M20 9l-8 8-8-8 1.414-1.414L12 14.172l6.586-6.586"></path></g></svg>
                        <span aria-hidden="false">{lang[24]}</span></button>
                </th>
                <th role="columnheader" scope="col" className="woocommerce-table__header is-sortable is-numeric" aria-sort="none">
                    <button type="button" aria-describedby="header-7-2" className="components-button components-icon-button has-text is-button is-default">
                        <svg className="gridicon gridicons-chevron-down" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M20 9l-8 8-8-8 1.414-1.414L12 14.172l6.586-6.586"></path></g></svg>
                            <span aria-hidden="false">{lang[25]}</span></button></th>
                <th role="columnheader" scope="col" className="woocommerce-table__header is-sortable is-numeric" aria-sort="none">
                    <button type="button" aria-describedby="header-7-3" className="components-button components-icon-button has-text is-button is-default">
                        <svg className="gridicon gridicons-chevron-down" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M20 9l-8 8-8-8 1.414-1.414L12 14.172l6.586-6.586"></path></g></svg>
                            <span aria-hidden="false">{lang[26]}</span></button></th>
                <th role="columnheader" scope="col" className="woocommerce-table__header is-sortable is-numeric" aria-sort="none">
                    <button type="button" aria-describedby="header-7-4" className="components-button components-icon-button has-text is-button is-default">
                        <svg className="gridicon gridicons-chevron-down" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M20 9l-8 8-8-8 1.414-1.414L12 14.172l6.586-6.586"></path></g></svg>
    <span aria-hidden="false">{lang[27]}</span></button></th>
                <th role="columnheader" scope="col" className="woocommerce-table__header is-sortable is-numeric" aria-sort="none">
                    <button type="button" aria-describedby="header-7-5" className="components-button components-icon-button has-text is-button is-default">
                        <svg className="gridicon gridicons-chevron-down" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M20 9l-8 8-8-8 1.414-1.414L12 14.172l6.586-6.586"></path></g></svg>
                            <span aria-hidden="false">{lang[28]}</span></button></th>
                <th role="columnheader" scope="col" className="woocommerce-table__header is-sortable is-numeric" aria-sort="none">
                    <button type="button" aria-describedby="header-7-6" className="components-button components-icon-button has-text is-button is-default">
                        <svg className="gridicon gridicons-chevron-down" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M20 9l-8 8-8-8 1.414-1.414L12 14.172l6.586-6.586"></path></g></svg>
                            <span aria-hidden="false">{lang[29]}</span></button></th>
                <th role="columnheader" scope="col" className="woocommerce-table__header is-sortable is-numeric" aria-sort="none">
                    <button type="button" aria-describedby="header-7-7" className="components-button components-icon-button has-text is-button is-default">
                        <svg className="gridicon gridicons-chevron-down" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M20 9l-8 8-8-8 1.414-1.414L12 14.172l6.586-6.586"></path></g></svg>
                            <span aria-hidden="false">{lang[30]}</span></button></th>
                <th role="columnheader" scope="col" className="woocommerce-table__header is-sortable is-numeric" aria-sort="none">
                    <button type="button" aria-describedby="header-7-8" className="components-button components-icon-button has-text is-button is-default">
                        <svg className="gridicon gridicons-chevron-down" height="18" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M20 9l-8 8-8-8 1.414-1.414L12 14.172l6.586-6.586"></path></g></svg>
                            <span aria-hidden="false">{lang[31]}</span></button></th>
            </tr>
                        {orders_status_row}
            </tbody>
            </table>
            </div>
            
            {/* <ul className="woocommerce-table__summary">
                <li className="woocommerce-table__summary-item"><span className="woocommerce-table__summary-value">11</span><span className="woocommerce-table__summary-label">days</span></li>
                <li className="woocommerce-table__summary-item"><span className="woocommerce-table__summary-value">47</span><span className="woocommerce-table__summary-label">orders</span></li>
                <li className="woocommerce-table__summary-item"><span className="woocommerce-table__summary-value">$6,891.68</span><span className="woocommerce-table__summary-label">gross sales</span></li>
                <li className="woocommerce-table__summary-item"><span className="woocommerce-table__summary-value">$152.53</span><span className="woocommerce-table__summary-label">returns</span></li>
                <li className="woocommerce-table__summary-item"><span className="woocommerce-table__summary-value">$799.95</span><span className="woocommerce-table__summary-label">coupons</span></li>
                <li className="woocommerce-table__summary-item"><span className="woocommerce-table__summary-value">$5,939.20</span><span className="woocommerce-table__summary-label">net sales</span></li>
                <li className="woocommerce-table__summary-item"><span className="woocommerce-table__summary-value">$489.96</span><span className="woocommerce-table__summary-label">taxes</span></li>
                <li className="woocommerce-table__summary-item"><span className="woocommerce-table__summary-value">$166.78</span><span className="woocommerce-table__summary-label">shipping</span></li>
                <li className="woocommerce-table__summary-item"><span className="woocommerce-table__summary-value">$6,595.94</span><span className="woocommerce-table__summary-label">total sales</span></li>
            </ul> */}
        </div>
    </div>








                             




                    </div>
                </div>                
            </div>
        );
    }
}

export default RevenueReporting;