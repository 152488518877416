import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Link} from 'react-router-dom';

import LANGUAGE from '../../../service/Language-data';
//import EmbeddedEditor from '../../../service/components/EmbeddedEditor';
import SelectLocation from '../../../service/components/SelectLocation';
import UploadWallNoCrop from '../../../service/components/UploadWallNoCrop';
import { IMAGE } from '../../../service/media-type';
import { 
    getAllDoctorCategories, 
    getAllLanguages, 
    getUserByName, 
    getDoctorByUsername, 
    insertDoctor } from '../../../service/axios-service';

class AddDoctor extends Component{
    state={
        categories: [],
        languages: [],
        language_id: 1,
        user_name: '',
        real_name: '',
        images: [],
        city_id: 0,
        title: '',
        edu: '',
        quali: '',        
        category_id: 1,
        major: '',
        achiev: ''
    }

    static propTypes={
        langState: PropTypes.string.isRequired,
        logInName: PropTypes.string.isRequired
    };

    handleChange=(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleURLImages=(images)=>{
        this.setState({ images });        
    }
    
    getCityId=(id)=>{
        this.setState({ city_id: id});
    }
    
/*
    getEditorContent=(content)=>{
        this.setState({ desc: content });
    }
*/

    handleClickSave=(e)=>{
        const { language_id, user_name, real_name, images, city_id, title, edu,
            quali, category_id, major, achiev } = this.state;
        
        axios.all([
            getUserByName(user_name), 
            getDoctorByUsername(user_name)
        ]).then(axios.spread((r1,r2)=>{
            const one1 = r1.data;
            const one2 = r2.data;
            if(user_name === ''){
                alert(`The username cannot be empty! Try again, please!`);
            }
            else if(one1.length === 0){
                alert(`The username you input dose NOT exist! Try again, please!`);
            }
            else if(one2.length>0){
                alert(`There is a doctor who is using this username. Please check and try again!`);
            }
            else if(city_id === 0){
                alert(`You have to select a city!`);
            }
            else if(real_name===''){
                alert(`The real name cannot be empty. Please try again!`);
            }
            else{
                const data={ language_id, user_name, real_name, images, city_id, title, edu,
                             quali, category_id, major, achiev};                
                insertDoctor(data)
                    .then(res => {
                        alert("A doctor was added!");
                        this.setState({
                            language_id: 1,
                            user_name: '',
                            real_name: '',
                            images: [],
                            city_id: 0,
                            title: '',
                            edu: '',
                            quali: '',        
                            category_id: 1,
                            major: '',
                            achiev: ''
                        });
                    })
                    .catch((err) => { 
                        console.log("[Error] - At AddDoctor/handleClickSave/insertDoctor!");
                        console.log(err);           
                    });
            }
        })).catch(err=>{ 
            console.log("[Error] - At AddDoctor/handleClickSave/getUserByName & getDoctorByUsername!");
            console.log(err);
        });
        e.preventDefault();
    }

    componentDidMount(){
        axios.all([
            getAllDoctorCategories(), 
            getAllLanguages()
        ]).then(axios.spread((r1,r2)=>{
            this.setState({ 
                categories: r1.data, 
                languages: r2.data
            });
        })).catch((err) => { 
            console.log("[Error] - At AddDoctor/componentDidMount!");
            console.log(err);            
        });
    }

    render(){
        let lang='';
        if(this.props.langState ==='cn') lang=LANGUAGE.AddDoctor.cn;
        else lang=LANGUAGE.AddDoctor.en;

        const { categories, languages, language_id, user_name, real_name, images, city_id, title, edu,
                quali, category_id, major, achiev } = this.state;
        return(
            <div className="container-fluid">
                <div className="card rounded">
                    <div className="card-header">
                        <span style={{fontSize: "1.4em"}}><i className="fas fa-pencil-alt"></i>&nbsp;{lang[0]}</span>
                        <div className="float-right">
                            <div className=" btn-toolbar " >
                                <div className="btn-group-sm mr-2" role="group" aria-label="First group">
                                    <button type="button" className="btn btn-success" onClick={this.handleClickSave} title={lang[1]}><i className="fas fa-save"></i></button>
                                </div>
                                <Link to='/health/doctors'>
                                    <div className="btn-group-sm mr-2" role="group" aria-label="Second group">
                                        <button type="button" className="btn btn-primary" title={lang[2]}><i className="fas fa-arrow-alt-circle-right"></i></button>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-body" style={{fontWeight: 'bold'}}>
                        <div className="row justify-content-end">
                            <div className="col-10">
                                <div>{lang[3]}</div>
                                <hr/>
                                <div className="row">
                                    <div className="col col-sm-2 text-right input-group-sm">
                                        <label htmlFor="user_name" className="col-form-label">{lang[4]}</label>
                                    </div>
                                    <div className="col col-sm-10 input-group-sm">
                                        <input name="user_name" type="text" className="form-control" id="user_name" value={user_name} onChange={this.handleChange}/>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row">
                                    <div className="col col-sm-2 text-right input-group-sm">
                                        <label htmlFor="real_name" className="col-form-label">{lang[5]}</label>
                                    </div>
                                    <div className="col col-sm-10 input-group-sm">
                                        <input name="real_name" type="text" className="form-control" id="real_name" value={real_name} onChange={this.handleChange}/>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row">
                                    <div className="col col-sm-2 text-right input-group-sm">
                                        <label htmlFor="image" className="col-form-label">{lang[6]}</label>
                                    </div>
                                    <div className="col col-sm-10 input-group-sm">
                                        <UploadWallNoCrop
                                            some={1}
                                            listType="picture-card"
                                            load={ images }
                                            urls={this.handleURLImages}
                                            format={ IMAGE.toString() }
                                        />                                        
                                    </div>
                                </div>
                                <hr/>
                                <div className="row">
                                    <div className="col col-sm-2 text-right input-group-sm">
                                        <label htmlFor="city" className="col-form-label">{lang[7]}</label>
                                    </div>
                                    <div className="col col-sm-10 input-group-sm">
                                        <SelectLocation
                                            cityId={city_id}
                                            getCityId={this.getCityId}
                                        />
                                    </div>
                                </div>
                                <hr/>
                                <div className="row">
                                    <div className="col col-sm-2 text-right input-group-sm">
                                        <label htmlFor="title" className="col-form-label">{lang[8]}</label>
                                    </div>
                                    <div className="col col-sm-10 input-group-sm">
                                        <input name="title" className="form-control" id="title" rows="5" value={title} onChange={this.handleChange}/>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row">
                                    <div className="col col-sm-2 text-right input-group-sm">
                                        <label htmlFor="edu" className="col-form-label">{lang[9]}</label>
                                    </div>
                                    <div className="col col-sm-10 input-group-sm">
                                        <input name="edu" type="text" className="form-control" id="edu" value={edu} onChange={this.handleChange}/>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row">
                                    <div className="col col-sm-2 text-right input-group-sm">
                                        <label htmlFor="quali" className="col-form-label">{lang[10]}</label>
                                    </div>
                                    <div className="col col-sm-10">
                                        <textarea name="quali" className="form-control" id="quali" rows="5" value={quali} onChange={this.handleChange}/>
                                    </div>
                                </div>
                                <hr/>                                
                                <div className="row">
                                    <div className="col col-sm-2 text-right input-group-sm">
                                        <label htmlFor="category_id" className="col-form-label">{lang[11]}</label>
                                    </div>
                                    <div className="col col-sm-10 input-group-sm">
                                        <select name="category_id" className="custom-select mr-sm-2" id="category_id" value={category_id} onChange={this.handleChange}>
                                            {categories.map((one, index) => <option key={index} value={one.id}>{one.name}</option> )}
                                        </select>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row">
                                    <div className="col col-sm-2 text-right input-group-sm">
                                        <label htmlFor="major" className="col-form-label">{lang[12]}</label>
                                    </div>
                                    <div className="col col-sm-10">
                                        <textarea name="major" className="form-control" id="major" rows="5" value={major} onChange={this.handleChange}/>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row">
                                    <div className="col col-sm-2 text-right input-group-sm">
                                        <label htmlFor="achiev" className="col-form-label">{lang[13]}</label>
                                    </div>
                                    <div className="col col-sm-10">
                                        <textarea name="achiev" className="form-control" id="achiev" rows="5" value={achiev} onChange={this.handleChange}/>
                                    </div>
                                    
                                    {/*<div className="col col-sm-10">
                                        <EmbeddedEditor 
                                            content={this.state.desc}
                                            getContent={this.getEditorContent}/>
                                        </div>*/}
                                </div>
                                <hr/>
                                <div className="row">
                                    <div className="col col-sm-2 text-right input-group-sm">
                                        <label htmlFor="lang" className="col-form-label">{lang[14]}</label>
                                    </div>
                                    <div className="col col-sm-10 input-group-sm">
                                        <select name="language_id" className="custom-select mr-sm-2" id="lang" value={language_id} onChange={this.handleChange}>
                                            {languages.map((one, index) => <option key={index} value={one.id}>{one.name}</option> )}
                                        </select>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>            
            </div>
        );
    }
}

export default AddDoctor;