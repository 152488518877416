import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Link} from 'react-router-dom';
import LANGUAGE from '../../../service/Language-data';
import apiRoot from '../../../config.api';

import SearchBar from '../../../service/components/SearchBar';
import PagingBar from '../../../service/components/PagingBar';

class OperationLogManagement extends Component{
    state = {
        subjectList:[],
        searchContent:'',     // Accept the value of "change"; Used for search after the button is clicked.
        searchChange:'',      // Accept the value changed in "input".
        currentPage: 1,
        };

    static propTypes={
        langState: PropTypes.string.isRequired
    };

    handleSearchContent=(e)=>{
        this.setState({
            searchChange: e.target.value
        });
    };
    
    handleSearchSubmit=(e)=>{
        this.setState({
            searchContent: this.state.searchChange,
            currentPage: 1,        
        });
        e.preventDefault();
    }

    handlePageChange=(e)=>{
        this.setState({
            currentPage: +e.target.value
        });
    }






    handleClickUpdate=(id)=>{
        this.props.clickUpdate(id);        
    }

    componentDidMount(){
        axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
        axios.get(apiRoot + "users/operation_logs")
            .then((res) => {
                this.setState({ subjectList: res.data });
            })
            .catch((err) => {            
                console.log("Getting users error in OperationLogManagement component!");            
        });
    }

    render(){
        let lang='';
        if(this.props.langState ==='cn') lang=LANGUAGE.UserManagement.cn;
        else lang=LANGUAGE.UserManagement.en;

        const {subjectList, searchContent, currentPage} = this.state;
        const { permissions } = this.props;

        const row=[];
        subjectList.forEach((one, index)=>{            
            if( 
                one.username.toUpperCase().indexOf(searchContent.toUpperCase())===-1
             ){
                return;
            }
            row.push(
                <tr key={index} >                                        
                    <td style={{verticalAlign: 'middle'}} >{one.username}</td>
                    <td style={{verticalAlign: 'middle'}} >{one.operate_memo}</td>
                    <td style={{verticalAlign: 'middle'}} >{one.create_time.slice(0, one.create_time.indexOf('T'))}</td>                    
                </tr>
            );
        });

        const totalPages=Math.ceil(row.length/10);
        const onePage=row.reverse().slice((currentPage*10-10), (currentPage*10));

        return(
            <div className="container-fluid">
                <div className="card rounded">
                    <div className="card-header">
                        <span style={{fontSize: "1.4em"}}>{lang[13]}</span>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <div className="float-left">
                                    <SearchBar 
                                        langState={this.props.langState}
                                        searchContent={this.handleSearchContent}
                                        searchSubmit={this.handleSearchSubmit} />
                                </div>
                            </div>
                            
                        </div>
                        
                        <div className="table-responsive rounded">
                            <table className="table table-hover table-bordered text-center">
                                <thead>
                                    <tr className="table-primary">                                                                                                                       
                                        <th scope="col" style={{width:"15%"}}>{lang[7]}</th>
                                        <th scope="col" style={{width:"25%"}}>{lang[13]}</th>                                        
                                        <th scope="col" style={{width:"10%"}}>{lang[10]}</th>                                       
                                    </tr>
                                </thead>
                                <tbody>
                                    {onePage}
                                </tbody>
                            </table>                
                        </div>

                        <PagingBar 
                            totalPages={totalPages}                     
                            currentPage={this.state.currentPage}
                            pageChange={this.handlePageChange} 
                            />
                    </div>
                </div>            
            </div>
        );
    }
}

export default OperationLogManagement;