import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Link} from 'react-router-dom';
import LANGUAGE from '../../../service/Language-data';
import apiRoot from '../../../config.api';


class AddSupplier extends Component{
    state={
        langList: [],
        name: '',
        description: '',
        contact_first_name: '',
        contact_last_name: '',
        mobile_phone: '',
        e_mail: '',
        telephone: '',
        website: '',
        street: '',
        city: '',
        state: '',
        country: '',
        postcode: '',
        notes: '',
        language: '' 
    }

    static propTypes={
        langState: PropTypes.string.isRequired
    };

    handleChange=(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        });
    }


    handleClick=(e)=>{
        const { langState } = this.props;
        const { name, description,contact_first_name,contact_last_name,mobile_phone,e_mail,telephone,website,street,city,state,country,postcode,notes,language } = this.state;



                    axios.post(apiRoot + "products/supplier/add", { 
                            name, description,contact_first_name,contact_last_name,mobile_phone,e_mail,telephone,website,street,city,state,country,postcode,notes
                        })
                        .then(res => {
                            alert("An Supplier was added!");
                            const language = langState==='cn'? '简体中文':'English';
                            this.setState({
                                name: '',
                                description: '',
                                contact_first_name: '',
                                contact_last_name: '',
                                mobile_phone: '',
                                e_mail: '',
                                telephone: '',
                                website: '',
                                street: '',
                                city: '',
                                state: '',
                                country: '',
                                postcode: '',
                                notes: '',
                                language
                            });
                        })
                        .catch(err=>{
                            // console.log("[Error] - POST /products/attribute_groups/add - at AddAttributeGroup component!");
                            console.log(err);
                    });
                    e.preventDefault();


    }

    componentDidMount(){
        const { langState } = this.props;
        const language = langState==='cn'? '简体中文':'English';
        axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
        axios.get(apiRoot + "languages")
            .then((res) => {          
                this.setState({ 
                    langList: res.data,
                    language
                });
            })
            .catch((err) => { 
                console.log("[Error] - GET /languages - at AddAttributeGroup component!");
                console.log(err);            
        });
    }

    render(){
        const { langState } = this.props;
        let lang='';
        if(langState ==='cn') lang=LANGUAGE.Supplier.cn;
        else lang=LANGUAGE.Supplier.en;

        const {langList} = this.state;
        return(
            <div className="container-fluid">
                <div className="card rounded">
                    <div className="card-header">
                        <span style={{fontSize: "1.4em"}}><i className="fas fa-pencil-alt"></i>&nbsp;{lang[2]}</span>
                        <div className="float-right">
                            <div className=" btn-toolbar " >
                                <div className="btn-group-sm mr-2" role="group" aria-label="First group">
                                    <button type="button" className="btn btn-success" onClick={this.handleClick} title={lang[3]}><i className="fas fa-save"></i></button>
                                </div>
                                <Link to='/Supplier'>
                                    <div className="btn-group-sm mr-2" role="group" aria-label="Second group">
                                        <button type="button" className="btn btn-primary" title={lang[4]}><i className="fas fa-arrow-alt-circle-right"></i></button>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-body" style={{fontWeight: 'bold'}}>
                    <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="name" className="col-form-label">{lang[5]}</label>
                            </div>
                            <div className="col col-sm-10 input-group-sm">
                                <input name="name" type="text" className="form-control" id="name" value={this.state.name} onChange={this.handleChange} required/>
                            </div>
                        </div>
                        <hr/>

                        

                        <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="description" className="col-form-label">{lang[21]}</label>
                            </div>
                            <div className="col col-sm-10 input-group-sm">
                                <input name="description" type="text" className="form-control" id="description" value={this.state.description} onChange={this.handleChange} required/>
                            </div>
                        </div>
                        <hr/>
                        
                        <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="contact_first_name" className="col-form-label">{lang[22]}</label>
                            </div>
                            <div className="col col-sm-4 input-group-sm">
                                <input name="contact_first_name" type="text" className="form-control" id="contact_first_name" value={this.state.contact_first_name} onChange={this.handleChange} required/>
                            </div>
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="contact_last_name" className="col-form-label">{lang[23]}</label>
                            </div>
                            <div className="col col-sm-4 input-group-sm">
                                <input name="contact_last_name" type="text" className="form-control" id="contact_last_name" value={this.state.contact_last_name} onChange={this.handleChange} required/>
                            </div>

                        </div>
                        <hr/>
                        
                        <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="mobile_phone" className="col-form-label">{lang[19]}</label>
                            </div>
                            <div className="col col-sm-4 input-group-sm">
                                <input name="mobile_phone" type="text" className="form-control" id="mobile_phone" value={this.state.mobile_phone} onChange={this.handleChange} required/>
                            </div>
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="e_mail" className="col-form-label">{lang[20]}</label>
                            </div>
                            <div className="col col-sm-4 input-group-sm">
                                <input name="e_mail" type="text" className="form-control" id="e_mail" value={this.state.e_mail} onChange={this.handleChange} required/>
                            </div>

                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="telephone" className="col-form-label">{lang[11]}</label>
                            </div>
                            <div className="col col-sm-4 input-group-sm">
                                <input name="telephone" type="text" className="form-control" id="telephone" value={this.state.telephone} onChange={this.handleChange} required/>
                            </div>
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="website" className="col-form-label">{lang[8]}</label>
                            </div>
                            <div className="col col-sm-4 input-group-sm">
                                <input name="website" type="text" className="form-control" id="website" value={this.state.website} onChange={this.handleChange} required/>
                            </div>

                        </div>
                        <hr/>

                        <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="street" className="col-form-label">{lang[12]}</label>
                            </div>
                            <div className="col col-sm-10 input-group-sm">
                                <input name="street" type="text" className="form-control" id="street" value={this.state.street} onChange={this.handleChange} required/>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="city" className="col-form-label">{lang[13]}</label>
                            </div>
                            <div className="col col-sm-4 input-group-sm">
                                <input name="city" type="text" className="form-control" id="city" value={this.state.city} onChange={this.handleChange} required/>
                            </div>
                              <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="state" className="col-form-label">{lang[15]}</label>
                            </div>
                            <div className="col col-sm-4 input-group-sm">
                                <input name="state" type="text" className="form-control" id="state" value={this.state.state} onChange={this.handleChange} required/>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="country" className="col-form-label">{lang[16]}</label>
                            </div>
                            <div className="col col-sm-4 input-group-sm">
                                <input name="country" type="text" className="form-control" id="country" value={this.state.country} onChange={this.handleChange} required/>
                            </div>
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="postcode" className="col-form-label">{lang[17]}</label>
                            </div>
                            <div className="col col-sm-4 input-group-sm">
                                <input name="postcode" type="text" className="form-control" id="postcode" value={this.state.postcode} onChange={this.handleChange} required/>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="notes" className="col-form-label">{lang[14]}</label>
                            </div>
                            <div className="col col-sm-10 input-group-sm">
                                <input name="notes" type="text" className="form-control" id="notes" value={this.state.notes} onChange={this.handleChange} required/>
                            </div>
                        </div>
                        <hr/>                                                                                                                                                
                        

                    </div>
                </div>            
            </div>
        );
    }
}

export default AddSupplier;