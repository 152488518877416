import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Link} from 'react-router-dom';
import LANGUAGE from '../../../service/Language-data';
import apiRoot from '../../../config.api';

import SearchBar from '../../../service/components/SearchBar';
import PagingBar from '../../../service/components/PagingBar';
import { getAllTopicTags, getAllLanguages, getAllTopicTagMapping, deleteTopicTags } from '../../../service/axios-service';

class TagsManagement extends Component{
    state = {
        languages: [],
        tags:[],
        searchContent:'',     // Accept the value of "change"; Used for search after the button is clicked.
        searchChange:'',      // Accept the value changed in "input".
        currentPage: 1,
        checkedArr: [],
        checkedIds: [],
        checkedAll: false
        };

    static propTypes={
        langState: PropTypes.string.isRequired
    };

    handleSearchContent=(e)=>{
        this.setState({
            searchChange: e.target.value
        });
    };
    
    handleSearchSubmit=(e)=>{
        this.setState({
            searchContent: this.state.searchChange,
            currentPage: 1,        
        });
        e.preventDefault();
    }

    handlePageChange=(e)=>{
        this.setState({
            currentPage: +e.target.value
        });
    }

    handleChecked=(e,i)=>{
        let {checkedArr} = this.state;
        checkedArr[i] = !checkedArr[i];

        let id = e.target.value;
        let ids = this.state.checkedIds;
        let index = ids.indexOf(+id);
        e.target.checked === true? ids.push(+id) : ids.splice(index,1);
        this.setState({ 
            checkedIds: ids,
            checkedArr 
        });
    }

    handleCheckedAll=(e)=>{
        const {checkedArr,tags } = this.state;
        let tempArr=[];
        let ids = [];
        if(e.target.checked){
            checkedArr.forEach(one=> tempArr.push(!one));
            tags.forEach((one, index) => { if(tempArr[index]) ids.push(+one.id)});
        }
        else{
            checkedArr.forEach(one=> tempArr.push(false));
        }
        this.setState({
            checkedArr : tempArr,
            checkedIds : ids,
            checkedAll : e.target.checked
        });
    }

    handleClickDelete=(e)=>{
        const { checkedIds } = this.state;
        const idsString=checkedIds.toString();
        getAllTopicTagMapping()
            .then((res)=>{
                const hasOneOnit = res.data.filter(one=>checkedIds.includes(one.id));
                if(checkedIds.length===0){
                    alert(`You did NOT select any item!`);
                }
                else{
                    if(window.confirm(`Do you really want to delete No. ${idsString} ${checkedIds.length>1? 'tags':'tag'}?`)){
                        if(checkedIds.length===1 && hasOneOnit.length!==0){
                            alert(`There is at least one topic under this tag. You can NOT delete it.`);
                        }
                        else if(checkedIds.length>1 && hasOneOnit.length!==0){
                            alert(`There is at least one topic under at least one of the tags you checked. You can NOT delete all of them.`);
                        }
                        else{
                            deleteTopicTags({ checkedIds })
                                .then(()=>{
                                    alert(`You have deleted ${checkedIds.length>1? checkedIds.length +' tags':'a tag'}.`);
                                    getAllTopicTags()
                                        .then(res=>{
                                            let checkedArr=[];
                                            res.data.forEach(one=>{
                                                checkedArr.push( false )
                                            });
                                            this.setState({ 
                                                checkedArr, 
                                                tags: res.data,
                                                currentPage: 1,
                                                checkedIds: [],
                                                checkedAll: false
                                            });
                                        }).catch(err=>{ 
                                            console.log("[Error] - At TagsManagement/handleClickDelete/getAllTopicTags!");
                                            console.log(err);
                                        });
                                    }).catch(err=>{ 
                                        console.log("[Error] - At TagsManagement/handleClickDelete/deleteTopicTags!");
                                        console.log(err);
                                });
                        }
                    }
                }
            })
            .catch(err=>{ 
                console.log("[Error] - At TagsManagement/handleClickDelete/getAllTopicTagMapping!");
                console.log(err);
            });
        e.preventDefault();
    }

    handleClickUpdate=(id)=>{
        this.props.clickUpdate(id);        
    }

    componentDidMount(){
        axios.all([
            getAllTopicTags(), 
            getAllLanguages()
        ]).then(axios.spread((r1,r2)=>{
            let checkedArr=[];
            r1.data.forEach(one=>{
                checkedArr.push( false )
            });
            this.setState({ 
                checkedArr, 
                tags: r1.data,
                languages: r2.data 
            });
        })).catch((err) => { 
            console.log("[Error] - At TagsManagement/componentDidMount!");
            console.log(err);          
        });
    }

    render(){
        let lang='';
        if(this.props.langState ==='cn') lang=LANGUAGE.TagsManagement.cn;
        else lang=LANGUAGE.TagsManagement.en;

        const {tags, languages, searchContent, currentPage } = this.state;

        const row=[];
        tags.forEach((one, index)=>{ 
            const language = languages.length>0? languages.find(two=>two.id === one.languages_id).name : '';
            if( (one.tags_name.toUpperCase().indexOf(searchContent.toUpperCase())===-1)&&
                (language.toUpperCase().indexOf(searchContent.toUpperCase())===-1)){
                return;
            }
            row.push(
                <tr key={index} >
                    <td style={{verticalAlign: 'middle'}} className="text-center"><input type="checkbox" name="checkbox" checked={this.state.checkedArr[index]} value={one.id} onChange={(e)=>this.handleChecked(e, index)}/></td>
                    <td style={{verticalAlign: 'middle'}} className="text-center">{one.id}</td>
                    <td style={{verticalAlign: 'middle'}} >{one.tags_name}</td>
                    <td style={{verticalAlign: 'middle'}} >
                        {(()=>{
                            switch(one.cover_image.slice(0,4)){
                                case '':        return (<span>{'No Image'}</span>);
                                case 'http':    return (<img src={one.cover_image} style={{height: '50px'}} alt={index}/>);
                                default:        return (<a href={apiRoot + 'display/image/file?file=' + one.cover_image}>
                                                            <img src={apiRoot + 'display/image/file?file=' + one.cover_image} style={{height: '50px'}} alt={index}/>
                                                        </a>)
                            }
                        })()}
                    </td>
                    <td style={{verticalAlign: 'middle'}} >{language}</td>
                    <td style={{verticalAlign: 'middle'}} >{one.tags_count}</td>
                    <td style={{verticalAlign: 'middle'}} className="text-center">
                        <Link to="/topics/tags/update" ><button className="btn btn-success mr-2 btn-sm rounded" onClick={()=>this.handleClickUpdate(one.id)} title={lang[9]}><i className="fas fa-pencil-alt"></i></button></Link>
                    </td>
                </tr>
            );
        });

        const totalPages=Math.ceil(row.length/10);
        const onePage=row.reverse().slice((currentPage*10-10), (currentPage*10));

        return(
            <div className="container-fluid">
                <div className="card rounded">
                    <div className="card-header">
                        <span style={{fontSize: "1.4em"}}>{lang[0]}</span>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <div className="float-left">
                                    <SearchBar 
                                            langState={this.props.langState}
                                            searchContent={this.handleSearchContent}
                                            searchSubmit={this.handleSearchSubmit} />
                                </div>
                            </div>
                            <div className="col">
                                <div className="btn-group float-right">
                                    <Link to='/topics/tags/add' className="btn btn-primary btn-sm mr-2 rounded" title={lang[1]}><i className="fas fa-plus-square"></i></Link>
                                    <button type="button" className="btn btn-danger btn-sm rounded" onClick={this.handleClickDelete} title={lang[2]}><i className="fas fa-trash-alt"></i></button>
                               </div>
                            </div>
                        </div>
                        <div className="table-responsive rounded">
                            <table className="table table-hover table-bordered text-center">
                                <thead>
                                    <tr className="table-primary">
                                        <th scope="col" style={{width: "5%"}}><input checked={this.state.checkedAll} type="checkbox" onChange={this.handleCheckedAll}/></th>
                                        <th scope="col" style={{width: "10%"}}>{lang[3]}</th>
                                        <th scope="col" style={{width: "30%"}}>{lang[4]}</th>
                                        <th scope="col" style={{width: "20%"}}>{lang[5]}</th>
                                        <th scope="col" style={{width: "20%"}}>{lang[6]}</th>
                                        <th scope="col" style={{width: "10%"}}>{lang[7]}</th>
                                        <th scope="col" style={{width: "5%"}}>{lang[8]}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {onePage}
                                </tbody>
                            </table>
                        </div>

                        <PagingBar 
                            totalPages={totalPages}                     
                            currentPage={this.state.currentPage}
                            pageChange={this.handlePageChange} 
                            />
                    </div>
                </div>            
            </div>
        );
    }
}

export default TagsManagement;