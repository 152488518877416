import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Link} from 'react-router-dom';
import LANGUAGE from '../../../service/Language-data';

import EmbeddedEditor from '../../../service/components/EmbeddedEditor';
import UploadWallNoCrop from '../../../service/components/UploadWallNoCrop';
import { IMAGE } from '../../../service/media-type';
import { getAllQuestionCategories, getAllCurrencies, insertQuestion } from '../../../service/axios-service';

class AddQuestion extends Component{
    state={
            categories: [],
            currencies: [],
            title: '',
            category_id: 1,
            payable: '',
            currency_id: 1,
            question: '',
            images: []
        };
    
    static propTypes={
        langState: PropTypes.string.isRequired,
        logInName: PropTypes.string.isRequired
    }

    handleChange=(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleURLImages=(images)=>{
        this.setState({ images });        
    }

    getEditorContent=(content)=>{
        this.setState({ question: content });
    }

    handleSubmit=(e)=>{
        const { title, category_id, currency_id, question, images } = this.state;
        const username = this.props.logInName;
        if(question === ''){
            alert(`What is the content of your question? It cannot be empty. Please try again!`);
        }
        else{
            let { payable } = this.state;
            if(payable === '' || payable === undefined) payable = 0;
            const data={username, title, category_id, payable, currency_id, question, images }

            insertQuestion(data)
                .then(res => {
                        alert("One question was added!");
                        this.setState({
                            title: '',
                            category_id: 1,
                            payable: '',
                            currency_id: 1,
                            question: '',
                            images: []
                        });
                }).catch(err=>{ 
                    console.log("[Error] - At AddQuestion/handleSubmit/insertQuestion!");
                    console.log(err);
                });
        }   
        e.preventDefault();
    }

    handleClickReset=()=>{
        this.setState({
            title: '',
            category_id: 1,
            payable: '',
            currency_id: 1,
            question: '',
            images: []
        });
    }

    componentDidMount(){
        axios.all([
            getAllQuestionCategories(), 
            getAllCurrencies()
        ]).then(axios.spread((r1, r2)=>{
            this.setState({ 
                categories: r1.data,
                currencies: r2.data
            });
        })).catch((err)=>{ 
            console.log("[Error] - At AddQuestion/componentDidMount!");
            console.log(err);
        });
    }

    render(){
        let lang='';
        if(this.props.langState ==='cn') lang=LANGUAGE.AddQuestion.cn;
        else lang=LANGUAGE.AddQuestion.en;
        
        const { categories, currencies, images, title, category_id, payable, currency_id, question } = this.state;

        return(
            <div className="container">
                <div className="card rounded">
                    <div className="card-header"> 
                        <h5>{lang[0]}</h5>
                    </div>
                    <div className="card-body">
                        <div className="text-right">
                            <Link to="/questions" className="btn btn-primary btn-sm rounded" title={lang[1]}><i className="fas fa-arrow-alt-circle-right"></i></Link>
                            <br/>
                        </div>
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-row">          
                                <div className="form-group col-md-8">
                                    <label htmlFor="title">{lang[2]}</label>
                                    <input name="title" type="text" className="form-control" id="title" value={title}  onChange={this.handleChange} required/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="categories">{lang[3]}</label>
                                    <select name="category_id" className="custom-select mr-sm-2" id="categories" value={category_id} onChange={this.handleChange}>
                                            {categories.map((one, index) => <option key={index} value={one.categories_id}>{one.name}</option> )}
                                    </select>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <label htmlFor="question_upload_image">{lang[4]}</label>
                                    <input name="upload" type="button" className="form-control btn btn-outline-secondary btn-sm rounded" id="question_upload_image" value={lang[5]} data-toggle="modal" data-target="#images"/>
                                </div>
                                <div className="modal fade" id="images" tabIndex="-1" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="images">{lang[6]}</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <UploadWallNoCrop
                                                    some={8}
                                                    listType="picture-card"
                                                    load={ images }
                                                    urls={this.handleURLImages}
                                                    format={ IMAGE.toString() }
                                                />
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-primary rounded" data-dismiss="modal">{lang[7]}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="reward">{lang[8]}</label>
                                    <input name="payable" type="text" className="form-control" id="reward" value={payable}  onChange={this.handleChange}/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="currency_id">{lang[9]}</label>
                                    <select name="currency_id" className="custom-select mr-sm-2" id="currency_id" value={currency_id} onChange={this.handleChange}>
                                            {currencies.map((one, index) => <option key={index} value={one.currencies_id}>{one.name}</option> )}
                                    </select>
                                </div>
                            </div>
                            
                            <div className="form-group">
                                <label>{lang[10]}</label>
                                <EmbeddedEditor 
                                    content={question}
                                    getContent={this.getEditorContent}/>
                            </div>
                            <div className="btn-group">
                                <button type="submit" className="btn btn-success btn-sm mr-2 rounded" title={lang[11]}><i className="fas fa-save"></i></button>
                                <button type="reset" className="btn btn-danger btn-sm rounded" title={lang[12]} onClick={this.handleClickReset}><i className="fas fa-undo-alt"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );        
    }
}

export default AddQuestion;