import apiRoot from '../config.api';
import dateFormat from 'dateformat';
/*
* Purpose: Make the order (ascending order) of an object array. The type of parameter is "number" or "string".
* Used By: Addproduct.js
*/ 
function compare(property){
    return (obj1,obj2)=>{
        var value1 = obj1[property];
        var value2 = obj2[property];
        return value1 - value2;             // Ascending order
    }
}
export const object_array_compare=(data, property)=>{
    return data.sort(compare(property));
}

/*
* Purpose: Get some property's array from a object array.
* Used By: Addproduct.js
*/ 
export const propArray=(objectArray, propString)=>{
    let propertyArray=[];
    if(objectArray.length!==0){
        objectArray.forEach(one=>{
            propertyArray.push(one[propString]);
        })
    }
    return propertyArray
}

/*
* Purpose: Deep copy an object array.
* Used By: SelectLocation.js
*/ 
export const objDeepCopy=(source)=>{
    var sourceCopy = source instanceof Array ? [] : {};
    for (var item in source) {
        sourceCopy[item] = typeof source[item] === 'object' ? objDeepCopy(source[item]) : source[item];
    }
    return sourceCopy;
}

export const getUTCTimeString=(timeString)=>{
    let UTCTimeString ='';
    if(timeString.length !== 0){
        const DBdate = new Date(timeString).getTime();          // 数据库距 1970 年 1 月 1 日午夜（GMT 时间）之间的毫秒数
        const offset_GMT = new Date().getTimezoneOffset();      //本地时间和格林威治的时间差，单位为分钟
        const FEdate = new Date(DBdate - offset_GMT*60*1000);
        UTCTimeString =  FEdate.toISOString();
    }
    return UTCTimeString;    
}

/*
* Purpose: Only works for AddProduct & UpdateProduct components
* Process the data coming from axios.get(apiRoot + "products/attributes"), and make it be the style like (id: xxx, title: xxx, pid: xxx).
* Note: attribute groups must be added into the array above, those ids are like "0, -1, -2", those pid are 0.
*/ 
export const getAttrArray=( data )=>{
    let sortData = object_array_compare(data, "products_options_id");
    let item = {};
    const newData = [];
    if(data.length !== 0){
        let group = sortData[0].products_options_name
        let group_id = sortData[0].products_options_id;
        let group_key = 0; //?0
        item = {
            id: group_id,
            title: group,
            p: 0,
            key: group_key--
        }
        newData.push(item);
        sortData.forEach((one)=>{
            // let childKey =0;
            if(one.products_options_name !== group){
                group = one.products_options_name;
                group_id = one.products_options_id;
                item = {
                    id: group_id,
                    title: group,
                    p: 0,
                    key: group_key--
                }
                newData.push(item);
            }
            item = {
                id: one.products_options_values_to_products_options_id,
                title: one.products_options_values_name,
                p: one.products_options_id,
                ov:one.products_options_values_id,
                key: one.products_options_values_to_products_options_id
            }
            newData.push(item);
        });
    }
    return newData
}

/* 
 * Purpose: Only used for UploadWall component
 */
export const getFileList=(load)=>{
    let fileList=[];
    if(load.length > 0){
      load.forEach((one)=>{
        if(one.value !== ''){
          const temp = one.value.split('/');
          const oneFile = {
            uid: one.id,
            name: temp[temp.length-1],
            url: one.value.slice(0,4) === 'http'? one.value : (apiRoot + 'display/image/file?file='+ one.value) 
                // The conditional statement is for invoking the images that were uploaded by mobile.
          }
          fileList.push(oneFile);
          }
      });
    }
    return fileList
  }


export const toCurrency=(value,currencyId)=>{
    var currencyFormatter = require('currency-formatter');
    let currencyCode = 'CNY';
    if ( parseInt( currencyId) === 2) currencyCode='CAD';
    return currencyFormatter.format(value, { code: currencyCode });
}

export const formatOrderId=(value) =>{
    let orderId = 100000000 + parseInt(value);
    return orderId;
}
export const formatDateTime=(value) =>{
    return dateFormat(value, "yyyy-mm-dd h:MM:ss");
}
export const isValidCustom=(startDate,endDate) =>{
    let s1 = parseInt(startDate.substring(0,4));
    let s2 = parseInt(endDate.substring(0,4));
    if(s2-s1>2) return false;
    return true;
}

