const LANGUAGE={
    LeftPanel: { 
            cn:[
                "仪表板",     // Index = 0
                "管理系统",
                "文章管理",
                "文章列表",
                "增加文章",
                "评论管理",
                "类别管理",     // Index = 6           
                "视频管理",     
                "视频列表",
                "增加视频",
                "评论管理",
                "类别管理",     // Index = 11
                "活动管理",
                "活动列表",
                "增加活动",
                "评论管理",
                "类别管理",     // Index = 16
                "课程管理",
                "课程列表",
                "增加课程",
                "评论管理",
                "类别管理",     // Index = 21
                "问答管理",
                "问题列表",
                "增加问题",
                "类别管理",     // Index = 25
                "商品管理",
                "商品目录",
                "商品类别",
                "商品属性",     
                "属性分组",     // Index = 30
                "健康管理",
                "医生列表",
                "专家类别",     // Index = 33
                "地点管理",
                "国家管理",
                "省/州管理",
                "城市管理",     // Index = 37
                "话题管理",
                "话题列表",
                "增加话题",
                "标签管理",
                "话题跟帖",       // Index = 42
                "用户管理",
                "用户列表",
                "增加用户",
                "分组管理",
                "权限管理",
                "活动日志",
                "登陆",     
                "登入|登出",
                "登入",
                "登出",
                "订单管理", // Index = 53
                "制造厂商",
                "品牌",  //55
                "库存管理", 
                "仓库",
                "供应商",
                "入库记录",
                "出库记录", //60
                "收益报表",
                "订单报表",
                "产品报表",
                "客户报表",
                "报表分析",
                "分销报表",//66
                "操作日志",
                "优惠券" //68
                ], 
            en:[
                "Dashboard",        // Index = 0
                "Admin System",
                "Documents",        // Index = 2
                "List",
                "Add",
                "Comments",
                "Categories",       // Index = 6       
                "Videos",
                "List",
                "Add",    
                "Comments",
                "Categories",       // Index = 11
                "Events",
                "List",
                "Add",
                "Comments",
                "Categories",       // Index = 16
                "Lessons",        
                "List",
                "Add",
                "Comments",
                "Categories",       // Index = 21
                "Questions",        
                "List",
                "Add",
                "Tags",             // Index = 25
                "Products",
                "Products",
                "Categories",
                "Attributes",
                "Attribute Group",  // Index = 30
                "Health",
                "Doctors List",
                "Categories",       // Index = 33
                "Location Management",
                "Country",
                "Province/State",
                "City",                 // Index = 37
                "Topic Management",
                "Topic List",
                "Category",
                "Tag",
                "Topic Thread",         // Index = 42
                "Users",        
                "Users List",
                "Add User",
                "Groups",
                "Permissions",
                "Activity",
                "LOG-IN",
                "Portal",
                "Log In",
                "Log Out",            // Index = 52      
                "Orders",
                "Manufacturers",
                "Brands",
                "Inventory", 
                "Warehouse",
                "Supplier",
                "Stock In",
                "Stock Out", //60                
                "Revenue",
                "Orders",
                "Products",
                "Customer",
                "Analytics",
                "Distribution", //66
                "Operation Log",
                "Coupon"
                ]
    },

    RightPanel: { 
        cn:[

            ], 
        en:[                
                
            ]
    },

    Header: { 
            cn:[                
                "用户资料",             // Index = 0
                "通告",
                "设置",
                "退出"                  // Index = 3
                ], 
            en:[
                "My Profile",           // Index = 0
                "Notifications",
                "Settings",
                "Logout"                // Index = 3
                ]
    },

    TitleBar: { 
            cn:[                
                "组件",         //Index=0
                "组件",
                ], 
            en:[
                "Components",   //Index=0
                "Components",
                ]
    },

// folder: content---------------------------------------------------------------------------------------------------------------
    CategoriesTree: { 
            cn:[                
                
                ], 
            en:[
                
                ]
    },

    Content: { 
            cn:[                
                
                ], 
            en:[
                
                ]
    },

    NotFound: { 
            cn:[                
                
                ], 
            en:[
                
                ]
    },

    PagingBar: {
            cn:[              
                
                ], 
            en:[
        
                ]
    },

    SearchBar: { 
        cn:[                
            "搜索"
            ], 
        en:[
            "Search"
            ]
    },

// folder: home------------------------------------------------------------------------------------------------------------------
    Home: { 
        cn:[                
            
            ], 
        en:[
            
            ]
    },

    Alert: { 
        cn:[                
            "成功",
            "重要报警信息读取完成。"
            ], 
        en:[
            "Success",
            "You successfully read this important alert message."
            ]
    },

// folder: log-in ---------------------------------------------------------------------------------------------------------------    
    LogIn: { 
            cn:[                
                "Happiify管理系统",
                "登陆",
                "用户名",
                "密码",
                "请登陆 ... ",
                "忘记密码？",
                "重置密码", //6
                "下次自动登录"
                
                ], 
            en:[
                "Happiify Admin System",
                "LOG IN",
                "Username",
                "Password",
                "LOG IN",
                "Forgot password? ",
                "Reset Password",
                "Auto login next time"
                ]
    },
    
// folder: doc-management--------------------------------------------------------------------------------------------------------
    DocManagement: { 
            cn:[                
                "文章管理",
                "增加文章",
                "更新",
                "删除"
                ], 
            en:[
                "Document Mangement",
                "Add a document",
                "Update",
                "Delete"
                ]
    },
    CouponManagement:{
        cn:[
            "优惠券",
            "名称",
            "号码",
            "开始日期",
            "结束日期",//4
            "状态",
            "操作", //6
            "折扣",
            "保存",//8
            "返回",
            "描述",//10
            "折扣(%)",
            "订单最低金额",
            "最后更新",//13
        ],
        en:[
            "Coupon",
            "Code",
            "Start Date",
            "Expired Date",
            "Status",
            "Operate",
            "Discount",
            "Save",//8
            "Return",
            "Description",
            "Discount(%)",
            "Minimal order amount",
            "Last Update"
        ]
    },
    DocTable: { 
            cn:[                
                "编号",
                "类别",
                "用户",
                "文章标题",
                "标题图片",
                "创建时间",
                "修改时间",
                "操作",//7
                "编辑推荐",
                "浏览量", //9
                "状态"
                ], 
            en:[
                "Id",
                "Category",
                "User",
                "Title",
                "Title Image",
                "Creation Time",
                "Modification Time",
                "Operations",
                "Editor Recommend",
                "Visited"
                ]
    },

    AddDoc: { 
        cn:[                
            "增加文章",               // Index = 0
            "返回",
            "标题",
            "类别",
            "上传标题图片",
            "点击上传",
            "上传标题图片",
            "关闭",
            "编辑内容",
            "保存",
            "重设",                      // Index = 10
            "关键字（空格隔开）",
            ], 
        en:[
            "Add a new article",
            "Return",
            "Title",
            "Category",
            "Upload a header image",
            "Click to upload",
            "Upload a header image",
            "Close",
            "Description",
            "Save",
            "Reset",
            "Keywores(sperate by space)"
            ]
    },

    UpdateDoc: { 
        cn:[                
            "更新文章",               // Index = 0
            "标题",
            "类别",
            "上传标题图片",
            "点击上传",
            "上传标题图片",
            "关闭",
            "编辑内容",
            "保存",
            "返回",                      // Index = 9
            "关键字（空格隔开）", //10
            ], 
        en:[
            "Update an article",
            "Title",
            "Category",
            "Upload a header image",
            "Click to upload",
            "Upload a header image",
            "Close",
            "Description",
            "Save",
            "Return",
            "Keywores(sperate by space)"
            ]
    },

    DocComments: { 
            cn:[                
                "文章管理 - 评论",                      // Index = 0
                "编号",
                "用户",
                "文章标题",
                "评论",
                "创建时间",
                "操作",
                "删除"                                  // Index = 7
                ], 
            en:[
                "Document Mangement - Comments",        // Index = 0
                "Id",
                "User",
                "Document Title",
                "Comment",
                "Creation Time",
                "Operation",
                "Delete"                                // Index = 7
                ]
    },

    DocCategories: { 
            cn:[                
                "文章管理 - 分类",                      // Index = 0
                "分类编号（本级分类编号用于更新，上级分类编号用于增加）",
                "分类名称",
                "分类标识图片",
                "增加",
                "更改",
                "删除"                               // Index = 6
                ], 
            en:[
                "Video Mangement - Categories",        // Index = 0
                "Category id (or: Parent id for adding a category)",
                "Category Name",
                "Category Image",
                "Add",
                "Update",
                "Delete"                               // Index = 6
                ]
    },

// folder: video-management------------------------------------------------------------------------------------------------------
    VideoManagement: { 
        cn:[                
            "视频管理",
            "增加视频",
            "更新",
            "删除"
            ], 
        en:[
            "Video Mangement",
            "Add a video",
            "Update",
            "Delete"
            ]
    },

    VideoTable: { 
        cn:[                
            "编号",
            "用户",
            "类别",
            "标题",
            "描述",
            "封面图片",
            "视频路径",
            "视频时长",
            "操作",
            "最后更新" //9
            ], 
        en:[
            "Id",
            "User",
            "Category",
            "Title",
            "Description",
            "Image",
            "Path",
            "Length",
            "Operations",
            "Last Update"
            
            ]
    },

    AddVideo: { 
        cn:[                
            "增加新视频",               // Index = 0
            "返回",
            "标题",
            "类别",
            "上传封面图片和视频",
            "点击上传",
            "上传封面图片",      
            "上传视频",
            "关闭",
            "描述",
            "保存",
            "重设"                      // Index = 11
            ], 
        en:[
            "Add a new video",
            "Return",
            "Title",
            "Category",
            "Upload a cover image and a video",
            "Click to upload",
            "Upload a cover image",
            "Upload a video",
            "Close",
            "Description",
            "Save",
            "Reset"
            ]
    },

    UpdateVideo: { 
        cn:[                
            "更新视频",               // Index = 0
            "标题",
            "类别",
            "上传封面图片和视频",
            "点击上传",
            "上传封面图片",      
            "上传视频",
            "关闭",
            "描述",
            "保存",
            "返回"                      // Index = 10
            ], 
        en:[
            "Update a video",
            "Title",
            "Category",
            "Upload a cover image and a video",
            "Click to upload",
            "Upload a cover image",
            "Upload a video",
            "Close",
            "Description",
            "Save",
            "Return"
            ]
    },

    VideoComments: { 
        cn:[                
            "视频管理 - 评论",                      // Index = 0
            "编号",
            "用户",
            "视频标题",
            "评论",
            "创建时间",
            "操作",
            "删除"                                  // Index = 7
            ], 
        en:[
            "Video Mangement - Comments",        // Index = 0
            "Id",
            "User",
            "Video Title",
            "Comments",
            "Create Time",
            "Operation",
            "Delete"                                // Index = 7
            ]
    },

    VideoCategories:{
        cn:[                
            "视频管理 - 分类",                      // Index = 0
            "分类编号（本级分类编号用于更新，上级分类编号用于增加）",
            "分类名称",
            "增加",
            "更改",
            "删除"                               // Index = 5
            ], 
        en:[
            "Video Mangement - Categories",        // Index = 0
            "Category id (or: Parent id for adding a category)",
            "Category Name",
            "Add",
            "Update",
            "Delete"                               // Index = 5
            ]
    },

// folder: event-management------------------------------------------------------------------------------------------------------
    EventManagement: { 
        cn:[                
            "活动管理",
            "增加活动",
            "更新",
            "删除"
            ], 
        en:[
            "Event Mangement",
            "Add an event",
            "Update",
            "Delete"
            ]
    },

    EventTable: { 
        cn:[                
            "编号",                 // Index = 0
            "用户",
            "标题",
            "类型",
            "标题图片",
            "花费",
            "币种",
            "创建时间",
            "操作"                  // Index = 8
            ], 
        en:[
            "Id",                   // Index = 0
            "User",
            "Title",
            "Type",
            "Cover Image",
            "Cost",
            "Currency",
            "Create Time",
            "Operations"            // Index = 8
            ]
    },

    AddEvent: { 
        cn:[                
            "增加活动",      // Index = 0
            "返回",
            "标题",
            "举办机构",
            "活动地址",
            "邮编",
            "所在城市",
            "类型",
            "封面图片",
            "附加图片/文件/URL",
            "开始日期和时间",
            "结束日期和时间",
            "活动描述",
            "相关费用",
            "免费",
            "付费",
            "创建票券",
            "增加",
            "货币",
            "票券名称",
            "价格",
            "最大票数",
            "操作",
            "删除",
            "退款政策",     
            "增加",
            "重设",                      
            "上传图片",
            "关闭",
            "上传文件",
            "关闭",
            "上传Url链接",
            "关闭"                      // Index = 32
            ], 
        en:[
            "Add an event",             // Index = 0
            "Return",
            "Title",
            "Organizer",
            "Address",
            "Zip Code",
            "City",
            "Type",
            "Cover Image",
            "Attachments(Image/File/URL)",
            "Start Date and Time",
            "End Date and Time",
            "Description",
            "correlative charges",
            "Free",
            "Paid",
            "Title",
            "Create Tickets",
            "Add New",
            "Currency",
            "Ticket Name",
            "Price",
            "Maximum",
            "Action",
            "Refund Policy",
            "Save",
            "Reset",                      
            "Upload Images",
            "Close",
            "Upload files",
            "Close",
            "Upload Urls",
            "Close"                      // Index = 32
            ]
    },

    UpdateEvent: { 
        cn:[                
            "更新活动",      // Index = 0
            "返回",
            "标题",
            "举办机构",
            "活动地址",
            "邮编",
            "所在城市",
            "类型",
            "封面图片",
            "附加图片/文件/URL",
            "开始日期和时间",
            "结束日期和时间",
            "活动描述",
            "相关费用",
            "免费",
            "付费",
            "创建票券",
            "增加",
            "货币",
            "票券名称",
            "价格",
            "最大票数",
            "操作",
            "删除",
            "退款政策",     
            "增加",
            "重设",                      
            "上传图片",
            "关闭",
            "上传文件",
            "关闭",
            "上传Url链接",
            "关闭"                      // Index = 32
            ], 
        en:[
            "Update an event",             // Index = 0
            "Return",
            "Title",
            "Organizer",
            "Address",
            "Zip Code",
            "City",
            "Type",
            "Cover Image",
            "Attachments(Image/File/URL)",
            "Start Date and Time",
            "End Date and Time",
            "Description",
            "correlative charges",
            "Free",
            "Paid",
            "Title",
            "Create Tickets",
            "Add New",
            "Currency",
            "Ticket Name",
            "Price",
            "Maximum",
            "Action",
            "Refund Policy",
            "Save",
            "Reset",                      
            "Upload Images",
            "Close",
            "Upload files",
            "Close",
            "Upload Urls",
            "Close"                      // Index = 32
            ]
    },

    EventComments: { 
        cn:[                
            "活动管理 - 评论",                      // Index = 0
            "编号",
            "用户",
            "活动标题",
            "评论",
            "创建时间",
            "操作",
            "删除"                                  // Index = 7
            ], 
        en:[
            "Event Mangement - Comments",        // Index = 0
            "Id",
            "User",
            "Event Title",
            "Comments",
            "Create Time",
            "Operation",
            "Delete"                                // Index = 7
            ]
    },

    EventCategories:{
        cn:[                
            "活动管理 - 分类",                      // Index = 0
            "分类编号（本级分类编号用于更新，上级分类编号用于增加）",
            "分类名称",
            "增加",
            "更改",
            "删除"                               // Index = 5
            ], 
        en:[
            "Event Mangement - Categories",        // Index = 0
            "Category id (or: Parent id for adding a category)",
            "Category Name",
            "Add",
            "Update",
            "Delete"                               // Index = 5
            ]
    },

    // folder: Lesson-management------------------------------------------------------------------------------------------------------
    LessonManagement: { 
        cn:[                
            "课程管理",
            "增加课程",
            "更新",
            "删除",
            "付费",
            "免费"
            ], 
        en:[
            "Lesson Mangement",
            "Add a Lesson",
            "Update",
            "Delete",
            "Pay",
            "Free"
            ]
    },

    LessonTable: { 
        cn:[                
            "编号",             // Index = 0
            "类别",
            "用户",
            "标题",
            "节数",
            "封面图片",
            "授课人",
            "价格",
            "优惠价",
            "收费",
            "币种",
            "操作"              // Index = 11
            ], 
        en:[
            "Id",               // Index = 0
            "Category",
            "User",
            "Title",
            "Sections",
            "Cover Image",
            "Lecturer",
            "Price",
            "Special Price",
            "Payment",
            "Currency",            
            "Operations"        // Index = 11
            ]
    },

    AddLesson: { 
        cn:[                
            "增加课程",                      // Index = 0
            "返回",
            "标题",
            "分类",
            "主讲人",
            "封面图片",
            "附加图片/文件/链接",
            "上传图片",
            "关闭",
            "上传文件",
            "关闭",
            "上传链接",
            "关闭",
            "付款类型",
            "货币",
            "课程售价",
            "优惠价",
            "简单描述",     
            "详细描述",
            "创建课程章节",
            "标题",
            "开始时间",
            "教程",
            "操作",
            "上传课程章节文件",
            "关闭",
            "上传课程章节链接",
            "关闭",
            "增加",
            "删除",
            "保存",
            "重设",                                      // Index = 31
            "付费",
            "免费"
            ], 
        en:[
            "Add a lesson",         // Index = 0
            "Return",
            "Title",
            "Category",
            "Lecturer",
            "Cover Image",
            "Attachments(Image/File/URL)",
            "Upload Images",
            "Close",
            "Upload files",
            "Close",
            "Upload Urls",
            "Close",       
            "Payment Type",
            "Currency",
            "Price",
            "Special Price",
            "Short Description",
            "Details",
            "Create a course",
            "Title",
            "Start Time",
            "Tutorial",
            "Action",            
            "Upload files",
            "Close",
            "Upload Urls",
            "Close",      
            "Add",
            "Delete",
            "Save",
            "Reset",                                     // Index = 31
            "Pay",
            "Free"
            ]
    },

    UploadLessonVideosModal: { 
        cn:[                
            "上传课程分段",                         // Index = 0
            "标题",
            "描述",
            "时长 00:00",
            "标题",
            "描述",
            "时长 00:00",
            "标题",
            "描述",
            "时长 00:00",
            "标题",
            "描述",
            "时长 00:00",
            "标题",
            "描述",
            "时长 00:00",
            "确认"                                  // Index = 16
            ], 
        en:[
            "Upload the lesson's sections",         // Index = 0
            "Title",
            "Description",
            "Duration 00:00",
            "Title",
            "Description",
            "Duration 00:00",
            "Title",
            "Description",
            "Duration 00:00",
            "Title",
            "Description",
            "Duration 00:00",
            "Title",
            "Description",
            "Duration 00:00",
            "Confirm"                                     // Index = 16
            ]
    },

    UpdateLesson: { 
        cn:[                
            "更新课程",                      // Index = 0
            "返回",
            "标题",
            "分类",
            "主讲人",
            "封面图片",
            "附加图片/文件/链接",
            "上传图片",
            "关闭",
            "上传文件",
            "关闭",
            "上传链接",
            "关闭",
            "付款类型",
            "货币",
            "课程售价",
            "优惠价",
            "简单描述",     
            "详细描述",
            "创建课程章节",
            "标题",
            "开始时间",
            "教程",
            "操作",
            "上传课程章节文件",
            "关闭",
            "上传课程章节链接",
            "关闭",
            "增加",
            "删除",
            "保存",
            "重设",                                      // Index = 31
            "付费",
            "免费"
            ], 
        en:[
            "Update a lesson",         // Index = 0
            "Return",
            "Title",
            "Category",
            "Lecturer",
            "Cover Image",
            "Attachments(Image/File/URL)",
            "Upload Images",
            "Close",
            "Upload files",
            "Close",
            "Upload Urls",
            "Close",       
            "Payment Type",
            "Currency",
            "Price",
            "Special Price",
            "Short Description",
            "Details",
            "Create a course",
            "Title",
            "Start Time",
            "Tutorial",
            "Action",            
            "Upload files",
            "Close",
            "Upload Urls",
            "Close",      
            "Add",
            "Delete",
            "Save",
            "Reset",                                     // Index = 31
            "Pay",
            "Free"
            ]
    },

    UpdateLessonVideosModal: { 
        cn:[                
            "课程编号：",                         // Index = 0
            "标题：",
            "编号",
            "标题",
            "描述",
            "课程视频",
            "时长",
            "操作",
            "删除",
            "关闭"                                // Index = 9
            ], 
        en:[
            "Lesson ID: ",                          // Index = 0
            "Title: ",
            "Id",
            "Title",
            "Description",
            "Video",
            "Duration",
            "Operation",
            "Delete",
            "Close"                                 // Index = 9
            ]
    },

    LessonComments: { 
        cn:[                
            "课程管理 - 评论",                      // Index = 0
            "编号",
            "用户",
            "课程标题",
            "评论",
            "创建时间",
            "操作",
            "删除"                                  // Index = 7
            ], 
        en:[
            "Lesson Mangement - Comments",        // Index = 0
            "Id",
            "User",
            "Lesson Title",
            "Comments",
            "Create Time",
            "Operation",
            "Delete"                                // Index = 7
            ]
    },

    LessonCategories:{
        cn:[                
            "课程管理 - 分类",                      // Index = 0
            "分类编号",
            "分类名称",
            "分类标识图片",
            "备注：使用本级分类编号和名称用于更新和删除操作，使用上级分类编号和所需的名称用于增加操作。",
            "增加",
            "更改",
            "删除"                                  // Index = 7
            ], 
        en:[
            "Lesson Mangement - Categories",        // Index = 0
            "Category id",
            "Category Name",
            "Category Image",
            "Note: Using category id for update or delete; using parent's category id for add.",
            "Add",
            "Update",
            "Delete"                                // Index = 7
            ]
    },

    // folder: question-management------------------------------------------------------------------------------------------------------
    QuestionManagement: { 
        cn:[                
            "问答管理",
            "增加问题",
            "更新问题",
            "删除问题",
            "编辑答案",
            ], 
        en:[
            "Question Mangement",
            "Add a question",
            "Update the question",
            "Delete the question",
            "Edit answers",
            ]
    },

    QuestionTable: { 
        cn:[                
            "编号",                  // Index = 0
            "标签",
            "提问者",
            "标题",
            "标题图片",
            "编辑问题",
            "回答数量",
            "编辑答案"               // Index = 7
            ], 
        en:[
            "Id",                   // Index = 0
            "Tag",
            "Questioner",
            "Title",
            "Cover Image",
            "Operations",           
            "Answers",
            "Operations"           // Index = 7
            ]
    },

    AnswersModal:  { 
        cn:[                
            "问题编号：",           // Index = 0
            "标题：",
            "编号",
            "用户",
            "回答",
            "相关图片",
            "操作",
            "删除",
            "关闭"                  // Index = 8
            ], 
        en:[
            "Question ID: ",        // Index = 0
            "Title: ",
            "Id",
            "User",
            "Answer",
            "Image",           
            "Operations",
            "Delete",
            "Close"                 // Index = 8
            ]
    },

    AddQuestion: { 
        cn:[                
            "增加问题",                      // Index = 0
            "返回",
            "标题",
            "类别",
            "上传图片(第一张图片将作为封面图片）",
            "点击上传",
            "上传图片",
            "关闭",
            "赏金",
            "币种",
            "问题内容",
            "增加",
            "重设"                                      // Index = 12
            ], 
        en:[
            "Add a question",         // Index = 0
            "Return",
            "Title",
            "Category",
            "Upload images (First one as cover image)",
            "Click to upload",
            "Upload images",
            "Close",
            "Reward",
            "Currency",
            "Question",
            "Save",
            "Reset"                                       // Index = 12
            ]
    },

    UpdateQuestion: { 
        cn:[                
            "更新问题",                      // Index = 0
            "标题",
            "类别",
            "上传图片(第一张图片将作为封面图片）",
            "点击上传",
            "上传图片",
            "关闭",
            "赏金",
            "币种",
            "问题内容",
            "保存",
            "返回"                                      // Index = 11
            ], 
        en:[
            "Update a question",         // Index = 0
            "Title",
            "Category",
            "Upload images (First one as cover image)",
            "Click to upload",
            "Upload images",
            "Close",
            "Reward",
            "Currency",
            "Question",
            "Save",
            "Return"                                       // Index = 11
            ]
    },

    QuestionImageModal: { 
        cn:[                
            "问题编号：",       // Index = 0
            "标题：",
            "编号",
            "图片",
            "操作",
            "删除",
            "关闭"              // Index = 6
            ], 
        en:[
            "Question ID: ",      // Index = 0
            "Title: ",
            "Id",
            "Image",       
            "Operations",
            "Delete",
            "Close"             // Index = 6
            ]
    },
    
    QuestionTags:{
        cn:[                
            "问答管理 - 标签",                      // Index = 0
            "标签编号",
            "标签名称",
            "备注：标签只有一级，无父标签和子标签概念。当增加一个标签时，不必输入标签编号。",
            "增加",
            "更改",
            "删除"                                  // Index = 6
            ], 
        en:[
            "Tag Mangement - Tags",        // Index = 0
            "Tag id",
            "Tag Name",
            "Note: There is only one level in Tag Management. When adding a tag, do not input a Tag id.",
            "Add",
            "Update",
            "Delete"                                // Index = 6
            ]
    },

    AnswersManagement: { 
        cn:[                
            "问答管理 - 编辑答案",                           // Index = 0
            "问题内容",
            "问题回答",
            "增加答案",
            "返回问题列表",
            "编号",
            "回答者",
            "答案",
            "附件图片数量",
            "操作",
            "编辑",
            "删除"                                          // Index = 8
            ], 
        en:[
            "Question Management - Edit answers",           // Index = 0
            "Question Details",
            "Answers Details",
            "Add new",
            "Return question list",
            "Id",
            "Responder",
            "Answer",
            "Images",
            "Operations",           
            "Edit",
            "Delete"                                        // Index = 8
            ]
    },

    AddAnswer: { 
        cn:[                
            "问答管理 - 添加答案",
            "返回答案列表",
            "上传附件图片",
            "编辑答案",
            "保存",
            "重设"
            ], 
        en:[
            "Question Management - Add an answer",
            "Return to answers list",
            "Upload images",
            "Edit answer",
            "Save",
            "Reset"
            ]
    },

    UpdateAnswer: { 
        cn:[                
            "问答管理 - 编辑答案",
            "返回答案列表",
            "附件图片",
            "编辑答案",
            "保存",
            "重设"
            ], 
        en:[
            "Question Management - Edit an answer",
            "Return to answers list",
            "Images",
            "Modify answer",
            "Save",
            "Reset"
            ]
    },

    AnswerImagesManagement: { 
        cn:[                
            "问答管理 - 编辑答案 - 编辑图片",                           // Index = 0
            "答案内容",
            "相关图片",
            "增加答案",
            "返回问题列表",
            "编号",
            "用户",
            "答案",
            "相关图片",
            "操作",
            "编辑",
            "删除"                                          // Index = 8
            ], 
        en:[
            "Question Management - Edit Answers - Edit Images",           // Index = 0
            "Answer Details",
            "Images",
            "Add new",
            "Return question list",
            "Id",
            "User",
            "Answer",
            "Images",
            "Operations",           
            "Edit",
            "Delete"                                        // Index = 8
            ]
    },
    // folder: product-mamagement---------------------------------------------------------------------------------------------------------------
    ProductManagement: { 
        cn:[                
            "商品管理",
            "增加商品",
            "编辑",
            "删除",//3
            "入库"
            ], 
        en:[
            "Product Mangement",
            "Add new",
            "Edit",
            "Delete",
            "Stock In"
            ]
    },

    ProductTable: { 
        cn:[                
            "图片",                 // Index = 0
            "商品名称",
            "型号",
            "价格(RMB)",
            "价格(CAD)",
            "存量",
            "状态",
            "操作",                  // Index = 7
            "首页推荐",
            "最后更新"               // Index = 9
            ], 
        en:[
            "Image",               // Index = 0
            "Product Name",
            "Model",
            "Price",
            "Currency",
            "Quantity",
            "Status",           
            "Operations",            // Index = 7
            "Show at Home",
            "Last Update"
            ]
    },

    AddProduct:{
        cn:[                
            "增加商品",                       // Index = 0
            "保存",
            "后退",
            "基本信息", //----------------
            "产品基本信息", //********
            "型号",
            "图片",
            "价格",
            "税",
            "数量",
            "重量(克)",
            "制造厂商",
            "供货日期",
            "状态",
            "产品条码",
            "产品描述",//********
            "名称",
            "描述",
            "URL",
            "语言",
            "产品分类",
            "分类",
            "产品属性",
            "属性",                              // Index = 23
            "货币",
            "品牌",
            "属性附加值",
            "上传产品图片",                       // Index = 27
            "产品图片",
            "增加",
            "物流信息",  
            "运送国家",
            "运输方式",
            "免费",
            "费用",
            "预计到达(天)",//35
            "额外费用",
            "长(厘米)",
            "宽(厘米)",
            "高(厘米)",
            "优惠价",//40        
            "发布平台",
            "分销信息",//42
            "是否分销",
            "分销利润金额",
            "预览",//45  
            "供应商",
            "库存管理", //47
            "请选择仓库",
            "入库数量",
            "库存预警" //50
            /*
            "产品类别",//----------------
            "选择列表",//********
            "类别",
            "选择结果",//********
            "产品属性",//----------------
            "选择列表",//********
            "属性分组",
            "属性",
            "选择结果",//********            // Index = 28
            "类别"
            */
            ], 
        en:[
            "Add Product",                  // Index = 0
            "Save",
            "Return",
            "General",  //----------------
            "Product Info",//********
            "Model",
            "Image",
            "Price",
            "Tax",
            "Quantity",
            "Weight(g)",
            "Manufacturer",
            "Available Date",
            "Status",
            "BarCode",
            "Product Description",//********
            "Name",
            "Description",
            "URL",
            "Language",
            "Product Category",
            "Category",
            "Product Attribute",
            "Attribute",                             // Index = 23
            "Currency",
            "Brand",
            "Additonal value",
            "Upload Images",                         // Index = 27
            "Attach Pictures",
            "Add New",
            "Shipping Information",
            "Ship To",
            "Shipping Method",
            "Free",
            "Shipping Cost",
            "Estimate Delivery Days", //35
            "Additional Charge",
            "Length(cm)",
            "Width(cm)",
            "Height(cm)",
            "Promotion Price",//40
            "Publish To",
            "Distribute",//42
            "Use for Distribute",
            "Commission Rate",
            "Preview",     //45              
            "Supplier",
            "Inventory", //47
            "Please choose a Warehouse",
            "Quantity",
            "Stock Alert" //50            
            /*
            "Category",//----------------
            "Selection List",//********
            "Category",
            "Selected Result",//********
            "Attribute",//----------------
            "Selection List",//********
            "Attribute Groups",
            "Attributes",
            "Selected Result",//********             // Index = 28
            "Category"
            */
            ]
    },

    UpdateProduct:{
        cn:[                
            "更新商品信息",                       // Index = 0
            "保存",
            "后退",
            "基本信息", //----------------
            "产品基本信息", //********
            "型号",
            "图片",
            "价格",
            "税",
            "数量",
            "重量(g)",
            "制造厂商",
            "供货日期",
            "状态",
            "产品条码",
            "产品描述",//********
            "名称",
            "描述",
            "URL",
            "语言",
            "产品分类",
            "分类",
            "产品属性",
            "属性",                              
            "货币",
            "品牌",
            "属性附加值",                    // Index = 26
            "上传产品图片",                       // Index = 27
            "上传图片",
            "增加",
            "物流信息",  
            "运送国家",
            "运输方式",
            "免费",
            "费用",
            "预计到达(天)",//35
            "额外费用",
            "长(厘米)",
            "宽(厘米)",
            "高(厘米)",        
            "优惠价",//40        
            "发布平台",
            "分销信息",//42
            "是否分销",
            "分销利润金额",
            "预览",
            "供应商",
            "库存管理", //47
            "请选择仓库",
            "入库数量",
            "库存预警" //50                           
            ], 
        en:[
            "Update Product Info.",                  // Index = 0
            "Save",
            "Return",
            "General",  //----------------
            "Product Info",//********
            "Model",
            "Image",
            "Price",
            "Tax",
            "Quantity",
            "Weight(g)",
            "Manufacturer",
            "Available Date",
            "Status",
            "BarCode",
            "Product Description",//********
            "Name",
            "Description",
            "URL",
            "Language",
            "Product Category",
            "Category",
            "Product Attribute",
            "Attribute",                             
            "Currency",
            "Brand",
            "Additonal value",               // Index = 26
            "Upload Images",                         // Index = 27
            "Attach Pictures",
            "Add New",
            "Shipping Information",
            "Ship To",
            "Shipping Method",
            "Free",
            "Shipping Cost",
            "Estimate Delivery Days", //35
            "Additional Charge",
            "Length(cm)",
            "Width(cm)",
            "Height(cm)",   
            "Promotion Price", //40    
            "Publish To",
            "Distribute",//42
            "Use for Distribute",
            "Commission Rate",
            "Preview",     //45                    
            "Supplier",
            "Inventory", //47
            "Please choose a Warehouse",
            "Quantity",
            "Stock Alert" //50              
            ]
    },

    ProductCategories:{
        cn:[                
            "商品管理 - 分类",                      // Index = 0
            "分类编号",
            "分类名称",
            "分类标识图片",
            "备注：使用本级分类编号和名称用于更新和删除操作，使用上级分类编号和所需的名称用于增加操作。",
            "增加",
            "更改",
            "删除",                                  // Index = 7
            "是否用于分销",
            "分销提成百分比(%)"
            ], 
        en:[
            "Product Mangement - Categories",        // Index = 0
            "Category id",
            "Category Name",
            "Category Image",
            "Note: Using category id for update or delete; using parent's category id for add.",
            "Add",
            "Update",
            "Delete",                                // Index = 7
            "Is this category use for distribution",
            "distribution commission rate(%)"
            ]
    },

    ProductAttributes:{
        cn:[                
            "商品属性",                         // Index = 0
            "增加属性",
            "删除属性",                      
            "属性名称",
            "属性分组",
            "操作",
            "编辑属性"                           // Index = 6
            ], 
        en:[
            "Attributes",                   // Index = 0
            "Add new",
            "Delete",
            "Attributes Name",
            "Attributes Group",
            "Operation",
            "Edit"                           // Index = 6
            ]
    },

    AddAttribute:{
        cn:[                
            "增加属性",                       // Index = 0
            "保存",
            "返回",
            "属性名称",
            "属性分组",
            "语言"                            // Index = 5
            ], 
        en:[
            "Add Attribute",                  // Index = 0
            "Save",
            "Return",
            "Attribute Name",
            "Attribute Group",
            "Language"                        // Index = 5
            ]
    },

    UpdateAttribute:{
        cn:[                
            "编辑属性",                       // Index = 0
            "保存",
            "返回",
            "属性名称",
            "属性分组",
            "语言"                            // Index = 5
            ], 
        en:[
            "Edit Attribute",                 // Index = 0
            "Save",
            "Return",
            "Attribute Name",
            "Attribute Group",
            "Language"                        // Index = 5
            ]
    },

    ProductAttributeGroups:{
        cn:[                
            "商品属性分组",                   // Index = 0
            "增加分组",
            "删除分组",
            "分组名称",
            "操作",
            "编辑"                           // Index =5
            ], 
        en:[
            "Attribute Group List",          // Index = 0
            "Add new",
            "Delete",
            "Attribute Group Name",
            "Operation",
            "Edit"                           // Index = 5
            ]
    },

    AddAttributeGroup:{
        cn:[                
            "增加属性分组",                    // Index = 0
            "保存",
            "返回",
            "分组名称",
            "语言"                            // Index = 4
            ], 
        en:[
            "Add Attribute Group",            // Index = 0
            "Save",
            "Return",
            "Group Name",
            "Language"                        // Index = 4
            ]
    },

    UpdateAttributeGroup:{
        cn:[                
            "编辑属性分组",                    // Index = 0
            "保存",
            "返回",
            "分组名称",
            "语言"                            // Index = 4
            ], 
        en:[
            "Edit Attribute Group",           // Index = 0
            "Save",
            "Return",
            "Group Name",
            "Language"                        // Index = 4
            ]
    },

    // folder: doctor-management--------------------------------------------------------------------------------------------------------
    DoctorManagement: { 
        cn:[                
            "健康管理 - 医生列表",
            "增加",
            "删除",
            "编辑"
            ], 
        en:[
            "Health Mangement - Doctors List",
            "Add new",
            "Delete",
            "Edit"
            ]
    },

    DoctorTable: { 
        cn:[                
            "#",                 // Index = 0
            "照片",
            "用户名",
            "实名",
            "头衔",
            "类别",
            "所在地",
            "操作"                  // Index = 7
            ], 
        en:[
            "ID",                   // Index = 0
            "Photo",
            "User Name",
            "Real Name",
            "Title",
            "Category",
            "City",           
            "Operations"            // Index = 7
            ]
    },

    AddDoctor:{
        cn:[                
            "增加医生",                       // Index = 0
            "保存",
            "返回",
            "医生基本信息",
            "用户名",
            "真实姓名",
            "上传照片",
            "所在城市",
            "头衔",
            "学历",
            "资历",
            "所属类别",
            "擅长方向",
            "学术成果", 
            "语言"                              // Index = 14
            ], 
        en:[
            "Add a doctor",                     // Index = 0
            "Save",
            "Return",
            "Doctor Details",
            "User Name",
            "Real Name",
            "Upload a photo",
            "City",
            "Title",
            "Education",
            "Qualification",
            "Category",
            "Major",           
            "Academic achievements",
            "Language"                          // Index = 14
            ]
    },

    UpdateDoctor:{
        cn:[                
            "更新医生信息",                       // Index = 0
            "保存",
            "返回",
            "医生基本信息",
            "用户名",
            "真实姓名",
            "上传照片",
            "所在城市",
            "头衔",
            "学历",
            "资历",
            "所属类别",
            "擅长方向",
            "学术成果", 
            "语言"                            // Index = 14
            ], 
        en:[
            "Add a doctor",                  // Index = 0
            "Save",
            "Return",
            "Doctor Details",
            "User Name",
            "Real Name",
            "Upload a photo",
            "City",
            "Title",
            "Education",
            "Qualification",
            "Category",
            "Major",           
            "Academic achievements",
            "Language"                    // Index = 14
            ]
    },

    DoctorCategories:{
        cn:[                
            "专家类别",                       // Index = 0
            "增加类别",
            "删除类别",                      
            "类别名称",
            "图片",
            "语言",
            "操作",
            "编辑属性"                        // Index = 7
            ], 
        en:[
            "Categories",                    // Index = 0
            "Add new",
            "Delete",
            "Category Name",
            "Image",
            "Language",
            "Operation",
            "Edit"                           // Index = 7
            ]
    },

    AddDoctorCategory:{
        cn:[                
            "增加医生类别",                    // Index = 0
            "保存",
            "返回",
            "类别名称",
            "类别图片",
            "语言"                            // Index = 5
            ], 
        en:[
            "Add Category",                  // Index = 0
            "Save",
            "Return",
            "Category Name",
            "Category Image",
            "Language"                        // Index = 5
            ]
    },

    UpdateDoctorCategory:{
        cn:[                
            "编辑类别",                       // Index = 0
            "保存",
            "返回",
            "类别 ID",
            "类别名称",
            "类别图片",
            "语言"                            // Index = 6
            ], 
        en:[
            "Edit Category",                 // Index = 0
            "Save",
            "Return",
            "Category ID",
            "Category Name",
            "Category Image",
            "Language"                        // Index = 6
            ]
    },

    // folder: location-management--------------------------------------------------------------------------------------------------------
    Countries:{
        cn:[                
            "国家管理",                   // Index = 0
            "更新",
            "名称",
            "ISO-2",
            "ISO-3",
            "状态"                           // Index =5
            ], 
        en:[
            "Country Management",          // Index = 0
            "Update",
            "Name",
            "ISO-2",
            "ISO-3",
            "Status"                           // Index = 5
            ]
    },

    Provinces:{
        cn:[                
            "省/州管理",                   // Index = 0
            "增加",
            "删除",
            "名称",
            "邮编",
            "所属国家",
            "操作"                           // Index =6
            ], 
        en:[
            "Province/State Management",          // Index = 0
            "Add",
            "Delete",
            "Name",
            "Zip Code",
            "Country",
            "Action"                           // Index = 6
            ]
    },

    AddProvince:{
        cn:[                
            "增加省/州",                       // Index = 0
            "保存",
            "返回",
            "名称",
            "邮编",
            "所属国家"                         // Index = 5
            ], 
        en:[
            "Add a province/state",           // Index = 0
            "Save",
            "Return",
            "Name",
            "Post Code",
            "Country"                         // Index = 5
            ]
    },

    UpdateProvince:{
        cn:[                
            "更新省/州信息",                       // Index = 0
            "保存",
            "返回",
            "名称",
            "邮编",
            "所属国家"                         // Index = 5
            ], 
        en:[
            "Update a province/state",           // Index = 0
            "Save",
            "Return",
            "Name",
            "Post Code",
            "Country"                         // Index = 5
            ]
    },

    Cities:{
        cn:[                
            "城市管理",                             // Index = 0
            "增加",
            "删除",
            "名称",
            "邮编",
            "所属省/州",
            "所属国家",
            "操作"                                  // Index =7
            ], 
        en:[
            "Province/State Management",            // Index = 0
            "Add",
            "Delete",
            "Name",
            "Zip Code",
            "Province/State",
            "Country",
            "Action"                                // Index = 7
        ]
    },

    AddCity:{
        cn:[                
            "增加城市",                       // Index = 0
            "保存",
            "返回",
            "名称",
            "邮编",
            "所属省/州",
            "所属国家"                         // Index = 6
            ], 
        en:[
            "Add a city",           // Index = 0
            "Save",
            "Return",
            "Name",
            "Post Code",
            "Province/State",
            "Country"                         // Index = 6
            ]
    },

    UpdateCity:{
        cn:[                
            "更新城市",                       // Index = 0
            "保存",
            "返回",
            "名称",
            "邮编",
            "所属省/州",
            "所属国家"                         // Index = 6
            ], 
        en:[
            "Update a city",                 // Index = 0
            "Save",
            "Return",
            "Name",
            "Post Code",
            "Province/State",
            "Country"                         // Index = 6
            ]
    },


    // folder: user-management--------------------------------------------------------------------------------------------------------
    UserManagement: { 
        cn:[                
            "用户管理 - 用户列表",              // Index = 0
            "增加",
            "删除",
            "编辑",
            "查看",
            "#",
            "头像",
            "用户名",
            "电子邮件/手机",
            "角色",
            "创建日期",
            "最近登录日期",
            "操作",                              // Index = 12
            "操作日志",
            "推荐人" // Index = 14
            ], 
        en:[
            "User Mangement - Users List",
            "Add new",
            "Delete",
            "Edite",
            "View User",
            "#",
            "Portrait",
            "Username",
            "Email",
            "Role",
            "Date Created",
            "Last Login",
            "Action",
            "Referrer"    
            ]
    },

    AddUser: { 
            cn:[                
                "创建新用户",              // Index = 0
                "保存",
                "返回",
                "基本信息",
                "用户名",
                "电子邮件",
                "手机",
                "生日",
                "星座",
                "婚姻状况",
                "角色",//10
                "描述",
                "密码",
                "密码",
                "确认",
                "头像",
                "上传头像",
                "权限管理",  //17
                "用户信息"
                ], 
            en:[
                "User Management - Create User",    // Index = 0
                "Save",
                "Return",
                "Basic Details",
                "Username",
                "Email",
                "Mobile",
                "Birthday",
                "Constellation",
                "Marital Status",
                "Role",
                "Description",
                "Password",
                "Password",
                "Confirm",
                "Profile Image",
                "Upload Image",                      // Index = 16 
                "Permission",
                "User Information"
                ]
    },

    UpdateUser: { 
        cn:[                
            "用户管理 - 更新用户信息",              // Index = 0
            "保存",
            "返回",
            "基本信息",
            "用户名",
            "电子邮件",
            "手机",
            "生日",
            "星座",
            "婚姻状况",
            "角色",
            "描述",
            "密码",
            "密码",
            "确认",
            "头像",
            "上传头像",                          // Index = 16
            "其他信息",
            "点数",
            "关注",
            "粉丝",
            "健康币",                            // Index = 21
            "权限管理",  //22
            "用户信息"            
            ], 
        en:[
            "User Management - Update User Info",    // Index = 0
            "Save",
            "Return",
            "Basic Details",
            "Username",
            "Email",
            "Mobile",
            "Birthday",
            "Constellation",
            "Marital Status",
            "Role",
            "Description",
            "Password",
            "Password",
            "Confirm",
            "Profile Image",
            "Upload Image",                      // Index = 16
            "Other Details",
            "Points",
            "Follows",
            "Fans",
            "Coins",                              // Index = 21
            "Permission",
            "User Information"            
            ]
    },

    ViewUser: { 
        cn:[                
            "用户管理 - 查看用户信息",              // Index = 0
            "返回",
            "概览",
            "活动",
            "用户名",
            "电子邮件",
            "移动电话",
            "最近登陆日期",
            "角色"                                  // Index = 8
            ], 
        en:[
            "User Management - View User Info",    // Index = 0
            "Return",
            "Overall",
            "Activity",
            "Username",
            "Email",
            "Mobile",
            "Last Login",
            "Role"                                  // Index = 8
            ]
    },

    RoleManagement:{
        cn:[                
            "用户分组（角色）",                   // Index = 0
            "增加分组",
            "删除分组",
            "分组名称",
            "操作",
            "编辑"                           // Index =5
            ], 
        en:[
            "User Group (Role)",          // Index = 0
            "Add new",
            "Delete",
            "Group Name",
            "Action",
            "Edit"                           // Index = 5
            ]
    },

    AddRole:{
        cn:[                
            "增加用户分组（角色）",                       // Index = 0
            "保存",
            "返回",
            "分组名称（角色）",
            "权限"                                      // Index = 4
            ], 
        en:[
            "Add User Group (Role)",                    // Index = 0
            "Save",
            "Return",
            "Group Name (Role)",
            "Permission"                                // Index = 4
            ]
    },

    UpdateRole:{
        cn:[                
            "编辑用户分组（角色）",                      // Index = 0
            "保存",
            "返回",
            "分组名称（角色）",
            "权限"                                     // Index = 4
            ], 
        en:[
            "Edit User Group (Role)",                  // Index = 0
            "Save",
            "Return",
            "Group Name (Role)",
            "Permission"                               // Index = 4
            ]
    },

    PermissionManagement:{
        cn:[                
            "用户权限",                         // Index = 0
            "第二级权限",
            "第一级权限",
            "增加权限",
            "删除权限",
            "第二级权限名称",
            "第一级权限名称",
            "操作",
            "编辑",                              // Index =8
            "增加权限",
            "删除权限",
            "#",
            "第一级权限名称",
            "操作",
            "编辑"                              // Index = 14

            ], 
        en:[
            "User Permission",                  // Index = 0
            "First-Level Permission",
            "Second-Level Permission",
            "Add new",
            "Delete",
            "Second-Level Permission Name",
            "First-Level Permission Name",
            "Action",
            "Edit",                              // Index = 8
            "Add new",
            "Delete",
            "#",
            "First-Level Permission Name",
            "Action",
            "Edit"
            ]
    },

    AddPermission2:{
        cn:[                
            "增加第二级权限",                         // Index = 0
            "保存",
            "返回",
            "本级权限名称",
            "上级权限名称"                      // Index = 4
            ], 
        en:[
            "Add Second-Level Permission",                   // Index = 0
            "Save",
            "Return",
            "Second-Level Permission Name",
            "First-Level Permission Name"                  // Index = 4
            ]
    },

    AddPermission1:{
        cn:[                
            "增加第一级权限",                         // Index = 0
            "保存",
            "返回",
            "第一级权限名称"                          // Index = 3
            ], 
        en:[
            "Add First-Level Permission",                   // Index = 0
            "Save",
            "Return",
            "First-Level Permission Name"                  // Index = 4
            ]
    },

    UpdatePermission2:{
        cn:[                
            "编辑第二级权限",                           // Index = 0
            "保存",
            "返回",
            "第二级权限名称",
            "第一级权限名称"                            // Index = 4
            ], 
        en:[
            "Edit Second-Level Permission",                     // Index = 0
            "Save",
            "Return",
            "Second-Level Permission Name",
            "First-Level Permission Name"                        // Index = 4
            ]
    },

    UpdatePermission1:{
        cn:[                
            "编辑第一级权限",                           // Index = 0
            "保存",
            "返回",
            "第一级权限名称"                           // Index = 3
            ], 
        en:[
            "Edit First-Level Permission",                     // Index = 0
            "Save",
            "Return",
            "First-Level Permission Name"                        // Index = 4
            ]
    },

    // folder: topic-management--------------------------------------------------------------------------------------------------------
    TopicManagement: { 
        cn:[                
            "话题管理 - 话题列表",              // Index = 0
            "增加",
            "删除",
            "#",
            "标签",
            "图片",
            "用户名",
            "浏览总数",
            "回答总数",
            "创建日期",
            "操作",
            "编辑"                             // Index = 11
            ], 
        en:[
            "Topic Mangement - Topic List",
            "Add new",
            "Delete",
            "#",
            "Tags",
            "Cover Image",
            "Username",
            "Total Views",
            "Total Answers",
            "Create Time",
            "Action",
            "Edit"
            ]
    },

    AddTopic:{
        cn:[                
            "增加话题",                       // Index = 0
            "保存",
            "返回",
            "标签",
            "内容",
            "相关图片"                         // Index = 5
            ], 
        en:[
            "Add a topic",           // Index = 0
            "Save",
            "Return",
            "Tags",
            "Content",
            "Images"                         // Index = 5
            ]
    },

    UpdateTopic:{
        cn:[                
            "增加话题",                       // Index = 0
            "保存",
            "返回",
            "标签",
            "内容",
            "查看人次",
            "回答次数",
            "相关图片"                         // Index = 7
            ], 
        en:[
            "Add a topic",                  // Index = 0
            "Save",
            "Return",
            "Tags",
            "Content",
            "Total Views",
            "Total Answers",
            "Images"                         // Index = 7
            ]
    },

    TagsManagement:{
        cn:[                
            "话题标签",                       // Index = 0
            "增加",
            "删除",
            "#",                      
            "标签名称",
            "图片",
            "语言",
            "使用次数",
            "操作",
            "编辑"                        // Index = 8
            ], 
        en:[
            "Tags",                    // Index = 0
            "Add new",
            "Delete",
            "#",   
            "Tag Name",
            "Image",
            "Language",
            "Count",
            "Action",
            "Edit"                           // Index = 8
            ]
    },

    AddTag:{
        cn:[                
            "增加标签",                       // Index = 0
            "保存",
            "返回",
            "名称",
            "语言",
            "描述",
            "图片"                          // Index = 6
            ], 
        en:[
            "Add a tag",                    // Index = 0
            "Save",
            "Return",
            "Tag Name",
            "Language",
            "Description",
            "Image"                         // Index = 6
            ]
    },

    UpdateTag:{
        cn:[                
            "更新标签",                       // Index = 0
            "保存",
            "返回",
            "名称",
            "语言",
            "描述",
            "引用次数",
            "图片"                          // Index = 7
            ], 
        en:[
            "Add a tag",                    // Index = 0
            "Save",
            "Return",
            "Tag Name",
            "Language",
            "Description",
            "Count",
            "Image"                         // Index = 7
            ]
    },

    ThreadManagement: { 
        cn:[                
            "话题跟帖",                     // Index = 0
            "删除",
            "返回话题列表",
            "#",
            "话题id",
            "图片",
            "用户名",
            "留言内容",
            "相关图片",
            "创建日期",
            "操作",
            "增加",
            "编辑",
            "相关图片"                             // Index = 13
            ], 
        en:[
            "Topic Followings",
            "Delete",
            "Return to topic list",
            "#",
            "Topic ID",
            "Cover Image",
            "Username",
            "Content",
            "Images",
            "Create Time",
            "Action",
            "Add new",
            "Edit",
            "Images"
            ]
    },

    AddThread:{
        cn:[                
            "新增跟帖",                       // Index = 0
            "保存",
            "返回",
            "话题内容",
            "跟帖内容",
            "相关图片"                         // Index = 5
            ], 
        en:[
            "Post Something",                   // Index = 0
            "Save",
            "Return",
            "Topic Content",
            "Post Content",
            "Images"                         // Index = 5
            ]
    },

    UpdateThread:{
        cn:[                
            "更新跟帖",                       // Index = 0
            "保存",
            "返回",
            "话题内容",
            "跟帖内容",
            "相关图片"                         // Index = 5
            ], 
        en:[
            "Update Post",                   // Index = 0
            "Save",
            "Return",
            "Topic Content",
            "Post Content",
            "Images"                         // Index = 5
            ]
    },

    OrdersManagement: { 
        cn:[                
            "订单管理",               // Index = 0
            "订单号",
            "状态",
            "姓名",
            "发货地址",
            "付款方式",
            "总金额",      
            "货运信息",
            "操作",//8
            "保存",
            "货运服务商",
            "货运跟踪号",                      // Index = 11
            "关闭",
            "订单详细", //13
            "出库",
            "来源",  //15
            "时间",
            "销售员" //17
            ], 
        en:[
            "Order Management",
            "Order#",
            "Status",
            "Name",
            "shipping Address",
            "Payment Method",
            "Total Amount",
            "Shipping Tracking",
            "Operation",
            "Save",
            "Shipping Provider",
            "Tracking#",
            "Close",
            "Order Details",
            "Stock out",
            "Source",
            "Create Time",
            "Sales"
            ]
        },    
        OrdersDashboard:{
            cn:[
                "产品名称",
                "订货数量",
                "订单仪表板",
                "订单状态", //3
                "总数",
                "货币",//5
                "总金额",
                "已付款订单报表",
                "按日期查询",//8
                "开始日期",
                "结束日期",
                "产品名称", //11
                "订单明细",
                "查看订单" //13
            ],
            en:[
                "Product name",
                "Quantity",
                "Order dashboard",
                "Order status", //3
                "Count",
                "Currency", //5
                "Total amount",
                "Report for Paid Orders",
                "Search by date",
                "Start Date",
                "End Date",
                "Product name",
                "Orders List",
                "View order"
            ]
        },
        Reporting:{
            cn:[
                "收益分析",
                "日期范围",
                "请选择日期范围",//2
                "预设",
                "自定义",
                "请选择预设值",//5
                "今天",
                "昨天",
                "本星期",//8
                "上周",
                "本月",//10
                "上月",
                "本季度",
                "上季度",//13
                "本年度",
                "去年",
                "到", //16
                "相比于",
                "上一个日期范围",//18
                "上一年",
                "重置",//20
                "更新",
                "货币",//
                "日期",//23
                "订单数",
                "Gross Sales",
                "退货",//26
                "折扣",
                "净收入",
                "税",//29
                "运费",
                "Total Sales",
                "产品报表", //32
                "产品名称",
                "销售数量",
                "产品目录",
                "产品属性",//36
                "产品销售数量",
                "产品名称关键字",//38
                "客户报表",
                "客户关键字",//40
                "客户姓名",
                "注册日期",
                "手机号码",
                "总消费", //44
                "AOV",
                "订单报表",//46
                "订单状态",
                "所有订单",//48
                "已付款",
                "已发货",//50
                "已收货",
                "已取消", //52
                "已退货",
                "分销报表",//54
                "销售员",
                "总销售" //56
            ],
            en:[
                "Revenue analytics",
                "Data Range",
                "select a date range",
                "Presets",
                "Custom",
                "select a preset period",
                "Today",
                "Yesterday",
                "Week to Date",
                "Last Week",
                "Month to Date",
                "Last Month",
                "Quarter to Date",//11
                "Last Quarter",
                "Year to Date",
                "Last Year",
                "to",//15
                "compare to",
                "Previous Period",
                "Previous Year",
                "Reset",//19
                "Update",
                "Currency",
                "Date",
                "Orders",
                "Gross Sales",//25
                "Returns",
                "Coupons",
                "Net Sales",//28
                "Taxes",
                "Shipping",
                "Total Sales",
                "Product analytics",//32
                "Product name",
                "Item Sold",
                "Category",
                "Attribute",
                "Item Sold",
                "Keywords",
                "Customer analytics",
                "Keywords",
                "Name",
                "Sign up",
                "Mobile",
                "Total Spend",
                "AOV", //45
                "Order analytics",
                "Order Status",
                "All Orders", //48
                "Paid",
                "Shipped",
                "Delivered",
                "Canceled",
                "Refunded",//53
                "Distribution",
                "Sales",
                "Total Sale"

            ]
        },        
        Dashboard:{
            cn:[
                "产品名称",
                "订货数量",
                "仪表板",
                "订单状态", //3
                "总数",
                "货币",//5
                "总金额",
                "已付款订单报表",
                "按日期查询",//8
                "开始日期",
                "结束日期",
                "产品名称", //11
                "订单明细",
                "查看订单", //13
                "订单",
                "用户", //15
                "年",
                "月",
                "周", //18
                "文章",
                "销售最佳Top 10",//20
                "浏览最多Top 10",
                "低库存产品",//22
                "从没出售产品",
                "库存量",  //24
                "仓库名称"
            ],
            en:[
                "Product name",
                "Quantity",
                "Order dashboard",
                "Order status", //3
                "Count",
                "Currency", //5
                "Total amount",
                "Report for Paid Orders",
                "Search by date",
                "Start Date",
                "End Date",
                "Product name",
                "Orders List",
                "View order",//13
                "Order",
                "User",
                "Year",
                "Month",
                "Week",//18
                "Posts",
                "Top 10 best sale products",
                "Top 10 most viewed products",
                "Low stock products",//22
                "Never purchase products",
                "Stock Qty",
                "Warehouse name"//25
            ]
        },        
        OrdersDetails: { 
            cn:[                
                "订单详细",               // Index = 0
                "产品名称",
                "购买数量",
                "单价",
                "发货地址",
                "账单地址",
                "状态",      //6
                "购买日期",
                "小计",//8
                "税",
                "运费",
                "合计",                      // Index = 11
                "订单号",
                "订单详细", //13
                "出货仓库",
                "出库数量",  //15
                "产品出库",
                "发票" //17
                ], 
            en:[
                "Order Details",
                "Product Name",
                "Qty.",
                "Unit Price",
                "Shipping Address",
                "Billing Address",
                "Status",
                "Order Date",
                "Sub-Total",
                "Tax",
                "Shipping Fee",
                "Total",
                "Order #",
                "Order Details",//13
                "Warehouse",
                "Quantity",
                "Product Stock out",
                "Invoice"
                ]
            },     
            ProductManufacturers: {
                cn:[ 
                    "制造厂商", //0
                    "增加",
                    "增加新的制造厂商",
                    "保存",
                    "返回",  //4
                    "制造厂商名称",
                    "操作",
                    "LOGO", //7
                    "网址",
                    "删除", //9
                    "修改制造厂商"
                ],
                en:[
                    "Manufacturers",
                    "Add",
                    "Add Manufacturer",
                    "Save",
                    "Return",
                    "Manufacturer Name",
                    "Operation",
                    "LOGO",
                    "Website",
                    "Delete",
                    "Modify Manufacturer"
                ]
            },
            ProductBrand: {
                cn:[ 
                    "品牌", //0
                    "增加",
                    "增加新的品牌",
                    "保存",
                    "返回",  //4
                    "品牌名称",
                    "操作",
                    "LOGO", //7
                    "网址",
                    "删除", //9
                    "修改品牌"                    
                ],
                en:[
                    "Brands",
                    "Add",
                    "Add Brand",
                    "Save",
                    "Return",
                    "Brand Name",
                    "Operation",
                    "LOGO",
                    "Website",
                    "Delete",
                    "Modify Brand"                    
                ]
            },
            Warehouse: {
                cn:[ 
                    "仓库", //0
                    "增加",
                    "增加新的仓库",
                    "保存",
                    "返回",  //4
                    "仓库名称",
                    "操作",
                    "LOGO", //7
                    "网址",
                    "删除", //9
                    "修改仓库",
                    "联系电话", //11       
                    "地址", //12
                    "城市",
                    "备注",
                    "省/州", //15 
                    "国家",
                    "邮编"            
                ],
                en:[
                    "Warehouse",
                    "Add",
                    "Add Warehouse",
                    "Save",
                    "Return",
                    "Warehouse Name",
                    "Operation",
                    "LOGO",
                    "Website",
                    "Delete",
                    "Modify Warehouse"                    
                ]
            },
            Supplier: {
                cn:[ 
                    "供应商", //0
                    "增加",
                    "增加新的供应商",
                    "保存",
                    "返回",  //4
                    "供应商名称",
                    "操作",
                    "LOGO", //7
                    "网址",
                    "删除", //9
                    "修改供应商",
                    "联系电话", //11       
                    "地址", //12
                    "城市",
                    "备注",
                    "省/州", //15 
                    "国家",
                    "邮编", //17
                    "联系人",
                    "手机", //19
                    "邮箱",
                    "描述",
                    "名",//22
                    "姓",            
                ],
                en:[
                    "Supplier",
                    "Add",
                    "Add Supplier",
                    "Save",
                    "Return",
                    "Supplier Name",
                    "Operation",
                    "LOGO",
                    "Website",
                    "Delete",
                    "Modify Supplier"                    
                ]
            },
            StockIn: {
                cn:[ 
                    "产品入库",
                    "保存",
                    "返回",
                    "产品名称",
                    "当前库存情况",//4
                    "仓库名称",
                    "库存数量",
                    "入库操作", //7
                    "请选择仓库",
                    "入库数量", //9
                    "产品名称",
                    "录入用户", //11
                    "入库时间",
                    "入库记录", //13
                    "查看订单",
                    "出库数量", //15
                    "出库时间",
                    "出库记录" //17

                ],
                en:[

                ]                     
            }
};

export default LANGUAGE;