import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Link} from 'react-router-dom';
import LANGUAGE from '../../../service/Language-data';
import apiRoot from '../../../config.api';

import SearchBar from '../../../service/components/SearchBar';
import PagingBar from '../../../service/components/PagingBar';

class Warehouse extends Component{
    state={
        allMappingList: [],
        subjectList:[],
        searchContent:'',     // Accept the value of "change"; Used for search after the button is clicked.
        searchChange:'',      // Accept the value changed in "input".
        currentPage: 1,
        };

    static propTypes={
        langState: PropTypes.string.isRequired
    };

    handleSearchContent=(e)=>{
        this.setState({
            searchChange: e.target.value
        });
    };
    
    handleSearchSubmit=(e)=>{
        this.setState({
            searchContent: this.state.searchChange,
            currentPage: 1,        
        });
        e.preventDefault();
    }

    handlePageChange=(e)=>{
        this.setState({
            currentPage: +e.target.value
        });
    }

      

    handleClickUpdate=(id)=>{
        //this.props.clickUpdate(e);      
        sessionStorage.setItem('warehouseId', id)  
    }
    handleClickDelete=(e,id)=>{
       // console.log(e);
        if(window.confirm(`Do you really want to delete No. ${id} Warehouse?`)){
            axios.put(apiRoot + "products/warehouse/delete", {id})
            .then(res => {
                alert("You have deleted a Warehouse.");
                axios.get(apiRoot + "products/loadwarehouse")
                .then((res) => {     
                    this.setState({ subjectList: res.data });
                })
                .catch((err) => {            
                    console.log("Getting videos error in VideoManagement component!"+err);            
                });
            })
            .catch(err=>{
                console.log("Deleting videos error in VideoManagement component!" +err);
        });
        e.preventDefault();
        }
    }


    componentDidMount(){
        axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
        axios.get(apiRoot + "products/loadwarehouse")
            .then((res) => {
                this.setState({ subjectList: res.data });
            })
            .catch((err) => {
                // console.log("[Error] - GET /products/attribute_groups - at Warehouse component!");
                console.log(err);            
        });

    }

    render(){
        let lang='';
        if(this.props.langState ==='cn') lang=LANGUAGE.Warehouse.cn;
        else lang=LANGUAGE.Warehouse.en;

        const {subjectList, searchContent, currentPage} = this.state;

        const row=[];
        subjectList.forEach((one, index)=>{            
            if( (one.warehouse_name.toUpperCase().indexOf(searchContent.toUpperCase())===-1)){
                return;
            }
            row.push(
                <tr key={index} >
                    
                    <td style={{verticalAlign: 'middle'}} className="text-center">{one.id}</td>
                    <td style={{verticalAlign: 'middle'}} >{one.warehouse_name}</td>
                    <td style={{verticalAlign: 'middle'}} >{one.contact_phone}</td>
                    <td style={{verticalAlign: 'middle'}} >{one.street}</td>
                    <td style={{verticalAlign: 'middle'}} >{one.city}</td>
                    <td style={{verticalAlign: 'middle'}} >{one.notes}</td>
                    <td style={{verticalAlign: 'middle'}} className="text-center">
                        <Link to="/warehouse/update" className="btn btn-success mr-2 btn-sm rounded" onClick={()=>this.handleClickUpdate(one.id)} title={lang[5]}><i className="fas fa-pencil-alt"></i></Link>
                        <button type="button" className="btn btn-danger btn-sm rounded" onClick={(e)=>this.handleClickDelete(e,one.id)} title={lang[9]}><i className="fas fa-trash-alt"></i></button>
                    </td>
                </tr>
            );
        });

        const totalPages=Math.ceil(row.length/10);
        const onePage=row.reverse().slice((currentPage*10-10), (currentPage*10));

        return(
            <div className="container-fluid">
                <div className="card rounded">
                    <div className="card-header">
                        <span style={{fontSize: "1.4em"}}>{lang[0]}</span>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <div className="float-left">
                                    <SearchBar 
                                        langState={this.props.langState}
                                        searchContent={this.handleSearchContent}
                                        searchSubmit={this.handleSearchSubmit} />
                                </div>
                            </div>
                            <div className="col">
                                <div className="btn-group float-right">
                                    <Link to='/warehouse/add' className="btn btn-primary btn-sm rounded mr-2" title={lang[1]}><i className="fas fa-plus-square"></i></Link>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive rounded">
                            <table className="table table-hover table-bordered">
                                <thead>
                                    <tr className="table-primary">
                                        
                                        <th scope="col" style={{width: "5%"}} className="text-center">#</th>
                                        <th scope="col" style={{width: "15%"}}>{lang[5]}</th>
                                        <th scope="col" style={{width: "10%"}}>{lang[11]}</th>
                                        <th scope="col" style={{width: "30%"}}>{lang[12]}</th>
                                        <th scope="col" style={{width: "5%"}}>{lang[13]}</th>
                                        <th scope="col" style={{width: "20%"}}>{lang[14]}</th>
                                        <th scope="col" style={{width: "15%"}} className="text-center">{lang[6]}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {onePage}
                                </tbody>
                            </table>
                        </div>
                        <PagingBar 
                            totalPages={totalPages}                     
                            currentPage={this.state.currentPage}
                            pageChange={this.handlePageChange} 
                            />
                    </div>
                </div>            
            </div>
        );
    }
}

export default Warehouse;