import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import LANGUAGE from '../../../service/Language-data';
import UploadWall from '../../../service/components/UploadWall';
import { IMAGE } from '../../../service/media-type';
import { getAllDocCategories, insertDoc } from '../../../service/axios-service';
import EmbeddedEditor from '../../../service/components/EmbeddedEditor';
import MultiSelectTree from '../../../service/components/MultiSelectTree_antd';

class AddDoc extends Component{
    // draft_timer;
    state={
        categories:[],
        title: '',
        category_id: 1,
        images:[],
        content: '',
        category: [],
        draft_text:"",
        keyWords:"",
       // feature_img_tag:''
    }

    static propTypes={
        langState: PropTypes.string.isRequired,
        logInName: PropTypes.string.isRequired
    }
    
//草稿功能
save_draft=()=>{
    console.log("开始保存草稿");
    const { title, category_id, images, content,keyWords } = this.state;
    const data={  title, category_id, images, content,keyWords};

    //save to local storage
    localStorage.setItem("article_draft",JSON.stringify(data));
}
getCategoryValue=(value)=>{
    const category = value.filter(one=> +one>0)
    this.setState({ category });
}
setDataFromDraft=(e)=>{
    var draft=localStorage.getItem("article_draft");
    var draft_data = JSON.parse(draft);
    console.log("load from draft,="+ draft_data.title);
    this.setState({
        title: draft_data.title,
        category_id:draft_data.category_id,
        images:draft_data.images,
        content:draft_data.content,
        draft_text:"",
        keyWords: draft_data.keyWords
    });
    e.preventDefault();
}
startTimer=()=>{
    //check draft existing
    var draft=localStorage.getItem("article_draft");
    if(draft!=null){
        var draft_data = JSON.parse(draft);
        if(draft_data.title.length>0 || draft_data.content.length>0){
            this.setState({
                draft_text:"你有没完成的草稿，继续编辑?"
                //continue edit, so need to set data
            });
        }
    }
    //启动定时器，1分钟保存草稿一次
    this.timer = setInterval(() => this.save_draft(), 30000)
    console.log("start")
  }
  stopTimer=()=>{
    clearInterval(this.timer);
    console.log("stop");
    
  }
  clearDraft=()=>{
    localStorage.removeItem("article_draft");
  }
  resetTimer=()=>{
    //this.setState({time: 0})
    console.log("reset")
  }
  componentWillUnmount(){
    //stop timer
    this.stopTimer();
  }
    handleChange=(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleURLImages=(images)=>{
       
       console.log(images);
        var  tag="<img src='"+ images[0] +"' />";
        this.setState({ images,
            
        });        
    }

    getEditorContent=(content)=>{
        this.setState({ content });
    }

    handleSubmit=(e)=>{
        const { title, category_id, images, content,category,keyWords } = this.state;
        const username = this.props.logInName;

        console.log("category=" + JSON.stringify(category));
        
        //Add by Long to verify images
        if(images === '' || images.length===0){
            e.preventDefault();
            alert("请上传标题图片!");
            return;
        }
        if(content === ''){
            alert(`What is the content of your article? It cannot be empty. Please try again!`);
        }else if (category.length==0){
            alert("请至少选择一个文章目录")
        }else{
            //check parent category 10,15,16,23,26
            var parent_cate =[10,15,16,23,26];

            for(var i=0;i<category.length;i++){
                if (parent_cate.includes( category[i] ) === true){
                    alert("请不要选择一级目录");
                    e.preventDefault();
                    return;
                }
            }
            const data={ username, title, category_id, images, content,category,keyWords};
            insertDoc(data)
                .then(res => {
                    //clear content
                    this.clearDraft();
                    alert("A document was added!");
                    this.setState({
                        title: '',
                        category_id: 1,
                        images:[],
                        content: '',
                        category:[],
                        keyWords:''
                    //    feature_img_tag:''

                    });
                })
                .catch(err=>{ 
                    console.log("[Error] - At AddDoc/handleSubmit/insertDoc!");
                    console.log(err);
                    alert("Sorry！ The system is very busy now. The data cannot be saved. Please try it later.");
                });
        }   
        e.preventDefault();
    }

    handleClickReset=()=>{
        this.setState({
            title: '',
            category_id: 1,
            images:[],
            content: '',
            category:[],
            keyWords:''
           // feature_img_tag:''

        });
    }

    componentDidMount(){
        getAllDocCategories()
            .then((res) => {            
                this.setState({ categories: res.data });
            })
            .catch((err) => { 
                console.log("[Error] - At AddDoc/componentDidMount!");
                console.log(err);             
        });

        //启动定时器?
        this.startTimer();
    }


    render(){
        let lang='';
        if(this.props.langState ==='cn') lang=LANGUAGE.AddDoc.cn;
        else lang=LANGUAGE.AddDoc.en;

        const {categories, title, category_id,category, images, content,draft_text,keyWords } = this.state;
        return(
            <div className="container-fluid">
                <div className="card rounded">
                    <div className="card-header"> 
                    <div className="form-group col-md-8">
                        <h5>{lang[0]}</h5>
                        <br/>
                            <div className="small-note-text">(*)系统会每隔30秒自动保存草稿</div> 
                    </div>
                        <div className="text-right col-md-4">
                            <Link to="/documents" className="btn btn-primary btn-sm rounded" title={lang[1]}><i className="fas fa-arrow-alt-circle-right"></i></Link>
                        </div> 
                    </div>
                    <div className="card-body">
                    <div className="divDraft">
<a type="button" onClick={this.setDataFromDraft}  title={draft_text}>{draft_text}</a>  
</div>                  
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-row">
                                <div className="form-group col-md-8">
                                    <label htmlFor="title">{lang[2]}</label>
                                    <input name="title" type="text" className="form-control" id="title" value={title} onChange={this.handleChange} required/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="category_id">{lang[3]}</label>
                                    <MultiSelectTree
                                                    init={ category }
                                                    data={categories}
                                                    property1={'categories_id'}
                                                    property2={'parent_id'}
                                                    property3={'name'}
                                                    getValue={this.getCategoryValue}
                                                />

                                    {/* <select name="category_id" className="custom-select mr-sm-2" id="category_id" value={category_id} onChange={this.handleChange}>
                                             {categories.map((one, index) => <option key={index} value={one.categories_id}>{one.name}</option> )}
                                    </select> */}
                                </div>
                            </div>
                            <div className="form-row">

                            
                            <div className="form-group col-md-8">          

                                <div className="form-group  row-panel" style={{height:"130px"}}>
                                    
                                    <label htmlFor="question_upload_image">{lang[4]}</label>
                                    <div className="form-group float-right">
                                    <UploadWall
                                                    some={1}
                                                    listType="picture-card"
                                                    load={ images }
                                                    urls={this.handleURLImages}
                                                    format={ IMAGE.toString() }
                                                />
                                    </div>
                                    <div className="form-group small-note-text">* 删除及预览图片，请移动鼠标到图片中，然后选择相应的功能!</div>                                                
                                    {/* <input name="upload" type="button" className="form-control btn btn-outline-secondary btn-sm rounded" id="question_upload_image" value={lang[5]} data-toggle="modal" data-target="#images"/> */}
                                </div>
                                    <div className="modal fade" id="images" tabIndex="-1" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="images">{lang[6]}</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                            
                                                <UploadWall
                                                    some={1}
                                                    listType="picture-card"
                                                    load={ images }
                                                    urls={this.handleURLImages}
                                                    format={ IMAGE.toString() }
                                                />
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-primary rounded" data-dismiss="modal">{lang[7]}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>           
                            </div>

                            <div className="form-group col-md-4">
                                    <label htmlFor="title">{lang[11]}</label>
                                    <input name="keyWords" type="text" className="form-control" id="keyWords" value={keyWords} 
                                    onChange={this.handleChange} required/>
                                </div>

                            </div>
                            <div className="form-group">
                                <label>{lang[8]}</label>
                                <EmbeddedEditor 
                                    content={content}
                                    getContent={this.getEditorContent}/>
                            </div>
                            <div className="btn-group">
                                <button type="submit" className="btn btn-success btn-sm mr-2 rounded" title={lang[9]}><i className="fas fa-save"></i></button>
                                <button type="reset" className="btn btn-danger btn-sm rounded" title={lang[10]} onClick={this.handleClickReset}><i className="fas fa-undo-alt"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );        
    }
}

export default AddDoc;