import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import LANGUAGE from '../../../service/Language-data';
import SearchBar from '../../../service/components/SearchBar';
import PagingBar from '../../../service/components/PagingBar';
import { toCurrency } from '../../../service/func-service';
import { orderReporting } from '../../../service/axios-service';

class OrdersDashboard extends Component{
    state={
            subjectList:[],
            orders_status:[],
            orders_total:[],
            searchContent:'',     // Accept the value of "change"; Used for search after the button is clicked.
            searchChange:'',      // Accept the value changed in "input".
            currentPage: 1,
            isSearchByProduct:false,
            start: new Date().toISOString().slice(0, 10),
            end: new Date().toISOString().slice(0, 10),
        };
    
    static propTypes = {
        langState: PropTypes.string.isRequired
    };
    

    handleProviderChange=(e)=>{
       // const { shipping_providers_id} = this.state;  
        this.setState({
            shipping_providers_id : e.target.value,
            [e.target.name]: e.target.value
            
        });
    }
    handleTrackingIdChange=(e)=>{
        //const { tracking_id} = this.state;  
        this.setState({
            tracking_id : e.target.value,
            [e.target.name]: e.target.value
            
        });
    }
    handleSearchContent=(e)=>{
        
        this.setState({
            searchChange: e.target.value
        });
    };
    
    handleSearchSubmit=(e)=>{
        this.setState({
            searchContent: this.state.searchChange,
            currentPage: 1,        
        });
        //e.preventDefault();

        //do search by products keyword
        const {start,end,searchChange} = this.state;

        var keyword =searchChange;
        const data={ start, end,keyword
       };
        orderReporting(data)
            .then((res) => { 
                let dataArray = res.data;
                this.setState({ 
                    isSearchByProduct:true,
                    orders_status: dataArray[0],
                    orders_total:dataArray[1],
                    subjectList: dataArray[2],
                 });  
            })
            .catch((err) => {
                console.log("[Error] - GET /orders - at OrdersDashboard component!");
                console.log(err);         
        });        
    }

    handlePageChange=(e)=>{
        this.setState({
            currentPage: +e.target.value
        });
    }
    displayCurrency=(id)=>{
        if(parseInt(id)===1) return "￥";
        if(parseInt(id)===2) return "C$";
        if(parseInt(id)===3) return "$";
    }
    handleClickOrderDetails=(id)=>{
        // this.props.clickDetail(id);     
        sessionStorage.setItem('view_orders_id', id);
        console.log("444444");
    }

    handleChange=(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    handleClickSearchByDate=(e)=>{
        //console.log('ddddddddddddd');
        const {start,end} = this.state;

        if(start === '' || start === null || start === undefined) {
            alert("Please choose start date");
            return;
        }
        if(end === '' || end === null || end === undefined) {
            alert("Please choose start date");
            return;
        }    
        var keyword ='';
        const data={ start, end,keyword
       };
        orderReporting(data)
            .then((res) => { 
                let dataArray = res.data;
                this.setState({ 
                    isSearchByProduct:false,
                    orders_status: dataArray[0],
                    orders_total:dataArray[1],
                    subjectList: dataArray[2],
                 });  
            })
            .catch((err) => {
                console.log("[Error] - GET /orders - at OrdersDashboard component!");
                console.log(err);         
        });


    }
  

    componentDidMount(){
        const {start,end} = this.state;
        var keyword ='';
        const data={ start, end,keyword
       };
        orderReporting(data)
            .then((res) => { 
                let dataArray = res.data;
                this.setState({ 
                    orders_status: dataArray[0],
                    orders_total:dataArray[1],
                    subjectList: dataArray[2],
                 });  
            })
            .catch((err) => {
                console.log("[Error] - GET /orders - at OrdersDashboard component!");
                console.log(err);         
        });

                
    }

    render(){
        let lang='';
        if(this.props.langState ==='cn') lang=LANGUAGE.OrdersDashboard.cn;
        else lang=LANGUAGE.OrdersDashboard.en;

        const {subjectList, searchContent,currentPage,orders_status,start, end,orders_total,isSearchByProduct} = this.state;
        const { permissions } = this.props;


        const orders_status_row=[];
        orders_status.forEach((one, index)=>{            
            orders_status_row.push(
                <tr key={index} >
                     <td style={{verticalAlign: 'middle'}} className='text-center'>{one.orders_status_name}</td>
                    <td style={{verticalAlign: 'middle'}} className='text-center'>{one.iCount}</td>

                </tr>
            );
        });

        const orders_total_row=[];
        orders_total.forEach((one, index)=>{            
            //console.log(JSON.stringify(one));
            orders_total_row.push(
                <tr key={index} >
                    <td style={{verticalAlign: 'middle'}} className='text-center'>{this.displayCurrency(one.currency)}</td>
                    <td style={{verticalAlign: 'middle'}} className='text-center'>{one.iCount}</td>
                    <td style={{verticalAlign: 'middle'}} className='text-center'>{ toCurrency(one.total_amount,one.currency)}</td>

                </tr>
            );
        });        





        const row=[];
        var row_header=[];
        if(isSearchByProduct){
            row_header.push( <tr className="table-primary" key="99999999">
            <th scope="col" style={{width:"70%"}}>{lang[0]}</th>
            <th scope="col" style={{width:"15%"}}>{lang[1]}</th>
            <th></th>
        </tr>);
            subjectList.forEach((one, index)=>{            
                //console.log(JSON.stringify(one));
                row.push(
                    <tr key={index} >
                        <td style={{verticalAlign: 'middle'}} className='text-center'>{one.products_name}</td>
                        <td style={{verticalAlign: 'middle'}} className='text-center'>{one.products_quantity}</td>
                        <td>{lang[13]}
                        {
                                permissions[8][4].value?
                                <Link to="/products/order_details"  className="btn btn-success btn-sm rounded"
                                 onClick={(e)=>{this.handleClickOrderDetails(one.orders_id);}} 
                                 title={lang[32]}><i className="fas fa-info-circle"></i></Link>:null
                            }   

                        </td>
    
                    </tr>
                );
            });
    
        }else{
            
            row_header.push( <tr className="table-primary" key="99999998">
            <th scope="col" style={{width:"20%"}}>{lang[0]}</th>
            <th scope="col" style={{width:"15%"}}>{lang[1]}</th>
        </tr>);
            subjectList.forEach((one, index)=>{            
                if( (one.orders_uuid!=null && one.orders_uuid.indexOf(searchContent)===-1)){
                    return;
                }
                //console.log(JSON.stringify(one));
                row.push(
                    <tr key={index} >
                        <td style={{verticalAlign: 'middle'}} className='text-center'>{one.products_name}</td>
                        <td style={{verticalAlign: 'middle'}} className='text-center'>{one.quantity}</td>
    
                    </tr>
                );
            });
    
        }

        const totalPages=Math.ceil(row.length/10);
        const onePage=row.reverse().slice((currentPage*10-10), (currentPage*10));
        return(
            <div className="container-fluid">
                <div className="card rounded">
                    <div className="card-header">
                        

                        <span style={{fontSize: "1.4em"}}><i className="fas fa-pencil-alt"></i>&nbsp;{lang[2]}</span>
                        <div className="float-right">
                            <div className=" btn-toolbar " >
                                {/* <div className="btn-group-sm mr-2" role="group" aria-label="First group">
                                    <button type="button" className="btn btn-success" onClick={this.handleClickSave}><i className="fas fa-save" title={lang[1]}></i></button>
                                </div>
                                <div className="btn-group-sm mr-2" role="group" aria-label="First group">
                                    <button type="button" className="btn btn-success" onClick={this.handleClickPreview}>{lang[45]}</button>
                                </div> */}

                                <Link to='/products/orders_mgr'>
                                    <div className="btn-group-sm mr-2" role="group" aria-label="Second group">
                                        <button type="button" className="btn btn-primary">{lang[12]}&nbsp;
                                            <i className="fas fa-arrow-alt-circle-right" title={lang[12]}></i></button>
                                    </div>
                                </Link>
                            </div>
                        </div>                        
                    </div>
                    <div className="card-body">
                        

                        <div className="row card-header">
                        {/* <div className="col-md-12">
<p>{lang[8]}</p>                            
</div> */}

<div className="col-md-2 text-right">
                                    <label htmlFor="start">{lang[9]}</label>
</div><div className="col-md-3">                                    
                                    <input name="start" type="date" className="form-control" id="start" value={start}  onChange={this.handleChange} />
                                </div>
                                <div className="col-md-2 text-right">
                                    <label htmlFor="end">{lang[10]}</label>
                                    </div><div className="col-md-3">                                                                        
                                    <input name="end" type="date" className="form-control" id="end" value={end} onChange={this.handleChange} />
                                </div>
<div className="col-md-2">
<button className="btn btn-success rounded" onClick={this.handleClickSearchByDate} type="button" title="搜索">
    <i  className="fas fa-search"></i></button>
</div>

                            </div>


                            <div className="row mt_20 card-header">
                            <div className="col-md-12">                                
                            <p>{lang[11]}:</p>  
                            </div>
                                <div className="float-left">
                                    <SearchBar 
                                        langState={this.props.langState}
                                        searchContent={this.handleSearchContent}
                                        searchSubmit={this.handleSearchSubmit} />
                                </div>
                            </div>
                             


{
    /*
    订单状态报表
    */
}
<div className="row mt_20">

<div className="col-md-6">
<div className="box-no-shadow box-info " id="order-statistics-box">
<div className="box-header with-border">
<h3 className="box-title">
<i className="fa fa-cart-arrow-down"></i>&nbsp;&nbsp;
{lang[20]}
</h3>

</div>
<div className="box-body">
<div className="chart">
    <div className="chartjs-size-monitor" >
        <div id="bestOrderedProduct" >


        <div className="table-responsive rounded">
                <table className="table table-hover table-bordered text-center">
                    <thead>
                        <tr className="table-primary">
                            <th scope="col" style={{width:"20%"}}>{lang[3]}</th>
                            <th scope="col" style={{width:"15%"}}>{lang[4]}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders_status_row}
                    </tbody>
                </table>                
            </div>

            <div className="table-responsive rounded">
<p>
{lang[7]}
    </p>                
                <table className="table table-hover table-bordered text-center">
                    <thead>
                        <tr className="table-primary">
                        <th scope="col" style={{width:"20%"}}>{lang[5]}</th>
                            <th scope="col" style={{width:"20%"}}>{lang[4]}</th>
                            <th scope="col" style={{width:"15%"}}>{lang[6]}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders_total_row}
                    </tbody>
                </table>                
            </div>


            </div>
            </div>
            </div>



</div>
</div>
</div>







<div className="col-md-6">
                        <div className="table-responsive rounded">
                <table className="table table-hover table-bordered text-center">
                    <thead>
{row_header}
                    </thead>
                    <tbody>
                        {onePage}
                    </tbody>
                </table>                
            </div>


                        <PagingBar 
                            totalPages={totalPages}                     
                            currentPage={this.state.currentPage}
                            pageChange={this.handlePageChange}/>

</div>
</div>

                    </div>
                </div>                
            </div>
        );
    }
}

export default OrdersDashboard;