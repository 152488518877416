import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Link} from 'react-router-dom';
import LANGUAGE from '../../../service/Language-data';
import apiRoot from '../../../config.api';

import SearchBar from '../../../service/components/SearchBar';
import PagingBar from '../../../service/components/PagingBar';

import { 
    loadStockIn
} from '../../../service/axios-service';


class ProductStockIn extends Component{
    state={
        allMappingList: [],
        subjectList:[],
        searchContent:'',     // Accept the value of "change"; Used for search after the button is clicked.
        searchChange:'',      // Accept the value changed in "input".
        currentPage: 1,
        };

    static propTypes={
        langState: PropTypes.string.isRequired
    };

    handleSearchContent=(e)=>{
        this.setState({
            searchChange: e.target.value
        });
    };
    
    handleSearchSubmit=(e)=>{
        this.setState({
            searchContent: this.state.searchChange,
            currentPage: 1,        
        });
        e.preventDefault();
    }

    handlePageChange=(e)=>{
        this.setState({
            currentPage: +e.target.value
        });
    }

      

    handleClickUpdate=(e)=>{
        this.props.clickUpdate(e);        
    }
    handleClickDelete=(e,id)=>{
       // console.log(e);
        if(window.confirm(`Do you really want to delete No. ${id} Brand?`)){
            axios.put(apiRoot + "products/brands/delete", {id})
            .then(res => {
                alert("You have deleted a Brand.");
                axios.get(apiRoot + "products/loadbrands")
                .then((res) => {     
                    this.setState({ subjectList: res.data });
                })
                .catch((err) => {            
                    console.log("Getting videos error in VideoManagement component!"+err);            
                });
            })
            .catch(err=>{
                console.log("Deleting videos error in VideoManagement component!" +err);
        });
        e.preventDefault();
        }
    }


    componentDidMount(){
        // axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
        // axios.get(apiRoot + "products/loadbrands")
        //     .then((res) => {
        //         this.setState({ subjectList: res.data });
        //     })
        //     .catch((err) => {
        //         // console.log("[Error] - GET /products/attribute_groups - at ProductStockIn component!");
        //         console.log(err);            
        // });
        axios.all([
            loadStockIn()
        ]).then(axios.spread((r1)=>{
            this.setState({ 
                subjectList:      r1.data
            });
        })).catch((err) => { 
                console.log("[Error] - At UpdateTag/componentDidMount!");
                console.log(err);           
        });  
    }

    render(){
        let lang='';
        if(this.props.langState ==='cn') lang=LANGUAGE.StockIn.cn;
        else lang=LANGUAGE.StockIn.en;

        const {subjectList, searchContent, currentPage} = this.state;

        const row=[];
        subjectList.forEach((one, index)=>{            
            if( (one.products_name.toUpperCase().indexOf(searchContent.toUpperCase())===-1)){
                return;
            }
            row.push(
                <tr key={index} >
                    
                    <td style={{verticalAlign: 'middle'}} className="text-center">{one.id}</td>
                    <td style={{verticalAlign: 'middle'}} >{one.products_name}</td>
                    <td style={{verticalAlign: 'middle'}} >{one.warehouse_name}</td>
                    <td style={{verticalAlign: 'middle'}} >{one.username}</td>
                    <td style={{verticalAlign: 'middle'}} >{one.quantity_in}</td>
                    <td style={{verticalAlign: 'middle'}} >{one.create_time}</td>

                </tr>
            );
        });

        const totalPages=Math.ceil(row.length/10);
        const onePage=row.reverse().slice((currentPage*10-10), (currentPage*10));

        return(
            <div className="container-fluid">
                <div className="card rounded">
                    <div className="card-header">
                        <span style={{fontSize: "1.4em"}}>{lang[13]}</span>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <div className="float-left">
                                    <SearchBar 
                                        langState={this.props.langState}
                                        searchContent={this.handleSearchContent}
                                        searchSubmit={this.handleSearchSubmit} />
                                </div>
                            </div>
                            <div className="col">
                                {/* <div className="btn-group float-right">
                                    <Link to='/products/brand/add' className="btn btn-primary btn-sm rounded mr-2" title={lang[1]}><i className="fas fa-plus-square"></i></Link>
                                    
                                </div> */}
                            </div>
                        </div>

                        <div>
                        <div className="small-note-text">
*Note: 如需要产品入库，请到商品管理 -->商品目录中找到相应的产品，然后点击右边的”入库“按钮 <br/><br/><br/>
                            </div>
                        </div>
                        <div className="table-responsive rounded">
                            <table className="table table-hover table-bordered">
                                <thead>
                                    <tr className="table-primary">
                                        
                                        <th scope="col" style={{width: "5%"}} className="text-center">#</th>
                                        <th scope="col" style={{width: "30%"}}>{lang[10]}</th>
                                        <th scope="col" style={{width: "25%"}}>{lang[5]}</th>
                                        <th scope="col" style={{width: "10%"}}>{lang[11]}</th>
                                        <th scope="col" style={{width: "10%"}}>{lang[9]}</th>
                                        <th scope="col" style={{width: "20%"}} className="text-center">{lang[12]}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {onePage}
                                </tbody>
                            </table>
                        </div>
                        <PagingBar 
                            totalPages={totalPages}                     
                            currentPage={this.state.currentPage}
                            pageChange={this.handlePageChange} 
                            />
                    </div>
                </div>            
            </div>
        );
    }
}

export default ProductStockIn;