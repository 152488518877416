import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Link} from 'react-router-dom';
import LANGUAGE from '../../../service/Language-data';

import EmbeddedEditor from '../../../service/components/EmbeddedEditor';
import UploadWallNoCrop from '../../../service/components/UploadWallNoCrop';
import { isP0_number } from '../../../service/validation';
import { VIDEO, AUDIO, IMAGE, DOCUMENT } from '../../../service/media-type';
import { getAllCurrencies, getAllLessonCategories, insertLesson } from '../../../service/axios-service';

class AddLesson extends Component{
    state={
            categories: [],
            currencies: [],
            title: '',
            category_id: 1,
            lecturer: '',
            price: 0,
            special: 0,
            type: 1,
            currency_id: 1,
            cover_image: '',
            uploadImage:'',
            images: [],
            pdfs: [],
            links: [{id: 0, value: ''}],
            short_desc: '',
            full_desc: '',
            lessons: [{ id: 0, title: '', time: '1900-01-01', url: '' }],
            oneLesson: []
    };
    
    static propTypes={
        langState: PropTypes.string.isRequired,
        logInName: PropTypes.string.isRequired
    }

    handleChange=(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handlePayment=(e)=>{
        if(+e.target.value === 2){
            this.setState({
                type: +e.target.value,
                price: 0,
                special: 0
            });
        }
        else{
            this.setState({ type: +e.target.value });
        }
    }

    handleUpload=(e)=>{
        this.setState({
            cover_image: e.target.value,
            uploadImage: e.target.files[0]
        });
    }

    handleURLimages=(images)=>{
        this.setState({ images });        
    }

    handleURLPdfs=(pdfs)=>{
        this.setState({ pdfs });        
    }

    handleLinkChange=(e, index)=>{
        let { links } = this.state;
        const value = e.target.value;
        if((value.length === 4 && value!=='http') || (value.length > 4 && !value.includes('http'))){
            alert(`There needs a link with the head like "http" or "https". Please try again.`);

        }
        links[index].value = e.target.value;
        this.setState({ links });
    }

    handleLinkAdd=()=>{
        let { links } = this.state;
        links.push({id: 0, value: ''});
        this.setState({ links });
    }

    handleLinkDelete=(index)=>{
        let { links } = this.state;
        if(links.length > 1){
            links.splice(index, 1);
            this.setState({ links });
        }
    }

    getEditorContent=(content)=>{
        this.setState({ full_desc: content });
    }

    handleLessonAdd=()=>{
        let { lessons } = this.state;
        lessons.push({ id: 0, title: '', time: '1900-01-01', url: '' });
        this.setState({ lessons });
    }

    handleLessonDelete=(index)=>{
        let { lessons } = this.state;
        if(lessons.length > 1){
            lessons.splice(index,1);
            this.setState({ lessons });
        }
    }

    handleLessonChange=(e, index)=>{
        let { lessons } = this.state;
        if(e.target.name === 'time' && e.target.value === ''){
            lessons[index][e.target.name]= '1900-01-01';
        }
        else{
            lessons[index][e.target.name]=e.target.value;
        }        
        this.setState({ lessons });
    }

    handleURLFiles=(urls, index)=>{
        let { lessons } = this.state;
        if(urls.length > 0){
            lessons[index].url=urls[0].value;
            this.setState({ lessons }); 
        }
        else{
            lessons[index].url='';
            this.setState({ lessons });
        }          
    }

    alertFile=(url)=>{
        if(!((url !== '' && !url.includes('http')) ||url === '')){
            alert(`Only one info is permmited. Now there is a saved link. If you want to upload a file. please delete the link you saved.`)
        }
    }

    handleURLTutoLink=(e, index)=>{
        let { lessons } = this.state;
        const value = e.target.value;
        if((value.length === 4 && value!=='http') || (value.length > 4 && !value.includes('http'))){
            alert(`There needs a link with the head like "http" or "https". Please try again.`);
        }
        lessons[index].url=e.target.value;
        this.setState({ lessons });       
    }

    alertTutoLink=(url)=>{
        if(!((url !== '' && url.includes('http')) || url === '')){
            alert(`Only one info is permmited. Now there is a saved file. If you want to upload a link. please delete the file you saved.`)
        }
    }

    handleSubmit=(e)=>{
        const { logInName } = this.props;
        const { title, category_id, lecturer, price, special, type, currency_id, cover_image, 
                uploadImage, images, pdfs, links, short_desc, full_desc, lessons } = this.state;
        if(!isP0_number.test(price)){
            alert(`Please enter a number or 0 in the "price" option.`);
        }
        else if(!isP0_number.test(special)){
            alert(`Please enter a number or 0 in the "special price" option.`);
        }
        else if(+special > +price){
            alert(`The special price should be less than the price. Please try again!`);
        }
        else if(full_desc === ''){
            alert(`There need description for your lesson! `);
        }
        else{
            let sections = 0;
            lessons.forEach(one=>{ if(one.url.length !== 0) sections++ }); // Have to use the same logic between FrontEnd and BackEnd, At server side, when one.url.length !== 0, the chapter in the lesson can be saved.
            
            const formData = {
                categories_id:      category_id,
                user:               logInName,
                title:              title,
                cover_image:        cover_image,
                image:              uploadImage,
                short_description:  short_desc,
                full_description:   full_desc,
                speaker:            lecturer,
                price:              price,
                special_price:      special,
                lessons_count:      sections,
                payment_type:       type,
                currencies_id:      currency_id,
                lessons:            JSON.stringify(lessons),
                images:             JSON.stringify(images),
                pdfs:               JSON.stringify(pdfs),
                links:              JSON.stringify(links)
            }

            insertLesson(formData)
                .then(res => {
                    alert("One lesson was added!");
                    this.setState({
                        title: '',
                        category_id: 1,
                        lecturer: '',
                        price: 0,
                        special: 0,
                        type: 1,
                        currency_id: 1,
                        cover_image: '',
                        uploadImage:'',
                        images: [],
                        pdfs: [],
                        links: [{id: 0, value: ''}],
                        short_desc: '',
                        full_desc: '',
                        lessons: [{ id: 0, title: '', time: '1900-01-01', url: '' }]
                    });
                })
                .catch(err=>{ 
                    console.log("[Error] - GET /lessons/add - at AddLesson component!");
                    console.log(err);
                });
        }        
        e.preventDefault();
    }

    handleClickReset=()=>{
        this.setState({
            title: '',
            category_id: 1,
            lecturer: '',
            price: 0,
            special: 0,
            type: 1,
            currency_id: 1,
            cover_image: '',
            uploadImage:'',
            images: [],
            pdfs: [],
            links: [{id: 0, value: ''}],
            short_desc: '',
            full_desc: '',
            lessons: [{ id: 0, title: '', time: '1900-01-01', url: '' }]
        });
    }

    componentDidMount(){
        axios.all([
            getAllLessonCategories(), 
            getAllCurrencies()
        ]).then(axios.spread((r1, r2)=>{
            this.setState({ 
                categories: r1.data,
                currencies: r2.data 
            });
        })).catch((err)=>{ 
            console.log("[Error] - At AddLesson Component! ************************");
            console.log(err);
        });
    }

    render(){
        let lang='';
        if(this.props.langState ==='cn') lang=LANGUAGE.AddLesson.cn;
        else lang=LANGUAGE.AddLesson.en;

        const { categories, currencies, title, category_id, lecturer, price, special, type, currency_id, cover_image, 
                images, pdfs, links, short_desc, lessons, oneLesson } = this.state;

        // For attachments uploading Links, not for lessons
        let link_rows=[];
        links.forEach((one, index)=>{
            link_rows.push(
                <div key={index} className="input-group mb-3">
                    <input type="url" className="form-control" value={one.value} onChange={(e)=>this.handleLinkChange(e, index)}/>
                    <div className="input-group-append">
                        <button className="btn btn-primary rounded" type="button" onClick={this.handleLinkAdd}><i className="fas fa-plus"></i></button>
                        <button className="btn btn-danger rounded" type="button" onClick={()=>{this.handleLinkDelete(index)}}><i className="fas fa-minus"></i></button>
                    </div>
                </div>
            );
        });
        
        // For free or paid
        let charge = '';
        if(type === 1){
            charge = (
                <div className="form-row">          
                    <div className="form-group col-md-6">
                        <label htmlFor="pric">{lang[15]}</label>
                        <input name="price" type="text" className="form-control" id="pric" value={price}  onChange={this.handleChange} required/>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="spec">{lang[16]}</label>
                        <input name="special" type="text" className="form-control" id="spec" value={special}  onChange={this.handleChange}/>
                    </div>
                </div>   
            )
        }
        // create lessons
        let rows=[];
        lessons.forEach((one, index)=>{
            rows.push(
                <tr key={index} style={{verticalAlign: 'middle'}}>
                    <th scope="row" style={{verticalAlign: 'middle'}}>
                        <input name="title" type="text" className="form-control" value={one.title} onChange={(e)=>this.handleLessonChange(e,index)}/>
                    </th>
                    <td style={{verticalAlign: 'middle'}}>
                        <input name="time" type="datetime-local" className="form-control" value={one.time.includes('1900-01-01')? '':one.time} onChange={(e)=>this.handleLessonChange(e,index)}/>
                    </td>
                    <td style={{verticalAlign: 'middle'}}>
                        <div className="btn-group">
                            <button type="button" className="btn btn-success btn-sm rounded mr-2" data-toggle="modal" data-target={(one.url !== '' && !one.url.includes('http')) ||one.url === ''? ("#file"+index) : ''} onClick={()=>this.alertFile(one.url)}><i className="far fa-image"></i></button>
                            <div className="modal fade" id={"file"+index} tabIndex="-1" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id={"file"+index}>{lang[24]}</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body text-left">
                                        <UploadWallNoCrop
                                            name={"uploadWall" + index}
                                            some={1}
                                            listType="text"
                                            load={ oneLesson }
                                            urls={(urls)=>this.handleURLFiles(urls, index)}
                                            format={ VIDEO.concat(AUDIO, IMAGE, DOCUMENT).toString() }
                                        />
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary rounded" data-dismiss="modal">{lang[25]}</button>
                                    </div>
                                    </div>
                                </div>
                            </div>                                
                            <button type="button" className="btn btn-success btn-sm rounded" data-toggle="modal" data-target={(one.url !== '' && one.url.includes('http')) ||one.url === '' ? ("#tutolinks"+index) : ''} onClick={()=>this.alertTutoLink(one.url)}><i className="fas fa-link"></i></button>
                            <div className="modal fade" id={"tutolinks"+index} tabIndex="-1" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id={"tutolinks"+index}>{lang[26]}</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <input type="url" className="form-control" onChange={(e)=>this.handleURLTutoLink(e, index)}/>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary rounded" data-dismiss="modal">{lang[27]}</button>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td style={{verticalAlign: 'middle'}}>
                        <div className="btn-group">
                            <button id="add" type="button" className="btn btn-primary btn-sm rounded mr-2" title={lang[28]} onClick={this.handleLessonAdd}><i className="fas fa-plus-square"></i></button>
                            <button type="button" className="btn btn-danger btn-sm rounded" onClick={()=>{this.handleLessonDelete(index);}} title={lang[29]}><i className="fas fa-trash-alt"></i></button>
                        </div>
                    </td>
                </tr>
            );                
        });

        return(
            <div className="container">
                <div className="card rounded">
                    <div className="card-header"> 
                        <h5>{lang[0]}</h5>
                    </div>
                    <div className="card-body">
                        <div className="text-right mb-2">
                            <Link to="/lessons" className="btn btn-primary btn-sm rounded" title={lang[1]}><i className="fas fa-arrow-alt-circle-right"></i></Link>
                        </div>
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-row">          
                                <div className="form-group col-md-4">
                                    <label htmlFor="title">{lang[2]}</label>
                                    <input name="title" type="text" className="form-control" id="title" value={title}  onChange={this.handleChange} required/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="cate">{lang[3]}</label>
                                    <select name="category_id" className="custom-select mr-sm-2" id="cate" value={category_id} onChange={this.handleChange}>
                                            {categories.map((one,index) => <option key={index} value={one.categories_id}>{one.name}</option>)}
                                    </select>
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="lect">{lang[4]}</label>
                                    <input name="lecturer" type="text" className="form-control" id="lect" value={lecturer} onChange={this.handleChange} required/>
                                </div>
                            </div>

                            <div className="form-row">          
                                <div className="form-group col-md-10">
                                    <label htmlFor="lesson_image_add">{lang[5]}</label>
                                    <input name="cover_image" type="file" className="form-control" id="lesson_image_add" accept={ IMAGE.toString() } value={cover_image}  onChange={this.handleUpload} required/>
                                </div>
                                <div className="form-group col-md-2">
                                    <div className="float-right">
                                        <label >{lang[6]}</label><br/>
                                        <div className="btn-group">
                                            <button type="button" className="btn btn-secondary rounded mr-1" data-toggle="modal" data-target="#images"><i className="far fa-image"></i></button>
                                            <div className="modal fade" id="images" tabIndex="-1" aria-hidden="true">
                                                <div className="modal-dialog" role="document">
                                                    <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="images">{lang[7]}</h5>
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <UploadWallNoCrop
                                                            some={8}
                                                            listType="picture-card"
                                                            load={ images }
                                                            urls={this.handleURLimages}
                                                            format={ IMAGE.toString() }
                                                        />
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-primary rounded" data-dismiss="modal">{lang[8]}</button>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="button" className="btn btn-secondary rounded mr-1" data-toggle="modal" data-target="#pdf"><i className="far fa-file-alt"></i></button>
                                            <div className="modal fade" id="pdf" tabIndex="-1" aria-hidden="true">
                                                <div className="modal-dialog" role="document">
                                                    <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="pdf">{lang[9]}</h5>
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <UploadWallNoCrop
                                                            some={8}
                                                            listType="text"
                                                            load={ pdfs } 
                                                            urls={this.handleURLPdfs}
                                                            format={ DOCUMENT.toString() }
                                                        />
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-primary rounded" data-dismiss="modal">{lang[10]}</button>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="button" className="btn btn-secondary rounded" data-toggle="modal" data-target="#links"><i className="fas fa-link"></i></button>
                                            <div className="modal fade" id="links" tabIndex="-1" aria-hidden="true">
                                                <div className="modal-dialog" role="document">
                                                    <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="links">{lang[11]}</h5>
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        { link_rows }
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-primary rounded" data-dismiss="modal">{lang[12]}</button>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="ty">{lang[13]}</label>
                                    <select name="type" className="custom-select mr-sm-2" id="ty" value={type} onChange={this.handlePayment} required>
                                            <option value={1}>{lang[32]}</option>
                                            <option value={2}>{lang[33]}</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="currency_id">{lang[14]}</label>
                                    <select name="currency_id" className="custom-select mr-sm-2" id="currency_id" value={currency_id} onChange={this.handleChange}>
                                            {currencies.map((one, index) => <option key={index} value={one.currencies_id}>{one.name}</option> )}
                                    </select>
                                </div>
                            </div>                             
                            
                            { charge }

                            <div className="form-group">
                                <label htmlFor="short">{lang[17]}</label>
                                <input name="short_desc" type="text" className="form-control" id="short" value={short_desc} onChange={this.handleChange}/>
                            </div>
                            <div className="form-group">
                                <label>{lang[18]}</label>                                
                                <EmbeddedEditor 
                                    content={this.state.full_desc}
                                    getContent={this.getEditorContent}/>
                            </div>
                            <div className="form-group row">
                                <h5 className="col-sm-2" >{lang[19]}</h5>
                            </div>
                            <div className="form-row">                        
                                <div className="form-group col-md-12">
                                    <div className="table-responsive rounded">
                                        <table className="table table-hover table-bordered text-center">
                                            <thead>
                                                <tr className="table-primary">
                                                    <th scope="col">{lang[20]}</th>
                                                    <th scope="col">{lang[21]}</th>
                                                    <th scope="col" >{lang[22]}</th>
                                                    <th scope="col">{lang[23]}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {rows}                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="btn-group">
                                <button type="submit" className="btn btn-success btn-sm mr-2 rounded" title={lang[30]}><i className="fas fa-save"></i></button>
                                <button type="reset" className="btn btn-danger btn-sm rounded" title={lang[31]} onClick={this.handleClickReset}><i className="fas fa-undo-alt"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );        
    }
}

export default AddLesson;