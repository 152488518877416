import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Upload, Icon, Modal } from 'antd';
import ImgCrop from "antd-img-crop";
import "antd/dist/antd.css";

import apiRoot from '../../config.api';

import { getFileList } from '../func-service';

class UploadWall34 extends Component {
  state = {
    previewVisible: false,
    previewImage: '',
    fileList: []
  };

  static ptopTypes = {
    some: PropTypes.number.isRequired,
    listType: PropTypes.string.isRequired,
    load: PropTypes.array.isRequired,
    urls: PropTypes.func.isRequired,
    format: PropTypes.string.isRequired    
  }

  handleCancel = () => this.setState({ previewVisible: false })

  handlePreview = (file) => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  }

  handleChange = ({ fileList }) => {
    const array = { fileList };
    let urls = [];
    array.fileList.forEach(one=>{
      if(Number.isInteger(one.uid)){
        urls.push({id: one.uid, value: one.url.split('=')[1]});
      }
      else if(one.response){
        urls.push({id: 0, value: one.response});
      }
    });
    this.props.urls(urls);    
    return this.setState({ fileList });
  }
  
  /*
  static getDerivedStateFromProps(props, state){
    console.log("Check props---------------------\n", props)
    if(props.load.length !== 0 && props.load.length === state.fileList.length && props.load[0].value !== state.fileList[0].value)
      return {fileList: getFileList(props.load)};
    else return null;
  }
  //*/
  
//*
  componentDidMount(){
    this.setState({
      fileList: getFileList(this.props.load) 
    })
  }
//*/
  componentDidUpdate(prevProps){
    const { load } = this.props;
    if(load.length !== prevProps.load.length){
        if(load.length === 0){
          this.setState({ fileList: [] });
        }
        else if(prevProps.load.length === 0){
          const fileList = getFileList(load);
          this.setState({ fileList });
        }
    }
}

  render() {
    const { previewVisible, previewImage, fileList } = this.state;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    let count=0;
    return (
      <div className="clearfix">
        <ImgCrop 
        width="750" 
        height="1000" >
        <Upload
          key={count++}
          action= {apiRoot + "uploadwall/add/file"}
          listType={ this.props.listType}
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          accept={this.props.format}
          onRemove={this.props.remove}
        >
          {fileList.length >= this.props.some ? null : uploadButton}
        </Upload>
        </ImgCrop>
        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
          <img alt="files" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </div>
    );
  }
}

export default UploadWall34;