import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Link} from 'react-router-dom';
import LANGUAGE from '../../../service/Language-data';
import apiRoot from '../../../config.api';


class AddCoupon extends Component{
    state={
        
        coupon_name: '',
        coupon_code:'',
        coupon_description:'',
        coupon_amount:0,
        coupon_minimum_order:0,
        start:'',
        end: '',
        
    }

    static propTypes={
        langState: PropTypes.string.isRequired
    };

    handleChange=(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    handleURLImages=(images)=>{
        this.setState({ images });        
    }

    handleClick=(e)=>{
        const { langState } = this.props;
        const { coupon_name, coupon_code,coupon_description, coupon_amount,start,end,coupon_minimum_order} = this.state;
        var user_id = localStorage.getItem("logInId");
                    axios.post(apiRoot + "coupon/add", { 
                        user_id, coupon_name, coupon_code,coupon_description, coupon_amount,start,end,coupon_minimum_order
                        })
                        .then(res => {
                            alert("An coupon was added!");
                            this.setState({
                                
                                coupon_name: '',
                                coupon_code:'',
                                coupon_description:'',
                                coupon_amount:0,
                                coupon_minimum_order:0,
                                start:'',
                                end: '',
                            });
                        })
                        .catch(err=>{
                            // console.log("[Error] - POST /products/attribute_groups/add - at AddAttributeGroup component!");
                            console.log(err);
                    });
                    e.preventDefault();


    }

    componentDidMount(){
        const { langState } = this.props;
        const language = langState==='cn'? '简体中文':'English';
        axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
        // axios.get(apiRoot + "languages")
        //     .then((res) => {          
        //         this.setState({ 
        //             langList: res.data,
        //             language
        //         });
        //     })
        //     .catch((err) => { 
        //         console.log("[Error] - GET /languages - at AddAttributeGroup component!");
        //         console.log(err);            
        // });
    }

    render(){
        const { langState } = this.props;
        let lang='';
        if(langState ==='cn') lang=LANGUAGE.CouponManagement.cn;
        else lang=LANGUAGE.CouponManagement.en;

        const {start,end} = this.state;
        return(
            <div className="container-fluid">
                <div className="card rounded">
                    <div className="card-header">
                        <span style={{fontSize: "1.4em"}}><i className="fas fa-pencil-alt"></i>&nbsp;{lang[0]}</span>
                        <div className="float-right">
                            <div className=" btn-toolbar " >
                                <div className="btn-group-sm mr-2" role="group" aria-label="First group">
                                    <button type="button" className="btn btn-success" onClick={this.handleClick} title={lang[8]}><i className="fas fa-save"></i></button>
                                </div>
                                <Link to='/coupon'>
                                    <div className="btn-group-sm mr-2" role="group" aria-label="Second group">
                                        <button type="button" className="btn btn-primary" title={lang[9]}><i className="fas fa-arrow-alt-circle-right"></i></button>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-body" style={{fontWeight: 'bold'}}>
                    <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="coupon_name" className="col-form-label">{lang[1]}</label>
                            </div>
                            <div className="col col-sm-4 input-group-sm">
                                <input name="coupon_name" type="text" className="form-control" id="coupon_name" value={this.state.coupon_name} onChange={this.handleChange} required/>
                            </div>
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="coupon_description" className="col-form-label">{lang[10]}</label>
                            </div>
                            <div className="col col-sm-4 input-group-sm">
                                <input name="coupon_description" type="text" className="form-control" id="coupon_description" value={this.state.coupon_description} onChange={this.handleChange} required/>
                            </div>                            
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="coupon_code" className="col-form-label">{lang[2]}</label>
                            </div>
                            <div className="col col-sm-4 input-group-sm">
                                <input name="coupon_code" type="text" className="form-control" id="coupon_code" value={this.state.coupon_code} onChange={this.handleChange} required/>
                            </div>
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="coupon_amount" className="col-form-label">{lang[11]}</label>
                            </div>
                            <div className="col col-sm-4 input-group-sm">
                                <input name="coupon_amount" type="text" className="form-control" id="coupon_amount" value={this.state.coupon_amount} onChange={this.handleChange} required/>
                            </div>                            
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="coupon_minimum_order" className="col-form-label">{lang[12]}</label>
                            </div>
                            <div className="col col-sm-4 input-group-sm">
                                <input name="coupon_minimum_order" type="text" className="form-control" id="coupon_minimum_order" value={this.state.coupon_minimum_order} onChange={this.handleChange} required/>
                            </div>
                            <div className="col col-sm-2 text-right input-group-sm">
                                
                            </div>
                            <div className="col col-sm-4 input-group-sm">
                                
                            </div>                            
                        </div>
                                                
                        <hr/>                                           
                        <div className="form-row">          
                                <div className="form-group col-md-2 text-right input-group-sm">
                                    <label htmlFor="start">{lang[3]}</label>
                                 </div>
                                <div className="col col-sm-4 input-group-sm">                                    
                                    <input name="start" type="date" className="form-control" id="start" value={this.state.start}  onChange={this.handleChange} title="Format:2018-08-18"/>
                                </div>
                                <div className="form-group col-md-2 text-right input-group-sm">
                                    <label htmlFor="end">{lang[4]}</label>
                                </div>
                                <div className="col col-sm-4 input-group-sm">                                    
                                    <input name="end" type="date" className="form-control" id="end" value={this.state.end} onChange={this.handleChange} title="Format:2018-08-18"/>
                                </div>
                            </div>

                        <hr/>

                    </div>
                </div>            
            </div>
        );
    }
}

export default AddCoupon;