import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import LANGUAGE from '../../../service/Language-data';

import EmbeddedEditor from '../../../service/components/EmbeddedEditor';
import UploadWallNoCrop from '../../../service/components/UploadWallNoCrop';
import { IMAGE } from '../../../service/media-type';
import { insertQuestionAnswer } from '../../../service/axios-service';

class AddAnswer extends Component{
    state={
        content:'',
        images: []
    }

    static propTypes={
        langState: PropTypes.string.isRequired,
        questionId: PropTypes.number.isRequired,
        total_answers: PropTypes.number.isRequired,
        logInName: PropTypes.string.isRequired
    }
    
    getEditorContent=(content)=>{
        this.setState({ content });
    }

    handleURLImages=(images)=>{
        this.setState({ images });        
    }
    
    handleClickSave=(e)=>{
        const { content, images } = this.state;
        const { questionId, logInName, total_answers } = this.props;

        if(content === '' && images.length === 0){
            alert(`You did Not change anything. Please try again, or return to answers list. `);
        }
        else{
            const data={
                questionId,
                username: logInName,
                total_answers: total_answers+1,
                images,
                content
            };
            insertQuestionAnswer(data)
                .then(res => {
                    alert("An answer was added!");
                    this.setState({
                        content:'',
                        images: []
                    });
                })
                .catch(err=>{ 
                    console.log("[Error] - At AddAnswer/handleClickSave/insertQuestionAnswer!");
                    console.log(err);
                });
        }
        e.preventDefault();
    }

    handleClickReset=()=>{
        this.setState({
            content:'',
            images: []
        });
    }
    
    render(){
        let lang='';
        if(this.props.langState ==='cn') lang=LANGUAGE.AddAnswer.cn;
        else lang=LANGUAGE.AddAnswer.en;

        const { content, images } = this.state
        
        return(
            <div className="container">
                <div className="card rounded">
                    <div className="card-header"> 
                        <h5>{lang[0]}</h5>
                    </div>
                    <div className="card-body">
                        <div className="mb-5">
                            <h6>{lang[3]}</h6>
                            <EmbeddedEditor 
                                content={ content }
                                getContent={this.getEditorContent}/>
                        </div>
                        <h6>{lang[2]}</h6>
                        <hr/>
                        <UploadWallNoCrop
                            some={8}
                            listType="picture-card"
                            load={ images }
                            urls={this.handleURLImages}
                            format={ IMAGE.toString() }
                        />
                        <hr/>                            
                        <div className="btn-group mt-2">
                            <button type="submit" className="btn btn-success btn-sm mr-2 rounded" title={lang[4]} onClick={this.handleClickSave}><i className="fas fa-save"></i></button>
                            <Link to="/questions/answers" className="btn btn-primary btn-sm rounded" title={lang[1]}><i className="fas fa-arrow-alt-circle-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        );        
    }
}

export default AddAnswer;