import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Link} from 'react-router-dom';

import LANGUAGE from '../../../service/Language-data';
import { propArray, getAttrArray } from '../../../service/func-service';
import { isP0_number, isP0_integer } from '../../../service/validation';
import MultiSelectTree from '../../../service/components/MultiSelectTree_antd';
import UploadWall34 from '../../../service/components/UploadWall34';
import UploadWallNoCrop from '../../../service/components/UploadWallNoCrop';
import { IMAGE } from '../../../service/media-type';
import { 
    getAllProductCategories,
    getAllProductAttributes,
    getProductsTaxeClasses,
    getAllManufacturers,
    getAllBrands,
    getAllCurrencies,
    getAllLanguages,
    getAllAvailableCountries,
    insertProduct,
    insertProductInPreviewMode,
    updateProductById,
    addShippingMethod,
    getShippingMethod,
    loadSupplier,
    loadWarehouse,
    deleteShippingMethod,
    updateShippingMethod
} from '../../../service/axios-service';
import EmbeddedEditor from '../../../service/components/EmbeddedEditor';



class AddProduct extends Component{
    constructor(props){
        super(props);
        this.state={
            model:'',
            price: 0,
            special_price:0,
            taxClass_id: 0,
            taxClasses: [],
            currency_id: 1,
            currencies: [],
            quantity: 0,
            weight: 0,
            manufacturer_id: 0,
            manufacturers: [],
            supplier_id: 1,
            suppliers:[],
            warehouse_id:1,
            warehouses:[],
            warehouse_threshhold:100,
            quantity_in:0,
            brand_id: 0,
            brands: [],
            date: '',
            status: 1,
            ordered: 0,
            images: [],
            featured_image:[],
            name: '',
            desc: '',
            url: '',
            lang_id: 1,
            languages: [],
            categories: [],
            category: [],
            attributeDetail: [],
            attributes: [],
            attribute: [],
            price_prefix: [],
            shiptocuntry_id:38,
            isFree:0,
            shipping_cost:0,
            additional_cost:0,
            estimate_days:7,
            countries:[],
            shipping_method_lists:[],
            shipping_method_ids:[], //选中的货运信息
            shippingMethodId:0,
            productId: 0,
            sort_order:100,
            price_cad: 0,
            special_price_cad:0,                   
            price_usd: 0,
            special_price_usd:0,            
        }
    }    

  


    static propTypes={
        langState: PropTypes.string.isRequired,
        logInName: PropTypes.string.isRequired
    };

    handleChange=(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    handleShippingMethodChecked=(e) =>{
        
        var cbCheckBox = document.getElementById(e.target.id);
        var ids = e.target.id.replace("Shipping_method_","") + ","+ e.target.value;
        console.log("check =" + e.target.id+ " value="+ e.target.value + " ids =" + ids);
        let {shipping_method_ids} = this.state;
        if(cbCheckBox.checked){
            console.log("checked");
            if(shipping_method_ids.includes(ids)){
                //if have,no need to add
            }else{
                shipping_method_ids.push(ids);
            }
            
        }else{
            if(shipping_method_ids.includes(ids)){
                //if have,no need to add
                for( var i = 0; i < shipping_method_ids.length; i++){ 
                    if ( shipping_method_ids[i] === ids) {
                      shipping_method_ids.splice(i, 1); 
                      i--;
                    }
                 }
            }else{
             
            }
        }
        this.setState({
            shipping_method_ids
        })
        

    }
    handleShipToCountryChange=(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    handleURLImages=(images)=>{
        this.setState({ images });        
    }
    handleURLFeaturedImages=(images) =>{
        this.setState({ featured_image: images });        
    }
    getCategoryValue=(value)=>{
        const category = value.filter(one=> +one>0)
        this.setState({ category });
    }

    getAttributeValue=(value)=>{
        // NOTE: only attributes can be include, parents-attribute group cannot.
        const attribute = value.filter(one=> +one>0)
        /*
        * Refresh "this.state.price_prefix". Add new element and initialize it, or delete the element which was just 
        * deleted from "this.state.attribute"
        */
        let { price_prefix, attributes } = this.state;
        const ids = propArray(price_prefix, 'id');
        attribute.forEach(one=>{
            if(!ids.includes(one)){
                const getOne=attributes.filter(a=>a.p !== 0).find(a=>a.id === one)
                let item = { id:getOne.id, p:getOne.p, ov:getOne.ov, value:0, pre:'+'};
                price_prefix.push(item);
            }
        })
        const refresh = price_prefix.filter(one=>attribute.includes(one.id));
        this.setState({ 
            attribute,
            price_prefix: refresh
         });
    }

    handlePrefix=(id, e)=>{
        const { price_prefix } = this.state;
        const ids = propArray(price_prefix, 'id');
        const index = ids.indexOf(id);
        price_prefix[index].pre = e.target.value;
        this.setState({ price_prefix })
    }

    handleVariation=(id, e)=>{
        const { price_prefix } = this.state;
        const ids = propArray(price_prefix, 'id');
        const index = ids.indexOf(id);
        price_prefix[index].value = e.target.value;
        this.setState({ price_prefix })
    }
    handleFreeShippingChange=(e)=>{
        var cbIsFree = document.getElementById("isFree");
        if(cbIsFree.checked===true){
            
            this.setState({ isFree:1 });
            
        }else{
            this.setState({ isFree:0 });
            
        }
    }
    handleUpdateShippingMethod=(e, id)=>{
        let {shipping_method_lists} = this.state;
        var ship = shipping_method_lists.find(function(element) {
            return element.id ===id;
        });
        if(ship){
            this.setState({
                shipping_method:ship.shipping_method_name,
                shipping_cost: ship.shipping_cost,
                additional_cost: ship.additional_cost,
                estimate_days: ship.estimate_days,
                shiptocuntry_id: ship.countries_id,
                shippingMethodId:id
            });
            // var hidden_electment =  document.getElementById("shippingMethodId");
            // hidden_electment.value= id;
        }
    }
    handleDeleteShippingMethod=(e, id)=>{
        
        if(window.confirm("请确实删除该记录?")){
            deleteShippingMethod(id)
            .then(res => {
                const user_id = localStorage.getItem("logInId");
                const user_data = {user_id};
                getShippingMethod( user_data).then(
                    res =>{
                         this.setState({
                             shipping_method_lists:res.data
                     });
     
                    }
                )
            });
        }
    }
    handleSaveShippingMethod=(e)=>{
        const {shiptocuntry_id,
            shipping_method,shippingMethodId,
            isFree,
            shipping_cost,
            currency_id,
            additional_cost,
            estimate_days } = this.state;
            const username = this.props.logInName;
            //if(isFree===undefined) isFree=0;
            const id = shippingMethodId;
            const data={ id, username,currency_id, shiptocuntry_id, shipping_method, isFree, shipping_cost, additional_cost, estimate_days }; 
   if(shippingMethodId===0){
            addShippingMethod(data)
            .then(res => {
                alert("A Shipping method was added!");

                const user_id = localStorage.getItem("logInId");
                const user_data = {user_id};
                getShippingMethod( user_data).then(
                 res =>{
                
                    this.setState({
                        shipping_method:'',
                        shipping_cost: 0,
                        additional_cost: 0,
                        estimate_days: 7,
                        shiptocuntry_id: 38,
                        shipping_method_lists:res.data
                });

               }
           )
           
           
            })
            .catch(err=>{ 
                alert("对不起，保存失败!");
                console.log("[Error] - At AddProduct/handleSaveShippingMethod/addShippingMethod!");
                console.log(err);
            });
        }else{
            //update shipping method
            updateShippingMethod(data)
            .then(res => {
                alert("A Shipping method was Update!");
                const user_id = localStorage.getItem("logInId");
                const user_data = {user_id};
                getShippingMethod( user_data).then(
                 res =>{
                
                    this.setState({
                        shipping_method:'',
                        shipping_cost: 0,
                        additional_cost: 0,
                        estimate_days: 7,
                        shiptocuntry_id: 38,
                        shipping_method_lists:res.data
                });

               }
           )
           
           
            })
            .catch(err=>{ 
                alert("更新失败!");
                console.log("[Error] - At AddProduct/handleSaveShippingMethod/updateShippingMethod!");
                console.log(err);
            });
        }

        e.preventDefault();

    }
    getEditorContent=(desc)=>{
        this.setState({ desc });
    }
    handleClickPreview=(e)=>{
        const { productId } = this.state;
        if(productId===0){
            //alert('请先点击b保存')
            //Do save preview mode
            const { model, price,special_price, taxClass_id, currency_id, quantity, weight, manufacturer_id, brand_id, status, 
                products_height,products_length,products_width,shipping_method_ids,barcode,publish_platform,is_distribution,commission_amount,
                     images,featured_image, name, desc, url, lang_id, category, price_prefix,supplier_id,warehouse_id,warehouse_threshhold,quantity_in,sort_order } = this.state;
            const username = this.props.logInName;
            if(!isP0_number.test(+price)){
                alert(`The price must be a positive number or 0. Try again, please.`);
            }
            else if(!isP0_integer.test(+quantity)){
                alert(`The quantity must be a positive integer or 0. Try again, please.`);
            }
            else if(!isP0_number.test(+weight)){
                alert(`The weight must be a positive number or 0. Try again, please.`);
            }
            else if(price_prefix === null || price_prefix === undefined || price_prefix.length === 0){
                alert(`请选择产品属性`);
           }
            else if(name === ''){
                alert(`The product name cannot be empty. Try again, please.`);
            }
            else{
                let { date } = this.state;
                const userId = sessionStorage.getItem('logInId');
                if(date === '') date = '1900-01-01';
                const data={ username, quantity, model, images, featured_image,price, special_price,date, weight, status, taxClass_id, manufacturer_id, brand_id, 
                    products_height,products_length,products_width,shipping_method_ids,barcode, publish_platform,is_distribution,commission_amount,currency_id, lang_id, name,
                     desc, url, category, price_prefix,supplier_id,warehouse_id,warehouse_threshhold,quantity_in,userId,sort_order }; 
                // if(is_distribution === undefined) is_distribution=0;
                // if(commission_amount === undefined) commission_amount=0;
    
                insertProductInPreviewMode(data)
                    .then(res => {
                        this.setState({productId:res.data});
                        console.log(res);
                        //open it in a new tab
                        window.open("https://shop.happiify.me/#/goodsDetails?productId=" + res.data,"_blank");
                    })
                    .catch(err=>{ 
                        alert("保存失败! ");
                        console.log("[Error] - At AddProduct/handleClickSave/insertProduct!");
                        console.log(err);
                });
                e.preventDefault();
            }
    
        }else{
            window.open("https://shop.happiify.me/#/goodsDetails?productId=" + productId,"_blank");
        }

    }
    handleClickSave=(e)=>{
        const { productId,model, price,special_price, taxClass_id, currency_id, quantity, weight, manufacturer_id, brand_id, status, 
            products_height,products_length,products_width,shipping_method_ids,barcode,publish_platform,is_distribution,commission_amount,
                 images, featured_image,name, desc, url, lang_id, category, price_prefix,supplier_id,warehouse_id,warehouse_threshhold,quantity_in,sort_order,
                 price_cad, special_price_cad, price_usd, special_price_usd
                } = this.state;
        const username = this.props.logInName;

        const userId = sessionStorage.getItem('logInId');

        if(!isP0_number.test(+price)){
            alert(`The price must be a positive number or 0. Try again, please.`);
        }
        else if(!isP0_integer.test(+quantity)){
            alert(`The quantity must be a positive integer or 0. Try again, please.`);
        }
        else if(!isP0_number.test(+weight)){
            alert(`The weight must be a positive number or 0. Try again, please.`);
        }
        else if(price_prefix === null || price_prefix === undefined || price_prefix.length === 0){
            alert(`请选择产品属性`);
       }
        else if(name === ''){
            alert(`The product name cannot be empty. Try again, please.`);
        }
        else{

        if(productId === 0){
            //用户没有预览，就直接点击保存
            let { date } = this.state;
            if(date === '') date = '1900-01-01';
            const data={ username, quantity, model, images,featured_image, price, special_price,date, weight, status, taxClass_id, manufacturer_id, brand_id, 
                products_height,products_length,products_width,shipping_method_ids,barcode, publish_platform,is_distribution,commission_amount,currency_id, lang_id, name,
                 desc, url, category, price_prefix,supplier_id,warehouse_id,warehouse_threshhold,quantity_in,userId,sort_order,
                 price_cad, special_price_cad, price_usd, special_price_usd      
                }; 
            // if(is_distribution === undefined) is_distribution=0;
            // if(commission_amount === undefined) commission_amount=0;

            insertProduct(data)
                .then(res => {
                    this.setState({productId:res.data});
                    console.log(res);
                    alert("A product was added!");
                    this.setState({
                        model:'',
                        price: 0,
                        special_price:0,
                        taxClass_id: -1,
                        currency_id: 1,
                        quantity: 0,
                        weight: 0,
                        manufacturer_id: -1,
                        brand_id: -1,
                        date: '',
                        status: 1,
                        ordered: 0,
                        images: [],
                        featured_image:[],
                        name: '',
                        desc: '',
                        url: '',
                        lang_id: 1,
                        category: [],
                        attribute: [],
                        price_cad:0, 
                        special_price_cad:0, 
                        price_usd:0, 
                        special_price_usd:0
                    });
                })
                .catch(err=>{ 
                    alert("保存失败! ");
                    console.log("[Error] - At AddProduct/handleClickSave/insertProduct!");
                    console.log(err);
            });
            e.preventDefault();

        }else{
            //用户点击了预览，产品已存在

            let { date, deleteImagesIds } = this.state;
            if(date==='') date='1900-01-01';
            // Handle images array to make two new arries, one for new images, another for images' ids that need deleting.
            const newImages = [];
            images.forEach(one=>{
                if(one.id === 0){
                    newImages.push(one);
                }
                else if(deleteImagesIds.includes(one.id)){
                        deleteImagesIds=deleteImagesIds.filter(id=>id!==one.id);
                }
            });
            
            let cover_image = '';
            const cover1 = images.find((one=> one.id !== 0));
            const cover2 = newImages.find((one=> one.id === 0));
            if(cover1) {cover_image = cover1.value;}
            else if(cover2) {cover_image = cover2.value;}

            const data={ username,special_price, quantity, model, cover_image, newImages, deleteImagesIds, featured_image,price, date, weight, status, taxClass_id, manufacturer_id, brand_id, 
                products_height,products_length,products_width,shipping_method_ids,barcode, publish_platform,is_distribution,commission_amount,currency_id, lang_id, name, desc, url, category, price_prefix
                ,supplier_id,warehouse_id,warehouse_threshhold,quantity_in,userId,sort_order,price_cad, special_price_cad, price_usd, special_price_usd
            };
            updateProductById(productId, data)
                .then(res => {
                    alert("A product was updated!");
                    this.setState({
                        model:'',
                        price: 0,
                        special_price:0,
                        taxClass_id: -1,
                        currency_id: 1,
                        quantity: 0,
                        weight: 0,
                        manufacturer_id: -1,
                        brand_id: -1,
                        date: '',
                        status: 1,
                        ordered: 0,
                        images: [],
                        deleteImageIds: [],
                        featured_image:[],
                        name: '',
                        desc: '',
                        url: '',
                        lang_id: 1,
                        category: [],
                        attribute: [],
                        price_prefix: [],
                        price_cad:0, 
                        special_price_cad:0, 
                        price_usd:0, 
                        special_price_usd:0
                    });
                })
                .catch(err=>{ 
                    console.log("[Error] - At AddProduct/handleClickSave/updateProductById!");
                    console.log(err);
            });
            e.preventDefault();
        }


        }
    }


    componentDidMount(){
        const user_id = localStorage.getItem("logInId");
        const user_data = {user_id};

        axios.all([
            getAllLanguages(),
            getProductsTaxeClasses(),
            getAllCurrencies(),
            getAllManufacturers(),
            getAllBrands(),
            getAllProductCategories(),
            getAllProductAttributes(),
            getAllAvailableCountries(),
            loadSupplier(),
            loadWarehouse(),
            getShippingMethod( user_data)
        ]).then(axios.spread((r1,r2,r3,r4,r5,r6,r7,r8,r9,r10,r11)=>{
            this.setState({ 
                languages: r1.data,
                taxClasses: r2.data,
                currencies: r3.data,
                manufacturers: r4.data,
                brands: r5.data,
                categories: r6.data,
                attributeDetail: r7.data,
                attributes: getAttrArray(r7.data),
                countries:r8.data,
                suppliers:r9.data,
                warehouses:r10.data,
                shipping_method_lists:r11.data
            });
        })).catch((err) => { 
            console.log("[Error] - At AddProduct/componentDidMount!");
            console.log(err);         
        });
    }


    render(){
        let lang='';
        if(this.props.langState ==='cn') lang=LANGUAGE.AddProduct.cn;
        else lang=LANGUAGE.AddProduct.en;

        const { model, price,special_price, taxClass_id, taxClasses, currency_id, currencies, quantity, weight, products_height,products_width,products_length,
                manufacturer_id, manufacturers, brand_id, brands, date, status, barcode, publish_platform,is_distribution,commission_amount,images,  featured_image,
                languages, categories, category, attributeDetail, attributes, attribute, countries,shiptocuntry_id,shipping_method_ids,
                shipping_method,isFree,shipping_cost,
                additional_cost,supplier_id,suppliers,warehouse_id,warehouse_threshhold,warehouses,quantity_in,
                estimate_days,shippingMethodId,sort_order,price_cad, special_price_cad, price_usd, special_price_usd,
                name, desc, url, lang_id } = this.state;

        // Handle attributes display & modification
        // is_distribution =0;
        // commission_amount =0;
        let attribute_table='';
        let rows=[];
        if(attribute.length !== 0){
            attribute.forEach((one, index)=>{
                const item=attributeDetail.find(o=>o.products_options_values_to_products_options_id === +one);
                rows.push(
                    <tr key={index} style={{verticalAlign: 'middle'}}>
                        <th scope="row" style={{verticalAlign: 'middle'}}>{one}</th>
                        <td style={{verticalAlign: 'middle'}}>{item.products_options_values_name}</td>
                        <td style={{verticalAlign: 'middle'}} hidden>
                            <select className="form-control custom-select" style={{width: '60px'}} onChange={(e)=>this.handlePrefix(one, e)}>
                                <option key="1" value="+" >+</option> 
                                <option key="2" value="-">-</option>
                            </select>
                        </td>
                        <td style={{verticalAlign: 'middle'}} hidden className="text-center">
                            <input name="variation" type="number" value="0" readOnly min="0" max={price} className="form-control" onChange={(e)=>this.handleVariation(one, e)}/>
                        </td>
                    </tr>
                )
            });
            attribute_table=(
                <div className="row mt-2">
                    <div className="col col-sm-2 text-right input-group-sm">
                        <label htmlFor="prefix" className="col-form-label">{lang[26]}</label>
                    </div>
                    <div className="col col-sm-10 input-group-sm" id="prefix">
                        <div className="table-responsive rounded">
                            <table className="table table-hover table-bordered text-center rounded">
                                <thead>
                                    <tr>
                                        <th scope="col">Id</th>
                                        <th scope="col">Atrribute Name</th>
                                        <th scope="col" hidden>+/-</th>
                                        <th scope="col" hidden>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rows}                                
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )
        }

        let shipping_method_rows = this.state.shipping_method_lists.map(ship => {
            let cb_id = "Shipping_method_" + ship.id;
            return  (
                <tr  key={ship.id}>
                  <td>
                      <input type="checkbox" onChange={ this.handleShippingMethodChecked } id={ cb_id } name="shipping_ids" value={ ship.countries_id }  />
                  </td>
                  <td>
                    { ship.countries_name }
                  </td>
                  <td>
                    { ship.shipping_method_name }
                  </td>
                  <td>
                    { ship.is_free===0 ? "否":"是" }
                  </td>
                  <td>
                    { ship.shipping_cost }
                  </td>
                  <td>
                    { ship.additional_cost }
                  </td>
                  <td>
                    { ship.estimate_days }
                  </td>          
                  
                  <td >
                      <div class="btn-group">
                      <button 
                      onClick={(e)=>this.handleUpdateShippingMethod(e,ship.id)} 
                      class="btn btn-success mr-2 btn-sm rounded" title="更新"><i class="fas fa-pencil-alt"></i></button>
                      <button type="button" 
                      onClick={(e)=>this.handleDeleteShippingMethod(e,ship.id)} 
                      class="btn btn-danger btn-sm rounded" title="删除"><i class="fas fa-trash-alt"></i></button>
                      </div></td>            
                  
                </tr>
            )
            
          })
              
        return(
            <div className="container-fluid">
                <div className="card rounded">
                    <div className="card-header">
                        <span style={{fontSize: "1.4em"}}><i className="fas fa-pencil-alt"></i>&nbsp;{lang[0]}</span>
                        <div className="float-right">
                            <div className=" btn-toolbar " >
                                <div className="btn-group-sm mr-2" role="group" aria-label="First group">
                                    <button type="button" className="btn btn-success" onClick={this.handleClickSave}><i className="fas fa-save" title={lang[1]}></i></button>
                                </div>
                                <div className="btn-group-sm mr-2" role="group" aria-label="First group">
                                    <button type="button" className="btn btn-success" onClick={this.handleClickPreview}>{lang[45]}</button>
                                </div>

                                <Link to='/products'>
                                    <div className="btn-group-sm mr-2" role="group" aria-label="Second group">
                                        <button type="button" className="btn btn-primary"><i className="fas fa-arrow-alt-circle-right" title={lang[2]}></i></button>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
<div className="small-note-text">
* 请按顺序输入产品信息，上传图片，选择产品属性（如果需要），选择相应的物流信息，然后点击右上角的"保存"按钮 <br/>
* <strong>保存产品前后，建议点击右上角的"预览"按钮来预览，确认没有问题了，然后再击右上角的"保存"按钮</strong><br/>
<span className="mandatory-input">*</span>为必填，请耐心完成填写并保证产品信息的正确性!
<br/>
<br/>
</div>
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item">
    <a class="nav-link active" id="product-info-tab" data-toggle="tab" href="#product-info" role="tab" aria-controls="home"
      aria-selected="true">{lang[4]}</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" id="images-tab" data-toggle="tab" href="#images" role="tab" aria-controls="images"
      aria-selected="false">{lang[27]}</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" id="attribute-tab" data-toggle="tab" href="#attribute" role="tab" aria-controls="attribute"
      aria-selected="false">{lang[22]}</a>
  </li>

  <li class="nav-item">
    <a class="nav-link" id="shipping-tab" data-toggle="tab" href="#shipping" role="tab" aria-controls="shipping"
      aria-selected="false">{lang[30]}</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" id="distribution-tab" data-toggle="tab" href="#distribution" role="tab" aria-controls="distribution"
      aria-selected="false">{lang[42]}</a>
  </li>

  <li class="nav-item">
    <a class="nav-link" id="inventory-tab" data-toggle="tab" href="#inventory" role="tab" aria-controls="inventory"
      aria-selected="false">{lang[47]}</a>
  </li>

</ul>
<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="product-info" role="tabpanel" aria-labelledby="product-info-tab">

  <div className="form-row">
                            {/*Product Info **********************************************************************/}
                            {
                                 <div className="form-group col-md-6">
                                <div className="card rounded mb-2">
                                    <div className="card-header"> 
                                        <span>{lang[4]}</span>
                                    </div>
                                    <div className="card-body">
                                        <div className="row mb-2">
                                            <div className="col col-sm-2 text-right input-group-sm">
                                             <span className="mandatory-input">*</span>   <label htmlFor="model" className="col-form-label">{lang[5]}</label>
                                            </div>
                                            <div className="col col-sm-10 input-group-sm">
                                                <input name="model" type="text" className="form-control" id="model" value={model} onChange={this.handleChange}/>
                                            </div>
                                        </div>

                                        <div className="row mb-2">
                                            <div className="col col-sm-2 text-right input-group-sm">
                                            <span className="mandatory-input">*</span><label htmlFor="currency" className="col-form-label">人民币 </label>
                                            </div>
                                            <div className="col col-sm-10 input-group-sm">
                                                
                                            
                                            <div className="col col-sm-2 text-right input-group-sm">
                                            <span className="mandatory-input">*</span><label htmlFor="price" className="col-form-label">{lang[7]}</label>
                                            </div>
                                            <div className="col col-sm-4 input-group-sm">
                                                <input name="price" type="number" className="form-control" id="price" value={price} onChange={this.handleChange}/>
                                            </div>

                                            <div className="col col-sm-2 text-right input-group-sm">
                                                <label htmlFor="special_price" className="col-form-label">{lang[40]}</label>
                                            </div>
                                            <div className="col col-sm-4 input-group-sm">
                                                <input name="special_price" type="number" className="form-control" id="special_price" value={special_price} onChange={this.handleChange}/>
                                            </div>
                                        

                                            </div>          
                                        </div>                               

                                        <div className="row mb-2">
                                            <div className="col col-sm-2 text-right input-group-sm">
                                            <span className="mandatory-input">*</span><label htmlFor="currency" className="col-form-label">加币 </label>
                                            </div>
                                             <div className="col col-sm-10 input-group-sm">

                                             
                                            <div className="col col-sm-2 text-right input-group-sm">
                                            <span className="mandatory-input">*</span><label htmlFor="price_cad" className="col-form-label">{lang[7]}</label>
                                            </div>
                                            <div className="col col-sm-4 input-group-sm">
                                                <input name="price_cad" type="number" className="form-control" id="price_cad" value={price_cad} onChange={this.handleChange}/>
                                            </div>
                                        
                                        
                                            <div className="col col-sm-2 text-right input-group-sm">
                                                <label htmlFor="special_price_cad" className="col-form-label">{lang[40]}</label>
                                            </div>
                                            <div className="col col-sm-4 input-group-sm">
                                                <input name="special_price_cad" type="number" className="form-control" id="special_price_cad" value={special_price_cad} onChange={this.handleChange}/>
                                            </div>
                                        


                                            </div>         
                                        </div>                               


                                        <div className="row mb-2">
                                            <div className="col col-sm-2 text-right input-group-sm">
                                            <label htmlFor="currency" className="col-form-label">美元 </label>
                                            </div>
                                             <div className="col col-sm-10 input-group-sm">
                                             
                                            <div className="col col-sm-2 text-right input-group-sm">
                                            <label htmlFor="price_usd" className="col-form-label">{lang[7]}</label>
                                            </div>
                                            <div className="col col-sm-4 input-group-sm">
                                                <input name="price_usd" type="number" className="form-control" id="price_usd" value={price_usd} onChange={this.handleChange}/>
                                            </div>
                                            <div className="col col-sm-2 text-right input-group-sm">
                                                <label htmlFor="special_price_usd" className="col-form-label">{lang[40]}</label>
                                            </div>
                                            <div className="col col-sm-4 input-group-sm">
                                                <input name="special_price_usd" type="number" className="form-control" id="special_price_usd" value={special_price_usd} onChange={this.handleChange}/>
                                            </div>

                                            </div>          
                                        </div>                                               



                                        <div className="row mb-2">
                                            <div className="col col-sm-2 text-right input-group-sm">
                                            <span className="mandatory-input">*</span><label htmlFor="taxClass_id" className="col-form-label">{lang[8]}</label>
                                            </div>
                                            <div className="col col-sm-10 input-group-sm">
                                                <select name="taxClass_id" className="form-control custom-select" id="taxClass_id" value={taxClass_id} onChange={this.handleChange}>
                                                    {<option key={-1} value={0}>-- Please choose an option --</option> }
                                                    {taxClasses.map((one, index) => <option key={index} value={one.tax_class_id}>{one.tax_class_title}</option>)}
                                                </select>
                                            </div>
                                        </div>

                                        
                                        {/* <div className="row mb-2">
                                            <div className="col col-sm-2 text-right input-group-sm">
                                                <label htmlFor="quantity" className="col-form-label">{lang[9]}</label>
                                            </div>
                                            <div className="col col-sm-10 input-group-sm">
                                                <input name="quantity" type="text" className="form-control" id="quantity" value={quantity} onChange={this.handleChange}/>
                                            </div>
                                        </div> */}

                                        <div className="row mb-2">
                                            <div className="col col-sm-2 text-right input-group-sm">
                                            <span className="mandatory-input">*</span><label htmlFor="weight" className="col-form-label">{lang[10]}</label>
                                            </div>
                                            <div className="col col-sm-10 input-group-sm">
                                                <input name="weight" type="text" className="form-control" id="weight" value={weight} onChange={this.handleChange}/>
                                            </div>
                                        </div>

                                        <div className="row mb-2">
                                            <div className="col col-sm-2 text-right input-group-sm">
                                            <span className="mandatory-input">*</span> <label htmlFor="products_length" className="col-form-label">{lang[37]}</label>
                                            </div>
                                            <div className="col col-sm-10 input-group-sm">
                                                <input name="products_length" type="text" className="form-control" id="products_length" value={products_length} onChange={this.handleChange}/>
                                            </div>
                                        </div>

                                        <div className="row mb-2">
                                            <div className="col col-sm-2 text-right input-group-sm">
                                            <span className="mandatory-input">*</span> <label htmlFor="products_width" className="col-form-label">{lang[38]}</label>
                                            </div>
                                            <div className="col col-sm-10 input-group-sm">
                                                <input name="products_width" type="text" className="form-control" id="products_width" value={products_width} onChange={this.handleChange}/>
                                            </div>
                                        </div>

                                        <div className="row mb-2">
                                            <div className="col col-sm-2 text-right input-group-sm">
                                            <span className="mandatory-input">*</span> <label htmlFor="products_height" className="col-form-label">{lang[39]}</label>
                                            </div>
                                            <div className="col col-sm-10 input-group-sm">
                                                <input name="products_height" type="text" className="form-control" id="products_height" value={products_height} onChange={this.handleChange}/>
                                            </div>
                                        </div>

                                        <div className="row mb-2">
                                            <div className="col col-sm-2 text-right input-group-sm">
                                                <label htmlFor="supplier_id" className="col-form-label">{lang[46]}</label>
                                            </div>
                                            <div className="col col-sm-10 input-group-sm">
                                                <select name="supplier_id" className="form-control custom-select" id="supplier_id" value={supplier_id} onChange={this.handleChange}>
                                                        {<option key={-1} value={0}>-- Please choose an option --</option> }
                                                        {suppliers.map((one, index) => <option key={index} value={one.id}>{one.supplier_name}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col col-sm-2 text-right input-group-sm">
                                                <label htmlFor="manufacturer_id" className="col-form-label">{lang[11]}</label>
                                            </div>
                                            <div className="col col-sm-10 input-group-sm">
                                                <select name="manufacturer_id" className="form-control custom-select" id="manufacturer_id" value={manufacturer_id} onChange={this.handleChange}>
                                                        {<option key={-1} value={0}>-- Please choose an option --</option> }
                                                        {manufacturers.map((one, index) => <option key={index} value={one.manufacturers_id}>{one.manufacturers_name}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col col-sm-2 text-right input-group-sm">
                                                <label htmlFor="brand_id" className="col-form-label">{lang[25]}</label>
                                            </div>
                                            <div className="col col-sm-10 input-group-sm">
                                                <select name="brand_id" className="form-control custom-select" id="brand_id" value={brand_id} onChange={this.handleChange}>
                                                    {<option key={-1} value={0}>-- Please choose an option --</option> }
                                                    {brands.map((one, index) => <option key={index} value={one.id}>{one.brands_name}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col col-sm-2 text-right input-group-sm">
                                                <label htmlFor="date" className="col-form-label">{lang[12]}</label>
                                            </div>
                                            <div className="col col-sm-10 input-group-sm">
                                                <input name="date" type="date" className="form-control" id="date" value={date} onChange={this.handleChange}/>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col col-sm-2 text-right input-group-sm">
                                                <label htmlFor="status" className="col-form-label">{lang[13]}</label>
                                            </div>
                                            <div className="col col-sm-10 input-group-sm">
                                                <select name="status" className="form-control custom-select" id="status" value={status} onChange={this.handleChange}>
                                                    <option key="1" value="1" >Enable</option> 
                                                    <option key="2" value="2">Disable</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col col-sm-2 text-right input-group-sm">
                                            <span className="mandatory-input">*</span><label htmlFor="barcode" className="col-form-label">{lang[14]}</label>
                                            </div>
                                            <div className="col col-sm-10 input-group-sm">
                                                <input name="barcode" type="text" className="form-control" id="barcode" value={barcode} onChange={this.handleChange}/>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col col-sm-2 text-right input-group-sm">
                                            <label htmlFor="publish_platform" className="col-form-label">{lang[41]}</label>
                                            </div>
                                            <div className="col col-sm-10 input-group-sm">
                                               <select name="publish_platform" className="form-control custom-select" id="publish_platform" value={publish_platform} onChange={this.handleChange}>
                                                    <option key="1" value="1" >App</option> 
                                                    <option key="2" value="2">网站</option>
                                                    <option key="3" value="3" selected>网站和App</option>
                                                </select>                                                
                                            </div>
                                        </div>    

                                        <div className="row mb-2">
                                            <div className="col col-sm-2 text-right input-group-sm">
                                            <label htmlFor="sort_order" className="col-form-label">Sort Order</label>
                                            </div>
                                            <div className="col col-sm-10 input-group-sm">
                                                <input name="sort_order" type="text" className="form-control" id="sort_order" value={sort_order} onChange={this.handleChange}/>
                                            </div>
                                        </div>                                                                            
                                    </div>
                                </div>
                                {/*Image upload **********************************************************************/}
                            </div> }
                            {/*Product description **********************************************************************/}
                            <div className="form-group col-md-6">
                                <div className="card rounded">
                                    <div className="card-header"> 
                                        <span>{lang[15]}</span>
                                    </div>
                                    <div className="card-body">
                                        <div className="row mb-2">
                                                <div className="col col-sm-2 text-right input-group-sm">
                                                <span className="mandatory-input">*</span> <label htmlFor="lang" className="col-form-label">{lang[19]}</label>
                                                </div>
                                                <div className="col col-sm-10 input-group-sm">
                                                    <select name="lang_id" className="form-control custom-select" id="lang" value={lang_id} onChange={this.handleChange}>
                                                        {languages.map((one, index) => <option key={index} value={one.id}>{one.name}</option> )}
                                                    </select>
                                                </div>
                                            </div>

                                        <div className="row mb-2">
                                            <div className="col col-sm-2 text-right input-group-sm">
                                            <span className="mandatory-input">*</span><label htmlFor="name" className="col-form-label">{lang[16]}</label>
                                            </div>
                                            <div className="col col-sm-10 input-group-sm">
                                                <input name="name" type="text" className="form-control" id="name" value={name} onChange={this.handleChange}/>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col col-sm-2 text-right input-group-sm">
                                            <span className="mandatory-input">*</span><label htmlFor="desc" className="col-form-label">{lang[17]}</label>
                                            </div>
                                            <div className="col col-sm-10">

                                                {/* <textarea name="desc" rows="5" className="form-control" id="desc"  value={desc} onChange={this.handleChange}/> */}
                                                <EmbeddedEditor 
                                                    content={desc}
                                                    getContent={this.getEditorContent}/>

                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col col-sm-2 text-right input-group-sm">
                                                <label htmlFor="url" className="col-form-label">{lang[18]}</label>
                                            </div>
                                            <div className="col col-sm-10 input-group-sm">
                                                <input name="url" type="text" className="form-control" id="url" value={url} onChange={this.handleChange}/>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                {/*Product Category **********************************************************************/}
                                <div className="card rounded mt-3">
                                    <div className="card-header"> 
                                        <span>{lang[20]}</span>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col col-sm-2 text-right input-group-sm">
                                            <span className="mandatory-input">*</span><label htmlFor="category" className="col-form-label">{lang[21]}</label>
                                            </div>
                                            <div className="col col-sm-10 input-group-sm" id="category">
                                                <MultiSelectTree
                                                    init={ category }
                                                    data={categories}
                                                    property1={'categories_id'}
                                                    property2={'parent_id'}
                                                    property3={'name'}
                                                    getValue={this.getCategoryValue}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*Product Attribute **********************************************************************/}
                               



                            </div>    
                        </div>   



  </div>
  <div class="tab-pane fade" id="images" role="tabpanel" aria-labelledby="images-tab">
        <div className="form-group">
                                {/*Image upload **********************************************************************/}
                                <div className="card rounded">
                                    <div className="card-header"> 
                                    <span className="mandatory-input">*</span> <span>{lang[27]}</span>
                                    </div>
                                    <div className="card-body">
                                        <div className="row mb-3">
                                            <div className="col col-sm-2 text-left input-group-sm">
                                                <label htmlFor="images_add" >{lang[28]}</label>
                                            </div>
                                            <div className="col col-sm-10 input-group-sm">
                                                <UploadWall34
                                                    some={8}
                                                    listType="picture-card"
                                                    load={ images }
                                                    urls={this.handleURLImages}
                                                    format={ IMAGE.toString() }
                                                />



                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col col-sm-2 text-left input-group-sm">
                                                <label htmlFor="images_add" >Featured Image</label>
                                                <p className="small-note-text">Note:这个图是在shop.happiify.me网站的最新产品页面中使用</p>
                                            </div>
                                            <div className="col col-sm-10 input-group-sm">
                                                <UploadWallNoCrop
                                                    some={1}
                                                    listType="picture-card"
                                                    load={ featured_image }
                                                    urls={this.handleURLFeaturedImages}
                                                    format={ IMAGE.toString() }
                                                />



                                            </div>
                                        </div>
                                        

                                    </div>
                                </div>
        </div>
 </div>
  <div class="tab-pane fade" id="attribute" role="tabpanel" aria-labelledby="attribute-tab">
  <div className="card rounded mt-3">
                                    <div className="card-header"> 
                                        <span>{lang[22]}</span>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col col-sm-2 text-right input-group-sm">
                                                <label htmlFor="attribute" className="col-form-label">{lang[23]}</label>
                                            </div>
                                            <div className="col col-sm-10 input-group-sm" id="attribute">
                                                <MultiSelectTree
                                                    init={ attribute } 
                                                    data={attributes}
                                                    property1={'id'}
                                                    property2={'p'}
                                                    property3={'title'}
                                                    property4={'key'}
                                                    getValue={this.getAttributeValue}
                                                />
                                            </div>
                                        </div>
                                        { attribute_table }
                                    </div>
                                </div>
    
    </div>

    <div class="tab-pane fade" id="shipping" role="tabpanel" aria-labelledby="shipping-tab">
    
{
    /* 物流 */
}
                                <div className="card rounded mt-3" >
                                    <div className="card-header"> 
                                    <span className="mandatory-input">*</span><span>{lang[30]}</span>
                                    </div>

<div className="small-note-text">
<br/><br/>
 &nbsp;&nbsp;&nbsp; *提示:请将物流信息分组，然后在下面的界面将信息录入，点击"保存物流信息"，以后不同的产品在相应的物流信息前面打勾就可以了
 <br/>
</div>                                    
<div id="divProductShppingTable" className="row-panel" >
    <table width="100%" cellPadding="10" cellSpacing="10" id="shippingInfoTable">
    <thead>
        <tr>
            <th></th>
            <th>{lang[31]}</th>
            <th>{lang[32]}</th>
            <th>{lang[33]}</th>
            <th>{lang[34]}</th>
            <th>{lang[36]}</th>
            <th>{lang[35]}</th>
            <th></th>
        </tr>
    </thead>        
<tbody>
{ shipping_method_rows }
</tbody>        

        

    </table>
</div>

<div id="divProductShppingInfo" className="row-panel">


<div className="row mb-2">
    <div className="col col-sm-2 text-right input-group-sm">
        <label htmlFor="shiptocuntry" className="col-form-label">{lang[31]}</label>
    </div>
    <div className="col col-sm-10 input-group-sm">
        <select name="shiptocuntry_id" className="form-control custom-select" id="shiptocuntry" value={shiptocuntry_id} 
        onChange={this.handleShipToCountryChange}>
            {countries.map((one, index) => <option key={index} value={one.id}>{one.value}</option> )}
        </select>
    </div>
</div>

<div className="row mb-2">
    <div className="col col-sm-2 text-right input-group-sm">
        <label htmlFor="shipping_method" className="col-form-label">{lang[32]}</label>
    </div>
    <div className="col col-sm-10 input-group-sm">
        <input name="shipping_method" type="text" className="form-control" id="shipping_method" value={shipping_method}  onChange={this.handleChange} />
    </div>
</div>

<div className="row mb-2">
    <div className="col col-sm-2 text-right input-group-sm">
        <label htmlFor="isFree" className="col-form-label">{lang[33]}</label>
    </div>
    <div className="col col-sm-10 input-group-sm">
        <input name="isFree" type="checkbox" className="form_checkbox" id="isFree" value={isFree}  onChange={this.handleFreeShippingChange} />
    </div>
</div>


<div className="row mb-2">
    <div className="col col-sm-2 text-right input-group-sm">
        <label htmlFor="shipping_cost" className="col-form-label">{lang[34]}</label>
    </div>
    <div className="col col-sm-10 input-group-sm">
        <input name="shipping_cost" type="text" className="form-control" id="shipping_cost" value={shipping_cost} onChange={this.handleChange} />
    </div>
</div>


<div className="row mb-2">
    <div className="col col-sm-2 text-right input-group-sm">
        <label htmlFor="additional_cost" className="col-form-label">{lang[36]}</label>
    </div>
    <div className="col col-sm-10 input-group-sm">
        <input name="additional_cost" type="text" className="form-control" id="additional_cost" value={additional_cost}  onChange={this.handleChange}/>
    </div>
</div>

<div className="row mb-2">
    <div className="col col-sm-2 text-right input-group-sm">
        <label htmlFor="estimate_days" className="col-form-label">{lang[35]}</label>
    </div>
    <div className="col col-sm-10 input-group-sm">
        <input name="estimate_days" type="text" className="form-control" id="estimate_days" value={estimate_days}  onChange={this.handleChange} /> 
    </div>
</div>



<div class="btn-group">
<input type="hidden" id="shippingMethodId" value={shippingMethodId} />
<button onClick={this.handleSaveShippingMethod} className="btn btn-success btn-sm mr-2 rounded" title="保存">保存物流信息 &nbsp; <i class="fas fa-save"></i></button>
</div>

</div>
                                </div>
{
    /* 物流 EOF */
}    
    </div>

    <div class="tab-pane fade" id="distribution" role="tabpanel" aria-labelledby="distribution-tab">

    <div className="card-header"> 
                                    <span className="mandatory-input"></span><span>{lang[42]}</span>
                                    </div>

<div className="small-note-text">
<br/><br/>
 &nbsp;&nbsp;&nbsp; *提示:只有当这个产品用于分销，才需要设置。分销利润金额是指给分销商的提成。这个金额需要仔细设定!
 <br/>
 <br/> <br/>
</div>  

    <div className="row mb-2">
                                            <div className="col col-sm-2 text-right input-group-sm">
                                                <label htmlFor="is_distribution" className="col-form-label">{lang[43]}</label>
                                            </div>
                                            <div className="col col-sm-10 input-group-sm">
                                                <select name="is_distribution" className="form-control custom-select" id="is_distribution" value={is_distribution} onChange={this.handleChange}>
                                                    <option key="1" value="1" >是</option> 
                                                    <option key="2" value="2" selected>否</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="row mb-2">
                                            <div className="col col-sm-2 text-right input-group-sm">
                                                <label htmlFor="commission_amount" className="col-form-label">{lang[44]}</label>
                                            </div>
                                            <div className="col col-sm-10 input-group-sm">
                                                <input name="commission_amount" type="text" className="form-control" id="commission_amount" value={commission_amount} onChange={this.handleChange}/>
                                            </div>
                                        </div>                                        

    </div>

    <div class="tab-pane fade" id="inventory" role="tabpanel" aria-labelledby="inventory-tab">

    <div className="card-header"> 
                                    <span className="mandatory-input"></span><span>{lang[47]}</span>
                                    </div>
    <div className="form-row pt_20" >
    <div className="col col-sm-1 text-right input-group-sm" >
                                <label htmlFor="lang" className="col-form-label">{lang[48]}</label>
                            </div>
                            <div className="col col-sm-3 input-group-sm">
                                <select name="warehouse_id" className="custom-select mr-sm-2" id="warehouse_id" value={this.state.warehouse_id} onChange={this.handleChange}>
                                    {warehouses.map((one, index) => <option key={index} value={one.id}>{one.warehouse_name}</option> )}
                                </select>
                            </div>
                            <div className="col col-sm-1 text-right input-group-sm">
                                <label htmlFor="quantity_in" className="col-form-label">{lang[49]}</label>
                            </div>
                            <div className="col col-sm-3 input-group-sm">
                                <input name="quantity_in" type="text" className="form-control" id="quantity_in" value={quantity_in} onChange={this.handleChange} required/>
                            </div>

                            <div className="col col-sm-1 text-right input-group-sm">
                                <label htmlFor="warehouse_threshhold" className="col-form-label">{lang[50]}</label>
                            </div>
                            <div className="col col-sm-3 input-group-sm">
                                <input name="warehouse_threshhold" type="text" className="form-control" id="warehouse_threshhold" value={warehouse_threshhold} onChange={this.handleChange} required/>
                            </div>


</div>        
        </div>

</div>


    
                    </div>
                </div>            
            </div>
        );
    }
}

export default AddProduct;