import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Link} from 'react-router-dom';
import LANGUAGE from '../../../service/Language-data';
import apiRoot from '../../../config.api';
import UploadWallNoCrop from '../../../service/components/UploadWallNoCrop';
import { IMAGE } from '../../../service/media-type';

class AddManufacturers extends Component{
    state={
        langList: [],
        name: '',
        images: [],
        url: '',
        language: '' 
    }

    static propTypes={
        langState: PropTypes.string.isRequired
    };

    handleChange=(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    handleURLImages=(images)=>{
        this.setState({ images });        
    }

    handleClick=(e)=>{
        const { langState } = this.props;
        const { name, images,url,language } = this.state;



                    axios.post(apiRoot + "products/manufacturers/add", { 
                            name, images,url,language
                        })
                        .then(res => {
                            alert("An manufacturer was added!");
                            const language = langState==='cn'? '简体中文':'English';
                            this.setState({
                                name: '',
                                url: '',
                                images: [],
                                language
                            });
                        })
                        .catch(err=>{
                            console.log("[Error] - POST /products/attribute_groups/add - at AddAttributeGroup component!");
                            console.log(err);
                    });
                    e.preventDefault();


    }

    componentDidMount(){
        const { langState } = this.props;
        const language = langState==='cn'? '简体中文':'English';
        axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
        axios.get(apiRoot + "languages")
            .then((res) => {          
                this.setState({ 
                    langList: res.data,
                    language
                });
            })
            .catch((err) => { 
                console.log("[Error] - GET /languages - at AddAttributeGroup component!");
                console.log(err);            
        });
    }

    render(){
        const { langState } = this.props;
        let lang='';
        if(langState ==='cn') lang=LANGUAGE.ProductManufacturers.cn;
        else lang=LANGUAGE.ProductManufacturers.en;

        const {langList,images,url} = this.state;
        return(
            <div className="container-fluid">
                <div className="card rounded">
                    <div className="card-header">
                        <span style={{fontSize: "1.4em"}}><i className="fas fa-pencil-alt"></i>&nbsp;{lang[2]}</span>
                        <div className="float-right">
                            <div className=" btn-toolbar " >
                                <div className="btn-group-sm mr-2" role="group" aria-label="First group">
                                    <button type="button" className="btn btn-success" onClick={this.handleClick} title={lang[3]}><i className="fas fa-save"></i></button>
                                </div>
                                <Link to='/products/manufacturers'>
                                    <div className="btn-group-sm mr-2" role="group" aria-label="Second group">
                                        <button type="button" className="btn btn-primary" title={lang[4]}><i className="fas fa-arrow-alt-circle-right"></i></button>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-body" style={{fontWeight: 'bold'}}>
                    <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="name" className="col-form-label">{lang[5]}</label>
                            </div>
                            <div className="col col-sm-10 input-group-sm">
                                <input name="name" type="text" className="form-control" id="name" value={this.state.name} onChange={this.handleChange} required/>
                            </div>
                        </div>
                    <hr/>
                        <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="name" className="col-form-label">{lang[8]}</label>
                            </div>
                            <div className="col col-sm-10 input-group-sm">
                                <input name="url" type="text" className="form-control" id="url" value={this.state.url} onChange={this.handleChange} required/>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                    <label htmlFor="question_upload_image">{lang[7]}</label>
                            </div>
                            <div className="col col-sm-10 input-group-sm">
                                    <div className="form-group">
                                         <UploadWallNoCrop
                                                    some={1}
                                                    listType="picture-card"
                                                    load={ images }
                                                    urls={this.handleURLImages}
                                                    format={ IMAGE.toString() }
                                                />
                                    </div>
                                    <div className="form-group small-note-text">* 删除及预览图片，请移动鼠标到图片中，然后选择相应的功能!</div>
                            </div>
                                </div>

                        <hr/>
                        {/* <div className="row">
                            <div className="col col-sm-2 text-right input-group-sm">
                                <label htmlFor="lang" className="col-form-label">{lang[4]}</label>
                            </div>
                            <div className="col col-sm-10 input-group-sm">
                                <select name="language" className="custom-select mr-sm-2" id="lang" value={this.state.language} onChange={this.handleChange}>
                                    {langList.map((one, index) => <option key={index} value={one.name}>{one.name}</option> )}
                                </select>
                            </div>
                        </div> */}
                    </div>
                </div>            
            </div>
        );
    }
}

export default AddManufacturers;