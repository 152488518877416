import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import LANGUAGE from '../service/Language-data';

class LeftPanel extends Component {
    handleLogOutClick = () => {
        //clear auto login
        localStorage.setItem("current_login_pwd","");
        localStorage.setItem("auto_login",0);  
        setTimeout(function(){ 
            this.props.logOutClick();
         }, 1000);
        
    }
    handleMenuClick(e,id) {
       // e.preventDefault();
       // console.log('Click happened');
        // $(".menu-item-has-children").removeClass("show");
        // $("#menu"+id).addClass("show");
        // $("#submenu"+id).addClass("show");
        //getElementsByClassName("menu-item-has-children").

        var menuItem = document.getElementById("menu"+id);
        menuItem.classList.add("show");
        var subMenuItem= document.getElementById("submenu"+id);
        subMenuItem.classList.add("show");
        localStorage.setItem("menuSelectedId",id)
    }
    componentDidMount() {
        console.log('componentDidMount');
    }
  
    componentWillUnmount() {
        console.log('componentWillUnmount');
    }
    getSelectedMenufromLocalStorage(id){
       // console.log("start getSelectedMenufromLocalStorage ");
        var selected = localStorage.getItem("menuSelectedId");
        //console.log(" get id return "+selected);
        if(selected==null) return false;
        if(parseInt(selected)===id){
          //  console.log("find it,should show menu");
            return true;
        }
        return false;
    }
    render(){
        let lang='';
        if(this.props.langState ==='cn') lang=LANGUAGE.LeftPanel.cn;
        else lang=LANGUAGE.LeftPanel.en;
        
        const { permissions } =this.props;
        return(
            <aside id="left-panel" className="left-panel">
                <nav className="navbar navbar-expand-sm navbar-light">
                    <div className="navbar-header">
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#main-menu" aria-controls="main-menu" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="fa fa-bars"></i>
                        </button>
                        <a className="navbar-brand" href="0" onClick={(e)=>e.preventDefault()}><img src="/images/logo.png" alt="Logo"/></a>
                        <a className="navbar-brand hidden" href="0" onClick={(e)=>e.preventDefault()}><img src="/images/logo2.png" alt="Logo"/></a>
                    </div>
                    <div id="main-menu" className="main-menu collapse navbar-collapse">
                        <ul className="nav navbar-nav">
                            {
                               permissions[0][0].value?<li><Link to="/dashboard"> <i className="menu-icon fas fa-tachometer-alt"></i>{lang[0]}</Link></li>:null
                            }
                            {/* <h3 className="menu-title">{lang[1]}</h3> */}
                            {
                                permissions[1][0].value?
                               this.getSelectedMenufromLocalStorage(10)?<li className="menu-item-has-children dropdown show" id="menu10">
                                    <a href="javascript:void(0)" onClick={(e)=>this.handleMenuClick(e,"10")} className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i className="menu-icon fas fa-file-alt"></i>{lang[2]}</a>
                                    <ul className="sub-menu children dropdown-menu show" id="submenu10">
                                        {permissions[1][1].value?<li><i className="fa fa-list-ol"></i><Link to="/documents"  onClick={(e)=>this.handleMenuClick(e,"10")}>{lang[3]}</Link></li>:null}
                                        {permissions[1][2].value?<li><i className="icon fa fa-plus-square"></i><Link to="/documents/add"  onClick={(e)=>this.handleMenuClick(e,"10")}>{lang[4]}</Link></li>:null}
                                        {permissions[1][5].value?<li><i className="icon fas fa-comment-dots"></i><Link to="/documents/comments" onClick={(e)=>this.handleMenuClick(e,"10")}>{lang[5]}</Link></li>:null}
                                        {permissions[1][6].value?<li><i className="fa fa-sitemap"></i><Link to="/documents/categories" onClick={(e)=>this.handleMenuClick(e,"10")}>{lang[6]}</Link></li>:null}
                                    </ul>
                                </li>:<li className="menu-item-has-children dropdown" id="menu10">
                                    <a href="javascript:void(0)" onClick={(e)=>this.handleMenuClick(e,"10")} className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i className="menu-icon fas fa-file-alt"></i>{lang[2]}</a>
                                    <ul className="sub-menu children dropdown-menu" id="submenu10">
                                        {permissions[1][1].value?<li><i className="fa fa-list-ol"></i><Link to="/documents"  onClick={(e)=>this.handleMenuClick(e,"10")}>{lang[3]}</Link></li>:null}
                                        {permissions[1][2].value?<li><i className="icon fa fa-plus-square"></i><Link to="/documents/add"  onClick={(e)=>this.handleMenuClick(e,"10")}>{lang[4]}</Link></li>:null}
                                        {permissions[1][5].value?<li><i className="icon fas fa-comment-dots"></i><Link to="/documents/comments" onClick={(e)=>this.handleMenuClick(e,"10")}>{lang[5]}</Link></li>:null}
                                        {permissions[1][6].value?<li><i className="fa fa-sitemap"></i><Link to="/documents/categories" onClick={(e)=>this.handleMenuClick(e,"10")}>{lang[6]}</Link></li>:null}
                                    </ul>
                                </li> : null
                            }
                            {
                                permissions[2][0].value?
                                this.getSelectedMenufromLocalStorage(20)?<li className="menu-item-has-children dropdown show" id="menu20">
                                    <a href="javascript:void(0)" onClick={(e)=>this.handleMenuClick(e,"20")} className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i className="menu-icon fas fa-video"></i>{lang[7]}</a>
                                    <ul className="sub-menu children dropdown-menu show" id="submenu20">
                                        {permissions[2][1].value?<li><i className="fa fa-list-ol"></i><Link  onClick={(e)=>this.handleMenuClick(e,"20")} to="/videos">{lang[8]}</Link></li>:null}
                                        {permissions[2][2].value?<li><i className="fa fa-plus-square"></i><Link onClick={(e)=>this.handleMenuClick(e,"20")} to="/videos/add">{lang[9]}</Link></li>:null}
                                        {permissions[2][5].value?<li><i className="fas fa-comment-dots"></i><Link onClick={(e)=>this.handleMenuClick(e,"20")} to="/videos/comments">{lang[10]}</Link></li>:null}
                                        {permissions[2][6].value?<li><i className="fa fa-sitemap"></i><Link onClick={(e)=>this.handleMenuClick(e,"20")} to="/videos/categories">{lang[11]}</Link></li>:null}
                                    </ul>
                                </li>
                                :<li className="menu-item-has-children dropdown" id="menu20">
                                <a href="javascript:void(0)" onClick={(e)=>this.handleMenuClick(e,"20")} className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i className="menu-icon fas fa-video"></i>{lang[7]}</a>
                                <ul className="sub-menu children dropdown-menu" id="submenu20">
                                    {permissions[2][1].value?<li><i className="fa fa-list-ol"></i><Link  onClick={(e)=>this.handleMenuClick(e,"20")} to="/videos">{lang[8]}</Link></li>:null}
                                    {permissions[2][2].value?<li><i className="fa fa-plus-square"></i><Link onClick={(e)=>this.handleMenuClick(e,"20")} to="/videos/add">{lang[9]}</Link></li>:null}
                                    {permissions[2][5].value?<li><i className="fas fa-comment-dots"></i><Link onClick={(e)=>this.handleMenuClick(e,"20")} to="/videos/comments">{lang[10]}</Link></li>:null}
                                    {permissions[2][6].value?<li><i className="fa fa-sitemap"></i><Link onClick={(e)=>this.handleMenuClick(e,"20")} to="/videos/categories">{lang[11]}</Link></li>:null}
                                </ul>
                            </li>
                                : null
                            }
                            {
                                permissions[3][0].value?
                                this.getSelectedMenufromLocalStorage(30)?<li className="menu-item-has-children dropdown show" id="menu30">
                                    <a href="javascript:void(0)" onClick={(e)=>this.handleMenuClick(e,"30")} className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i className="menu-icon fas fa-hiking" style={{size: '10x'}}></i>{lang[12]}</a>
                                    <ul className="sub-menu children dropdown-menu show" id="submenu30">
                                        {permissions[3][1].value?<li><i className="fa fa-list-ol"></i><Link onClick={(e)=>this.handleMenuClick(e,"30")} to="/events">{lang[13]}</Link></li>:null}
                                        {permissions[3][2].value?<li><i className="fa fa-plus-square"></i><Link onClick={(e)=>this.handleMenuClick(e,"30")} to="/events/add">{lang[14]}</Link></li>:null}
                                        {permissions[3][5].value?<li><i className="fas fa-comment-dots"></i><Link onClick={(e)=>this.handleMenuClick(e,"30")} to="/events/comments">{lang[15]}</Link></li>:null}
                                        {permissions[3][6].value?<li><i className="fa fa-sitemap"></i><Link onClick={(e)=>this.handleMenuClick(e,"30")} to="/events/categories">{lang[16]}</Link></li>:null}
                                    </ul>
                                </li>
                                :<li className="menu-item-has-children dropdown" id="menu30">
                                <a href="javascript:void(0)" onClick={(e)=>this.handleMenuClick(e,"30")} className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i className="menu-icon fas fa-hiking" style={{size: '10x'}}></i>{lang[12]}</a>
                                <ul className="sub-menu children dropdown-menu" id="submenu30">
                                    {permissions[3][1].value?<li><i className="fa fa-list-ol"></i><Link onClick={(e)=>this.handleMenuClick(e,"30")} to="/events">{lang[13]}</Link></li>:null}
                                    {permissions[3][2].value?<li><i className="fa fa-plus-square"></i><Link onClick={(e)=>this.handleMenuClick(e,"30")} to="/events/add">{lang[14]}</Link></li>:null}
                                    {permissions[3][5].value?<li><i className="fas fa-comment-dots"></i><Link onClick={(e)=>this.handleMenuClick(e,"30")} to="/events/comments">{lang[15]}</Link></li>:null}
                                    {permissions[3][6].value?<li><i className="fa fa-sitemap"></i><Link onClick={(e)=>this.handleMenuClick(e,"30")} to="/events/categories">{lang[16]}</Link></li>:null}
                                </ul>
                            </li>
                                : null
                            }
                            {
                                permissions[4][0].value?
                                this.getSelectedMenufromLocalStorage(40)? <li className="menu-item-has-children dropdown show" id="menu40">
                                    <a href="javascript:void(0)" onClick={(e)=>this.handleMenuClick(e,"40")} className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i className="menu-icon  fas fa-book-reader"></i>{lang[17]}</a>
                                    <ul className="sub-menu children dropdown-menu show" id="submenu40">
                                        {permissions[4][1].value?<li><i className="fa fa-list-ol"></i><Link onClick={(e)=>this.handleMenuClick(e,"40")} to="/lessons">{lang[18]}</Link></li>:null}
                                        {permissions[4][2].value?<li><i className="fa fa-plus-square"></i><Link onClick={(e)=>this.handleMenuClick(e,"40")} to="/lessons/add">{lang[19]}</Link></li>:null}
                                        {permissions[4][5].value?<li><i className="fas fa-comment-dots"></i><Link onClick={(e)=>this.handleMenuClick(e,"40")} to="/lessons/comments">{lang[20]}</Link></li>:null}
                                        {permissions[4][6].value?<li><i className="fa fa-sitemap"></i><Link onClick={(e)=>this.handleMenuClick(e,"40")} to="/lessons/categories">{lang[21]}</Link></li>:null}
                                    </ul>
                                </li>
                                :<li className="menu-item-has-children dropdown" id="menu40">
                                <a href="javascript:void(0)" onClick={(e)=>this.handleMenuClick(e,"40")} className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i className="menu-icon  fas fa-book-reader"></i>{lang[17]}</a>
                                <ul className="sub-menu children dropdown-menu" id="submenu40">
                                    {permissions[4][1].value?<li><i className="fa fa-list-ol"></i><Link onClick={(e)=>this.handleMenuClick(e,"40")} to="/lessons">{lang[18]}</Link></li>:null}
                                    {permissions[4][2].value?<li><i className="fa fa-plus-square"></i><Link onClick={(e)=>this.handleMenuClick(e,"40")} to="/lessons/add">{lang[19]}</Link></li>:null}
                                    {permissions[4][5].value?<li><i className="fas fa-comment-dots"></i><Link onClick={(e)=>this.handleMenuClick(e,"40")} to="/lessons/comments">{lang[20]}</Link></li>:null}
                                    {permissions[4][6].value?<li><i className="fa fa-sitemap"></i><Link onClick={(e)=>this.handleMenuClick(e,"40")} to="/lessons/categories">{lang[21]}</Link></li>:null}
                                </ul>
                            </li>
                                : null
                            }
                            {
                                permissions[5][0].value?
                                this.getSelectedMenufromLocalStorage(50)? <li className="menu-item-has-children dropdown show" id="menu50">
                                    <a href="javascript:void(0)" onClick={(e)=>this.handleMenuClick(e,"50")} className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="menu-icon fas fa-question-circle"></i>{lang[22]}</a>
                                    <ul className="sub-menu children dropdown-menu show" id="submenu50">
                                        {permissions[5][1].value?<li><i className="fa fa-list-ol"></i><Link onClick={(e)=>this.handleMenuClick(e,"50")} to="/questions">{lang[23]}</Link></li>:null}
                                        {permissions[5][3].value?<li><i className="fa fa-plus-square"></i><Link onClick={(e)=>this.handleMenuClick(e,"50")} to="/questions/add">{lang[24]}</Link></li>:null}
                                        {permissions[5][6].value?<li><i className="fa fa-sitemap"></i><Link onClick={(e)=>this.handleMenuClick(e,"50")} to="/questions/categories">{lang[25]}</Link></li>:null}
                                    </ul>
                                </li>
                                :<li className="menu-item-has-children dropdown" id="menu50">
                                <a href="javascript:void(0)" onClick={(e)=>this.handleMenuClick(e,"50")} className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="menu-icon fas fa-question-circle"></i>{lang[22]}</a>
                                <ul className="sub-menu children dropdown-menu" id="submenu50">
                                    {permissions[5][1].value?<li><i className="fa fa-list-ol"></i><Link onClick={(e)=>this.handleMenuClick(e,"50")} to="/questions">{lang[23]}</Link></li>:null}
                                    {permissions[5][3].value?<li><i className="fa fa-plus-square"></i><Link onClick={(e)=>this.handleMenuClick(e,"50")} to="/questions/add">{lang[24]}</Link></li>:null}
                                    {permissions[5][6].value?<li><i className="fa fa-sitemap"></i><Link onClick={(e)=>this.handleMenuClick(e,"50")} to="/questions/categories">{lang[25]}</Link></li>:null}
                                </ul>
                            </li>
                                : null
                            }
                            {
                                permissions[8][0].value?
                                this.getSelectedMenufromLocalStorage(80)? <li className="menu-item-has-children dropdown show" id="menu80">
                                    <a href="javascript:void(0)" onClick={(e)=>this.handleMenuClick(e,"80")} className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i className="menu-icon fas fa-cart-arrow-down"></i>{lang[26]}</a>
                                    <ul className="sub-menu children dropdown-menu show" id="submenu80">
                                        {permissions[8][1].value?<li><i className="fa fa-list-ol"></i><Link onClick={(e)=>this.handleMenuClick(e,"80")} to="/products">{lang[27]}</Link></li>:null}
                                        {permissions[8][5].value?<li><i className="fa fa-sitemap"></i><Link onClick={(e)=>this.handleMenuClick(e,"80")} to="/products/categories">{lang[28]}</Link></li>:null}
                                        {permissions[8][6].value?<li><i className="fas fa-cogs"></i><Link onClick={(e)=>this.handleMenuClick(e,"80")} to="/products/attributes">{lang[29]}</Link></li>:null}
                                        {permissions[8][7].value?<li><i className="fas fa-object-ungroup"></i><Link onClick={(e)=>this.handleMenuClick(e,"80")} to="/products/attribute_groups">{lang[30]}</Link></li>:null}
                                        {permissions[8][7].value?<li><i className="fas fa-object-ungroup"></i><Link onClick={(e)=>this.handleMenuClick(e,"80")} to="/products/order_dashboard">{lang[53]}</Link></li>:null}
                                        {permissions[8][7].value?<li><i className="fas fa-object-ungroup"></i><Link onClick={(e)=>this.handleMenuClick(e,"80")} to="/products/manufacturers">{lang[54]}</Link></li>:null}
                                        {permissions[8][7].value?<li><i className="fas fa-object-ungroup"></i><Link onClick={(e)=>this.handleMenuClick(e,"80")} to="/products/brand">{lang[55]}</Link></li>:null}
                                        {permissions[8][8].value?<li><i className="fas fa-object-ungroup"></i><Link onClick={(e)=>this.handleMenuClick(e,"80")} to="/coupon">{lang[68]}</Link></li>:null}
                                    </ul>
                                </li>
                                :<li className="menu-item-has-children dropdown" id="menu80">
                                <a href="javascript:void(0)" onClick={(e)=>this.handleMenuClick(e,"80")} className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i className="menu-icon fas fa-cart-arrow-down"></i>{lang[26]}</a>
                                <ul className="sub-menu children dropdown-menu" id="submenu80">
                                    {permissions[8][1].value?<li><i className="fa fa-list-ol"></i><Link onClick={(e)=>this.handleMenuClick(e,"80")} to="/products">{lang[27]}</Link></li>:null}
                                    {permissions[8][5].value?<li><i className="fa fa-sitemap"></i><Link onClick={(e)=>this.handleMenuClick(e,"80")} to="/products/categories">{lang[28]}</Link></li>:null}
                                    {permissions[8][6].value?<li><i className="fas fa-cogs"></i><Link onClick={(e)=>this.handleMenuClick(e,"80")} to="/products/attributes">{lang[29]}</Link></li>:null}
                                    {permissions[8][7].value?<li><i className="fas fa-object-ungroup"></i><Link onClick={(e)=>this.handleMenuClick(e,"80")} to="/products/attribute_groups">{lang[30]}</Link></li>:null}
                                    {permissions[8][7].value?<li><i className="fas fa-object-ungroup"></i><Link onClick={(e)=>this.handleMenuClick(e,"80")} to="/products/order_dashboard">{lang[53]}</Link></li>:null}
                                    {permissions[8][7].value?<li><i className="fas fa-object-ungroup"></i><Link onClick={(e)=>this.handleMenuClick(e,"80")} to="/products/manufacturers">{lang[54]}</Link></li>:null}
                                    {permissions[8][7].value?<li><i className="fas fa-object-ungroup"></i><Link onClick={(e)=>this.handleMenuClick(e,"80")} to="/products/brand">{lang[55]}</Link></li>:null}
                                    {permissions[8][8].value?<li><i className="fas fa-object-ungroup"></i><Link onClick={(e)=>this.handleMenuClick(e,"80")} to="/coupon">{lang[68]}</Link></li>:null}

                                </ul>
                            </li>
                                : null
                            }

                            {
                                permissions[10][0].value?
                                this.getSelectedMenufromLocalStorage(75)? <li className="menu-item-has-children dropdown show" id="menu75">
                                    <a href="javascript:void(0)" onClick={(e)=>this.handleMenuClick(e,"75")} className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="menu-icon fas fa-warehouse"></i>{lang[56]}</a>
                                    <ul className="sub-menu children dropdown-menu show" id="submenu75">
                                        {permissions[10][1].value?<li><i className="fa fa-warehouse"></i><Link onClick={(e)=>this.handleMenuClick(e,"75")} to="/warehouse">{lang[57]}</Link></li>:null}
                                        {permissions[10][2].value?<li><i className="fa fa-plus-square"></i><Link onClick={(e)=>this.handleMenuClick(e,"75")} to="/supplier">{lang[58]}</Link></li>:null}
                                        {permissions[10][3].value?<li><i className="fa fa-sitemap"></i><Link onClick={(e)=>this.handleMenuClick(e,"75")} to="/productStock">{lang[59]}</Link></li>:null}
                                        {permissions[10][4].value?<li><i className="fa fa-sitemap"></i><Link onClick={(e)=>this.handleMenuClick(e,"75")} to="/productStockOut">{lang[60]}</Link></li>:null}
                                    </ul>
                                </li>
                                :<li className="menu-item-has-children dropdown" id="menu75">
                                <a href="javascript:void(0)" onClick={(e)=>this.handleMenuClick(e,"75")} className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="menu-icon fas fa-warehouse"></i>{lang[56]}</a>
                                <ul className="sub-menu children dropdown-menu" id="submenu75">
                                    {permissions[10][1].value?<li><i className="fa fa-warehouse"></i><Link onClick={(e)=>this.handleMenuClick(e,"75")} to="/warehouse">{lang[57]}</Link></li>:null}
                                    {permissions[10][2].value?<li><i className="fa fa-plus-square"></i><Link onClick={(e)=>this.handleMenuClick(e,"75")} to="/supplier">{lang[58]}</Link></li>:null}
                                    {permissions[10][3].value?<li><i className="fa fa-sitemap"></i><Link onClick={(e)=>this.handleMenuClick(e,"75")} to="/productStock">{lang[59]}</Link></li>:null}
                                    {permissions[10][4].value?<li><i className="fa fa-sitemap"></i><Link onClick={(e)=>this.handleMenuClick(e,"75")} to="/productStockOut">{lang[60]}</Link></li>:null}
                                </ul>
                            </li>
                                : null
                            }
                            {
                                permissions[6][0].value?
                                this.getSelectedMenufromLocalStorage(60)?<li className="menu-item-has-children dropdown show" id="menu60">
                                    <a href="javascript:void(0)" onClick={(e)=>this.handleMenuClick(e,"60")} className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i className="menu-icon fas fa-briefcase-medical"></i>{lang[31]}</a>
                                    <ul className="sub-menu children dropdown-menu show" id="submenu60">
                                        {permissions[6][1].value?<li><i className="fa fa-list-ol"></i><Link onClick={(e)=>this.handleMenuClick(e,"60")} to="/health/doctors">{lang[32]}</Link></li>:null}
                                        {permissions[6][5].value?<li><i className="fa fa-sitemap"></i><Link onClick={(e)=>this.handleMenuClick(e,"60")} to="/health/doctors/categories">{lang[33]}</Link></li>:null}
                                    </ul>
                                </li>
                                :<li className="menu-item-has-children dropdown" id="menu60">
                                <a href="javascript:void(0)" onClick={(e)=>this.handleMenuClick(e,"60")} className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i className="menu-icon fas fa-briefcase-medical"></i>{lang[31]}</a>
                                <ul className="sub-menu children dropdown-menu" id="submenu60">
                                    {permissions[6][1].value?<li><i className="fa fa-list-ol"></i><Link onClick={(e)=>this.handleMenuClick(e,"60")} to="/health/doctors">{lang[32]}</Link></li>:null}
                                    {permissions[6][5].value?<li><i className="fa fa-sitemap"></i><Link onClick={(e)=>this.handleMenuClick(e,"60")} to="/health/doctors/categories">{lang[33]}</Link></li>:null}
                                </ul>
                            </li>
                                : null
                            }
                            {
                                permissions[9][0].value?
                                this.getSelectedMenufromLocalStorage(90)?<li className="menu-item-has-children dropdown show" id="menu90">
                                    <a href="javascript:void(0)" onClick={(e)=>this.handleMenuClick(e,"90")} className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i className="menu-icon fas fa-compass"></i>{lang[34]}</a>
                                    <ul className="sub-menu children dropdown-menu show" id="submenu90">
                                        {permissions[9][1].value?<li><i className="fas fa-globe-americas"></i><Link onClick={(e)=>this.handleMenuClick(e,"90")}  to="/location/countries">{lang[35]}</Link></li>:null}
                                        {permissions[9][3].value?<li><i className="fas fa-map-marked-alt"></i><Link onClick={(e)=>this.handleMenuClick(e,"90")} to="/location/provinces">{lang[36]}</Link></li>:null}
                                        {permissions[9][7].value?<li><i className="fas fa-city"></i><Link onClick={(e)=>this.handleMenuClick(e,"90")} to="/location/cities">{lang[37]}</Link></li>:null}
                                    </ul>
                                </li>
                                :<li className="menu-item-has-children dropdown" id="menu90">
                                <a href="javascript:void(0)" onClick={(e)=>this.handleMenuClick(e,"90")} className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i className="menu-icon fas fa-compass"></i>{lang[34]}</a>
                                <ul className="sub-menu children dropdown-menu" id="submenu90">
                                    {permissions[9][1].value?<li><i className="fas fa-globe-americas"></i><Link onClick={(e)=>this.handleMenuClick(e,"90")}  to="/location/countries">{lang[35]}</Link></li>:null}
                                    {permissions[9][3].value?<li><i className="fas fa-map-marked-alt"></i><Link onClick={(e)=>this.handleMenuClick(e,"90")} to="/location/provinces">{lang[36]}</Link></li>:null}
                                    {permissions[9][7].value?<li><i className="fas fa-city"></i><Link onClick={(e)=>this.handleMenuClick(e,"90")} to="/location/cities">{lang[37]}</Link></li>:null}
                                </ul>
                            </li>
                                : null
                            }
                            {
                                permissions[9][0].value?
                                this.getSelectedMenufromLocalStorage(100)?<li className="menu-item-has-children dropdown show" id="menu100">
                                    <a href="javascript:void(0)" onClick={(e)=>this.handleMenuClick(e,"100")} className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i className="menu-icon fas fa-comments"></i>{lang[38]}</a>
                                    <ul className="sub-menu children dropdown-menu show" id="submenu100">
                                        { permissions[9][1].value?<li><i className="fa fa-list-ol"></i><Link onClick={(e)=>this.handleMenuClick(e,"100")}  to="/topics">{lang[39]}</Link></li>:null}
                                        { permissions[9][3].value?<li><i className="fa fa-plus-square"></i><Link onClick={(e)=>this.handleMenuClick(e,"100")} to="/topics/add">{lang[40]}</Link></li>:null}
                                        { permissions[9][7].value?<li><i className="fas fa-tags"></i><Link onClick={(e)=>this.handleMenuClick(e,"100")} to="/topics/tags">{lang[41]}</Link></li>:null}
                                        { permissions[9][7].value?<li><i className="far fa-arrow-alt-circle-down"></i><Link onClick={(e)=>this.handleMenuClick(e,"100")} to="/topics/threads">{lang[42]}</Link></li>:null}
                                    </ul>
                                </li>:
                                <li className="menu-item-has-children dropdown" id="menu100">
                                <a href="javascript:void(0)" onClick={(e)=>this.handleMenuClick(e,"100")} className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i className="menu-icon fas fa-comments"></i>{lang[38]}</a>
                                <ul className="sub-menu children dropdown-menu" id="submenu100">
                                    { permissions[9][1].value?<li><i className="fa fa-list-ol"></i><Link onClick={(e)=>this.handleMenuClick(e,"100")}  to="/topics">{lang[39]}</Link></li>:null}
                                    { permissions[9][3].value?<li><i className="fa fa-plus-square"></i><Link onClick={(e)=>this.handleMenuClick(e,"100")} to="/topics/add">{lang[40]}</Link></li>:null}
                                    { permissions[9][7].value?<li><i className="fas fa-tags"></i><Link onClick={(e)=>this.handleMenuClick(e,"100")} to="/topics/tags">{lang[41]}</Link></li>:null}
                                    { permissions[9][7].value?<li><i className="far fa-arrow-alt-circle-down"></i><Link onClick={(e)=>this.handleMenuClick(e,"100")} to="/topics/threads">{lang[42]}</Link></li>:null}
                                </ul>
                            </li>: null
                            }
                            {  permissions[7][0].value?
                                this.getSelectedMenufromLocalStorage(70)?<li className="menu-item-has-children dropdown show" id="menu70">
                                    <a href="javascript:void(0)" onClick={(e)=>this.handleMenuClick(e,"70")} className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i className="menu-icon fas fa-users"></i>{lang[43]}</a>
                                    <ul className="sub-menu children dropdown-menu show" id="submenu70">
                                        {permissions[7][1].value?<li><i className="fa fa-list-ol"></i><Link onClick={(e)=>this.handleMenuClick(e,"70")} to="/users">{lang[44]}</Link></li>:null}
                                        {permissions[7][2].value?<li><i className="fa fa-plus-square"></i><Link onClick={(e)=>this.handleMenuClick(e,"70")} to="/users/add">{lang[45]}</Link></li>:null}
                                        {permissions[7][6].value?<li><i className="fas fa-object-ungroup"></i><Link onClick={(e)=>this.handleMenuClick(e,"70")} to="/users/roles">{lang[46]}</Link></li>:null}
                                        {permissions[7][7].value?<li><i className="fas fa-lock"></i><Link onClick={(e)=>this.handleMenuClick(e,"70")} to="/users/permissions">{lang[47]}</Link></li>:null}
                                        { permissions[7][8].value?<li><i className="fas fa-file-alt"></i><Link onClick={(e)=>this.handleMenuClick(e,"70")} to="/users/activity">{lang[48]}</Link></li>:null}
                                        { permissions[7][9].value?<li><i className="fas fa-file-alt"></i><Link onClick={(e)=>this.handleMenuClick(e,"70")} to="/user/operation_log">{lang[67]}</Link></li>:null}

                                                                               
                                    </ul>
                                </li>
                                :<li className="menu-item-has-children dropdown" id="menu70">
                                <a href="javascript:void(0)" onClick={(e)=>this.handleMenuClick(e,"70")} className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i className="menu-icon fas fa-users"></i>{lang[43]}</a>
                                <ul className="sub-menu children dropdown-menu" id="submenu70">
                                    {permissions[7][1].value?<li><i className="fa fa-list-ol"></i><Link onClick={(e)=>this.handleMenuClick(e,"70")} to="/users">{lang[44]}</Link></li>:null}
                                    {permissions[7][2].value?<li><i className="fa fa-plus-square"></i><Link onClick={(e)=>this.handleMenuClick(e,"70")} to="/users/add">{lang[45]}</Link></li>:null}
                                    {permissions[7][6].value?<li><i className="fas fa-object-ungroup"></i><Link onClick={(e)=>this.handleMenuClick(e,"70")} to="/users/roles">{lang[46]}</Link></li>:null}
                                    {permissions[7][7].value?<li><i className="fas fa-lock"></i><Link onClick={(e)=>this.handleMenuClick(e,"70")} to="/users/permissions">{lang[47]}</Link></li>:null}
                                    {permissions[7][8].value?<li><i className="fas fa-file-alt"></i><Link onClick={(e)=>this.handleMenuClick(e,"70")} to="/users/activity">{lang[48]}</Link></li>:null}
                                    { permissions[7][9].value?<li><i className="fas fa-file-alt"></i><Link onClick={(e)=>this.handleMenuClick(e,"70")} to="/user/operation_log">{lang[67]}</Link></li>:null}
                                </ul>
                            </li>
                                : null
                            }
                            
                            {  permissions[11][0].value?
                                this.getSelectedMenufromLocalStorage(110)?<li className="menu-item-has-children dropdown show" id="menu110">
                                    <a href="javascript:void(0)" onClick={(e)=>this.handleMenuClick(e,"110")} className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i className="menu-icon fas fa-users"></i>{lang[65]}</a>
                                    <ul className="sub-menu children dropdown-menu show" id="submenu110">
                                        {permissions[11][1].value?<li><i className="fa fa-list-ol"></i><Link onClick={(e)=>this.handleMenuClick(e,"110")} to="/report/revenue">{lang[61]}</Link></li>:null}
                                        {permissions[11][2].value?<li><i className="fa fa-plus-square"></i><Link onClick={(e)=>this.handleMenuClick(e,"110")} to="/report/order">{lang[62]}</Link></li>:null}
                                        {permissions[11][3].value?<li><i className="fas fa-object-ungroup"></i><Link onClick={(e)=>this.handleMenuClick(e,"110")} to="/report/product">{lang[63]}</Link></li>:null}
                                        {permissions[11][4].value?<li><i className="fas fa-lock"></i><Link onClick={(e)=>this.handleMenuClick(e,"110")} to="/report/customer">{lang[64]}</Link></li>:null}
                                        {permissions[11][5].value?<li><i className="fas fa-lock"></i><Link onClick={(e)=>this.handleMenuClick(e,"110")} to="/report/distribution">{lang[66]}</Link></li>:null}
                                    </ul>
                                </li>
                                :<li className="menu-item-has-children dropdown" id="menu110">
                                <a href="javascript:void(0)" onClick={(e)=>this.handleMenuClick(e,"110")} className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i className="menu-icon fas fa-users"></i>{lang[65]}</a>
                                <ul className="sub-menu children dropdown-menu" id="submenu110">
                                    {permissions[11][1].value?<li><i className="fa fa-list-ol"></i><Link onClick={(e)=>this.handleMenuClick(e,"110")} to="/report/revenue">{lang[61]}</Link></li>:null}
                                    {permissions[11][2].value?<li><i className="fa fa-plus-square"></i><Link onClick={(e)=>this.handleMenuClick(e,"110")} to="/report/order">{lang[62]}</Link></li>:null}
                                    {permissions[11][3].value?<li><i className="fas fa-object-ungroup"></i><Link onClick={(e)=>this.handleMenuClick(e,"110")} to="/report/product">{lang[63]}</Link></li>:null}
                                    {permissions[11][4].value?<li><i className="fas fa-lock"></i><Link onClick={(e)=>this.handleMenuClick(e,"110")} to="/report/customer">{lang[64]}</Link></li>:null}
                                    {permissions[11][5].value?<li><i className="fas fa-lock"></i><Link onClick={(e)=>this.handleMenuClick(e,"110")} to="/report/distribution">{lang[66]}</Link></li>:null}
                                </ul>
                            </li>
                                : null
                            }


                            {/*<h3 className="menu-title">Icons</h3>
                            <li>
                                <a href="widgets.html"> <i className="menu-icon ti-email"></i>Widgets </a>
                            </li>
                            <li className="menu-item-has-children dropdown">
                                <a href="javascript:void(0)" className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i className="menu-icon fas fa-chart-line"></i>Charts</a>
                                <ul className="sub-menu children dropdown-menu">
                                    <li><i className="menu-icon fa fa-line-chart"></i><a href="charts-chartjs.html">Chart JS</a></li>
                                    <li><i className="menu-icon fa fa-area-chart"></i><a href="charts-flot.html">Flot Chart</a></li>
                                    <li><i className="menu-icon fa fa-pie-chart"></i><a href="charts-peity.html">Peity Chart</a></li>
                                </ul>
                            </li>

                            <li className="menu-item-has-children dropdown">
                                <a href="javascript:void(0)" className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="menu-icon fas fa-map-marked-alt"></i>Maps</a>
                                <ul className="sub-menu children dropdown-menu">
                                    <li> <i className="menu-icon fas fa-map-signs"></i><a href="maps-gmap.html">Google Maps</a></li>
                                    <li><i className="menu-icon fa fa-street-view"></i><a href="maps-vector.html">Vector Maps</a></li>
                                </ul>
                            </li>*/}

                            <h3 className="menu-title">{lang[49]}</h3>
                            <li className="menu-item-has-children dropdown">
                                <a href="0" onClick={(e)=>e.preventDefault()} className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="menu-icon fas fa-window-maximize"></i>{lang[50]}</a>
                                <ul className="sub-menu children dropdown-menu">
                                    <li><i className="menu-icon fas fa-sign-in-alt"></i><Link to="/login">{lang[51]}</Link></li>
                                    <li><i className="menu-icon fas fa-sign-out-alt"></i><Link to="/login" onClick={this.handleLogOutClick}>{lang[52]}</Link></li>
                                </ul>
                            </li>
                            {/* {renderIcon()} */}
                        </ul>
                    </div>
                </nav>
            </aside>
        );
    }
}

export default LeftPanel;

    

// function renderIcon(){
//     console.log("Show me now: " + window.location.href.substr(window.location.origin.length));

//     var link = window.location.href.substr(window.location.origin.length);
    
    
// }