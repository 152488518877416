import React, { Component } from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';

import LANGUAGE from '../../../service/Language-data';
import apiRoot from '../../../config.api';
import EmbeddedEditor from '../../../service/components/EmbeddedEditor';
import SelectLocation from '../../../service/components/SelectLocation';
import UploadWallNoCrop from '../../../service/components/UploadWallNoCrop';
import { isP0_integer } from '../../../service/validation';
import { getUTCTimeString } from '../../../service/func-service';
import { IMAGE, DOCUMENT } from '../../../service/media-type';

class UpdateEvent extends Component{
    state={
        types: [],
        currencies: [],
        title: '',
        organizer: '',
        location: '',
        zipcode:'',
        city_id: 0,
        type_id: 1,
        cover_image: '',
        uploadImage:'',
        start: '',
        end: '',
        content: '',
        free: true,
        pay: false,
        currency_id: 1,
        ticket_types: [{ id: 0, name: '', price: 0, max: 0 }],
        deleteTicketsIds: [],
        refund: '',
        images: [], // for attachments
        deleteImagesIds: [],
        pdfs: [],
        deletePdfsIds: [],
        links: [{id: 0, value: ''}],
        deleteLinksIds: []
    }

handleChange=(e)=>{
    this.setState({
        [e.target.name]: e.target.value
    });
}

handleUpload=(e)=>{
    this.setState({
        cover_image: e.target.value,
        uploadImage: e.target.files[0]
    });
}

handleURLImages=(images)=>{
    this.setState({ images });        
}

handleURLPdfs=(pdfs)=>{
    this.setState({ pdfs });        
}

handleLinkChange=(e, index)=>{
    let { links } = this.state;
    links[index].value = e.target.value;
    this.setState({ links });
}

handleLinkAdd=()=>{
    let { links } = this.state;
    links.push({id: 0, value: ''});
    this.setState({ links });
}

handleLinkDelete=(index)=>{
    let { links } = this.state;
    if(links.length > 1){
        links.splice(index, 1);
        this.setState({ links });
    }
}

getCityId=(id)=>{
    this.setState({ city_id: id});
}

getEditorContent=(content)=>{
    this.setState({ content });
}

radioChange=(e)=>{
    this.setState(prev=>({
        free: !prev.free,
        pay: !prev.pay
    }));
    if(e.target.name === 'free' && e.target.value){
        this.setState({
            currency_id: 1,
            ticket_types: [{ id: 0, name: '', price: 0, max: 0 }],
            refund: ''
        });
    }
}

handleTicketAdd=()=>{
    let { ticket_types } = this.state;
    ticket_types.push({ id: 0, name: '', price: 0, max: 0 });
    this.setState({ ticket_types });
}
handleCoverImages=(cover_image)=>{

    this.setState({ 
        uploadImage:cover_image,
        cover_image:cover_image[0]});        
}
handleTicketDelete=(index)=>{
    let { ticket_types } = this.state;
    if(ticket_types.length > 1){
        ticket_types.splice(index,1);
        this.setState({ ticket_types });
    }
}

handleTicketChange=(e, index)=>{
    let { ticket_types } = this.state;
    if(e.target.name === 'max' && e.target.value !== '' && !isP0_integer.test(e.target.value)){
        alert(`You should input a positive integer or 0 in this choice!`);
    }
    else{
        ticket_types[index][e.target.name]=e.target.value;
        this.setState({ ticket_types });
    }
    e.preventDefault();
}

handleSubmit=(e)=>{
    const { eventId } = this.props;
    const { title, organizer, location, zipcode, city_id, type_id, cover_image, uploadImage, start, end, content, free, 
            currency_id, refund, ticket_types, deleteTicketsIds, images, pdfs, links, deleteLinksIds } = this.state;
    const { logInName } = this.props;
    if(city_id === 0){
        alert(`You must select a city. Try again, please.`);
    }
    else{
        const formData = new FormData();
        formData.append('username', logInName);
        formData.append('events_title', title);
        formData.append('organizer', organizer);
        formData.append('events_location', location);
        formData.append('events_postcode', zipcode);
        //If there is not date & time input, set the value with a standard value.
        this.state.start===''? formData.append('start_date', '1970-01-01'):formData.append('start_date', start); 
        this.state.end===''? formData.append('end_date', '1970-01-01'):formData.append('end_date', end); 
        formData.append('cover_image', cover_image);
        formData.append('image', uploadImage);
        formData.append('events_type', type_id);       
        formData.append('events_description', content);
        const costs = free? 0 : 1;
        formData.append('events_costs', costs); // According to it, we know whether save tickets info or not.
        formData.append('city_id', city_id);
        formData.append('currencies_id', currency_id);
        formData.append('refund_policy', refund);        
        formData.append('ticket_types', JSON.stringify(ticket_types));
        formData.append('deleteTicketsIds', JSON.stringify(deleteTicketsIds));

        // Handle images array to make two new arries, one for new images, another for images' ids that need deleting.
        const newImages = [];
        let { deleteImagesIds } = this.state;
        images.forEach(one=>{
            if(one.id === 0){
                newImages.push(one);
            }
            else if(deleteImagesIds.includes(one.id)){
                    deleteImagesIds=deleteImagesIds.filter(id=>id!==one.id);
            }
        });
        formData.append('images', JSON.stringify(newImages));
        formData.append('deleteImagesIds', JSON.stringify(deleteImagesIds));

        // Handle pdf files array to make two new arries, one for new files, another for files' ids that need deleting.
        const newPdfs = [];
        let { deletePdfsIds } = this.state;
        pdfs.forEach(one=>{
            if(one.id === 0){
                newPdfs.push(one);
            }
            else if(deletePdfsIds.includes(one.id)){
                    deletePdfsIds=deletePdfsIds.filter(id=>id!==one.id);
            }
        });
        formData.append('pdfs', JSON.stringify(newPdfs));
        formData.append('deletePdfsIds', JSON.stringify(deletePdfsIds));
        
        formData.append('links', JSON.stringify(links));
        formData.append('deleteLinksIds', JSON.stringify(deleteLinksIds));

        axios.put(apiRoot + "event/" + eventId, formData, {
                headers:{'Content-Type':'multipart/form-data'}
            })
            .then(res => {
                alert("One event was added!");
                this.setState({
                    title: '',
                    organizer: '',
                    location: '',
                    zipcode:'',
                    city_id: 0,
                    type_id: 1,
                    cover_image: '',
                    uploadImage: '',
                    start: '',
                    end: '',
                    content: '',
                    free: true,
                    pay: false,
                    currency_id: 1,
                    ticket_types: [{ id: 0, name: '', price: 0, max: 0 }],
                    deleteTicketsIds: [],
                    refund: '',
                    images: [], // for attachments
                    deleteImagesIds: [],
                    pdfs: [],
                    deletePdfsIds: [],
                    links: [{id: 0, value: ''}],
                    deleteLinksIds: []  
                });
            })
            .catch(err=>{ 
                console.log("[Error] - GET /events/update - at AddEvent component!");
                console.log(err);  
        });
    }
    e.preventDefault();
}

componentDidMount(){
    const { eventId } = this.props;
    axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
    axios.get(apiRoot + "event/" + eventId)
        .then((res) => {
            const currentOne = res.data[0];
            axios.get(apiRoot + "currencies")
                .then((res) => {
                    const currencies = res.data;
                    axios.get(apiRoot + "events/categories")
                        .then((res) => {
                            const types = res.data;
                            const start = currentOne.start_date.includes('1970')? '': getUTCTimeString(currentOne.start_date).replace('Z', '');
                            const end = currentOne.end_date.includes('1970')? '': getUTCTimeString(currentOne.end_date).replace('Z', '');
                            this.setState({ 
                                types,
                                currencies,
                                title: currentOne.events_title,
                                organizer: currentOne.organizer,
                                location: currentOne.events_location,
                                zipcode:currentOne.events_postcode,
                                city_id: currentOne.city_id,
                                type_id: currentOne.events_type,
                                cover_image: currentOne.cover_image,
                                start,
                                end,
                                content: currentOne.events_description,
                                currency_id: currentOne.currencies_id,
                                refund: currentOne.refund_policy                                
                            });
                            if(currentOne.events_costs === 1){
                                axios.get(apiRoot + "event/ticket/" + eventId)
                                    .then((res) => {
                                        const ticket_types=[];
                                        const deleteTicketsIds = [];
                                        res.data.forEach(one=>{
                                            const ticket = {
                                                id: one.id,
                                                name: one.tickets_name,
                                                price: one.tickets_price,
                                                max: one.tickets_max
                                            }
                                            ticket_types.push(ticket);
                                            deleteTicketsIds.push(one.id);
                                        });
                                        if(ticket_types.length === 0) ticket_types.push({ id: 0, name: '', price: 0, max: 0 });
                                        this.setState({ 
                                            ticket_types,
                                            deleteTicketsIds,
                                            free: false,
                                            pay: true
                                        })
                                    })
                                    .catch((err) => { 
                                        console.log("[Error] - GET /events/ticket/eventId - at UpdteEvent component!");
                                        console.log(err);            
                                });
                            }
                            axios.get(apiRoot + "event/attach/" + eventId)
                                .then((res) => {
                                    const images = [];
                                    const deleteImagesIds = [];
                                    const pdfs = [];
                                    const deletePdfsIds = [];
                                    const links = [];
                                    const deleteLinksIds = [];
                                    res.data.forEach(one=>{
                                        if(one.iMediaType === 1){
                                            images.push({id: one.id, value: one.file_url});
                                            deleteImagesIds.push(one.id);
                                        }
                                        else if(one.iMediaType === 2){
                                            pdfs.push({id: one.id, value: one.file_url});
                                            deletePdfsIds.push(one.id);
                                        }
                                        else{
                                            links.push({id: one.id, value: one.file_url});
                                            deleteLinksIds.push(one.id);
                                        }
                                    });
                                    if(links.length === 0) links.push({id: 0, value: ''});
                                    this.setState({ images, deleteImagesIds, pdfs, deletePdfsIds, links, deleteLinksIds });
                                })
                                .catch((err) => { 
                                    console.log("[Error] - GET /events/attach/eventId - at UpdteEvent component!");
                                    console.log(err);            
                            });
                        })
                        .catch((err) => { 
                            console.log("[Error] - GET /events/categories - at UpdteEvent component!");
                            console.log(err);            
                    });
                })
                .catch((err) => { 
                    console.log("[Error] - GET /currencies - at UpdteEvent component!");
                    console.log(err);           
            });
        })
        .catch((err) => { 
            console.log("[Error] - GET /events/eventId - at UpdteEvent component!");
            console.log(err);          
    });
}

render(){
    let lang='';
    if(this.props.langState ==='cn') lang=LANGUAGE.UpdateEvent.cn;
    else lang=LANGUAGE.UpdateEvent.en;
    const { eventId } = this.props;
    const { types, currencies, title, organizer, location, zipcode, city_id, type_id, start,
            end, content, free, pay, currency_id, ticket_types, refund, cover_image,images, pdfs, links } = this.state;
    
    // If not free for events, show it to save tickets.
    console.log("cover_image is  "+cover_image);
    let tickets_table='';
    let rows=[];
    if(pay){
        ticket_types.forEach((one, index)=>{
            rows.push(
                <tr key={index} style={{verticalAlign: 'middle'}}>
                    <th scope="row" style={{verticalAlign: 'middle'}}>
                        <input name="name" type="text" className="form-control" value={one.name} onChange={(e)=>this.handleTicketChange(e,index)}/>
                    </th>
                    <td style={{verticalAlign: 'middle'}}>
                        <input name="price" type="number" min="0" className="form-control" value={one.price} onChange={(e)=>this.handleTicketChange(e,index)}/>
                    </td>
                    <td style={{verticalAlign: 'middle'}}>
                        <input name="max" type="number" min="0" className="form-control" value={one.max} onChange={(e)=>this.handleTicketChange(e,index)}/>
                    </td>
                    <td style={{verticalAlign: 'middle'}}>
                        <div className="btn-group">
                            <button id="add" type="button" className="btn btn-primary btn-sm rounded mr-2" title={lang[17]} onClick={this.handleTicketAdd}><i className="fas fa-plus-square"></i></button>
                            <button type="button" className="btn btn-danger btn-sm rounded" onClick={()=>{this.handleTicketDelete(index);}} title={lang[23]}><i className="fas fa-trash-alt"></i></button>
                        </div>
                        
                    </td>
                </tr>
            );                
        });
        tickets_table=(
            <div>                    
                <div className="form-group row">
                    <h5 className="col-sm-2" >{lang[16]}</h5>
                </div>                
                <div className="form-group row">
                    <label className="col-sm-1 col-form-label" htmlFor="currency">{lang[18]}</label>
                    <div className="col-sm-2">
                        <select name="currency_id" className="custom-select mr-sm-2" id="currency" value={currency_id} onChange={this.handleChange}>
                            {currencies.map((one, index) => <option key={index} value={one.currencies_id}>{one.name}</option> )}
                        </select>
                    </div>
                </div>
                <div className="form-row">                        
                    <div className="form-group col-md-12">
                        <div className="table-responsive rounded">
                            <table className="table table-hover table-bordered text-center">
                                <thead>
                                    <tr className="table-primary">
                                        <th scope="col">{lang[19]}</th>
                                        <th scope="col">{lang[20]}</th>
                                        <th scope="col">{lang[21]}</th>
                                        <th scope="col">{lang[22]}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rows}                                
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="form-row">                        
                    <div className="form-group col-md-12">
                        <label htmlFor="refund" className="col-form-label">{lang[24]}</label>
                        <textarea name="refund" className="form-control" id="refund" rows="5" value={refund} onChange={this.handleChange}/>
                    </div>
                </div>
            </div>
        );
    }

    // For uploading Links
    let link_rows=[];
    links.forEach((one, index)=>{
        link_rows.push(
            <div key={index} className="input-group mb-3">
                <input type="url" className="form-control" value={one.value} onChange={(e)=>this.handleLinkChange(e, index)}/>
                <div className="input-group-append">
                    <button className="btn btn-primary rounded" type="button" onClick={this.handleLinkAdd}><i className="fas fa-plus"></i></button>
                    <button className="btn btn-danger rounded" type="button" onClick={()=>{this.handleLinkDelete(index)}}><i className="fas fa-minus"></i></button>
                </div>
            </div>
        );
    })
    
    return(
        <div className="container-fluid">
            <div className="card rounded">
                <div className="card-header"> 
                        <div className="form-group col-md-8">
                            <h5>{lang[0]}</h5>
                        </div>
                        <div className="text-right col-md-4">
                                <Link to="/events" className="btn btn-primary btn-sm rounded" title={lang[1]}><i className="fas fa-arrow-alt-circle-right"></i></Link>
                                <br/>
                        </div>
                </div>
                <div className="card-body">
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-row">          
                            <div className="form-group col-md-6">
                                <label htmlFor="title">{lang[2]}</label>
                                <input name="title" type="text" className="form-control" id="title" value={title}  onChange={this.handleChange} required/>
                            </div>
                            <div className="form-group col-md-2">
                                <label htmlFor="type">{lang[7]}</label>
                                <select name="type_id" className="custom-select mr-sm-2" id="type" value={type_id} onChange={this.handleChange}>
                                    {types.map((one, index) => <option key={index} value={one.categories_id}>{one.name}</option> )}
                                </select>
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="organizer">{lang[3]}</label>
                                <input name="organizer" type="text" className="form-control" id="organizer" value={organizer} onChange={this.handleChange}/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-5">
                                <label htmlFor="location">{lang[4]}</label>
                                <input name="location" type="text" className="form-control" id="location" value={location} onChange={this.handleChange}/>
                            </div>
                            <div className="form-group col-md-2">
                                <label htmlFor="zipcode">{lang[5]}</label>
                                <input name="zipcode" type="text" className="form-control" id="zipcode" value={zipcode} onChange={this.handleChange}/>
                            </div>
                            <div className="form-group col-md-5">
                                <label htmlFor="city">{lang[6]}</label>
                                <SelectLocation
                                    cityId={city_id}
                                    getCityId={this.getCityId}
                                />
                            </div>
                        </div>
                        <div className="form-row row-panel">

                            <div className="form-group col-md-3">
                                <label htmlFor="event_image_add">{lang[8]}</label>
                               
                                { <input name="cover_image" type="file" className="form-control" id="event_image_add" 
                                accept={ IMAGE.toString() } onChange={this.handleUpload}/> }
<br/>
                                <img src={apiRoot + 'display/image/file?file=' +cover_image} width="100" />
                                    {/* <UploadWallNoCrop
                                        some={1}
                                        listType="picture-card"
                                        load={ [{id:eventId,value:cover_image}] }
                                        urls={this.handleCoverImages}
                                        format={ IMAGE.toString() }
                                    />    */}
                            </div>
                            <div className="form-group col-md-9">
                                <label >{lang[9]}</label><br/>
                                <div className="small-note-text">
* 这里的图片，文件是作为内容的扩展，可以允许8个以下的图片或者文件，如PDF,DOC,DOCX
<br/>
<br/>
</div>

<div className="float-left col-md-9">
                                        
                                        <div className="form-group">

<div className="col-md-6">
<label >图片</label><br/>
<UploadWallNoCrop
        some={8}
        listType="picture-card"
        load={ images }
        urls={this.handleURLImages}
        format={ IMAGE.toString() }
    />
</div>
<div className="col-md-6">
<label >文档文件</label><br/>
<UploadWallNoCrop
        some={8}
        listType="text"
        load={ pdfs } 
        urls={this.handleURLPdfs}
        format={ DOCUMENT.toString() }
    />

</div>
                                 
                                    
                                    
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-row">          
                            <div className="form-group col-md-6">
                                <label htmlFor="start">{lang[10]}</label>
                                <input name="start" type="datetime-local" className="form-control" id="start" value={start}  onChange={this.handleChange} title="Format:2018-08-18 18:18:18.000"/>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="end">{lang[11]}</label>
                                <input name="end" type="datetime-local" className="form-control" id="end" value={end} onChange={this.handleChange} title="Format:2018-08-18 18:18:18.000"/>
                            </div>
                        </div>
                        <div className="form-group">
                                <label>{lang[12]}</label>
                                <EmbeddedEditor 
                                    content={content}
                                    getContent={this.getEditorContent}
                                />
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-1">
                                <label htmlFor="end">{lang[13]}</label>
                            </div>
                            <div className="form-group col-md-11">
                                <div className="form-check form-check-inline">
                                    <input name="free" className="form-check-input" type="radio" id="inlineRadio1" checked={free} value="1" onChange={this.radioChange}/>
                                    <label className="form-check-label" htmlFor="inlineRadio1">{lang[14]}</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input name="pay" className="form-check-input" type="radio" id="inlineRadio2" checked={pay} value="2" onChange={this.radioChange}/>
                                    <label className="form-check-label" htmlFor="inlineRadio2">{lang[15]}</label>
                                </div>
                            </div>
                        </div>
                        {tickets_table}
                        <div className="btn-group">
                            <button type="submit" className="btn btn-success btn-sm mr-2 rounded" title={lang[25]}><i className="fas fa-save"></i></button>
                            <Link to="/events" className="btn btn-primary btn-sm rounded" title={lang[1]}><i className="fas fa-arrow-alt-circle-right"></i></Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );        
}
}

export default UpdateEvent;