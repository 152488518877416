/** 
 * @desc API Root configuration file
 **/

//const apiRoot="http://localhost:8088/";
//const apiV2Root="https://testapi.happiify.me/api";
//const apiRoot="https://mapi.happiify.me/";
//const apiRoot="https://mgr.happiify.me/api/";

const apiRoot="https://mgr.happiify.me/api/";

// const apiRoot="http://127.0.0.1:8088/";
export default apiRoot;