import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Link} from 'react-router-dom';
import LANGUAGE from '../../../service/Language-data';
import apiRoot from '../../../config.api';

import SearchBar from '../../../service/components/SearchBar';
import PagingBar from '../../../service/components/PagingBar';

class ProductBrand extends Component{
    state={
        allMappingList: [],
        subjectList:[],
        searchContent:'',     // Accept the value of "change"; Used for search after the button is clicked.
        searchChange:'',      // Accept the value changed in "input".
        currentPage: 1,
        };

    static propTypes={
        langState: PropTypes.string.isRequired
    };

    handleSearchContent=(e)=>{
        this.setState({
            searchChange: e.target.value
        });
    };
    
    handleSearchSubmit=(e)=>{
        this.setState({
            searchContent: this.state.searchChange,
            currentPage: 1,        
        });
        e.preventDefault();
    }

    handlePageChange=(e)=>{
        this.setState({
            currentPage: +e.target.value
        });
    }

      

    handleClickUpdate=(e)=>{
        this.props.clickUpdate(e);        
    }
    handleClickDelete=(e,id)=>{
       // console.log(e);
        if(window.confirm(`Do you really want to delete No. ${id} Brand?`)){
            axios.put(apiRoot + "products/brands/delete", {id})
            .then(res => {
                alert("You have deleted a Brand.");
                axios.get(apiRoot + "products/loadbrands")
                .then((res) => {     
                    this.setState({ subjectList: res.data });
                })
                .catch((err) => {            
                    console.log("Getting videos error in VideoManagement component!"+err);            
                });
            })
            .catch(err=>{
                console.log("Deleting videos error in VideoManagement component!" +err);
        });
        e.preventDefault();
        }
    }


    componentDidMount(){
        axios.defaults.headers.common['x-access-token'] = localStorage.getItem("happiify_token");
        axios.get(apiRoot + "products/loadbrands")
            .then((res) => {
                this.setState({ subjectList: res.data });
            })
            .catch((err) => {
                // console.log("[Error] - GET /products/attribute_groups - at ProductBrand component!");
                console.log(err);            
        });

    }

    render(){
        let lang='';
        if(this.props.langState ==='cn') lang=LANGUAGE.ProductBrand.cn;
        else lang=LANGUAGE.ProductBrand.en;

        const {subjectList, searchContent, currentPage} = this.state;

        const row=[];
        subjectList.forEach((one, index)=>{            
            if( (one.brands_name.toUpperCase().indexOf(searchContent.toUpperCase())===-1)){
                return;
            }
            row.push(
                <tr key={index} >
                    
                    <td style={{verticalAlign: 'middle'}} className="text-center">{one.id}</td>
                    <td style={{verticalAlign: 'middle'}} >
                    {(()=>{
                            switch(one.brands_logo.slice(0,4)){
                                case '':        return (<span>{'No Image'}</span>);
                                case 'http':    return (<img src={one.brands_logo} style={{height: '50px'}} alt={index}/>);
                                default:        return (<a href={apiRoot + 'display/image/file?file=' + one.brands_logo}>
                                                            <img src={apiRoot + 'display/image/file?file=' + one.brands_logo} style={{height: '50px'}} alt={index}/>
                                                        </a>)
                            }
                        })()}                    
                    </td>
                    <td style={{verticalAlign: 'middle'}} >{one.brands_name}</td>
                    <td style={{verticalAlign: 'middle'}} className="text-center">
                        <Link to="/products/brand/update" className="btn btn-success mr-2 btn-sm rounded" onClick={()=>this.handleClickUpdate(one.id)} title={lang[5]}><i className="fas fa-pencil-alt"></i></Link>
                        <button type="button" className="btn btn-danger btn-sm rounded" onClick={(e)=>this.handleClickDelete(e,one.id)} title={lang[9]}><i className="fas fa-trash-alt"></i></button>
                    </td>
                </tr>
            );
        });

        const totalPages=Math.ceil(row.length/10);
        const onePage=row.reverse().slice((currentPage*10-10), (currentPage*10));

        return(
            <div className="container-fluid">
                <div className="card rounded">
                    <div className="card-header">
                        <span style={{fontSize: "1.4em"}}>{lang[0]}</span>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <div className="float-left">
                                    <SearchBar 
                                        langState={this.props.langState}
                                        searchContent={this.handleSearchContent}
                                        searchSubmit={this.handleSearchSubmit} />
                                </div>
                            </div>
                            <div className="col">
                                <div className="btn-group float-right">
                                    <Link to='/products/brand/add' className="btn btn-primary btn-sm rounded mr-2" title={lang[1]}><i className="fas fa-plus-square"></i></Link>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive rounded">
                            <table className="table table-hover table-bordered">
                                <thead>
                                    <tr className="table-primary">
                                        
                                        <th scope="col" style={{width: "5%"}} className="text-center">#</th>
                                        <th scope="col" style={{width: "40%"}}>{lang[7]}</th>
                                        <th scope="col" style={{width: "40%"}}>{lang[5]}</th>
                                        <th scope="col" style={{width: "15%"}} className="text-center">{lang[6]}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {onePage}
                                </tbody>
                            </table>
                        </div>
                        <PagingBar 
                            totalPages={totalPages}                     
                            currentPage={this.state.currentPage}
                            pageChange={this.handlePageChange} 
                            />
                    </div>
                </div>            
            </div>
        );
    }
}

export default ProductBrand;