import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Link} from 'react-router-dom';
import LANGUAGE from '../../../service/Language-data';
import apiRoot from '../../../config.api';

import SearchBar from '../../../service/components/SearchBar';
import PagingBar from '../../../service/components/PagingBar';
import { getAllTopicThreads, getAllTopicThreadImages, deleteTopicsThreads } from '../../../service/axios-service';
import UploadWallNoCrop from '../../../service/components/UploadWallNoCrop';
import { IMAGE } from '../../../service/media-type';

class ThreadManagement extends Component{
    state = {
        threads: [],
        allImages: [],
        images: [],
        image_count: 0,
        searchContent:'',     // Accept the value of "change"; Used for search after the button is clicked.
        searchChange:'',      // Accept the value changed in "input".
        currentPage: 1,
        checkedArr: [],
        checkedIds: [],
        checkedAll: false
        };

    static propTypes={
        langState: PropTypes.string.isRequired
    };

    handleSearchContent=(e)=>{
        this.setState({
            searchChange: e.target.value
        });
    };
    
    handleSearchSubmit=(e)=>{
        this.setState({
            searchContent: this.state.searchChange,
            currentPage: 1,        
        });
        e.preventDefault();
    }

    handlePageChange=(e)=>{
        this.setState({
            currentPage: +e.target.value
        });
    }

    handleChecked=(e,i)=>{
        let {checkedArr} = this.state;
        checkedArr[i] = !checkedArr[i];

        let id = e.target.value;
        let ids = this.state.checkedIds;
        let index = ids.indexOf(+id);
        e.target.checked === true? ids.push(+id) : ids.splice(index,1);
        this.setState({ 
            checkedIds: ids,
            checkedArr 
        });
    }

    handleCheckedAll=(e)=>{
        const { checkedArr,threads } = this.state;
        let tempArr=[];
        let ids = [];
        if(e.target.checked){
            checkedArr.forEach(one=> tempArr.push(!one));
            threads.forEach((one, index) => { if(tempArr[index]) ids.push(+one.id)});
        }
        else{
            checkedArr.forEach(one=> tempArr.push(false));
        }
        this.setState({
            checkedArr : tempArr,
            checkedIds : ids,
            checkedAll : e.target.checked
        });
    }

    showImages=(id)=>{
        const { allImages } = this.state;
        const images=[];
        allImages.forEach(one=>{
            if(one.topic_thread_id === id){
                const oneImage = { id: one.id, value: one.media_url }
                images.push(oneImage);
            }
        });
        const image_count = images.length;
        this.setState({ images, image_count });
    }

    handleClose=()=>{
         this.setState({ 
             images: [], 
             image_count: 0 
        });
    }

    handleClickDelete=(e)=>{
        const {checkedIds} = this.state;
        const idsString=checkedIds.toString();
        if(checkedIds.length===0){
            alert(`You did NOT select any item!`);
        }
        else{
            if(window.confirm(`Do you really want to delete No. ${idsString} ${checkedIds.length>1? 'threads':'thread'}?`)){
                deleteTopicsThreads({checkedIds})
                    .then(res => {
                        alert(`You have deleted ${checkedIds.length>1? checkedIds.length +' threads':'a thread'}.`);
                        getAllTopicThreads()
                            .then((res) => {                   
                                this.setState({ threads: res.data });                                
                                let tempArr = [];
                                this.state.checkedArr.forEach(()=> tempArr.push(false));
                                this.setState({
                                    checkedArr: tempArr,
                                    checkedIds: [],
                                    checkedAll: false
                                }); 
                            })
                            .catch((err) => { 
                                console.log("[Error] - At ThreadManagement/handleClickDelete/getAllTopicThreads!");
                                console.log(err);
                            });
                    })
                    .catch(err=>{ 
                        console.log("[Error] - At ThreadManagement/handleClickDelete/deleteTopicThreads!");
                        console.log(err);
                });
            }
        }
        e.preventDefault();
    }

    handleClickUpdate=(threadId, topicId)=>{
        this.props.clickUpdate(threadId, topicId);        
    }

    componentDidMount(){
        axios.all([
            getAllTopicThreads(), 
            getAllTopicThreadImages()
        ]).then(axios.spread((r1,r2)=>{
            let checkedArr=[];
            r1.data.forEach(one=>{
                checkedArr.push( false );
            });
            
            this.setState({ 
                checkedArr, 
                threads: r1.data,
                allImages: r2.data
            });
        })).catch((err) => { 
            console.log("[Error] - At ThreadManagement/componentDidMount!");
            console.log(err);            
        }); 
    }

    render(){
        let lang='';
        if(this.props.langState ==='cn') lang=LANGUAGE.ThreadManagement.cn;
        else lang=LANGUAGE.ThreadManagement.en;

        const {threads, searchContent, currentPage, allImages, images, image_count} = this.state;
        //const { permissions } = this.props;

        const row=[];
        threads.forEach((one, index)=>{            
            if( (String(one.posts_id).toUpperCase().indexOf(searchContent.toUpperCase())===-1)&&
                (one.username.toUpperCase().indexOf(searchContent.toUpperCase())===-1)&&
                (one.contents.toUpperCase().indexOf(searchContent.toUpperCase())===-1)){
                return;
            }
            const imageCount = allImages.filter(two=>two.topic_thread_id===one.id).length
            const imageLink = imageCount>0? (<a href="0" data-toggle="modal" data-target="#images" data-backdrop="false" onClick={()=>{this.showImages(one.id)}}>{imageCount}</a>) :imageCount;
            row.push(
                <tr key={index} >
                    <td style={{verticalAlign: 'middle'}} className="text-center"><input type="checkbox" name="checkbox" checked={this.state.checkedArr[index]} value={one.id} onChange={(e)=>this.handleChecked(e, index)}/></td>
                    <td style={{verticalAlign: 'middle'}} className="text-center">{one.id}</td>
                    <td style={{verticalAlign: 'middle'}} className="text-center">{one.posts_id}</td>
                    <td style={{verticalAlign: 'middle'}} className="text-center">
                        {(()=>{
                            if(one.cover_image === null) one.cover_image='';
                            switch(one.cover_image.slice(0,4)){
                                case '':        return (<span>{'No Image'}</span>);
                                case 'http':    return (<img src={one.cover_image} style={{height: '60px'}} alt={index}/>);
                                default:        return (<a href={apiRoot + 'display/image/file?file=' + one.cover_image}>
                                                            <img src={apiRoot + 'display/image/file?file=' + one.cover_image} style={{height: '60px'}} alt={index}/>
                                                        </a>)
                            }
                        })()}
                    </td>
                    <td style={{verticalAlign: 'middle'}} className="text-center" >{one.username}</td>
                    <td style={{verticalAlign: 'middle'}} >{one.contents}</td>
                    <td style={{verticalAlign: 'middle'}} className="text-center" >{imageLink} </td>
                    <td style={{verticalAlign: 'middle'}} className="text-center" >{one.create_time.slice(0, one.create_time.indexOf('T'))}</td>
                    {
                        //permissions[7][2].value || permissions[7][3].value?
                        <td style={{verticalAlign: 'middle'}} className="text-center">
                            <div className="btn-group">                                
                                {
                                    //permissions[7][2].value?
                                    <Link to='/topics/threads/add' className="btn btn-primary btn-sm rounded mr-2" onClick={()=>this.handleClickUpdate(one.id, one.posts_id)} title={lang[11]}><i className="fas fa-plus-square"></i></Link>//:null
                                }
                                {
                                    //permissions[7][3].value?
                                    <Link to="/topics/threads/update" className="btn btn-success btn-sm rounded mr-2" onClick={()=>this.handleClickUpdate(one.id, one.posts_id)} title={lang[12]}><i className="fas fa-pencil-alt"></i></Link>//:null
                                }
                            </div>
                        </td>//:null
                    }
                </tr>
            );
        });

        const totalPages=Math.ceil(row.length/10);
        const onePage=row.reverse().slice((currentPage*10-10), (currentPage*10));
        return(
            <div className="container-fluid">
                <div className="card rounded">
                    <div className="card-header">
                        <span style={{fontSize: "1.4em"}}>{lang[0]}</span>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <div className="float-left">
                                    <SearchBar 
                                        langState={this.props.langState}
                                        searchContent={this.handleSearchContent}
                                        searchSubmit={this.handleSearchSubmit} />
                                </div>
                            </div>
                            {
                                //permissions[7][4].value?
                                <div className="col">
                                    <div className="btn-group float-right">
                                        {
                                            //permissions[7][4].value?
                                            <button type="button" className="btn btn-danger btn-sm rounded mr-2" onClick={this.handleClickDelete} title={lang[1]}><i className="fas fa-trash-alt"></i></button>//:null
                                        }                                 
                                        {
                                            //permissions[7][2].value?
                                            <Link to='/topics' className="btn btn-primary btn-sm rounded" title={lang[2]}><i className="fas fa-arrow-alt-circle-right"></i></Link>//:null
                                        }
                                    </div>
                                </div>//:null
                            }
                            
                        </div>
                        
                        <div className="table-responsive rounded">
                            <table className="table table-hover table-bordered">
                                <thead>
                                    <tr className="table-primary">
                                        <th scope="col" style={{width: "5%"}} className="text-center"><input checked={this.state.checkedAll} type="checkbox" onChange={this.handleCheckedAll}/></th>
                                        <th scope="col" style={{width:"5%"}} className="text-center">{lang[3]}</th>
                                        <th scope="col" style={{width:"5%"}} className="text-center">{lang[4]}</th>
                                        <th scope="col" style={{width:"10%"}} className="text-center">{lang[5]}</th>
                                        <th scope="col" style={{width:"10%"}} className="text-center">{lang[6]}</th>
                                        <th scope="col" style={{width:"35%"}} className="text-center">{lang[7]}</th>
                                        <th scope="col" style={{width:"10%"}} className="text-center">{lang[8]}</th>
                                        <th scope="col" style={{width:"10%"}} className="text-center">{lang[9]}</th>
                                        {
                                            //permissions[7][3].value || permissions[7][5].value?
                                            <th scope="col" style={{width:"10%"}} className="text-center">{lang[10]}</th>//:null
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {onePage}
                                </tbody>
                            </table>                
                        </div>

                        <PagingBar 
                            totalPages={totalPages}                     
                            currentPage={this.state.currentPage}
                            pageChange={this.handlePageChange} 
                            />
                        {/*Model for displaying images.*/}
                        <div className="modal fade" id="images" tabIndex="-1" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="images">{lang[13]}</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <UploadWallNoCrop
                                            some={image_count}
                                            listType="picture-card"
                                            load={ images }
                                            urls={this.handleURLImages}
                                            format={ IMAGE.toString() }
                                            remove={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>            
            </div>
        );
    }
}

export default ThreadManagement;