import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Link} from 'react-router-dom';
import LANGUAGE from '../../../service/Language-data';
import apiRoot from '../../../config.api';

import SearchBar from '../../../service/components/SearchBar';
import PagingBar from '../../../service/components/PagingBar';
import { getAllTopics, deleteTopics, getAllTopicTagMapping, getTopicsThreadsCount } from '../../../service/axios-service';
import { propArray } from '../../../service/func-service';

class TopicManagement extends Component{
    state = {
        subjectList:[],
        searchContent:'',     // Accept the value of "change"; Used for search after the button is clicked.
        searchChange:'',      // Accept the value changed in "input".
        currentPage: 1,
        checkedArr: [],
        checkedIds: [],
        checkedAll: false,
        tags: []
        };

    static propTypes={
        langState: PropTypes.string.isRequired
    };

    handleSearchContent=(e)=>{
        this.setState({
            searchChange: e.target.value
        });
    };
    
    handleSearchSubmit=(e)=>{
        this.setState({
            searchContent: this.state.searchChange,
            currentPage: 1,        
        });
        e.preventDefault();
    }

    handlePageChange=(e)=>{
        this.setState({
            currentPage: +e.target.value
        });
    }

    handleChecked=(e,i)=>{
        let {checkedArr} = this.state;
        checkedArr[i] = !checkedArr[i];

        let id = e.target.value;
        let ids = this.state.checkedIds;
        let index = ids.indexOf(+id);
        e.target.checked === true? ids.push(+id) : ids.splice(index,1);
        this.setState({ 
            checkedIds: ids,
            checkedArr 
        });
    }

    handleCheckedAll=(e)=>{
        const { checkedArr,subjectList } = this.state;
        let tempArr=[];
        let ids = [];
        if(e.target.checked){
            checkedArr.forEach(one=> tempArr.push(!one));
            subjectList.forEach((one, index) => { if(tempArr[index]) ids.push(+one.id)});
        }
        else{
            checkedArr.forEach(one=> tempArr.push(false));
        }
        this.setState({
            checkedArr : tempArr,
            checkedIds : ids,
            checkedAll : e.target.checked
        });
    }

    handleClickDelete=(e)=>{
        const {checkedIds} = this.state;
        const idsString=checkedIds.toString();        
        getTopicsThreadsCount({checkedIds})
        .then(res=>{
            const count = res.data[0].count;
            console.log(count)
            if(checkedIds.length===0){
                alert(`You did NOT select any item!`);
            }
            else{
                if(window.confirm(`Do you really want to delete No. ${idsString} ${checkedIds.length>1? 'topics':'topic'}?`)){
                    if(count !== 0 && checkedIds.length>1) 
                        alert(`There is at least one of your selected topics which has follow-up comment. Only the topic which does not have any follow-up comments can be deleted. Please try again.`);
                    else if(count !== 0 && checkedIds.length===1)
                        alert(`The selected topic has follow-up comments. Only the topic which does not have any follow-up comments can be deleted.`);
                    else{
                        deleteTopics({checkedIds})
                            .then(res => {
                                alert(`You have deleted ${checkedIds.length>1? checkedIds.length +' topics':'a topic'}.`);
                                getAllTopics()
                                    .then((res) => {                   
                                        this.setState({ subjectList: res.data });
                                        let tempArr = [];
                                        this.state.checkedArr.forEach(()=> tempArr.push(false));
                                        this.setState({
                                            checkedArr: tempArr,
                                            checkedIds: [],
                                            checkedAll: false
                                        });  
                                    })
                                    .catch((err) => { 
                                        console.log("[Error] - At TopicManagement/handleClickDelete/getAllTopics!");
                                        console.log(err);
                                    });
                            })
                            .catch(err=>{ 
                                console.log("[Error] - At TopicManagement/handleClickDelete/deleteTopics!");
                                console.log(err);
                        });
                    }
                }
            }
        }).catch(err=>{ 
            console.log("[Error] - At TopicManagement/handleClickDelete/getTopicsThreadsCount!");
            console.log(err);
        })
    e.preventDefault();
    }

    handleClickUpdate=(id)=>{
        this.props.clickUpdate(id);        
    }

    componentDidMount(){
        axios.all([
            getAllTopics(),
            getAllTopicTagMapping()
        ]).then(axios.spread((r1,r2)=>{
            let checkedArr=[];
            let obj_tags=[];
            r1.data.forEach(one=>{
                checkedArr.push( false );
                obj_tags.push(r2.data.filter(two=>two.topics_id === one.id));
            });
            let tags=[]
            obj_tags.forEach(one=>tags.push(propArray(one, 'tags_name').join('/')));
            
            this.setState({ 
                checkedArr, 
                subjectList: r1.data,
                tags 
            });
        })).catch((err) => { 
            console.log("[Error] - At TopicManagement/componentDidMount!");
            console.log(err);            
        }); 
    }

    render(){
        let lang='';
        if(this.props.langState ==='cn') lang=LANGUAGE.TopicManagement.cn;
        else lang=LANGUAGE.TopicManagement.en;

        const {subjectList, searchContent, currentPage, tags} = this.state;
        //const { permissions } = this.props;

        const row=[];
        subjectList.forEach((one, index)=>{            
            if( (String(one.id).toUpperCase().indexOf(searchContent.toUpperCase())===-1)&&
                (one.username.toUpperCase().indexOf(searchContent.toUpperCase())===-1)&&
                (String(one.total_views).toUpperCase().indexOf(searchContent.toUpperCase())===-1)&&
                (String(one.total_answers).toUpperCase().indexOf(searchContent.toUpperCase())===-1)&&
                (one.create_time.slice(0, one.create_time.indexOf('T')).toUpperCase().indexOf(searchContent.toUpperCase())===-1)){
                return;
            }
            row.push(
                <tr key={index} >
                    <td style={{verticalAlign: 'middle'}} className="text-center"><input type="checkbox" name="checkbox" checked={this.state.checkedArr[index]} value={one.id} onChange={(e)=>this.handleChecked(e, index)}/></td>
                    <td style={{verticalAlign: 'middle'}} className="text-center">{one.id}</td>
                    <td style={{verticalAlign: 'middle'}} className="text-center">{tags[index]}</td>
                    <td style={{verticalAlign: 'middle'}} className="text-center">
                        {(()=>{
                            if(one.cover_image === null) one.cover_image='';
                            switch(one.cover_image.slice(0,4)){
                                case '':        return (<span>{'No Image'}</span>);
                                case 'http':    return (<img src={one.cover_image} style={{height: '60px'}} alt={index}/>);
                                default:        return (<a href={apiRoot + 'display/image/file?file=' + one.cover_image}>
                                                            <img src={apiRoot + 'display/image/file?file=' + one.cover_image} style={{height: '60px'}} alt={index}/>
                                                        </a>)
                            }
                        })()}
                    </td>
                    <td style={{verticalAlign: 'middle'}} >{one.username}</td>
                    <td style={{verticalAlign: 'middle'}} >{one.total_views}</td>
                    <td style={{verticalAlign: 'middle'}} >{one.total_answers}</td>
                    <td style={{verticalAlign: 'middle'}} >{one.create_time.slice(0, one.create_time.indexOf('T'))}</td>
                    {
                        //permissions[7][3].value || permissions[7][5].value?
                        <td style={{verticalAlign: 'middle'}} className="text-center">
                            <div className="btn-group">
                                {
                                    //permissions[7][3].value?
                                    <Link to="/topics/update" className="btn btn-success btn-sm rounded mr-2" onClick={()=>this.handleClickUpdate(one.id)} title={lang[11]}><i className="fas fa-pencil-alt"></i></Link>//:null
                                }
                            </div>
                        </td>//:null
                    }
                </tr>
            );
        });

        const totalPages=Math.ceil(row.length/10);
        const onePage=row.reverse().slice((currentPage*10-10), (currentPage*10));

        return(
            <div className="container-fluid">
                <div className="card rounded">
                    <div className="card-header">
                        <span style={{fontSize: "1.4em"}}>{lang[0]}</span>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <div className="float-left">
                                    <SearchBar 
                                        langState={this.props.langState}
                                        searchContent={this.handleSearchContent}
                                        searchSubmit={this.handleSearchSubmit} />
                                </div>
                            </div>
                            {
                                //permissions[7][2].value || permissions[7][4].value?
                                <div className="col">
                                    <div className="btn-group float-right">
                                        {
                                            //permissions[7][2].value?
                                            <Link to='/topics/add' className="btn btn-primary btn-sm rounded mr-2" title={lang[1]}><i className="fas fa-plus-square"></i></Link>//:null
                                        }
                                        {
                                            //permissions[7][4].value?
                                            <button type="button" className="btn btn-danger btn-sm rounded" onClick={this.handleClickDelete} title={lang[2]}><i className="fas fa-trash-alt"></i></button>//:null
                                        } 
                                    </div>
                                </div>//:null
                            }
                            
                        </div>
                        
                        <div className="table-responsive rounded">
                            <table className="table table-hover table-bordered text-center">
                                <thead>
                                    <tr className="table-primary">
                                        <th scope="col" style={{width: "5%"}} className="text-center"><input checked={this.state.checkedAll} type="checkbox" onChange={this.handleCheckedAll}/></th>
                                        <th scope="col" style={{width:"5%"}}>{lang[3]}</th>
                                        <th scope="col" style={{width:"15%"}}>{lang[4]}</th>
                                        <th scope="col" style={{width:"15%"}}>{lang[5]}</th>
                                        <th scope="col" style={{width:"15%"}}>{lang[6]}</th>
                                        <th scope="col" style={{width:"10%"}}>{lang[7]}</th>
                                        <th scope="col" style={{width:"10%"}}>{lang[8]}</th>
                                        <th scope="col" style={{width:"15%"}}>{lang[9]}</th>
                                        {
                                            //permissions[7][3].value || permissions[7][5].value?
                                            <th scope="col" style={{width:"10%"}}>{lang[10]}</th>//:null
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {onePage}
                                </tbody>
                            </table>                
                        </div>

                        <PagingBar 
                            totalPages={totalPages}                     
                            currentPage={this.state.currentPage}
                            pageChange={this.handlePageChange} 
                            />
                    </div>
                </div>            
            </div>
        );
    }
}

export default TopicManagement;