const VIDEO = ['.mp4', '.mov'];
const AUDIO = ['.mp3', '.m4a'];
const IMAGE = [ // 27 items
    '.webp', '.bmp', '.pcx',  '.tif', '.gif', '.jpg', '.jpeg', '.jpeg2000', 
    '.tga', '.exif', '.fpx', '.svg', '.psd', '.cdr', '.pcd', '.dxf', '.ufo',
    '.eps', '.ai', '.png', '.hdri', '.raw', '.wmf', '.flic', '.emf', '.ico', '.swf'];
const DOCUMENT = [
    '.wps', '.doc', '.rtf', '.txt', '.docx', '.xml', '.html', '.mht', '.mhtml', '.htm', '.uof', '.pdf', // Related to WORD
    '.et', '.xls', '.xlsx', '.xlsm', '.dbf', '.csv', '.pm', '.dif',                                     // Related to EXCEL
    '.dps', '.ppt', '.pps', '.pptx', '.pptm', '.ppsx','.ppsm',                                          // Related to PowerPOINT
    '.js', '.jsx'                                                                                       // Related to code
]; 

module.exports={ VIDEO, AUDIO, IMAGE, DOCUMENT }                                     